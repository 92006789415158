/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState, useEffect } from 'react'
import { useFormik } from 'formik'

import { IGridData, IGridHeader } from '@components/UI/DataGrid'
import { useAuthMethods } from '@store/auth'
import { useOperationManagementReportByCustomer } from '@hooks/operationManagement/useOperationManagementReport'
import Typography from '@components/UI/Typography'
import { ProfileTypes } from '@resources/types/profileTypes'
import { FieldSelectOptionsProps } from '@components/UI/FieldSelect/types'
import {
  useGetTableListByCustomer,
  useGetTableByOperator,
  useGetTableDetail,
} from '@hooks/tables/useTables'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { formatStringDate } from '@resources/utils/forDate'

import { theme } from '@resources/theme'
// import { SelectType } from './types'

const header: IGridHeader[] = [
  {
    label: 'Data',
    grid: 3,
    action: null,
    value: 'date',
    field: 'values',
    styles: {
      align: 'center',
      fontType: undefined,
      fontSize: 14,
    },
  },
  {
    label: 'ID',
    grid: 1,
    action: null,
    value: 'idOperation',
    field: 'values',
    styles: {
      align: 'center',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Cliente',
    grid: 5,
    action: null,
    value: 'customerOperationName',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Horario',
    grid: 3,
    action: null,
    value: 'time',
    field: 'values',
    styles: {
      align: 'center',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Operação',
    grid: 3,
    action: null,
    value: 'typeOperation',
    field: 'values',
    styles: {
      align: 'center',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Moeda Extrangeira',
    grid: 4,
    action: null,
    value: 'dealtAmout',
    field: 'values',
    styles: {
      align: 'right',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Taxa Negociada',
    grid: 3,
    action: null,
    value: 'allInRate',
    field: 'values',
    styles: {
      align: 'center',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Valor Reais',
    grid: 4.5,
    action: null,
    value: 'contraAmout',
    field: 'values',
    styles: {
      align: 'right',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Operador',
    grid: 5,
    action: null,
    value: 'operatorName',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Mesa',
    grid: 4,
    action: null,
    value: 'tableName',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Liquidação',
    grid: 2,
    action: null,
    value: 'executionDate',
    field: 'values',
    styles: {
      align: 'center',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Comentário',
    grid: 5,
    action: null,
    value: 'privateNote',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 14,
    },
  },
]

export const useOperationalManagementReport = () => {
  const { profile } = useAuthMethods()

  const isOperator = profile?.profile === ProfileTypes.operator

  const [dataOperator, setDataOperator] = useState<FieldSelectOptionsProps[]>(
    [],
  )
  const fetchGetTableList = useGetTableListByCustomer(
    !isOperator ? profile?.parentCustomer?.uuid : '',
  )

  const fetchGetTableListByOperator = useGetTableByOperator(
    isOperator ? profile?.uuid : '',
  )

  const handleSubmitFilter = (form: any) => {
    console.log('handleSubmitFilter', { form })
  }

  const formik = useFormik({
    initialValues: {
      initialDate: new Date().setDate(new Date().getDate()),
      finalDate: new Date().setDate(new Date().getDate()),
      exchangeTable: '',
      proxy: '',
    },
    onSubmit: handleSubmitFilter,
  })

  const { data: tableDetail, isLoading: dataOperatorLoading } =
    useGetTableDetail(String(formik.values.exchangeTable))

  const { isError, isLoading, data, refetch, isRefetching } =
    useOperationManagementReportByCustomer(profile.parentCustomer.uuid, {
      proxy: isOperator
        ? profile.uuid
        : formik.values.proxy
        ? formik.values.proxy
        : null,
      exchangeTable: formik.values.exchangeTable
        ? formik.values.exchangeTable
        : null,
      direction: null,
      createdAtFrom: dayjs(formik.values.initialDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD',
      ),
      createdAtTo: dayjs(formik.values.finalDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD',
      ),
    })

  const formatCurrencyWithouSimbol = (value) => {
    if (!value) return '0.00'

    return value
      .toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
      .replace('R$', '')
  }

  const formatReal = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  const formatDolar = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }

  const customField = (value, type) => {
    let color = theme.palette.secondary.dark

    if (type === 'SELL') {
      color = theme.palette.error.light
    }

    return (
      <Typography size={13} color={color}>
        {value}
      </Typography>
    )
  }

  const handleData = (): IGridData[] | [] => {
    if (!data || !data.data.length) return []

    const prepareData = data.data.map((item) => {
      const type = item.direction.toUpperCase()

      return {
        id: item.id.toString(),
        values: {
          date: customField(
            formatStringDate(item.createdAt, 'DD/MM/YYYY'),
            type,
          ),
          idOperation: customField(item.id, type),
          customerOperationName: customField(item.operationCustomer.name, type),
          time: customField(formatStringDate(item.createdAt, 'HH:ss A'), type),
          typeOperation: customField(`${type}/${item.dealtCcy}`, type),
          dealtAmout: customField(formatDolar(item.dealtAmount), type),
          allInRate: customField(item.allInRate, type),
          contraAmout: customField(formatReal(item.contraAmount), type),
          operatorName: customField(item.proxy.name, type),
          tableName: customField(item.exchangeTable.name, type),
          executionDate: customField(
            formatStringDate(item.executionDate, 'DD/MM/YYYY'),
            type,
          ),
          privateNote: item.privateNote,
        },
        // formatCurrencyWithouSimbol(item.customerOperationName),
      }
    })

    return prepareData
  }

  const handleTable = useCallback(() => {
    const isLoading =
      fetchGetTableList.isLoading || fetchGetTableListByOperator.isLoading

    const prepareData: any = [
      {
        value: '',
        label: isLoading ? 'Carregando...' : 'Selecione a mesa',
      },
    ]

    if (fetchGetTableListByOperator.data) {
      fetchGetTableListByOperator.data.forEach((item) => {
        prepareData.push({
          value: item?.exchangeTable?.uuid,
          label: item?.exchangeTable?.name,
        })
      })
    }

    if (fetchGetTableList.data) {
      fetchGetTableList.data.map((item) => {
        prepareData.push({
          value: item.uuid,
          label: item.name,
        })
      })
    }

    return prepareData
  }, [
    fetchGetTableList.data,
    fetchGetTableList.isLoading,
    fetchGetTableListByOperator.isLoading,
    fetchGetTableListByOperator.data,
  ])

  const getDataOperatorByTable = useCallback((operatorsTable) => {
    if (!operatorsTable?.length) return

    const preparedDataOperators: FieldSelectOptionsProps[] = [
      { value: '', label: 'Todos os operadores' },
    ]

    operatorsTable.forEach((item) => {
      preparedDataOperators.push({
        value: item?.operator?.uuid,
        label: item?.operator?.name,
      })
    })

    setDataOperator(preparedDataOperators)
  }, [])

  useEffect(() => {
    if (!tableDetail) return

    getDataOperatorByTable(tableDetail.exchangeTableOperations)
  }, [tableDetail, getDataOperatorByTable])

  return {
    isError,
    isLoading: isRefetching || isLoading,
    header,
    handleData,
    refetch,
    dataConsolidated: data?.consolidated,
    formatCurrencyWithouSimbol,
    formatDolar,
    formatReal,
    tableListOptions: handleTable(),
    formik,
    dataOperatorLoading,
    dataOperator,
    getDataEOperatorByTable: getDataOperatorByTable,
    isOperator,
    data: !data || !data.data.length ? [] : data.data,
    // setTableId,
  }
}
