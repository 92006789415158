import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import {
  ITable,
  IAddTableRequest,
  IaddOperationBytableRequest,
  IDeleteTableRequest,
  ITableOperationByOperator,
  ITableDetail,
} from '@resources/types/services/tables'
import { translateUrlParam } from '@resources/utils/forString'
import { HttpCodes } from '@resources/types/httpCode'

export const TablesService = {
  getTableList: async (): Promise<ITable[]> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.get(endpoints.URL.tables.list)

    return response.data
  },

  getTableListByCustomer: async (customerId: string): Promise<ITable[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.tables.listByCustomer, {
      customerId,
    })

    const response: AxiosResponse = await api.get(url)

    return response.data
  },

  getTableDetail: async (uuid: string): Promise<ITableDetail> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.tables.detail, {
      uuid,
    })

    const response: AxiosResponse = await api.get(url)

    return response.data
  },

  addTable: async (data: IAddTableRequest): Promise<ITable> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.tables.addTable,
      data,
    )

    return response.data
  },

  deleteTable: async (data: IDeleteTableRequest): Promise<boolean> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.tables.delete, {
      id: data.id,
    })

    const response: AxiosResponse = await api.delete(url)

    return response.status === HttpCodes.SUCCESS
  },

  addOperationBytable: async (
    data: IaddOperationBytableRequest,
  ): Promise<boolean> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.tables.addOperationBytable,
      data,
    )

    return response.status === HttpCodes.SUCCESS
  },

  removeOperatorFromTable: async (uuid: string): Promise<boolean> => {
    const api = getApiInstance()

    const url = translateUrlParam(
      endpoints.URL.tables.removeOperatorFromTable,
      {
        uuid,
      },
    )

    const response: AxiosResponse = await api.delete(url)

    return response.status === HttpCodes.SUCCESS
  },

  getTableByOperator: async (
    uuid: string,
  ): Promise<ITableOperationByOperator[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.tables.tableByOperator, {
      operatorId: uuid,
    })

    const response: AxiosResponse = await api.get(url)

    return response.data
  },
}
