import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const SectionMain = styled.div<DefaultProps>`
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OrganizeHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
`

export const WrapperField = styled.div<{ widthFull?: boolean }>`
  width: ${({ widthFull }) => (widthFull ? '100%' : '300px')};
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90px;
  height: 20px;

  cursor: pointer;
`

export const WrapperIcone = styled.div`
  width: 20px;
  height: 15px;
  margin: 0 5px 0 0;
`
