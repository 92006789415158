import Separator from '@components/UI/Separator'

import Products from './Products'
import Publicity from './Publicity'
import About from './About'

import * as SC from './styles'
import { useHome } from './useHome'

const Home = () => {
  useHome()

  return (
    <SC.Container>
      <Publicity />

      <div id="produtos" />
      <Separator verticalSize={180} />
      <Products />

      <div id="quem-somos" />
      <Separator verticalSize={100} displayLine />
      <About />
    </SC.Container>
  )
}

export default Home
