import { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import Dayjs from 'dayjs'

import { theme } from '@resources/theme'
import Typography, { FontType } from '@components/UI/Typography'
import Icon from '@components/UI/Icon'
import Separator from '@components/UI/Separator'

import * as SC from './styles'
import { IProps } from './types'

import ptBR from 'date-fns/locale/pt-BR'

import 'react-datepicker/dist/react-datepicker.css'

export const FieldDatePicker = ({
  inputWidth = 100,
  inputHeight = 48,
  widthFull = false,
  defaultDate = undefined,
  minDate = null,
  maxDate = null,

  onChange,
  label,

  secondary = false,
  tertiary = false,
  disabled = false,
}: IProps) => {
  registerLocale('pt-BR', ptBR)

  const [date, setDate] = useState(defaultDate)
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (e) => {
    setIsOpen(!isOpen)
    setDate(e[0])
    onChange(Dayjs(e[0]).format('DD/MM/YYYY'))
  }

  const handleClick = (e) => {
    if (disabled) return

    setIsOpen(!isOpen)
    e.preventDefault()
  }

  const themes = {
    primary: {
      active: {
        background: theme.palette.primary.light,
        color: theme.palette.neutral.regular,
        placeholder: theme.palette.neutral.regular,
      },
      disabled: {
        background: theme.palette.neutral.regular,
        color: theme.palette.primary.light,
        placeholder: '',
      },
    },
    secondary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
    tertiary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
  }

  const themeActive =
    themes[(secondary && 'secondary') || (tertiary && 'tertiary') || 'primary']

  return (
    <SC.Container>
      {!!label && (
        <SC.TextAlignLeft>
          {label}
          <Separator verticalSize={3} />
        </SC.TextAlignLeft>
      )}

      <SC.WrapperDate
        textColor={
          disabled ? themeActive.disabled.color : themeActive.active.color
        }
        backgroundColor={
          disabled
            ? themeActive.disabled.background
            : themeActive.active.background
        }
        placeholderColor={
          disabled
            ? themeActive.disabled.placeholder
            : themeActive.active.placeholder
        }
        onClick={handleClick}
        inputWidth={inputWidth}
        widthFull={widthFull}
        inputHeight={inputHeight}
      >
        <Typography
          fontType={FontType.regular}
          color={theme.palette.secondary.regular}
          size={14}
        >
          {date ? Dayjs(date).format('DD/MM/YYYY') : 'dd/mm/aaaa'}
        </Typography>
      </SC.WrapperDate>
      <SC.WrapperIcon>
        <Icon name="FaCalendarDay" />
      </SC.WrapperIcon>

      {isOpen && (
        <SC.WrapperDatePicker>
          <DatePicker
            closeOnScroll={true}
            selectsRange={true}
            selected={date}
            onChange={handleChange}
            inline
            dateFormat="dd/mm/yyyy"
            locale="pt-BR"
            minDate={minDate}
            maxDate={maxDate}
          />
        </SC.WrapperDatePicker>
      )}
    </SC.Container>
  )
}

export default FieldDatePicker
