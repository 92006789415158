import styled from '@emotion/styled'
import { Link as LinkRouter } from 'react-router-dom'

import { DefaultProps } from '@resources/types'

export const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 500px;
`
export const Content = styled.div<DefaultProps>`
  width: 920px;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ul {
    margin-top: 30px;
    margin-left: 20px;

    li {
      height: 20px;
      text-align: left;
      font-size: 15px;
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`

export const Title = styled.p<DefaultProps>`
  font-weight: 900px;
  font-size: 180px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const Description = styled.p<DefaultProps>`
  font-size: 30px;
  color: ${({ theme }) => theme.palette.primary.light};
  margin-bottom: 60px;
`

export const TextCommon = styled.p<DefaultProps>`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const Link = styled(LinkRouter)<DefaultProps>`
  margin-top: 30px;
  color: ${({ theme }) => theme.palette.primary.light};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.palette.primary.light};
  cursor: pointer;
`
export const Footer = styled.footer<DefaultProps>`
  display: flex;
  justify-content: center;
  background: transparent;

  p {
    color: ${({ theme }) => theme.palette.primary.light};
    margin-top: 10px;
  }
`
