export enum typeIcons {
  SVG = 'svg',
  PNG = 'png',
  RA = 'RA',
}

export interface IViewProps {
  element: any
  dimensions: IDimensionsProps
  type: string
  onCallback?: () => void | null
  alt: string
  title: string
  id: string
}

export interface IProps {
  name: string
  id?: string
  sizes?: string[]
  color?: string
  width?: number
  height?: number
  onCallback?: () => void | null
  alt?: string
  title?: string
}

export interface IStyleProps {
  height: number
  width: number
}

export interface IDimensionsProps {
  width: number | string
  height: number | string
}
