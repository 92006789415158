import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'operationalManagement', {
    title: 'PAINEL DE NEGOCIAÇÃO',
    tradableCurrencies: {
      title: 'Moedas comercializáveis',
    },
    exoticCurrencies: {
      title: 'Moedas exóticas',
    },
  })
}
