import React from 'react'

import { t } from '@resources/translation'

import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import Button from '@components/UI/Button'

import Modal from '@components/Modal'
import { cpfMask, cellPhoneMask } from '@resources/helpers/utils'
import Typography, { FontType } from '@components/UI/Typography'
import FieldSelect from '@components/UI/FieldSelect'

import { useSignUpAccess } from './useSignUpAccess'
import { IProps } from './types'
import * as SC from './styles'

const SignUp = ({
  showModal,
  handleModal,
  parentId,
  parentName = '',
  isMaster = false,
}: IProps) => {
  const { formik, createRoleList, isLoading } = useSignUpAccess({
    handleModal,
    parentId,
    isMaster,
  })

  return (
    <Modal
      display={showModal}
      onClose={handleModal}
      title={
        <>
          <Typography
            fontType={FontType.bold}
            color={theme.palette.primary.lightest}
            size={30}
          >
            {t('signUp.title')}
            <br />
          </Typography>
          <Typography
            fontType={FontType.bold}
            color={theme.palette.primary.lightest}
            size={18}
          >
            {`${parentName ? `- ${parentName}` : ''}`}
          </Typography>
        </>
      }
      width={950}
      height={isMaster ? 500 : 580}
    >
      <SC.WrapperContent>
        <form onSubmit={formik.handleSubmit}>
          <Field
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            widthFull
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('signUp.input.name.label')}
              </Typography>
            }
            placeholder={t('signUp.input.name.placeholder')}
          />

          <Separator verticalSize={15} />

          <Field
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            widthFull
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('signUp.input.email.label')}
              </Typography>
            }
            placeholder={t('signUp.input.email.placeholder')}
          />

          <Separator verticalSize={15} />

          <SC.WrapperFormContent>
            <Field
              id="cpf"
              name="cpf"
              onChange={(e) => {
                formik.setFieldValue('cpf', cpfMask(e.target.value || ''))
              }}
              value={formik.values.cpf}
              inputWidth={384}
              maxLength={100}
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('signUp.input.id.label')}
                </Typography>
              }
              placeholder={t('signUp.input.id.placeholder')}
            />

            <Separator horizontalSize={16} />
            <Separator verticalSize={15} />

            <Field
              id="phone"
              name="phone"
              onChange={(e) => {
                formik.setFieldValue(
                  'phone',
                  cellPhoneMask(e.target.value || ''),
                )
              }}
              value={formik.values.phone}
              inputWidth={384}
              maxLength={100}
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('signUp.input.phone.label')}
                </Typography>
              }
              placeholder={t('signUp.input.phone.placeholder')}
            />
          </SC.WrapperFormContent>

          {!isMaster && (
            <>
              <Separator verticalSize={15} />

              <FieldSelect
                label={
                  <Typography
                    fontType={FontType.medium}
                    color={theme.palette.neutral.regular}
                    size={14}
                  >
                    PERFIL
                  </Typography>
                }
                name="profile"
                id="profile"
                options={createRoleList}
                selected={formik.values.profile}
                handleChange={(value) => {
                  formik.setFieldValue('profile', value)
                }}
                placeholder="Selecione o perfil"
              />
            </>
          )}
          <Separator verticalSize={50} />

          <Button
            type="submit"
            width={384}
            height={38}
            loading={isLoading}
            disabled={!formik.isValid || !formik.dirty}
          >
            {t('signUp.buttonCreateAccount')}
          </Button>
        </form>
      </SC.WrapperContent>
    </Modal>
  )
}

export default SignUp
