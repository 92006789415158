import { createElement, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'

import View from './view'
import { useForgotPassword } from '@hooks/auth/useAuth'
import { IFormikValues, IViewProps } from './types'
import PathRoutes from '@route/PathRoutes'
import { forgotSchema } from './yup/forgotSchema'
import { useFormik } from 'formik'

const ForgotPasswordContainer = () => {
  const alert = useAlert()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')

  const { isError, isSuccess, isLoading } = useForgotPassword(email)

  useEffect(() => {
    if (isSuccess) {
      alert.success(
        'Solicitação para alterar senha enviado. Verifique seu email.',
      )

      navigate(PathRoutes.backoffice.signIn.url)
      return
    }

    if (isError) {
      alert.error('Não foi possível enviar o link. Tente novamente.')
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError])

  const handleForgotPassword = ({ email }: IFormikValues) => {
    setEmail(email)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotSchema,
    onSubmit: handleForgotPassword,
  })

  const viewProps: IViewProps = {
    formik,
    isLoading,
  }

  return createElement(View, viewProps)
}

export default ForgotPasswordContainer
