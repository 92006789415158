import { useMutation, UseMutationResult } from 'react-query'

import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'
import { SendEmailService } from '@services/sendEmail'
import { ISendEmailCustomProspectingResquet } from '@resources/types/services/sendEmail'

export const useSendEmailCustomProspecting = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  string,
  any,
  ISendEmailCustomProspectingResquet,
  unknown
> => {
  return useMutation(SendEmailService.customerProspecting, options)
}
