import * as Yup from 'yup'

export const createTableSchema = Yup.object().shape({
  name: Yup.string()
    .test('isValidName', 'Digite o nome da tabela', (val) => {
      return String(val).length > 6
    })
    .required('Digite o nome da tabela'),

  description: Yup.string().required('Required'),

  paymaster: Yup.string().required('Selecione um tesoureiro'),
})
