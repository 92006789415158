export const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export const cpfMask = (value): string => {
  const result = value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
    .toString()

  return result
}

export const cpfCnpjMask = (value: string) => {
  let result = value.replace(/\D/g, '')

  if (result.length <= 11) {
    result = result.replace(/(\d{3})(\d)/, '$1.$2')
    result = result.replace(/(\d{3})(\d)/, '$1.$2')
    result = result.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    result = result.replace(/^(\d{2})(\d)/, '$1.$2')
    result = result.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    result = result.replace(/\.(\d{3})(\d)/, '.$1/$2')
    result = result.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return result
}

export const cellPhoneMask = (value): string => {
  let result = value.replace(/\D/g, '')
  result = result.replace(/^0/, '')

  if (result.length > 11) {
    result = result.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  } else if (result.length > 7) {
    result = result.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3')
  } else if (result.length > 2) {
    result = result.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else if (value.trim() !== '') {
    result = result.replace(/^(\d*)/, '($1')
  }

  return result
}

export const phoneMask = (value): string => {
  let result = value.replace(/\D/g, '')
  result = result.replace(/^0/, '')

  if (result.length > 10) {
    result = result.replace(/^(\d\d)(\d{4})(\d{4}).*/, '($1) $2-$3')
  } else if (result.length > 6) {
    result = result.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
  } else if (result.length > 2) {
    result = result.replace(/^(\d\d)(\d{0,4})/, '($1) $2')
  } else if (value.trim() !== '') {
    result = result.replace(/^(\d*)/, '($1')
  }

  return result
}

export const cepMask = (value): string => {
  let result = value.replace(/\D/g, '')

  if (result.length > 5) {
    result = result.replace(/^(\d{5})(\d{0,3}).*/, '$1-$2')
  } else if (value.trim() !== '') {
    result = result.replace(/^(\d*)/, '$1')
  }

  return result
}
