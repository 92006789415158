import { createElement, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import PathRoutes from '@route/PathRoutes'

import View from './view'
import { IViewProps } from './types'
import { IAccessResponse } from '@resources/types/services/accessManagement'
import { useAuthMethods } from '@store/auth'

const MenuContainer = () => {
  const { profile } = useAuthMethods()

  const navigate = useNavigate()
  const [userData, setUserData] = useState<IAccessResponse | null>(null)

  const handleUpdateModal = (open?: boolean) => {
    open ? setUserData(profile) : setUserData(null)
  }

  const logout = () => {
    navigate(PathRoutes.backoffice.signOut.url, { replace: true })
  }

  const viewProps: IViewProps = {
    logout,
    handleUpdateModal,
    userData,
  }

  return createElement(View, viewProps)
}
export default MenuContainer
