import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { TranslationsManager } from './managers/translations-manager'

export class Translation {
  public static initialize(
    translationsManager: typeof TranslationsManager,
  ): void {
    i18n.use(initReactI18next).init({
      resources: {
        pt: {
          translation: translationsManager.generateTranslations('ptBr'),
        },
        en: {
          translation: translationsManager.generateTranslations('en'),
        },
      },
      lng: 'pt',
      fallbackLng: 'pt',
      interpolation: {
        escapeValue: false,
        defaultVariables: translationsManager.getVariables(),
      },
    })
  }
}
