import * as Yup from 'yup'

export const signUpSchema = Yup.object().shape({
  name: Yup.string().when('cpfCnpj', {
    is: (val) => String(val).length === 14,
    then: Yup.string()
      .test(
        'isValidName',
        'Digite seu nome completo',
        (val) => String(val).length > 5,
      )
      .required('Digite seu nome completo'),
    otherwise: Yup.string(),
  }),

  cpfCnpj: Yup.string()
    .test('isValidCPF', 'Digite um CPF/CNPJ válido', (val) => {
      return String(val).length === 14 || String(val).length === 18
    })
    .required('Required'),

  cellPhone: Yup.string()
    .test('isValidPhone', 'Digite um número de celular válido', (val) => {
      return String(val).length === 15
    })
    .required('Required'),

  phone: Yup.string()
    .test('isValidPhone', 'Digite um número de telefone válido', (val) => {
      return String(val).length === 14
    })
    .required('Required'),

  email: Yup.string().email('Digite um email válido').required('Required'),

  state: Yup.string().required('Selecione um estado'),

  city: Yup.string().required('Selecione uma cidade'),

  corporateName: Yup.string().when('cpfCnpj', {
    is: (val) => String(val).length === 18,
    then: Yup.string()
      .test('Digite razão social', (val) => String(val).length > 5)
      .required('Digite razão social'),
    otherwise: Yup.string(),
  }),

  tradingName: Yup.string().when('cpfCnpj', {
    is: (val) => String(val).length === 18,
    then: Yup.string()
      .test(
        'isValidName',
        'Digite o nome fantasia',
        (val) => String(val).length > 5,
      )
      .required('Digite o nome fantasia'),
    otherwise: Yup.string(),
  }),

  address: Yup.string().required('Digite endereço'),

  zipCode: Yup.string()
    .test('isValidCep', 'Digite CEP', (val) => {
      return String(val).length === 9
    })
    .required('Required'),

  negotiationPanel: Yup.string().required('Você deve selecionar uma opção'),

  strategyMonitor: Yup.string().required('Você deve selecionar uma opção'),
})
