import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useAlert } from 'react-alert'

import { IGridData, IGridHeader } from '@components/UI/DataGrid'
import Icon from '@components/UI/Icon'
import {
  useDeleteTable,
  useGetTableListByCustomer,
} from '@hooks/tables/useTables'
import { useAuthMethods } from '@store/auth'
import { ProfileTypes } from '@resources/types/profileTypes'

const header: IGridHeader[] = [
  {
    label: 'Nome',
    grid: 3.5,
    action: null,
    value: 'name',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 14,
    },
  },
  {
    label: 'Tesoureiro',
    grid: 4.5,
    action: null,
    value: 'paymaster',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Operadores',
    grid: 4.5,
    action: null,
    value: 'operators',
    field: 'values',
    styles: {
      align: 'center',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: '',
    grid: 0.5,
    action: null,
    value: 'icon1',
    field: 'element',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
  {
    label: '',
    grid: 0.5,
    action: null,
    value: 'icon2',
    field: 'element',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
]

export const useTables = () => {
  const alert = useAlert()
  const queryClient = useQueryClient()

  const [tableId, setTableId] = useState<string | undefined>(undefined)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showOperatorModal, setShowOperatorModal] = useState(false)
  const { mutate, isLoading: deleteTableIsLoading } = useDeleteTable()

  const { profile } = useAuthMethods()
  const fetchGetTableList = useGetTableListByCustomer(
    profile.parentCustomer.uuid,
  )

  const [tableDeleteId, setTableDeleteId] = useState<string>('')

  const handleViewOperators = (tableId?: string) => {
    if (showOperatorModal) {
      fetchGetTableList.refetch()
    }

    setTableId(tableId)
    setShowOperatorModal(!showOperatorModal)
  }

  const handleCreateModal = () => {
    if (showCreateModal) {
      fetchGetTableList.refetch()
    }

    setShowCreateModal(!showCreateModal)
  }

  const onSuccessDeleteTable = (): void => {
    alert.success('Mesa excluída com sucesso!')

    queryClient.invalidateQueries([
      ['useGetTableListByCustomer', profile.parentCustomer.uuid],
    ])

    fetchGetTableList.refetch()
  }
  const onErrorDeleteTable = (): void => {
    alert.error('Houve uma erro ao deletear a mesa.')
  }

  const fetchDeleteModal = () => {
    const deleteModalData = { id: tableDeleteId }
    mutate(deleteModalData, {
      onSuccess: onSuccessDeleteTable,
      onError: onErrorDeleteTable,
    })

    setShowDeleteModal(!showDeleteModal)
  }

  const handleDeleteModal = (id = '') => {
    setTableDeleteId(id)
    setShowDeleteModal(!showDeleteModal)
  }

  const handleData = (): IGridData[] | [] => {
    if (!fetchGetTableList.data || !fetchGetTableList.data.length) return []

    const dataTable: IGridData[] = []

    fetchGetTableList.data.forEach((table) => {
      const prepareData = {
        id: table.uuid.toString(),
        icon1:
          profile.profile === ProfileTypes.admin ? (
            <Icon
              name={'FaUserTie'}
              onCallback={() => handleViewOperators(table.uuid)}
            />
          ) : null,

        icon2:
          profile.profile === ProfileTypes.admin ? (
            <Icon
              name={'FaTrash'}
              onCallback={() => handleDeleteModal(table.uuid.toString())}
            />
          ) : null,
        values: {
          name: table.name,
          paymaster: table.paymaster,
          operators: table.operators,
        },
      }

      dataTable.push(prepareData)
    })

    return dataTable
  }

  return {
    isLoading: fetchGetTableList.isLoading || deleteTableIsLoading,
    header,
    handleData,
    handleCreateModal,
    showCreateModal,
    handleDeleteModal,
    showDeleteModal,
    fetchDeleteModal,
    showOperatorModal,
    handleViewOperators,
    tableId,
    enableButton: profile.profile === ProfileTypes.admin,
  }
}
