const apiOneForge = 'https://api.1forge.com'

export const endpoints = {
  URL: {
    auth: {
      profile: 'auth/profile',
      signIn: 'auth/login',
    },
    user: {
      forgot: 'users/forgot-password/{{email}}',
      reset: 'users/reset/{{userid}}',
      confirm: 'users/confirm/{{userid}}',
      allByCustomer: 'users/by-customer/{{customerId}}',
      delete: 'users/{{uuid}}',
    },
    signal: {
      list: 'signals',
      listByCategory: 'signals/all-by-category-strategy/{{category}}',
    },
    accessManagement: {
      update: 'users/{{userid}}',
      access: 'users',
      signUp: 'users',
    },
    customer: {
      signUp: 'customers',
      list: 'customers',
      update: 'customers/{{customerId}}',
      usersByCustomer: 'users/user-by-customer/{{userid}}',
    },
    customerOperation: {
      getByDocument:
        'operation-customers/by-document-customer/{{document}}/{{customerId}}',
      getByName: 'operation-customers/by-name-customer/{{name}}/{{customerId}}',
      addByCustomer: 'operation-customers',
    },
    operationManagement: {
      add: 'operation-managements',
      list: {
        consolidated:
          'operation-managements/consolidated-report/{{customerId}}',
        customer: 'operation-managements/by-customer/{{customerId}}',
      },
      perfomeReport: 'operation-managements/perfomance-report/{{customerId}}',
    },
    operationMatching: {
      create: 'operation-matching',
      getOpenedByCustomer: 'operation-matching/opened',
    },
    perfomanceReportFee: {
      addOrUpdate: 'performance-report-fee',
      byCustomer: 'performance-report-fee/by-customer/{{customerId}}',
    },
    sendEmail: {
      customerProspecting: 'send-email/customer-prospecting',
    },
    quotes: {
      quotesByPairs: `${apiOneForge}/quotes?pairs={{pairs}}&api_key={{apiKey}}`,
      symbolsList: `${apiOneForge}/symbols?api_key={{apiKey}}`,
      convert: `${apiOneForge}/convert?from={{pairFrom}}&to={{pairTo}}&quantity=100&api_key={{apiKey}}`,
      marketStatus: `${apiOneForge}/market_status?api_key={{apiKey}}`,
      quota: `${apiOneForge}/quota?api_key={{apiKey}}`,
    },
    tables: {
      list: 'exchange-tables',
      listByCustomer: 'exchange-tables/by-customer/{{customerId}}',
      addOperationBytable: 'exchange-table-operations',
      removeOperatorFromTable: 'exchange-table-operations/{{uuid}}',
      tableByOperator: 'exchange-table-operations/by-operator/{{operatorId}}',
      addTable: 'exchange-tables',
      delete: 'exchange-tables/{{id}}',
      detail: 'exchange-tables/{{uuid}}',
    },
    sentiment: {
      list: 'sentiments',
    },
    coverageRates: {
      createAllRatesByCustomer: 'coverage-rates/by-customer/all-rates',
      getRatesByCustomer: 'coverage-rates/by-customer/{{customerId}}',
      updateById: 'coverage-rates/{{id}}',
    },
    marketOpening: {
      createByCustomer: 'market-opening',
      getByCustomer: 'market-opening/by-customer/{{customerId}}',
      update: 'market-opening/{{uuid}}',
    },
    pivotPoint: {
      withTimes: 'pivot-points/with-times?symbol={{symbol}}',
    },
  },
}
