import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import { useAccessUpdate } from '@hooks/accessManagement/useAccessManagement'
import { cellPhoneMask, cpfMask } from '@resources/helpers/utils'
import { UserProfileRule } from '@pages/Backoffice/SignUpAccess/types'

import { updateSchema } from './yup/updateSchema'
import { IFormikValues, IHookProps } from './types'

export const useUpdateAccess = ({ handleModal, data }: IHookProps) => {
  const alert = useAlert()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useAccessUpdate()

  const createRoleList = Object.values(UserProfileRule).map((item) => {
    switch (item) {
      case UserProfileRule.PROFILE_OPERATOR:
        return { value: item, label: 'Operador' }

      case UserProfileRule.PROFILE_PAYMASTER:
        return { value: item, label: 'Tesoureiro' }

      default:
        return { value: item, label: 'Admin' }
    }
  })

  const onSuccess = (): void => {
    queryClient.invalidateQueries(['useAccessManagement', 'allUserByCustomer'])
    alert.success('Dados alterados com sucesso')

    handleModal()
  }

  const onError = (): void => {
    alert.error('Ocorreu um erro inesperado. Tente novamente.')
  }

  const handleAuthenticate = (prop: IFormikValues) => {
    const dataUpdate = {
      data: {
        profile: prop.profile,
        name: prop.name,
        cpf: prop.cpf,
        cellphone: prop.cellphone,
        email: prop.email,
        password: '123456789',
      },
      userid: String(data?.uuid),
    }

    mutate(dataUpdate, { onSuccess, onError })
  }

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      cpf: cpfMask(data?.cpf.toString() || ''),
      cellphone: cellPhoneMask(data?.cellphone || ''),
      email: data?.email || '',
      profile: data?.profile || '',
    },
    validationSchema: updateSchema,
    onSubmit: handleAuthenticate,
  })

  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    formik,
    createRoleList,
    isLoading,
  }
}
