import { useQuery, UseQueryResult } from 'react-query'

import { SentimentsService } from '@services/sentiments'

import { ISentiment } from '@resources/types/services/sentiments'

export const useGetSentimentsList = (): UseQueryResult<ISentiment[]> => {
  return useQuery(['useGetSentimentsList'], () => {
    return SentimentsService.getSentimentList()
  })
}
