import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'update', {
    title: 'CADASTRO DE USUÁRIO',
    input: {
      name: {
        label: 'Nome completo',
        placeholder: 'Insira seu nome completo',
      },
      id: {
        label: 'CPF',
        placeholder: 'Insira seu CPF',
      },
      phone: {
        label: 'Celular',
        placeholder: 'Insira seu celular com DDD',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Insira seu e-email',
      },
      password: {
        label: 'Senha',
        placeholder: 'Insira uma senha',
      },
      confirmPassword: {
        label: 'Confirme a senha',
        placeholder: 'Confirme sua senha',
      },
    },
    buttonCreateAccount: 'Atualizar',
  })
}
