import Icon from '@components/UI/Icon'

import { generateKey } from '@resources/utils/generateKey'
import Typography, { FontType } from '@components/UI/Typography'
import { ITheme, theme } from '@resources/theme'

import * as SC from './styles'
import { useMenu } from './useMenu'
import { IMenuByProfile } from '@route/menu'

const Menu = () => {
  const {
    activeItem,
    menuNavigate,
    handleAccordionMainMenu,
    activeMenu,
    menuAuthorized,
    firstRender,
  } = useMenu()

  const renderMenuItems = (
    items: any[],
    activeItem: any,
    menuNavigate: (item: IMenuByProfile, e?: any) => void,
    theme: ITheme,
    parentIndex = '',
  ) => {
    return items.map((menuItem, index) => {
      const currentIndex = `${parentIndex}-${index}`
      return (
        <SC.MenuItem
          key={generateKey()}
          active={activeItem === menuItem.link}
          onClick={(e) => menuNavigate(menuItem, e)}
          menuItemsChildrenClassName={`menu-items-children_${menuItem.id}`}
        >
          <SC.MenuItemLabel key={`menuItemLabel-${currentIndex}`}>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.primary.light}
              size={12.5}
            >
              {menuItem.label}
            </Typography>
          </SC.MenuItemLabel>

          {!!menuItem.items?.length && (
            <SC.MenuItemsChildren
              className={`menu-items-children_${menuItem.id}`}
            >
              {renderMenuItems(
                menuItem.items,
                activeItem,
                menuNavigate,
                theme,
                currentIndex,
              )}
            </SC.MenuItemsChildren>
          )}
        </SC.MenuItem>
      )
    })
  }

  const renderMenu = (
    menuAuthorized: any[],
    activeMenu: string,
    activeItem: any,
    firstRender: boolean,
    theme: ITheme,
  ) => {
    return menuAuthorized.map((menu, index) => {
      if (!menu) return null

      return (
        <SC.AccordionWrapper key={menu.id}>
          <SC.AccordionTitle
            isActive={activeMenu === menu.id}
            onClick={(e) => {
              handleAccordionMainMenu(menu.id)
              if (!menu.items?.length) {
                menuNavigate(menu, e)
              }
            }}
            hasItems={!!menu.items?.length}
          >
            <SC.OrganizerHorizontal>
              <Icon name={menu.icon} />
              <Typography
                fontType={FontType.medium}
                color={theme.palette.primary.light}
                size={12.5}
              >
                {menu.label}
              </Typography>
            </SC.OrganizerHorizontal>
          </SC.AccordionTitle>

          {!!menu.items?.length && (
            <SC.AccordionContent
              open={activeMenu === menu.id}
              firstrender={firstRender}
            >
              {renderMenuItems(
                menu.items,
                activeItem,
                menuNavigate,
                theme,
                index.toString(),
              )}
            </SC.AccordionContent>
          )}
        </SC.AccordionWrapper>
      )
    })
  }

  return (
    <SC.Container>
      {renderMenu(menuAuthorized, activeMenu, activeItem, firstRender, theme)}
    </SC.Container>
  )
}

export default Menu
