import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const WrapperFlex = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`

export const WrapperFlexBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const CoinCard = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  padding: 20px;
  margin: 10px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.regular};
  border: 1px solid ${({ theme }) => theme.palette.neutral.dark};
`

export const WrapperCoinPosition = styled.div<DefaultProps>`
  width: 60px;
  height: 22px;
  text-align: center;
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.primary.darkest};
`

export const WrapperCoinValue = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 10px);
  height: 90px;
  border-radius: 6px;
  margin: 0 10px 0 0;
`

export const WrapperCircle = styled.div<
  { buy?: boolean; sel?: boolean } & DefaultProps
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  cursor: pointer;

  ${({ buy, theme }) => buy && `background: ${theme.palette.success.dark};`}
  ${({ sel, theme }) => sel && `background: ${theme.palette.error.regular};`}
`

export const WrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 20px;
  margin: 10px 0 0 0;
`

export const Disabled = styled.div<DefaultProps>`
  width: 210px;
  height: 210px;
  border-radius: 50%;
  background: #00000097;
  z-index: 9;
  position: absolute;
`
