import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import {
  IPerfomanceReportFeeAddOrUpdateRequest,
  IPerfomanceReportFee,
} from '@resources/types/services/performanceReportFee'

export const PerfomanceReportFeeService = {
  createOrUpdate: async (
    data: IPerfomanceReportFeeAddOrUpdateRequest,
  ): Promise<IPerfomanceReportFee> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.perfomanceReportFee.addOrUpdate,
      data,
    )

    return response.data
  },

  byCustomerList: async (
    customerId: string,
  ): Promise<Array<IPerfomanceReportFee>> => {
    const api = getApiInstance()

    const url = translateUrlParam(
      endpoints.URL.perfomanceReportFee.byCustomer,
      {
        customerId,
      },
    )

    const response: AxiosResponse = await api.get(url)

    return response.data
  },
}
