import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import { cpfCnpjMask } from '@resources/helpers/utils'
import Button from '@components/UI/Button'
import Icon from '@components/UI/Icon'
import Loading from '@components/Loading'
import { generateKey } from '@resources/utils/generateKey'

import { useCustomerOperationSearch } from './useCustomerOperationSearch'
import { IProps } from './types'
import * as SC from './styles'

const CustomerOperationSearch = ({
  selectCustomerOperation,
  setCurrentStep,
  handleCloseModal,
  documentSearch,
  setDocumentSearch,
}: IProps) => {
  const {
    handleSelectCustomerOperation,
    setFetchSearch,
    isLoading,
    data,
    isSuccess,
    isError,
  } = useCustomerOperationSearch({
    handleCloseModal,
    selectCustomerOperation,
    documentSearch,
    setDocumentSearch,
  })

  return (
    <SC.SectionMain>
      <SC.OrganizeHorizontal>
        <SC.WrapperField>
          <Field
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                BUSCAR POR NOME
              </Typography>
            }
            name="document"
            value={documentSearch}
            placeholder="Informe o nome para busca"
            onChange={(e) => {
              // setDocumentSearch(cpfCnpjMask(e.target.value || ''))
              setDocumentSearch(e.target.value || '')
            }}
            maxLength={18}
            widthFull
          />
        </SC.WrapperField>

        <Separator horizontalSize={10} />

        <SC.WrapperSearchButon>
          <Button
            tertiary
            onClick={() => setFetchSearch(true)}
            height={60}
            width={80}
            disabled={documentSearch.length < 3}
          >
            <Icon name={'FaSearch'} />
          </Button>
        </SC.WrapperSearchButon>
      </SC.OrganizeHorizontal>

      <Separator verticalSize={5} />

      <Typography
        fontType={FontType.regular}
        color={theme.palette.neutral.regular}
      >
        {!data && isError && 'Não existe cadastro com nome informado.'}
        {!data?.length &&
          isSuccess &&
          'Não existe cadastro com nome informado.'}
        {!!data?.length &&
          isSuccess &&
          'Registro(s) encontrado(s) com nome informado.'}
      </Typography>

      <SC.WrapperSearchResult>
        {isLoading && <Loading size={30} />}

        {data?.map((found) => (
          <SC.WrapperResult key={generateKey()}>
            <SC.TextResult>
              <Typography
                fontType={FontType.regular}
                color={theme.palette.primary.light}
                size={13}
              >
                {found.name}
              </Typography>

              <Typography
                fontType={FontType.regular}
                color={theme.palette.primary.darkest}
                size={13}
              >
                {cpfCnpjMask(found.document)}
              </Typography>
            </SC.TextResult>

            <SC.ButtonResult>
              <Icon
                name="FaArrowRight"
                onCallback={() => handleSelectCustomerOperation(found)}
              />
            </SC.ButtonResult>
          </SC.WrapperResult>
        ))}
      </SC.WrapperSearchResult>

      <Separator verticalSize={10} />

      <SC.Link onClick={() => setCurrentStep('add')}>CADASTRAR CLIENTE</SC.Link>
    </SC.SectionMain>
  )
}

export default CustomerOperationSearch
