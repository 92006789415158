import * as Yup from 'yup'

// const cpfMask = (value): void => {
//   const result = value
//     .replace(/\D/g, '')
//     .replace(/(\d{3})(\d)/, '$1.$2')
//     .replace(/(\d{3})(\d)/, '$1.$2')
//     .replace(/(\d{3})(\d{1,2})/, '$1-$2')
//     .replace(/(-\d{2})\d+?$/, '$1')
//     .toString()

//   return result
// }

export const signUpSchema = Yup.object().shape({
  name: Yup.string()
    .test('isValidName', 'Digite seu nome completo', (val) => {
      return String(val).length > 6
    })
    .required('Digite seu nome completo'),

  // cpf: Yup.string()
  //   .test('isValidCPF', 'Digite um CPF válido', (val) => {
  //     cpfMask(String(val))
  //     return String(val).length > 11
  //   })
  //   .required('Required'),

  // phone: Yup.string()
  //   .test('isValidPhone', 'Digite um número de celular válido', (val) => {
  //     return String(val).length > 11
  //   })
  //   .required('Required'),

  email: Yup.string().email('Digite um email válido').required('Required'),
})
