const snakeToCamel = (string: string) => {
  const splitStringArr = string.split('_')
  const builtStr = splitStringArr.reduce((acc, curr, i) => {
    curr = i !== 0 ? curr[0].toUpperCase() + curr.slice(1) : curr
    return acc + curr
  }, '')
  return builtStr
}

export const convertResponse = (response) => {
  const parentKeys = Object.keys(response)
  parentKeys.forEach((key) => {
    const currentObj = response[key]
    delete response[key]
    const newKey = snakeToCamel(key)
    response[newKey] = currentObj
    if (typeof response[newKey] === 'object') {
      convertResponse(currentObj)
    }
  })
  return response
}
