import { AxiosResponse } from 'axios'

import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { ISendEmailCustomProspectingResquet } from '@resources/types/services/sendEmail'

export const SendEmailService = {
  customerProspecting: async (
    data: ISendEmailCustomProspectingResquet,
  ): Promise<string> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.sendEmail.customerProspecting,
      data,
    )

    return response.data
  },
}
