/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
import Loading from '@components/Loading'
import Button from '@components/UI/Button'
import DataGrid from '@components/UI/DataGrid'
import Icon from '@components/UI/Icon'
import Typography, { FontType } from '@components/UI/Typography'

import SignUpAccess from '@pages/Backoffice/SignUpAccess'
import UpdateAccess from '@pages/Backoffice/UpdateAccess'
import { useUsersByCustomer } from './useUsersByCustomer'
import * as SC from './styles'
import ModalConfirmDelete from '@components/ModalConfirmDelete'
import { theme } from '@resources/theme'

const UsersByCustomer = () => {
  const {
    // isError, //TODO - handleErrorAfter
    isLoading,
    handleModal,
    showModal,
    header,
    handleData,
    userData,
    parentId,
    parentName,
    handleUpdateModal,
    goBack,
    showDeleteModal,
    handleDeleteModal,
    fetchDeleteModal,
    isLoadingDeleteUser,
  } = useUsersByCustomer()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>
          <>
            GESTÃO DE ACESSOS
            <br />
            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              lineHeight="2.5"
              size={20}
            >
              {parentName}
            </Typography>
          </>
        </PageTitle>

        <Button onClick={handleModal}>{t('customer.buttonAdd')}</Button>
      </SC.WrapperHeader>

      <Separator verticalSize={40} />

      {handleData && (
        <DataGrid header={header} data={handleData()} loading={isLoading} />
      )}

      {!isLoading && !handleData() && (
        <Typography fontType={FontType.medium} color={'white'} size={13}>
          Nenhum acesso cadastrado
        </Typography>
      )}

      <SC.WrapperBackButton onClick={goBack}>
        <Icon name={'FaArrowCircleLeft'} />
        <Separator horizontalSize={5} />

        <Typography fontType={FontType.medium} color={'white'} size={13}>
          Voltar
        </Typography>
      </SC.WrapperBackButton>

      {!!showModal && (
        <SignUpAccess
          showModal={showModal}
          handleModal={handleModal}
          parentId={parentId}
          parentName={parentName}
        />
      )}

      {!!userData && (
        <UpdateAccess
          showModal={!!userData}
          handleModal={handleUpdateModal}
          data={userData}
        />
      )}

      {!!showDeleteModal && (
        <ModalConfirmDelete
          showModal={showDeleteModal}
          onCloseModal={!isLoadingDeleteUser ? handleDeleteModal : () => ''}
          onReturnYes={fetchDeleteModal}
          onReturnNo={handleDeleteModal}
        />
      )}
    </SC.Container>
  )
}

export default UsersByCustomer
