import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'access', {
    title: 'GESTÃO DE USUÁRIOS',
    buttonAdd: 'Adicionar',
  })
}
