import Typography, { FontType } from '@components/UI/Typography'
import Separator from '@components/UI/Separator'
import Icon from '@components/UI/Icon'
import { theme } from '@resources/theme'

import {
  Container,
  Content,
  WrapperInformation,
  LinkOption,
  WrapperPrivacy,
  WrapperFlex,
} from './styles'

const Footer = () => {
  return (
    <Container>
      <Content>
        <WrapperInformation>
          <Icon name="logoWhite" width={80} height={60} />

          <Separator verticalSize={10} />

          <Typography
            fontType={FontType.regular}
            size={11}
            color={theme.palette.primary.lightest}
            lineHeight="15px"
          >
            © Exchange spot
          </Typography>

          <Separator verticalSize={10} />

          <WrapperFlex>
            <Typography
              fontType={FontType.regular}
              size={11}
              color={theme.palette.primary.lightest}
              lineHeight="15px"
            >
              contato@exchangespot.com.br
            </Typography>

            <Separator horizontalSize={15} />

            <Icon name="footerLinkedin" />
            <Separator horizontalSize={10} />
            <Icon name="footerYouTube" />
          </WrapperFlex>
        </WrapperInformation>

        <WrapperPrivacy>
          <LinkOption nav-item="link" to="" target="">
            Política de privacidade
          </LinkOption>

          <Separator horizontalSize={8} />

          <Typography fontType={FontType.bold} size={14}>
            |
          </Typography>

          <Separator horizontalSize={8} />

          <LinkOption nav-item="link" to="" target="">
            Termos e condições
          </LinkOption>
        </WrapperPrivacy>
      </Content>
    </Container>
  )
}

export default Footer
