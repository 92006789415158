import { useState } from 'react'

import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'

import Memo from './Memo'
import Chat from './Chat'
// import SplitDates from './SplitDates'
import * as SC from './styles'
import { IProps } from './types'

const Tabs = ({ formik }: IProps) => {
  const [tabCurrent, setTabCurrent] = useState<number>(0)
  const [currentDirection, setCurrentDirection] = useState<string>('right')

  const setupTabs = [
    {
      component: () => <Memo formik={formik} />,
    },
    {
      component: () => <Chat />,
    },
    // {
    //   component: () => <SplitDates formik={formik} />,
    // },
  ]

  const handleStep = (step: number) => {
    if (step > tabCurrent) {
      setCurrentDirection('right')
    } else {
      setCurrentDirection('left')
    }

    setTabCurrent(step)
  }

  return (
    <SC.Container>
      <SC.Header>
        <SC.Tab onClick={() => handleStep(0)}>
          <Typography
            fontType={FontType.medium}
            color={
              tabCurrent === 0
                ? theme.palette.success.dark
                : theme.palette.neutral.regular
            }
            size={15}
          >
            Memo
          </Typography>
        </SC.Tab>

        <SC.Tab onClick={() => handleStep(1)}>
          <Typography
            fontType={FontType.medium}
            color={
              tabCurrent === 1
                ? theme.palette.success.dark
                : theme.palette.neutral.regular
            }
            size={15}
          >
            Chat
          </Typography>
        </SC.Tab>

        {/* <SC.Tab onClick={() => handleStep(2)}>
          <Typography
            fontType={FontType.medium}
            color={
              tabCurrent === 2
                ? theme.palette.success.dark
                : theme.palette.neutral.regular
            }
            size={15}
          >
            Datas para operação
          </Typography>
        </SC.Tab> */}
      </SC.Header>

      <SC.Body>
        <SC.Content currentDirection={currentDirection}>
          {setupTabs[tabCurrent].component()}
        </SC.Content>
      </SC.Body>
    </SC.Container>
  )
}

export default Tabs
