import { useQuery } from 'react-query'
import { RssFeedService } from '@services/rss'

export const UseGetRssFeed = (url: string) => {
  return useQuery(
    ['UseGetRssFeed', url],
    () => {
      return RssFeedService.getRssFeed(url)
    },
    {
      enabled: !!url,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}
