export * from './auth'

export const clearStorage = async () => {
  return localStorage.clear()
}

export const removeStorage = async (key = 'exchange') => {
  return localStorage.removeItem(`persist:${key}`)
}

export const getStorage = async (key = 'exchange') => {
  return localStorage.getItem(`persist:${key}`)
}
