import { useQuery, useMutation, UseMutationResult } from 'react-query'

import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'
import { PerfomanceReportFeeService } from '@services/perfomanceReportFee'
import {
  IPerfomanceReportFeeAddOrUpdateRequest,
  IPerfomanceReportFee,
} from '@resources/types/services/performanceReportFee'

export const usePerfomanceReportFeeCreateOrUpdate = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  IPerfomanceReportFee,
  any,
  IPerfomanceReportFeeAddOrUpdateRequest,
  unknown
> => {
  return useMutation(PerfomanceReportFeeService.createOrUpdate, options)
}

export const usePerfomanceReportFeeByCustomer = (customerId: string) => {
  return useQuery<Array<IPerfomanceReportFee>, Error>(
    ['perfomanceReportFeeByCustomer', customerId],
    () => {
      return PerfomanceReportFeeService.byCustomerList(customerId)
    },
    {
      enabled: !!customerId,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
      refetchInterval: 0,
    },
  )
}
