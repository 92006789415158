/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import { ProfileTypes } from '@resources/types/profileTypes'
import { IGridData, IGridHeader } from '@components/UI/DataGrid/types'
import {
  useGetTableDetail,
  useRemoveOperatorFromTable,
  useSetupTableOperator,
} from '@hooks/tables/useTables'
// import { ITableOperationByOperator } from '@resources/types/services/tables'
import { useGetAllByCustomer } from '@hooks/user/useUser'
import { IUsersByCustomerResponse } from '@resources/types/services/user'
import Icon from '@components/UI/Icon'
import { FieldSelectOptionsProps } from '@components/UI/FieldSelect/types'
import { useAuthMethods } from '@store/auth'

import { IHookProps } from './types'

const OPTION_INITAL_OPERATORS = {
  label: 'Seleciona um operador(a)',
  disabled: false,
  value: '',
}

const header: IGridHeader[] = [
  {
    label: 'Nome',
    grid: 5.5,
    action: null,
    value: 'name',
    field: 'values',
    styles: {
      align: 'left',
      fontSize: 14,
    },
  },
  {
    label: 'Email',
    grid: 6.0,
    action: null,
    value: 'email',
    field: 'values',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
  {
    label: '',
    grid: 0.5,
    action: null,
    value: 'icon1',
    field: 'element',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
]

export const useSetupOperator = ({ tableId, handleModal }: IHookProps) => {
  const alert = useAlert()
  const queryClient = useQueryClient()

  const { profile } = useAuthMethods()

  const { data: dataUsers, isLoading: fetchUsersLoading } = useGetAllByCustomer(
    profile.parentCustomer.uuid,
  )

  const { mutate: addOperatorMutate, isLoading: setupTableOperatorLoading } =
    useSetupTableOperator()

  const { mutate: removeOperatorMutate, isLoading: removeOperatorLoading } =
    useRemoveOperatorFromTable()

  const { data: tableDetail, isLoading: isLoadingTableDetail } =
    useGetTableDetail(String(tableId))

  const [fieldSelect, setFieldSelect] = useState<FieldSelectOptionsProps[]>([])
  const [dataExistingOperators, setDataExistingOperators] = useState<
    IGridData[]
  >([])
  const [dataNewOperators, setDataNewOperators] = useState<IGridData[]>([])
  const [selected, setSelected] = useState<string>('')
  const [deleteOperatorLocal, setDeleteOperatorLocal] =
    useState<IUsersByCustomerResponse | null>(null)

  const onSuccess = (): void => {
    alert.success('Operador vinculado com sucesso')
    queryClient.invalidateQueries([
      'useGetTableListByCustomer',
      profile.parentCustomer.uuid,
    ])
    queryClient.invalidateQueries(['useGetTableDetail'])
    handleModal()
  }

  const onDeleteSuccess = (): void => {
    alert.success('Operador desvinculado com sucesso')

    queryClient.invalidateQueries([
      'useGetTableListByCustomer',
      profile.parentCustomer.uuid,
    ])
    queryClient.invalidateQueries(['useGetTableDetail'])
  }

  const onError = (): void => {
    alert.error('Ocorreu um erro inesperado. Tente novamente.')

    queryClient.invalidateQueries([
      'useGetTableListByCustomer',
      profile.parentCustomer.uuid,
    ])
    queryClient.invalidateQueries(['useGetTableDetail'])
  }

  const selectOPeratorUUID = (operatorUUID: string) => {
    setSelected(operatorUUID)
  }

  const getAllUsersOperators = () => {
    if (!dataUsers?.length) return []

    const allOperators = dataUsers.filter(
      (item) => item.profile === ProfileTypes.operator,
    )

    return allOperators
  }

  const prepareDataToSelectField = (
    listExistingOperators,
    listNewOperators,
  ) => {
    setFieldSelect([])

    const allOperators = getAllUsersOperators()

    const preparedSelectField: FieldSelectOptionsProps[] = [
      OPTION_INITAL_OPERATORS,
    ]

    const operatorTheTableIds = [
      ...listExistingOperators.map((item) => item.id),
      ...listNewOperators.map((item) => item.id),
    ]

    allOperators.forEach((item) => {
      if (!operatorTheTableIds.includes(item.uuid)) {
        preparedSelectField.push({
          label: item.name,
          disabled: false,
          value: item.uuid,
        })
      }
    })

    setFieldSelect([...preparedSelectField])
    selectOPeratorUUID('')
  }

  const handleSubmit = () => {
    const list = dataNewOperators.map((item) => item.id)

    addOperatorMutate(
      {
        exchangeTable: tableId || '',
        operators: list as any[],
      },
      {
        onSuccess,
        onError,
      },
    )
  }

  const handleDeleteOperator = (tableOperatorUUID: string) => {
    removeOperatorMutate(tableOperatorUUID, {
      onSuccess: onDeleteSuccess,
      onError,
    })
  }

  const handleDeleteLocalOperator = (operator: IUsersByCustomerResponse) => {
    const newOperators = dataNewOperators.filter(
      (item) => item.id !== String(operator.uuid),
    )

    setDataNewOperators(newOperators)
    selectOPeratorUUID('')
    prepareDataToSelectField(dataExistingOperators, newOperators)
  }

  const handleAddOperator = () => {
    if (!selected || !dataUsers?.length) return

    const operatorSelected = dataUsers.find((item) => item.uuid === selected)

    if (operatorSelected) {
      const newOperator: any = {
        id: operatorSelected.uuid.toString(),
        icon1: (
          <Icon
            name={'FaTrash'}
            onCallback={() => setDeleteOperatorLocal(operatorSelected)}
          />
        ),
        values: {
          name: operatorSelected.name,
          email: operatorSelected.email,
        },
      }

      setDataNewOperators([...dataNewOperators, newOperator])
      prepareDataToSelectField(dataExistingOperators, [
        ...dataNewOperators,
        newOperator,
      ])
    }

    selectOPeratorUUID('')
  }

  const mountDataLists = (
    existingOperators: any[],
    allOperators: any[],
  ): void => {
    setDataExistingOperators([])

    const preparedExistingOperators: IGridData[] = []

    existingOperators.forEach((item) => {
      preparedExistingOperators.push({
        id: item.operator.uuid.toString(),
        icon1: (
          <Icon
            name={'FaTrash'}
            onCallback={() => handleDeleteOperator(item.uuid.toString())}
          />
        ),
        values: {
          name: item.operator.name,
          email: item.operator.email,
        },
      })
    })

    setDataExistingOperators(preparedExistingOperators)
    prepareDataToSelectField(preparedExistingOperators, dataNewOperators)
  }

  const handleData = () => {
    if (!dataUsers?.length || !tableDetail) return []

    const allOperators = getAllUsersOperators()

    mountDataLists(tableDetail?.exchangeTableOperations, allOperators)
  }

  useEffect(() => {
    handleData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUsers, tableDetail])

  useEffect(() => {
    if (!deleteOperatorLocal) return
    handleDeleteLocalOperator(deleteOperatorLocal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOperatorLocal])

  return {
    isLoading:
      fetchUsersLoading ||
      setupTableOperatorLoading ||
      isLoadingTableDetail ||
      removeOperatorLoading,
    header,
    fieldSelect,
    dataExistingOperators,
    dataNewOperators,
    selectOPeratorUUID,
    handleSubmit,
    selected,
    handleAddOperator,
  }
}
