import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'

import * as SC from './styles'

const Tables = () => {
  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('economicCalendar.title')}</PageTitle>
      </SC.WrapperHeader>

      <Separator verticalSize={20} />

      <SC.WrapperFilter>
        <SC.WrapperIFrame>
          <iframe src="https://sslecal2.investing.com?ecoDayBackground=%232d313b&innerBorderColor=%232d313b&borderColor=%232d313b&columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=110,17,29,25,32,6,37,26,5,22,39,14,48,10,35,7,43,38,4,36,12,72&calType=week&timeZone=12&lang=12"></iframe>
        </SC.WrapperIFrame>
      </SC.WrapperFilter>
    </SC.Container>
  )
}

export default Tables
