import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'updateCustomer', {
    title: 'Cadastro de Cliente',
    input: {
      name: {
        label: 'NOME COMPLETO',
        placeholder: 'Insira seu nome completo',
      },
      email: {
        label: 'E-MAIL',
        placeholder: 'Insira seu e-email',
      },
      tradingName: {
        label: 'NOME FANTASIA',
        placeholder: 'Insira o nome fantasia',
      },
      corporateName: {
        label: 'RAZÃO SOCIAL',
        placeholder: 'Insira sua razão social',
      },
      id: {
        label: 'CPF/CNPJ',
        placeholder: 'Insira seu CPF/CNPJ',
      },
      cellPhone: {
        label: 'CELULAR',
        placeholder: 'Insira seu celular com DDD',
      },
      phone: {
        label: 'TELEFONE',
        placeholder: 'Insira seu telefone com DDD',
      },
      address: {
        label: 'ENDEREÇO',
        placeholder: 'Insira seu endereço',
      },
      zipCode: {
        label: 'CEP',
        placeholder: 'Insira seu CEP',
      },
    },
    select: {
      city: {
        label: 'Cidade',
      },
      state: {
        label: 'Estado',
      },
    },
    modules: {
      negotiationPanel: {
        label: 'Mesa de Câmbio',
      },
      strategyMonitor: {
        label: 'Mesa Tesouraria',
      },
    },
    buttonCreateAccount: 'Atualizar',
  })
}
