import React from 'react'
import { Routes as ListRoutes, Route, Navigate } from 'react-router-dom'

import { Authenticate } from '@resources/helpers/authenticated'

import Backoffice from '@components/App/Backoffice'
import Panel from '@components/App/Panel'
import Site from '@components/App/Site'
import { permissionsByProfile } from '@route/menu/permissions'
import { useAuthMethods } from '@store/auth'
import { WrapperRouteType } from '@resources/types/route'

import PathRoutes from './PathRoutes'

const BackOfficeRoutes = ({ children }: any) => {
  const authed = Authenticate()

  if (!authed) {
    return <Navigate to={PathRoutes.backoffice.signIn.url} replace />
  }

  return <Backoffice>{children}</Backoffice>
}

const SiteRoutes = ({ children }: any) => {
  return <Site>{children}</Site>
}

const Routes = () => {
  const authStore = useAuthMethods()

  const handleWrapper = (wrapper: string, screen: React.ReactNode) => {
    const wrapperSetup: Record<string, () => React.ReactNode> = {
      [WrapperRouteType.site]: () => <SiteRoutes>{screen}</SiteRoutes>,
      [WrapperRouteType.panel]: () => <Panel>{screen}</Panel>,
      [WrapperRouteType.backoffice]: () => (
        <BackOfficeRoutes>{screen}</BackOfficeRoutes>
      ),
    }

    try {
      return wrapperSetup[wrapper]()
    } catch {
      return <SiteRoutes>{screen}</SiteRoutes>
    }
  }

  const permissions = permissionsByProfile[authStore.profile.profile]
  const routesInArray = Object.values(PathRoutes.backoffice)
  const routesMatchesPermissions = [
    ...routesInArray.filter(
      (route) => !route.autenticated || permissions?.routes.includes(route.url),
    ),
  ]

  return (
    <React.Fragment>
      <ListRoutes>
        {/* Site */}
        <Route
          index
          element={handleWrapper(WrapperRouteType.site, PathRoutes.site.screen)}
        />
        <Route
          path={PathRoutes.notFound.url}
          element={handleWrapper(
            WrapperRouteType.site,
            PathRoutes.notFound.screen,
          )}
        />

        {/* Backoffice */}
        {routesMatchesPermissions.map((menu) => (
          <Route
            key={menu.url}
            path={menu.url}
            element={handleWrapper(String(menu.wrapper), menu.screen)}
          />
        ))}
      </ListRoutes>
    </React.Fragment>
  )
}

export default Routes
