import styled from '@emotion/styled'

import { FontType } from '@components/UI/Typography'
import { DefaultProps } from '@resources/types'

export const SectionMain = styled.div<DefaultProps>`
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OrganizeHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
`

export const WrapperField = styled.div`
  width: 320px;
`

export const WrapperSearchButon = styled.div`
  width: 80px;
  margin: 28px 0 0 0;
`

export const WrapperSearchResult = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 10px);
  height: 180px;
  padding: 10px 10px 10px 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.palette.neutral.regular}80;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.primary.regular};
  }
`

export const WrapperResult = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 30px);
  height: 30px;
  padding: 10px 15px;
  margin: 0 0 5px 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral.regular}80;
`

export const TextResult = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  /* width: 100%; */
  height: 30px;

  span + span {
    margin: -3px 0 0 0;
  }
`

export const ButtonResult = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 29px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.success.dark};
  padding: 2px 0 0 0;
  margin: 0;
`

export const Link = styled.span<DefaultProps>`
  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.info.dark};
  font-style: normal;
  cursor: pointer;
  position: absolute;
  bottom: 30px;

  &:hover {
    color: ${({ theme }) => theme.palette.info.dark}80;
  }
`

export const WrapperListSearch2 = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 127px);
  height: 150px;
  overflow-x: auto;
  position: absolute;
  z-index: 999;
  background: ${({ theme }) => theme.palette.primary.regular};
  margin: 0;
  padding: 5px 0 0 0;
  top: 200px;
  left: 40px;
  right: 0;
  border: 1.5px solid ${({ theme }) => theme.palette.neutral.darkest}30;
`
export const WrapperItem = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  height: 25px;
  margin: 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.light};
  margin: 2px 0;
  cursor: pointer;
`
