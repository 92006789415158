import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { ISentiment } from '@resources/types/services/sentiments'

export const SentimentsService = {
  getSentimentList: async (): Promise<ISentiment[]> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.get(endpoints.URL.sentiment.list)

    return response.data
  },
}
