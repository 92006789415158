import { UseRssFeed } from './useRssFeed'

import * as SC from './styles'

const RssFeed = ({ url }: { url: string }) => {
  const { items, fixGarbledText } = UseRssFeed({ url })

  return (
    <SC.Container>
      <SC.Title>
        <span>LINK</span>
        <span>AUTOR</span>
      </SC.Title>

      {items.map((item, index) => {
        return (
          <SC.WrapperContent key={index}>
            <div>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {fixGarbledText(item.title)}
              </a>
            </div>
            <div key={index}>{fixGarbledText(item.author)}</div>
          </SC.WrapperContent>
        )
      })}
    </SC.Container>
  )
}

export default RssFeed
