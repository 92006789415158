import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/constants/devices'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (${mediaQuerydevices.mobile}) {
    flex-direction: column;
    align-items: center;
  }
`
export const WrapperMenu = styled.div`
  margin: 0;
  padding: 0;
  margin: 0 30px 0 0;
`

export const WrapperButton = styled.div`
  margin: 0;
  padding: 0;

  @media (${mediaQuerydevices.mobile}) {
    display: none;
  }
`

export const Ul = styled.div`
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: inline-flex;

  @media (${mediaQuerydevices.mobile}) {
    display: block;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`

export const Li = styled.div<DefaultProps>`
  padding: 16px 5px;
  cursor: pointer;

  a {
    padding: 10px 20px;
    color: ${({ theme }) => theme.palette.primary.lightest};
    text-decoration: none;
  }

  &:hover {
    a {
      border-radius: 30px;
      background: ${({ theme }) => theme.palette.neutral.dark};
    }
  }

  &.active {
    a {
      border-radius: 30px;
      background: ${({ theme }) => theme.palette.neutral.dark};
    }
  }
`
