import { useQuery, UseQueryResult } from 'react-query'

import { SignalService } from '@services/signal'

import { ISignal } from '@resources/types/services/signal'

export const useGetSignalList = (): UseQueryResult<ISignal[]> => {
  return useQuery(
    ['useGetSignalList'],
    () => {
      return SignalService.getSignalList()
    },
    {
      refetchInterval: 1000,
    },
  )
}

export const useGetSignalListByCategory = (
  category: string,
): UseQueryResult<ISignal[]> => {
  return useQuery(
    ['useGetSignalListByCategory', category],
    () => {
      return SignalService.getSignalByCategory(category)
    },
    {
      enabled: !!category,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
      refetchInterval: 0,
    },
  )
}
