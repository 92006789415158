/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment } from 'react'

import Footer from './Footer'
import Header from './Header'

import * as SC from './styles'
import { IViewProps } from './types'
import { StyleGlobal } from './styleGlobal'

const Panel = ({ children }: IViewProps) => {
  return (
    <Fragment>
      <StyleGlobal />

      <SC.Container>
        <Header />

        <SC.WrapperContent>
          <SC.Content>{children}</SC.Content>
        </SC.WrapperContent>

        <Footer />
      </SC.Container>
    </Fragment>
  )
}

export default Panel
