import React, { ReactElement } from 'react'
import { Global, css } from '@emotion/react'

import { theme } from '@resources/theme'

export const StyleGlobal = (): ReactElement => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
      }

      html {
        scroll-behavior: smooth;
      }

      body {
        background: ${theme.palette.primary.darkest};
        padding: 0;
        margin: 0;
        overflow-x: hidden;
      }
    `}
  />
)
