import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'updateCustomer', {
    title: 'Update',
    input: {
      name: {
        label: 'FULL NAME',
        placeholder: 'Type your full name',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Type your e-email',
      },
      tradingName: {
        label: 'TRADING NAME',
        placeholder: 'Type your trading name',
      },
      corporateName: {
        label: 'COMPANY NAME',
        placeholder: 'Type the company name',
      },
      id: {
        label: 'CPF/CNPJ',
        placeholder: 'Type your CPF/CNPJ',
      },
      cellPhone: {
        label: 'CELL PHONE',
        placeholder: 'Type your cellPhone with DDD',
      },
      phone: {
        label: 'PHONE',
        placeholder: 'Type your phone with DDD',
      },
      address: {
        label: 'ADDRESS',
        placeholder: 'Type your address',
      },
      zipCode: {
        label: 'ZIP CODE',
        placeholder: 'Type your zip code',
      },
    },
    select: {
      city: {
        label: 'City',
      },
      state: {
        label: 'State',
      },
    },
    modules: {
      negotiationPanel: {
        label: 'Negotiation Panel',
      },
      strategyMonitor: {
        label: 'Strategy Monitor',
      },
    },
    buttonCreateAccount: 'Update',
  })
}
