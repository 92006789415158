import { Label, Input, Switch, CheckBoxWrapper } from './styles'

import { IProps } from './types'

const Toggle = ({
  labelLeft,
  labelRight,
  id,
  isChecked,
  handleChange,
}: IProps) => {
  return (
    <CheckBoxWrapper>
      {labelLeft && <Label>{labelLeft}</Label>}
      <Input
        id={id}
        checked={isChecked}
        type="checkbox"
        onChange={handleChange}
        data-testid={'toggle'}
      />
      <Switch id="switch-toggle" />
      {labelRight && <Label>{labelRight}</Label>}
    </CheckBoxWrapper>
  )
}

export default Toggle
