import Modal from '@components/Modal'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'

import { IProps } from './types'
import * as SC from './styles'

const CardDetail = ({ display, closeModal, data }: IProps) => {
  const createMarkup = () => {
    return { __html: data.strategy.description }
  }

  return (
    <Modal
      display={display}
      onClose={closeModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={25}
        >
          {data.strategy.name}
        </Typography>
      }
      width={700}
      height={550}
    >
      <SC.SectionMain>
        <SC.WrapperField>
          <Typography
            fontType={FontType.medium}
            color={theme.palette.neutral.regular}
            size={16}
          >
            Relação risco retorno:
          </Typography>
          <Separator verticalSize={5} />
          <Typography
            fontType={FontType.regular}
            color={theme.palette.primary.light}
            size={16}
          >
            {data.strategy.totalProfit}
          </Typography>
        </SC.WrapperField>

        <SC.WrapperField>
          <Typography
            fontType={FontType.medium}
            color={theme.palette.neutral.regular}
            size={16}
          >
            Taxa de acerto:
          </Typography>
          <Separator verticalSize={5} />
          <Typography
            fontType={FontType.regular}
            color={theme.palette.primary.light}
            size={16}
          >
            {Math.round(data.strategy.hitRate)}%
          </Typography>
        </SC.WrapperField>

        <SC.WrapperField>
          <Typography
            fontType={FontType.medium}
            color={theme.palette.neutral.regular}
            size={16}
          >
            Descrição:
          </Typography>
          <Separator verticalSize={5} />

          <SC.WrapperDescription>
            <Typography
              fontType={FontType.regular}
              color={theme.palette.primary.light}
              size={14}
            >
              <div dangerouslySetInnerHTML={createMarkup()} />
            </Typography>
          </SC.WrapperDescription>
        </SC.WrapperField>
      </SC.SectionMain>
    </Modal>
  )
}

export default CardDetail
