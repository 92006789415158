import * as Yup from 'yup'

export const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .test('isValidPassword', 'Digite uma senha válida', (val) => {
      return String(val).length >= 8 && String(val).trim().match(/\W/)
        ? true
        : false
    })
    .required('Required'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas precisam ser identicas')
    .required('Required'),
})
