import { ILayout } from './types'
import { sizes } from '@resources/types/devices'

export const layout: ILayout = {
  desktop: {
    size: {
      min: sizes.desktop.min,
      max: sizes.desktop.max,
    },
  },
  tablet: {
    size: {
      min: sizes.tablet.min,
      max: sizes.tablet.max,
    },
  },
  general: {
    body: {
      margin: 0,
      padding: 0,
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
      webkitFontSmoothing: 'antialiased',
      mozOsxFontSmoothing: 'grayscale',
    },
  },
}
