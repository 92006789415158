import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { IStyleProps } from './types'
import { DefaultProps } from '@resources/types'
import { Fonts } from '@resources/types/mixins'

export const Button = styled.button<IStyleProps & DefaultProps>`
  height: 45px;

  ${({ width }) => width && `width: ${width}px`};
  ${({ widthFull }) => widthFull && `width: 100%`};

  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
  ${({ buttonTextColor }) => buttonTextColor && `color: ${buttonTextColor}`};
  padding: 0 25px;
  border-radius: 30px;
  border: ${({ outline, theme }) =>
    outline ? `2px solid ${theme.palette.primary.darkest}` : 'none'};

  cursor: pointer;
  ${({ theme }) => theme.mixins.fonts(Fonts.medium)};
  font-size: 15px;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;

    ${({ theme }) => {
      return css`
        li a: {
          color: ${theme.typography.fonts.normal};
        }
      `
    }}
  }
`
