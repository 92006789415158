import axios from 'axios'

export const RssFeedService = {
  getRssFeed: async (url: string) => {
    const response = await axios.get(
      `https://api.allorigins.win/get?url=${url}`,
    )

    return response.data.contents
  },
}
