import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div``

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const WrapperTradables = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
  min-height: 400px;
`

export const WrapperFiedlOptions = styled.div<DefaultProps>`
  width: 300px;
  height: 40px;
`

export const WrapperDescription = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  width: calc(100% - 70px);
  height: 50px;
  margin: 0px 35px 15px 12px;
  padding: 0 20px;
  border-radius: 12px;
  gap: 10px;
  background: ${({ theme }) => theme.palette.primary.dark};
`
