import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const CheckBoxWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 10px;
`

export const Label = styled.span<DefaultProps>`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 15px;
  font-weight: 700;
`

export const Switch = styled.div<DefaultProps>`
  position: relative;
  width: 52px;
  height: 20px;
  background: ${({ theme }) => theme.palette.neutral.dark};
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: ${({ theme }) => theme.palette.primary.light};
    transform: translate(0, -50%);
  }
`

export const Input = styled.input<DefaultProps>`
  opacity: 0;
  position: absolute;

  &:checked + div {
    background: ${({ theme }) => theme.palette.success.regular};

    &:before {
      transform: translate(32px, -50%);
    }
  }
`
