// import { CSVLink } from 'react-csv'

import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
import Typography, { FontType } from '@components/UI/Typography'
import FieldSelect from '@components/UI/FieldSelect'
import Button from '@components/UI/Button'
import DataGrid from '@components/UI/DataGrid'
import { theme } from '@resources/theme'
import Icon from '@components/UI/Icon'
import FieldDatePicker from '@components/UI/FieldDatePicker'

import * as SC from './styles'
import { useCsvExport } from './csvExport/useCsvExport'
import { useOperationalManagementReport } from './useOperationalManagementReport'

const Tables = () => {
  const {
    isLoading,
    header,
    handleData,
    // dataConsolidated,
    // formatCurrencyWithouSimbol,
    // formatDolar,
    // formatReal,
    tableListOptions,
    formik,
    dataOperatorLoading,
    dataOperator,
    // setTableId,
    refetch,
    isOperator,
    data,
  } = useOperationalManagementReport()
  const { csvReport } = useCsvExport(data)

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('operationalManagementReport.title')}</PageTitle>

        {/* <Button onClick={() => ''}>
          {t('operationalManagementReport.buttonExport')}
        </Button> */}
        <SC.ButtonExport {...csvReport}>
          {t('operationalManagementReport.buttonExport')}
        </SC.ButtonExport>
      </SC.WrapperHeader>

      <Separator verticalSize={20} />

      <SC.WrapperFilter>
        <SC.OrganizeHorizontal>
          <SC.WrapperField>
            <FieldDatePicker
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  Data inicial
                </Typography>
              }
              maxDate={new Date().setDate(new Date().getDate() - 1)}
              defaultDate={formik.values.initialDate}
              onChange={(date: string) => {
                const dateSplit = date.split('/')
                formik.setFieldValue(
                  'initialDate',
                  `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`,
                )
              }}
              widthFull
            />
          </SC.WrapperField>

          <SC.WrapperField>
            <FieldDatePicker
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  Data final
                </Typography>
              }
              maxDate={new Date()}
              defaultDate={formik.values.finalDate}
              onChange={(date: string) => {
                const dateSplit = date.split('/')
                formik.setFieldValue(
                  'finalDate',
                  `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`,
                )
              }}
              widthFull
            />
          </SC.WrapperField>
          <SC.WrapperField />
          <SC.WrapperField />
        </SC.OrganizeHorizontal>

        <SC.OrganizeHorizontal>
          <SC.WrapperField fullWidth>
            <FieldSelect
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  MESA
                </Typography>
              }
              name="exchangeTable"
              options={tableListOptions ?? []}
              selected={formik.values.exchangeTable}
              handleChange={(value) => {
                formik.setFieldValue('exchangeTable', value)
                // setTableId(value)
              }}
            />
          </SC.WrapperField>

          {!isOperator && (
            <SC.WrapperField fullWidth>
              <FieldSelect
                label={
                  <Typography
                    fontType={FontType.medium}
                    color={theme.palette.neutral.regular}
                    size={14}
                  >
                    Operador(a)
                  </Typography>
                }
                name="proxy"
                options={
                  dataOperatorLoading
                    ? [
                        {
                          value: '',
                          label: 'Carregando, aguarde...',
                        },
                      ]
                    : dataOperator?.length
                    ? dataOperator
                    : [
                        {
                          value: '',
                          label: 'Todos os operadores',
                        },
                      ]
                }
                selected={formik.values.proxy}
                handleChange={(value) => {
                  formik.setFieldValue('proxy', value)
                }}
              />
            </SC.WrapperField>
          )}

          <SC.WrapperButonSearch>
            <Button onClick={refetch as () => void}>
              <Icon name={'FaSearch'} />
            </Button>
          </SC.WrapperButonSearch>
        </SC.OrganizeHorizontal>
      </SC.WrapperFilter>

      <Separator verticalSize={12} />

      <SC.WrapperDataGrid>
        <DataGrid
          header={header}
          data={handleData()}
          loading={isLoading}
          width="150%"
        />

        {!isLoading && !handleData().length && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Separator verticalSize={100} />

            <Typography
              fontType={FontType.bold}
              color={theme.palette.neutral.regular}
              size={16}
            >
              Sem registro de negociação
            </Typography>

            <Separator verticalSize={100} />
          </div>
        )}
      </SC.WrapperDataGrid>
    </SC.Container>
  )
}

export default Tables
