import React from 'react'

import Loading from '@components/Loading'

import { Button as StyledButton } from './styles'
import { IViewProps } from './types'
import { theme } from '@resources/theme'

const Button = ({
  type = 'button',
  children,
  loading = false,
  disabled = false,
  height = 30,
  outline,
  width = 0,
  widthFull = false,
  onClick,
  secondary = false,
  tertiary = false,
}: IViewProps) => {
  const themes = {
    primary: {
      disabled: {
        background: theme.palette.neutral.regular,
        color: theme.palette.primary.light,
      },
      active: {
        background: theme.palette.success.dark,
        color: theme.palette.primary.lightest,
      },
    },
    secondary: {
      disabled: {
        background: '',
        color: '',
      },
      active: {
        background: theme.palette.neutral.regular,
        color: theme.palette.neutral.dark,
      },
    },
    tertiary: {
      disabled: {
        background: theme.palette.secondary.lightest,
        color: theme.palette.neutral.dark,
      },
      active: {
        background: theme.palette.success.dark,
        color: theme.palette.neutral.darkest,
      },
    },
  }

  const themeActive =
    themes[(secondary && 'secondary') || (tertiary && 'tertiary') || 'primary']

  return (
    <StyledButton
      bgColor={themeActive[disabled ? 'disabled' : 'active'].background}
      buttonTextColor={themeActive[disabled ? 'disabled' : 'active'].color}
      disabled={disabled}
      height={height}
      onClick={onClick}
      outline={outline}
      width={width}
      widthFull={widthFull}
      type={type}
    >
      {loading ? <Loading size={20} /> : children}
    </StyledButton>
  )
}

export default Button
