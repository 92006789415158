import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0;
  background-color: 'red';
`
export const WrapperField = styled.div`
  width: 500;
`
