import { useState } from 'react'

export const useModalCustomerOperation = ({ closeModal }) => {
  const [currentStep, setCurrentStep] = useState<string>('search')
  const [documentSearch, setDocumentSearch] = useState<string>('')

  const handleCloseModal = () => {
    closeModal()
    setCurrentStep('search')
    setDocumentSearch('')
  }

  return {
    handleCloseModal,
    currentStep,
    setCurrentStep,
    documentSearch,
    setDocumentSearch,
  }
}
