import {
  FaPeopleArrows,
  FaUserCog,
  FaUsers,
  FaNewspaper,
  FaUserTie,
  FaCoins,
  FaHome,
  FaSearch,
  FaStopwatch20,
  FaClock,
  FaTrash,
  FaMoneyCheck,
  FaArrowRight,
  FaArrowLeft,
  FaCalendarDay,
  FaArrowCircleLeft,
  FaPlus,
  FaLockOpen,
  FaCheck,
  FaStream,
  FaChartLine,
  FaFilter,
} from 'react-icons/fa'
import {
  AiOutlineSliders,
  AiFillCaretUp,
  AiOutlineClose,
  AiFillAlert,
} from 'react-icons/ai'
import { BsCurrencyExchange, BsNewspaper } from 'react-icons/bs'
import { BiDetail, BiTrendingUp, BiTrendingDown } from 'react-icons/bi'
import { RxUpdate } from 'react-icons/rx'

import { theme } from '@resources/theme'

import { typeIcons } from './types'
import {
  BlockedEyeSVG,
  UnblockedEyeSVG,
  LogoSVG,
  PadlockSVG,
  LogoWhiteSVG,
  HomeInvistSVG,
  HomeTraderSVG,
  HomeServiceSVG,
  FooterLinkedinSVG,
  FooterYouTubeSVG,
  LogoDashboardSVG,
  LogoDashboard,
  MenuHamburguerSVG,
  AvatarSVG,
  LogoutSVG,
  DashboardIconHomeSVG,
  ArrowPositiveSVG,
  ArrowNegativeSVG,
  CloseSVG,
} from './styles'

export const mapIcons = [
  {
    name: 'blockedEye',
    type: typeIcons.SVG,
    element: BlockedEyeSVG,
    dimensions: {
      width: 22,
      height: 22,
    },
  },

  {
    name: 'unblockedEye',
    type: typeIcons.SVG,
    element: UnblockedEyeSVG,
    dimensions: {
      width: 22,
      height: 22,
    },
  },

  {
    name: 'logo',
    type: typeIcons.SVG,
    element: LogoSVG,
    dimensions: {
      width: 170,
      height: 170,
    },
  },
  {
    name: 'padlock',
    type: typeIcons.SVG,
    element: PadlockSVG,
    dimensions: {
      width: 16,
      height: 21,
    },
  },
  {
    name: 'logoWhite',
    type: typeIcons.SVG,
    element: LogoWhiteSVG,
    dimensions: {
      width: 60,
      height: 47,
    },
  },
  {
    name: 'homeInvist',
    type: typeIcons.SVG,
    element: HomeInvistSVG,
    dimensions: {
      width: 71,
      height: 71,
    },
  },
  {
    name: 'homeTrader',
    type: typeIcons.SVG,
    element: HomeTraderSVG,
    dimensions: {
      width: 71,
      height: 71,
    },
  },
  {
    name: 'homeService',
    type: typeIcons.SVG,
    element: HomeServiceSVG,
    dimensions: {
      width: 71,
      height: 71,
    },
  },
  {
    name: 'footerLinkedin',
    type: typeIcons.SVG,
    element: FooterLinkedinSVG,
    dimensions: {
      width: 30,
      height: 30,
    },
  },
  {
    name: 'footerYouTube',
    type: typeIcons.SVG,
    element: FooterYouTubeSVG,
    dimensions: {
      width: 40,
      height: 40,
    },
  },
  {
    name: 'logoDashboardPng',
    type: typeIcons.PNG,
    element: LogoDashboard,
    dimensions: {
      width: 70,
      height: 55,
    },
  },
  {
    name: 'logoDashboard',
    type: typeIcons.SVG,
    element: LogoDashboardSVG,
    dimensions: {
      width: 70,
      height: 55,
    },
  },
  {
    name: 'menuHamburguer',
    type: typeIcons.SVG,
    element: MenuHamburguerSVG,
    dimensions: {
      width: 18,
      height: 12,
    },
  },
  {
    name: 'avatar',
    type: typeIcons.SVG,
    element: AvatarSVG,
    dimensions: {
      width: 20,
      height: 20,
    },
  },
  {
    name: 'logout',
    type: typeIcons.SVG,
    element: LogoutSVG,
    dimensions: {
      width: 20,
      height: 20,
    },
  },
  {
    name: 'dashboardIconHome',
    type: typeIcons.SVG,
    element: DashboardIconHomeSVG,
    dimensions: {
      width: 31,
      height: 26,
    },
  },
  {
    name: 'arrowPositive',
    type: typeIcons.SVG,
    element: ArrowPositiveSVG,
    dimensions: {
      width: 15,
      height: 20,
    },
  },
  {
    name: 'arrowNegative',
    type: typeIcons.SVG,
    element: ArrowNegativeSVG,
    dimensions: {
      width: 15,
      height: 20,
    },
  },
  {
    name: 'IconCustomer',
    type: typeIcons.RA,
    element: <FaPeopleArrows color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 0,
      height: 0,
    },
  },
  {
    name: 'FaUserCog',
    type: typeIcons.RA,
    element: <FaUserCog color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 0,
      height: 0,
    },
  },
  {
    name: 'FaUsers',
    type: typeIcons.RA,
    element: <FaUsers color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 0,
      height: 0,
    },
  },
  {
    name: 'FaNewspaper',
    type: typeIcons.RA,
    element: <FaNewspaper color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 0,
      height: 0,
    },
  },
  {
    name: 'BsNewspaper',
    type: typeIcons.RA,
    element: <BsNewspaper color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 0,
      height: 0,
    },
  },
  {
    name: 'FaUserTie',
    type: typeIcons.RA,
    element: <FaUserTie color={theme.palette.success.darkest} size={22} />,
    dimensions: {
      width: 0,
      height: 0,
    },
  },
  {
    name: 'FaCoins',
    type: typeIcons.RA,
    element: <FaCoins color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 0,
      height: 0,
    },
  },
  {
    name: 'closeSVG',
    type: typeIcons.SVG,
    element: CloseSVG,
    dimensions: {
      width: 13,
      height: 13,
    },
  },
  {
    name: 'FaSearch',
    type: typeIcons.RA,
    element: <FaSearch color={theme.palette.success.darkest} size={20} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaStopwatch20',
    type: typeIcons.RA,
    element: <FaStopwatch20 color={theme.palette.success.darkest} size={40} />,
    dimensions: {
      width: 20,
      height: 20,
    },
  },
  {
    name: 'FaClock',
    type: typeIcons.RA,
    element: <FaClock color={theme.palette.neutral.darkest} size={25} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaTrash',
    type: typeIcons.RA,
    element: <FaTrash color={theme.palette.error.regular} size={20} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaMoneyCheck',
    type: typeIcons.RA,
    element: <FaMoneyCheck color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaLockOpen',
    type: typeIcons.RA,
    element: <FaLockOpen color={theme.palette.success.darkest} size={30} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaArrowRight',
    type: typeIcons.RA,
    element: <FaArrowRight color={theme.palette.success.darkest} size={20} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaArrowLeft',
    type: typeIcons.RA,
    element: <FaArrowLeft color={theme.palette.primary.light} size={15} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaCalendarDay',
    type: typeIcons.RA,
    element: <FaCalendarDay color={theme.palette.success.darkest} size={25} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaHome',
    type: typeIcons.RA,
    element: <FaHome color={theme.palette.success.darkest} size={25} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaArrowCircleLeft',
    type: typeIcons.RA,
    element: <FaArrowCircleLeft color={theme.palette.neutral.dark} size={25} />,
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaPlus',
    type: typeIcons.RA,
    element: <FaPlus color={theme.palette.neutral.dark} size={30} />,
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    name: 'AiOutlineSliders',
    type: typeIcons.RA,
    element: (
      <AiOutlineSliders color={theme.palette.success.darkest} size={30} />
    ),
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    name: 'BsCurrencyExchange',
    type: typeIcons.RA,
    element: (
      <BsCurrencyExchange color={theme.palette.success.darkest} size={30} />
    ),
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    name: 'AiFillCaretUp',
    type: typeIcons.RA,
    element: <AiFillCaretUp color={theme.palette.neutral.regular} size={25} />,
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    name: 'FaCheck',
    type: typeIcons.RA,
    element: <FaCheck color={theme.palette.success.dark} size={15} />,
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    name: 'BiDetail',
    type: typeIcons.RA,
    element: <BiDetail color={theme.palette.info.regular} size={30} />,
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    name: 'RxUpdate',
    type: typeIcons.RA,
    element: <RxUpdate color={theme.palette.success.light} size={30} />,
    dimensions: {
      width: 1,
      height: 1,
    },
  },
  {
    name: 'AiOutlineClose',
    type: typeIcons.RA,
    element: (
      <AiOutlineClose
        color={theme.palette.secondary.light}
        height={10}
        width={10}
        size={10}
      />
    ),
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'AiFillAlert',
    type: typeIcons.RA,
    element: (
      <AiFillAlert
        color={theme.palette.error.dark}
        height={20}
        width={20}
        size={20}
      />
    ),
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaStream',
    type: typeIcons.RA,
    element: (
      <FaStream
        color={theme.palette.secondary.light}
        height={10}
        width={10}
        size={10}
      />
    ),
    dimensions: {
      width: 10,
      height: 10,
    },
  },
  {
    name: 'FaChartLine',
    type: typeIcons.RA,
    element: (
      <FaChartLine
        color={theme.palette.success.dark}
        height={20}
        width={20}
        size={20}
      />
    ),
    dimensions: {
      width: 20,
      height: 20,
    },
  },
  {
    name: 'BiTrendingUp',
    type: typeIcons.RA,
    element: (
      <BiTrendingUp
        color={theme.palette.success.dark}
        height={20}
        width={20}
        size={20}
      />
    ),
    dimensions: {
      width: 20,
      height: 20,
    },
  },
  {
    name: 'BiTrendingDown',
    type: typeIcons.RA,
    element: (
      <BiTrendingDown
        color={theme.palette.error.dark}
        height={20}
        width={20}
        size={20}
      />
    ),
    dimensions: {
      width: 20,
      height: 20,
    },
  },
  {
    name: 'FaFilter',
    type: typeIcons.RA,
    element: (
      <FaFilter
        color={theme.palette.neutral.regular}
        height={30}
        width={30}
        size={30}
      />
    ),
    dimensions: {
      width: 30,
      height: 30,
    },
  },
]
