import * as Yup from 'yup'
import dayjs from 'dayjs'

const setupHour = {
  opening: '09h',
  closing: '17h',
}

const validOpenning = (hour: string) => {
  const dateNow = dayjs(new Date()).format('YYYY-MM-DD')
  const hourOpening = dayjs(`${dateNow} ${hour}:00`).isAfter(
    `${dateNow} 08:59:00`,
  )
  const hourClosing = dayjs(`${dateNow} 15:59:00`).isAfter(
    `${dateNow} ${hour}:00`,
  )

  // console.log('validOpenning', { dateNow, hour, hourOpening, hourClosing })

  return hourOpening && hourClosing
}

const validClosing = (hour: string) => {
  const dateNow = dayjs(new Date()).format('YYYY-MM-DD')
  const hourOpening = dayjs(`${dateNow} 10:00:00`).isBefore(
    `${dateNow} ${hour}:00`,
  )
  const hourClosing = dayjs(`${dateNow} ${hour}:00`).isBefore(
    `${dateNow} 17:01:00`,
  )

  // console.log('validClosing', { dateNow, hour, hourOpening, hourClosing })

  return hourOpening && hourClosing
}

export const schemaValidation = Yup.object().shape({
  mondayOpening: Yup.string().test(
    'mondayOpening',
    `Abertura deve ser a partir de ${setupHour.opening} até 1h antes de ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validOpenning(value)

      return validHour
    },
  ),
  mondayClosing: Yup.string().test(
    'mondayClosing',
    `Horario de fechando até ${setupHour.closing} ou 1h após abertura às ${setupHour.opening} `,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validClosing(value)

      return validHour
    },
  ),
  tuesdayOpening: Yup.string().test(
    'tuesdayOpening',
    `Abertura deve ser a partir de ${setupHour.opening} até 1h antes de ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validOpenning(value)

      return validHour
    },
  ),
  tuesdayClosing: Yup.string().test(
    'tuesdayClosing',
    `Horario de fechando até ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validClosing(value)

      return validHour
    },
  ),
  wednesdayOpening: Yup.string().test(
    'wednesdayOpening',
    `Abertura deve ser a partir de ${setupHour.opening} até 1h antes de ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validOpenning(value)

      return validHour
    },
  ),
  wednesdayClosing: Yup.string().test(
    'wednesdayClosing',
    `Horario de fechando até ${setupHour.closing} ou 1h após abertura às ${setupHour.opening} `,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validClosing(value)

      return validHour
    },
  ),
  thursdayOpening: Yup.string().test(
    'valid-video-link',
    `Abertura deve ser a partir de ${setupHour.opening} até 1h antes de ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validOpenning(value)

      return validHour
    },
  ),
  thursdayClosing: Yup.string().test(
    'thursdayClosing',
    `Horario de fechando até ${setupHour.closing} ou 1h após abertura às ${setupHour.opening} `,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validClosing(value)

      return validHour
    },
  ),
  fridayOpening: Yup.string().test(
    'valid-video-link',
    `Abertura deve ser a partir de ${setupHour.opening} até 1h antes de ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validOpenning(value)

      return validHour
    },
  ),
  fridayClosing: Yup.string().test(
    'fridayClosing',
    `Horario de fechando até ${setupHour.closing} ou 1h após abertura às ${setupHour.opening} `,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validClosing(value)

      return validHour
    },
  ),
  saturdayOpening: Yup.string().test(
    'saturdayOpening',
    `Abertura deve ser a partir de ${setupHour.opening} até 1h antes de ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validOpenning(value)

      return validHour
    },
  ),
  saturdayClosing: Yup.string().test(
    'saturdayClosing',
    `Horario de fechando até ${setupHour.closing} ou 1h após abertura às ${setupHour.opening} `,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validClosing(value)

      return validHour
    },
  ),
  sundayOpening: Yup.string().test(
    'sundayOpening',
    `Abertura deve ser a partir de ${setupHour.opening} até 1h antes de ${setupHour.closing}`,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validOpenning(value)

      return validHour
    },
  ),
  sundayClosing: Yup.string().test(
    'sundayClosing',
    `Horario de fechando até ${setupHour.closing} ou 1h após abertura às ${setupHour.opening} `,
    (value) => {
      if (!value || value.includes('_')) return false

      const validHour = validClosing(value)

      return validHour
    },
  ),
})
