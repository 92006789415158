import React from 'react'

import { t } from '@resources/translation'

import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import Button from '@components/UI/Button'

import { IViewProps } from './types'
import Modal from '@components/Modal'
import { cellPhoneMask, cpfCnpjMask, phoneMask } from '@resources/helpers/utils'
import Typography, { FontType } from '@components/UI/Typography'
import * as SC from './styles'
import { PersonType } from '@pages/Backoffice/Customer/SignUpCustomer'
import FieldSelect from '@components/UI/FieldSelect'

const Update = ({
  formik,
  isLoading,
  showModal,
  handleModal,
  personType,
  setPersonType,
  handleStateChange,
  handleCityChange,
  stateList,
  cityOptions,
  modulesOptions,
}: IViewProps) => {
  return (
    <Modal
      display={showModal}
      onClose={handleModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={30}
        >
          {t('updateCustomer.title')}
        </Typography>
      }
      width={950}
      height={820}
    >
      <form onSubmit={formik.handleSubmit}>
        <SC.WrapperContent>
          <SC.WrapperForm>
            <Field
              id="cpfCnpj"
              name="cpfCnpj"
              onChange={(e) => {
                formik.setFieldValue(
                  'cpfCnpj',
                  cpfCnpjMask(e.target.value || ''),
                )

                setPersonType(
                  e.target.value.length > 14 ? PersonType.PJ : PersonType.PF,
                )
              }}
              value={formik.values.cpfCnpj}
              widthFull
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('updateCustomer.input.id.label')}
                </Typography>
              }
              placeholder={t('updateCustomer.input.id.placeholder')}
            />

            <Separator verticalSize={15} />

            {personType === PersonType.PF && (
              <Field
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                widthFull
                label={
                  <Typography
                    fontType={FontType.medium}
                    color={theme.palette.neutral.regular}
                    size={14}
                  >
                    {t('updateCustomer.input.name.label')}
                  </Typography>
                }
                placeholder={t('updateCustomer.input.name.placeholder')}
              />
            )}

            <Separator verticalSize={15} />

            {personType === PersonType.PJ && (
              <SC.WrapperFormContent>
                <Field
                  id="corporateName"
                  name="corporateName"
                  onChange={formik.handleChange}
                  value={formik.values.corporateName}
                  inputWidth={384}
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      {t('updateCustomer.input.corporateName.label')}
                    </Typography>
                  }
                  placeholder={t(
                    'updateCustomer.input.corporateName.placeholder',
                  )}
                />

                <Separator horizontalSize={16} />

                <Field
                  id="tradingName"
                  name="tradingName"
                  onChange={formik.handleChange}
                  value={formik.values.tradingName}
                  inputWidth={384}
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      {t('updateCustomer.input.tradingName.label')}
                    </Typography>
                  }
                  placeholder={t(
                    'updateCustomer.input.tradingName.placeholder',
                  )}
                />
              </SC.WrapperFormContent>
            )}

            <Separator verticalSize={15} />

            <Field
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              widthFull
              maxLength={100}
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('updateCustomer.input.email.label')}
                </Typography>
              }
              placeholder={t('updateCustomer.input.email.placeholder')}
            />

            <Separator verticalSize={15} />

            <SC.WrapperFormContent>
              <Field
                id="cellphoneNumber"
                name="cellphoneNumber"
                onChange={(e) => {
                  formik.setFieldValue(
                    'cellPhone',
                    cellPhoneMask(e.target.value || ''),
                  )
                }}
                value={formik.values.cellphoneNumber}
                inputWidth={384}
                label={
                  <Typography
                    fontType={FontType.medium}
                    color={theme.palette.neutral.regular}
                    size={14}
                  >
                    {t('updateCustomer.input.cellPhone.label')}
                  </Typography>
                }
                placeholder={t('updateCustomer.input.cellPhone.placeholder')}
              />

              <Separator horizontalSize={16} />
              <Separator verticalSize={15} />

              <Field
                id="phone"
                name="phone"
                onChange={(e) => {
                  formik.setFieldValue('phone', phoneMask(e.target.value || ''))
                }}
                value={formik.values.phone}
                inputWidth={384}
                label={
                  <Typography
                    fontType={FontType.medium}
                    color={theme.palette.neutral.regular}
                    size={14}
                  >
                    {t('updateCustomer.input.phone.label')}
                  </Typography>
                }
                placeholder={t('updateCustomer.input.phone.placeholder')}
              />
            </SC.WrapperFormContent>

            <Separator verticalSize={15} />

            <SC.WrapperFormContent>
              <Field
                id="zipCode"
                name="zipCode"
                onChange={formik.handleChange}
                value={formik.values.zipCode}
                inputWidth={384}
                label={
                  <Typography
                    fontType={FontType.medium}
                    color={theme.palette.neutral.regular}
                    size={14}
                  >
                    {t('updateCustomer.input.zipCode.label')}
                  </Typography>
                }
                placeholder={t('updateCustomer.input.zipCode.placeholder')}
              />

              <Separator horizontalSize={16} />
              <Separator verticalSize={15} />

              <Field
                id="address"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                inputWidth={384}
                label={
                  <Typography
                    fontType={FontType.medium}
                    color={theme.palette.neutral.regular}
                    size={14}
                  >
                    {t('updateCustomer.input.address.label')}
                  </Typography>
                }
                placeholder={t('updateCustomer.input.address.placeholder')}
              />
            </SC.WrapperFormContent>

            <Separator verticalSize={20} />

            <SC.WrapperFormContent>
              <SC.WrapperField>
                <FieldSelect
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      ESTADO
                    </Typography>
                  }
                  name="state"
                  id="state"
                  options={stateList()}
                  selected={formik.values.state}
                  handleChange={handleStateChange}
                />
              </SC.WrapperField>

              <Separator horizontalSize={16} />
              <Separator verticalSize={5} />

              <SC.WrapperField>
                <FieldSelect
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      CIDADE
                    </Typography>
                  }
                  name="city"
                  id="city"
                  options={cityOptions}
                  selected={formik.values.city}
                  handleChange={handleCityChange}
                />
              </SC.WrapperField>
            </SC.WrapperFormContent>

            <Separator verticalSize={50} />

            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              size={20}
            >
              {t('signUpCustomer.modulesTitle')}
            </Typography>

            <Separator verticalSize={20} displayLine />

            <SC.WrapperFormContent>
              <SC.WrapperField>
                <FieldSelect
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      {t('signUpCustomer.modules.negotiationPanel.label')}
                    </Typography>
                  }
                  name="negotiationPanel"
                  id="negotiationPanel"
                  options={modulesOptions}
                  selected={formik.values.negotiationPanel}
                  handleChange={(value) =>
                    formik.setFieldValue('negotiationPanel', value)
                  }
                />
              </SC.WrapperField>

              <SC.WrapperField>
                <FieldSelect
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      {t('signUpCustomer.modules.strategyMonitor.label')}
                    </Typography>
                  }
                  name="strategyMonitor"
                  id="strategyMonitor"
                  options={modulesOptions}
                  selected={formik.values.strategyMonitor}
                  handleChange={(value) =>
                    formik.setFieldValue('strategyMonitor', value)
                  }
                />
              </SC.WrapperField>
            </SC.WrapperFormContent>
          </SC.WrapperForm>

          <Separator verticalSize={50} />

          <Button
            type="submit"
            width={384}
            height={38}
            loading={isLoading}
            disabled={!formik.isValid}
          >
            {t('updateCustomer.buttonCreateAccount')}
          </Button>
        </SC.WrapperContent>
      </form>
    </Modal>
  )
}

export default Update
