import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { CSVLink } from 'react-csv'

import { DefaultProps } from '@resources/types'
import { Fonts } from '@resources/types/mixins'
export const Container = styled.div`
  width: 1040px;
`

export const WrapperHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
`

export const WrapperFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const WrapperConsolidated = styled.div`
  width: 100%;
  height: 300px;
  margin: 40px 0 20px 0;
`

export const WrapperTotals = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0 20px 0;
`

export const Total = styled.div<{ width?: string }>`
  text-align: left;
  flex-direction: row;

  width: ${({ width }) => (width ? width : '50%')};
  height: 30px;
  margin-right: 10px;
`

export const WrapperFilter = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  background: #f0f0f010;
  border-radius: 10px;
`
export const OrganizeHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 10px 0;
`

export const WrapperField = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '450px' : '210px')};
  min-height: 20px;
  margin: 0 20px 0 0;
`

export const WrapperButonSearch = styled.div`
  padding: 22px 0 0 0;
  height: 40px;
`

export const WrapperDataGrid = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 0;
  overflow-x: scroll;
`

export const ButtonExport = styled(CSVLink)<DefaultProps>`
  height: 35px;
  width: 100px;

  background-color: ${({ theme }) => theme.palette.success.dark};
  padding: 10px 25px 0px;
  border-radius: 30px;
  border: ${({ theme }) => `2px solid ${theme.palette.primary.darkest}`};

  cursor: pointer;
  ${({ theme }) => theme.mixins.fonts(Fonts.medium)};
  font-size: 15px;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;

    ${({ theme }) => {
      return css`
        li a: {
          color: ${theme.typography.fonts.normal};
        }
      `
    }}
  }
`

export const WrapperIFrame = styled.div`
  display: flex;
  justify-content: center;

  iframe {
    width: 300px;
    height: 467px;
    border: none;
  }
`
