import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'trendingMarket', {
    title: 'MERCADO COM TENDÊNCIA',
    tabs: {
      tab1: { label: 'Teahupoo', key: 'teahupoo' },
      tab2: { label: 'Cloudbreak', key: 'cloudbreak' },
      tab3: { label: 'Pipeline', key: 'pipeline' },
    },
    signalCard: {
      goal: 'OBJETIVO',
      prohibited: 'ENTRADA',
      escape: 'FUGA',
    },
  })
}
