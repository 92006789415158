import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Loading from '@components/Loading'
import Tab from '@components/Tab'
import { generateKey } from '@resources/utils/generateKey'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Icon from '@components/UI/Icon'

import ModalDetail from './ModalDetail'
import SelectSignalToView from './SelectSignalToView'
import SignalCard from './SignalCard'
import { useTrendlessMarket } from './useTrendlessMarket'
import * as SC from './styles'

const TrendlessMarket = () => {
  const {
    data,
    isLoading,
    handleSelectSignal,
    dataOptions,
    dataOptionsSelected,
    handleSignalMarking,
    tabs,
    handleSelectTab,
    handleDetailModal,
    showDetailModal,
  } = useTrendlessMarket()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <SC.WrapperIconTitle>
          <Icon
            name="BiDetail"
            title="Clique aqui para conhecer a descrição da estratégia"
            onCallback={handleDetailModal}
          />
        </SC.WrapperIconTitle>
        <PageTitle>
          <>
            {t('trendlessMarket.title')}
            <br />
            <Typography
              fontType={FontType.regular}
              color={theme.palette.primary.lightest}
              size={16}
            >
              {t('trendlessMarket.subtitle')}
            </Typography>
          </>
        </PageTitle>

        <SC.WrapperFiedlOptions>
          <SelectSignalToView
            onChange={handleSelectSignal}
            selected={dataOptionsSelected}
            options={dataOptions}
          />
        </SC.WrapperFiedlOptions>
      </SC.WrapperHeader>

      <SC.WrapperTradables>
        <Tab data={tabs} onCallback={handleSelectTab} />

        {isLoading && <Loading />}

        {!isLoading && !!data?.length && (
          <>
            {data.map(
              (item) =>
                handleSignalMarking(item.uuid) && (
                  <SignalCard
                    key={generateKey()}
                    name={item.strategy.name}
                    marketPosition={item.marketPosition}
                    orderPrice={item.orderPrice}
                    stopPrice={item.stopPrice}
                    pivotPoint={item.pivotPoint}
                  />
                ),
            )}
          </>
        )}

        {!isLoading && !data?.length && (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Typography
              fontType={FontType.bold}
              color={theme.palette.neutral.regular}
              size={16}
            >
              Nenhum sinal registrado
            </Typography>
          </div>
        )}
      </SC.WrapperTradables>

      {showDetailModal && (
        <ModalDetail display={showDetailModal} closeModal={handleDetailModal} />
      )}
    </SC.Container>
  )
}

export default TrendlessMarket
