import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import { CoverageRatesService } from '@services/coverageRates'

import {
  ICoverageRates,
  ICoverageRatesUpdateRequest,
  ICoverageRatesCreateRequest,
} from '@resources/types/services/coverageRates'
import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'

export const useCreateCovertageRatesAllByCustomer = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  ICoverageRates[],
  any,
  ICoverageRatesCreateRequest,
  unknown
> => {
  return useMutation(CoverageRatesService.crateRatesByCustomer, options)
}

export const useGetCovertageRatesByCustomer = (
  customerId = '',
): UseQueryResult<ICoverageRates[]> => {
  return useQuery(
    ['getCovertageRatesByCustomer', customerId],
    () => {
      return CoverageRatesService.getRatesByCustomer(customerId)
    },
    {
      enabled: !!customerId,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useUpdateCovertageRatesById = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  ICoverageRates,
  any,
  ICoverageRatesUpdateRequest,
  unknown
> => {
  return useMutation(CoverageRatesService.updateRateById, options)
}
