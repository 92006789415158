import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
import Loading from '@components/Loading'
import DataGrid from '@components/UI/DataGrid'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'

import * as SC from './styles'
import { useCoverageRates } from './useCoverageRates'

const CoverageRates = () => {
  const {
    isLoading,
    isLoadingMutationCreateRates,
    header,
    data,
    handleData,
    createCoverageRates,
  } = useCoverageRates()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('coverageRates.title')}</PageTitle>
      </SC.WrapperHeader>

      <Separator verticalSize={8} />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data?.length ? (
            <DataGrid header={header} data={handleData()} />
          ) : (
            <SC.WrapperLink>
              <Typography
                fontType={FontType.bold}
                color={theme.palette.neutral.regular}
                size={16}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  Sua taxa de abertura ainda não foi criada.
                  <br /> Clique no link abaixo para criar e aplicar a cobertura
                  no Painel de Negociação.
                </div>
              </Typography>

              <Separator verticalSize={20} />

              <SC.Link onClick={createCoverageRates}>
                {!isLoadingMutationCreateRates
                  ? 'Criar minhas taxas de cobertura'
                  : 'Criando suas taxas, aguarde...'}
              </SC.Link>
            </SC.WrapperLink>
          )}
        </>
      )}
    </SC.Container>
  )
}

export default CoverageRates
