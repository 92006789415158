import styled from '@emotion/styled'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 300px);
  margin: 0;
`
