import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/constants/devices'

export const Content = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 60px);
  padding: 50px 0 0 0;

  @media (${mediaQuerydevices.mobile}) {
    width: 100%;
  }
`

export const WrapperCard = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  @media (${mediaQuerydevices.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const Card = styled.div<DefaultProps>`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 40px 0;
`

export const WrapperImage = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.neutral.regular};
  margin: 0 0 30px 0;
`
