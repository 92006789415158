import SiteHome from '@pages/Site/Home'
import SiteNotFound from '@pages/Site/NotFound'

import { WrapperRouteType } from '@resources/types/route'
import BackofficeOperationalManagement from '@pages/Backoffice/OperationalManagement'
import BackofficeOperationalMatching from '@pages/Backoffice/OperationalMatching'
import BackofficeSignIn from '@pages/Backoffice/SignIn'
import BackofficeSignOut from '@pages/Backoffice/SignOut'
import ForgotPasswordContainer from '@pages/Backoffice/ForgotPassword'
import NewPasswordContainer from '@pages/Backoffice/NewPassword'
import CreatePassword from '@pages/Backoffice/CreatePassword'
import BackofficeNotFound from '@pages/Backoffice/NotFound'
import Access from '@pages/Backoffice/AccessManagement'
import AccessByCustomer from '@pages/Backoffice/AccessByCustomer'
import BackofficeCustomer from '@pages/Backoffice/Customer'
import UsersByCustomer from '@pages/Backoffice/UsersByCustomer'
import Tables from '@pages/Backoffice/Tables'
import OperationalManagementReport from '@pages/Backoffice/OperationalManagementReport'
import StrategiesMonitor from '@pages/Backoffice/StrategiesMonitor'
import PerformanceReport from '@pages/Backoffice/PerformanceReport'
import CoverageRates from '@pages/Backoffice/CoverageRates'
import MarketOpening from '@pages/Backoffice/MarketOpening'
import TrendingMarket from '@pages/Backoffice/TrendingMarket'
import TrendlessMarket from '@pages/Backoffice/TrendlessMarket'
import EconomicCalendar from '@pages/Backoffice/EconomicCalendar'
import CurrencyConverter from '@pages/Backoffice/CurrencyConverter'
import FibonacciCalculator from '@pages/Backoffice/FibonacciCalculator'
import PivotPointCalculator from '@pages/Backoffice/PivotPointCalculator'
import CommoditiesTechnicalAnalysis from '@pages/Backoffice/CommoditiesTechnicalAnalysis'
import CurrencyTechnicalAnalysis from '@pages/Backoffice/CurrencyTechnicalAnalysis'
import CommoditiesAgricultureAnalysis from '@pages/Backoffice/CommoditiesAgricultureAnalysis'
import CommoditiesEnergyAnalysis from '@pages/Backoffice/CommoditiesEnergyAnalysis'
import CommoditiesFundamentalistAnalysis from '@pages/Backoffice/CommoditiesFundamentalistAnalysis'
import CommoditiesMetalsAnalysis from '@pages/Backoffice/CommoditiesMetalsAnalysis'
import CommoditiesStrategy from '@pages/Backoffice/CommoditiesStrategy'
import CurrencyFundamentalistAnalysis from '@pages/Backoffice/CurrencyFundamentalistAnalysis'
import CurrencySignals from '@pages/Backoffice/CurrencySignals'
import MarketResumeFundamentalistAnalysis from '@pages/Backoffice/MarketResumeFundamentalistAnalysis'
import MarketResumeTechnicalAnalysis from '@pages/Backoffice/MarketResumeTechnicalAnalysis'
import NewsCommodities from '@pages/Backoffice/NewsCommodities'
import NewsCurrencyAndExchange from '@pages/Backoffice/NewsCurrencyAndExchange'
import NewsEconomicIndicators from '@pages/Backoffice/NewsEconomicIndicators'
import NewsEconomy from '@pages/Backoffice/NewsEconomy'
import NewsPolitics from '@pages/Backoffice/NewsPolitics'
import NewsStockMarket from '@pages/Backoffice/NewsStockMarket'

const PathRoutes = {
  site: {
    url: '/',
    screen: <SiteHome />,
    autenticated: false,
    wrapper: WrapperRouteType.site,
  },
  notFound: {
    url: '/*',
    screen: <SiteNotFound />,
    autenticated: false,
    wrapper: WrapperRouteType.site,
  },
  backoffice: {
    signIn: {
      url: '/sign-in',
      screen: <BackofficeSignIn />,
      autenticated: false,
      wrapper: WrapperRouteType.panel,
    },
    forgot: {
      url: '/forgot',
      screen: <ForgotPasswordContainer />,
      autenticated: false,
      wrapper: WrapperRouteType.panel,
    },
    newPassword: {
      url: '/new-password/:userid',
      screen: <NewPasswordContainer />,
      autenticated: false,
      wrapper: WrapperRouteType.panel,
    },
    confirmPassword: {
      url: '/confirm/:userid',
      screen: <CreatePassword />,
      autenticated: false,
      wrapper: WrapperRouteType.panel,
    },
    signOut: {
      url: '/sign-out',
      screen: <BackofficeSignOut />,
      autenticated: false,
      wrapper: WrapperRouteType.panel,
    },
    operationalManagement: {
      url: '/operational-management',
      screen: <BackofficeOperationalManagement />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    operationalMatching: {
      url: '/operational-matching',
      screen: <BackofficeOperationalMatching />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    accessManagement: {
      url: '/access',
      screen: <Access />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    accessByCustomer: {
      url: '/access-by-customer',
      screen: <AccessByCustomer />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    customer: {
      url: '/customer',
      screen: <BackofficeCustomer />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    usersByCustomer: {
      url: '/users-by-customer',
      screen: <UsersByCustomer />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    tables: {
      url: '/tables',
      screen: <Tables />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    operationalManagementReport: {
      url: '/operationa-managemen-report',
      screen: <OperationalManagementReport />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    notFound: {
      url: '/404',
      screen: <BackofficeNotFound />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    strategiesMonitor: {
      url: '/strategies-monitor',
      screen: <StrategiesMonitor />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    trendingMarket: {
      url: '/trending-market',
      screen: <TrendingMarket />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },

    trendlessMarket: {
      url: '/trendless-market',
      screen: <TrendlessMarket />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    perfomanceReport: {
      url: '/perfomance-report',
      screen: <PerformanceReport />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    coverageTaxes: {
      url: '/coverage-taxes',
      screen: <CoverageRates />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    marketOpening: {
      url: '/market-opening',
      screen: <MarketOpening />,
      autenticated: true,
      wrapper: WrapperRouteType.backoffice,
    },
    economicCalendar: {
      url: '/economic-calendar',
      screen: <EconomicCalendar />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    currencyConverter: {
      url: '/currency-converter',
      screen: <CurrencyConverter />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    fibonacciCalculator: {
      url: '/fibonacci-calculator',
      screen: <FibonacciCalculator />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    pivotPointCalculator: {
      url: '/pivot-point-calculator',
      screen: <PivotPointCalculator />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },

    /* MARKET COMPONENTS */
    marketResumeTechnicalAnalysis: {
      url: '/market-resume-technical-analysis',
      screen: <MarketResumeTechnicalAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    marketResumeFundamentalistAnalysis: {
      url: '/market-resume-fundamentalist-analysis',
      screen: <MarketResumeFundamentalistAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },

    /* CURRENCY COMPONENTS */
    currencyTechnicalAnalysis: {
      url: '/currency-technical-analysis',
      screen: <CurrencyTechnicalAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    currencyFundamentalistAnalysis: {
      url: '/currency-fundamentalist-analysis',
      screen: <CurrencyFundamentalistAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    currencySignals: {
      url: '/currency-signals',
      screen: <CurrencySignals />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },

    /* COMMODITIES COMPONENTS */
    commoditiesTechnicalAnalysis: {
      url: '/commodities-technical-analysis',
      screen: <CommoditiesTechnicalAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    commoditiesFundamentalistAnalysis: {
      url: '/commodities-fundamentalist-analysis',
      screen: <CommoditiesFundamentalistAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    commoditiesEnergyAnalysis: {
      url: '/commodities-energy-analysis',
      screen: <CommoditiesEnergyAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    commoditiesAgricultureAnalysis: {
      url: '/commodities-agriculture-analysis',
      screen: <CommoditiesAgricultureAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    commoditiesMetalsAnalysis: {
      url: '/commodities-metals-analysis',
      screen: <CommoditiesMetalsAnalysis />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    commoditiesStrategy: {
      url: '/commodities-strategy',
      screen: <CommoditiesStrategy />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },

    /* NEWS COMPONENTS */
    newsCommodities: {
      url: '/news-commodities',
      screen: <NewsCommodities />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    newsPolitics: {
      url: '/news-politics',
      screen: <NewsPolitics />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    newsCurrencyAndExchange: {
      url: '/news-currency-and-exchange',
      screen: <NewsCurrencyAndExchange />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    newsEconomy: {
      url: '/news-economy',
      screen: <NewsEconomy />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    newsStockMarket: {
      url: '/news-stock-market',
      screen: <NewsStockMarket />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
    newsEconomicIndicators: {
      url: '/news-economic-indicators',
      screen: <NewsEconomicIndicators />,
      autenticated: false,
      wrapper: WrapperRouteType.backoffice,
    },
  },
}

export default PathRoutes
