import { useEffect, useState } from 'react'

import { IMenuByProfile } from '@route/menu'
import { getNewMenuByProfile } from '@route/menu/newMenu'
import { useAuthMethods } from '@store/auth'
import { permissionsByProfile } from '@route/menu/permissions'
import { ProfileTypes } from '@resources/types/profileTypes'

import { useNavigate, useLocation } from 'react-router-dom'

export const useMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const authStore = useAuthMethods()

  const newMenu: Array<Record<string, any>> = getNewMenuByProfile()
  const permissions = permissionsByProfile[authStore.profile.profile]

  const [firstRender, setFirstRender] = useState(true)
  const [activeMenu, setActiveMenu] = useState<string>('')
  const [activeItem, setActiveItem] = useState(permissions.routes[0])

  const menuNavigate = (item: IMenuByProfile, e?: any) => {
    e.stopPropagation()

    navigate(item.link)
    setActiveItem(item.label)
  }

  useEffect(() => {
    const foundMenu = permissions.routes.find(
      (item) => item === location.pathname,
    )

    newMenu.forEach((menuItem) => {
      const menuItemChildren = menuItem.items.find((menuChildrenItem) => {
        return menuChildrenItem.link === location.pathname
      })

      if (!menuItemChildren && menuItem.link === location.pathname) {
        setActiveMenu(menuItem.id)
        return { menuMain: menuItem.id, menuChildren: '' }
      }

      const menuItemId = menuItemChildren?.id.split('_')[0]

      if (menuItemId === menuItem.id) {
        setActiveMenu(menuItem.id)
        return { menuMain: menuItem.id, menuChildren: menuItemChildren.id }
      }
    })

    setActiveItem(String(foundMenu))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, permissions])

  const handleAccordionMainMenu = (menuId: string) => {
    const setToMenuId = menuId === activeMenu ? '' : menuId

    setActiveMenu(setToMenuId)
  }

  const allowedByProfile = (allowed: boolean) => {
    return authStore.profile.profile === ProfileTypes.master ? true : allowed
  }

  const menuAuthorized = newMenu.map((menu) => {
    const mainMenu =
      permissions.menu.includes(menu.id) &&
      allowedByProfile(menu.allowed) &&
      menu
    const childrenMenu = mainMenu?.items
      ? mainMenu?.items.filter(
          (item) =>
            permissions.menu.includes(item.id) &&
            allowedByProfile(item.allowed) &&
            item,
        )
      : []

    return !mainMenu ? null : { ...mainMenu, items: childrenMenu }
  })

  useEffect(() => {
    if (activeMenu) {
      setFirstRender(false)
    }
  }, [activeMenu])

  return {
    menuNavigate,
    handleAccordionMainMenu,
    activeMenu,
    activeItem, //TODO - Melhorar essa lógica
    menuAuthorized,
    firstRender,
  }
}
