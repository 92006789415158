import { createElement, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import { updateSchema } from './yup/updateSchema'
import { IFormikValues, IProps } from './types'
import { cellPhoneMask, cpfCnpjMask } from '@resources/helpers/utils'
import { PersonType } from '@pages/Backoffice/Customer/SignUpCustomer'
import { States } from '@resources/helpers/states'
import { useCustomerUpdate } from '@hooks/customer/useCustomer'

import View from './view'

const UpdateContainer = ({ showModal, handleModal, data }: IProps) => {
  const alert = useAlert()
  const queryClient = useQueryClient()

  const modulesOptions = [
    { value: 'o', label: 'Negar acesso' },
    { value: '1', label: 'Liberar acesso' },
  ]

  const [cityOptions, setCityOptions] = useState<
    { value: string; label: string }[]
  >([])

  const [personType, setPersonType] = useState<PersonType | null>(
    data?.personType === PersonType.PJ ? PersonType.PJ : PersonType.PF || '',
  )

  const { mutate, isLoading } = useCustomerUpdate()

  const onSuccess = (): void => {
    alert.success('Cliente atualizado com sucesso!')

    queryClient.invalidateQueries(['getTableList'])

    handleModal()
  }

  const onError = (): void => {
    alert.error('Houve um erro ao cadastrar o cliente. Tente novamente!')
  }

  const handleAuthenticate = (prop: IFormikValues) => {
    const dataCustomer = {
      user: String(data?.uuid),
      email: prop.email,
      name: prop.name,
      tradingName: prop.tradingName,
      corporateName: prop.corporateName,
      personType: personType === PersonType.PJ ? PersonType.PJ : PersonType.PF,
      document: prop.cpfCnpj,
      cellphone: prop.cellphoneNumber,
      phone: prop.phone,
      city: prop.city,
      state: prop.state,
      zipCode: prop.zipCode,
      address: prop.address,
      negotiationPanel: prop.negotiationPanel,
      strategyMonitor: prop.strategyMonitor,
    }

    mutate(dataCustomer, { onSuccess, onError })
  }

  const stateList = () => {
    return [
      { value: '', label: '' },
      ...States.map((item) => {
        return { value: item.name, label: item.name }
      }),
    ]
  }

  const formik = useFormik({
    initialValues: {
      email: data?.email || '',
      name: data?.name || '',
      tradingName: data?.tradingName || '',
      corporateName: data?.corporateName || '',
      personType:
        data?.personType === PersonType.PJ
          ? PersonType.PJ
          : PersonType.PF || '',
      cpfCnpj: cpfCnpjMask(data?.document.toString() || ''),
      cellphoneNumber: cellPhoneMask(data?.cellphone || ''),
      phone: data?.phone || '',
      city: data?.city || '',
      state: data?.state || '',
      zipCode: data?.zipCode || '',
      address: data?.address || '',
      negotiationPanel: data?.negotiationPanel || '0',
      strategyMonitor: data?.strategyMonitor || '0',
    },
    validationSchema: updateSchema(data?.personType),
    onSubmit: handleAuthenticate,
  })

  const handleCityChange = (citySelected: string) => {
    formik.setFieldValue('city', citySelected)
  }

  const handleStateChange = (stateSelected: string) => {
    const state = States.find((item) => item.name === stateSelected)

    if (!state) return

    const cities = [
      { value: '', label: '' },
      ...state.cities.map((item) => {
        return { value: item, label: item }
      }),
    ]
    setCityOptions(cities)
    formik.setFieldValue('state', stateSelected)
    formik.setFieldValue('city', '')
  }

  useEffect(() => {
    handleStateChange(data?.state || '')
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (formik.values.city || !data?.city) return

    const selectedCity = cityOptions.find((item) => item.label === data?.city)

    handleCityChange(selectedCity?.value as string)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.city, cityOptions, data?.city])

  const viewProps = {
    formik,
    isLoading,
    showModal,
    handleModal,
    personType,
    setPersonType,
    handleStateChange,
    handleCityChange,
    cityOptions,
    stateList,
    modulesOptions,
  }

  return createElement(View, viewProps)
}

export default UpdateContainer
