import { createElement, useEffect, useState } from 'react'

import { IDimensionsProps, IViewProps, IProps, typeIcons } from './types'
import { mapIcons } from './mapper'

import View from './view'

const IconContainer = ({
  name,
  id = name,
  width,
  height,
  onCallback,
  alt = '',
  title = '',
}: IProps) => {
  const [iconElement, setIconElement] = useState(undefined)
  const [type, setType] = useState('')
  const [iconDimensions, setIconDimensions] = useState<IDimensionsProps>({
    width: 0,
    height: 0,
  })

  const setDimensions = (dimensions) => {
    if (width && height) {
      setIconDimensions({ width, height })
      return
    }

    setIconDimensions(dimensions)
  }

  const prepareIconInternal = (icon) => {
    setIconElement(icon.element)
    setDimensions(icon.dimensions)
  }

  const prepareIconReactIcon = (icon) => {
    setIconElement(icon.element)
    setDimensions(icon.dimensions)
  }

  const actionsByType = {
    [typeIcons.SVG]: (icon: any) => prepareIconInternal(icon),
    [typeIcons.PNG]: (icon: any) => prepareIconInternal(icon),
    [typeIcons.RA]: (icon: any) => prepareIconReactIcon(icon),
  }

  const getIcon = () => {
    try {
      const icon = mapIcons.find((item) => item.name === name)
      if (!icon) return

      const type: typeIcons = icon.type

      setType(icon.type)

      actionsByType[type](icon)
    } catch {
      //retunr
    }
  }

  useEffect(() => {
    getIcon()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const viewProps: IViewProps = {
    element: iconElement,
    dimensions: iconDimensions,
    type,
    onCallback,
    alt,
    title,
    id,
  }

  return createElement(View, viewProps)
}

export default IconContainer
