import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { IinputStyle } from './types'
import { FontType } from '@components/UI/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`

export const WrapperDate = styled.div<DefaultProps & IinputStyle>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  text-align: center;
  border: ${({ backgroundColor }) => `1px solid ${backgroundColor}`};
  box-sizing: border-box;
  font-size: 14px;
  height: ${({ inputHeight }) => `${inputHeight}px`};
  outline: none;
  padding: 0 8px;
  width: ${({ inputWidth, widthFull }) =>
    widthFull ? '100%' : `${inputWidth}px`};
  color: ${({ textColor }) => textColor};

  :-ms-input-placeholder,
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
`

export const WrapperIcon = styled.div<DefaultProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
  margin: -42px 10px 0 0;
`

export const WrapperDatePicker = styled.div`
  height: 40px;
  margin: 5px 0 0 20px;
  position: absolute;
  z-index: 999;
`

export const TextAlignLeft = styled.div`
  width: 100%;
  text-align: left;
`
