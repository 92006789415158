import styled from '@emotion/styled'
import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/types/devices'

export const Container = styled.div``

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0;
  background-color: 'red';
`

export const WrapperFormContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;

  @media (${mediaQuerydevices.tablet}) {
    flex-direction: column;
  }
`
