import { useQuery } from 'react-query'

import { OperationCustomerService } from '@services/operationCustomer'

import {
  IOperationCustomerResponse,
  IOperationCustomerAddRequest,
} from '@resources/types/services/operationCustomer'

export const useGetCustomerOperationByDocument = (
  enableSearch: boolean,
  document: string,
  customerId: string,
) => {
  return useQuery<IOperationCustomerResponse, Error>(
    ['useSignIn', enableSearch, document, customerId],
    () => {
      return OperationCustomerService.getByDocument(document, customerId)
    },
    {
      enabled: enableSearch,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useGetCustomerOperationByName = (
  enableSearch: boolean,
  name: string,
  customerId: string,
) => {
  return useQuery<IOperationCustomerResponse[], Error>(
    ['getCustomerOperationByName', name, customerId],
    () => {
      return OperationCustomerService.getByName(name, customerId)
    },
    {
      enabled: enableSearch,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: 0,
      retry: 0,
    },
  )
}

export const useAddOperarionCustomerByCustomer = (
  customerOperation: IOperationCustomerAddRequest | null,
) => {
  return useQuery<IOperationCustomerResponse, Error>(
    ['useAccessSignUp', customerOperation],
    () => {
      return OperationCustomerService.addOperarionCustomerByCustomer(
        customerOperation as IOperationCustomerAddRequest,
      )
    },
    {
      enabled: !!customerOperation,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}
