/* eslint-disable @typescript-eslint/no-unused-vars */
import Icon from '@components/UI/Icon'
import Typography, { FontType } from '@components/UI/Typography'
import { generateKey } from '@resources/utils/generateKey'
import { theme } from '@resources/theme'

import { useFieldOptionsCustom } from './useFieldOptionsCustom'
import * as SC from './styles'
import { IProps, OptionType } from './types'
import Separator from '@components/UI/Separator'

const SelectSignalToView = ({ onChange, selected, options }: IProps) => {
  const wrapperKey = generateKey()

  const { handleChange, handleWrapperListOption, showListOption } =
    useFieldOptionsCustom({
      onChange,
      selected,
      wrapperKey,
      options,
    })

  const OptionItem = (item: OptionType) => {
    const generatedKey = generateKey()

    const itemSelected = [...selected].includes(item.id)

    return (
      <SC.WrapperItem
        onClick={() => handleChange(item)}
        key={generatedKey}
        className="item-option"
      >
        <Typography
          fontType={FontType.light}
          size={16}
          color={theme.palette.neutral.regular}
        >
          {item.name}
        </Typography>
        <Separator horizontalSize={10} />

        {itemSelected && <Icon name="FaCheck" />}
      </SC.WrapperItem>
    )
  }

  return (
    <SC.Container>
      <SC.ContainerInput
        onClick={handleWrapperListOption}
        id={`input-list-option-${wrapperKey}`}
      >
        <SC.Name>
          <Typography
            fontType={FontType.medium}
            size={16}
            color={theme.palette.primary.light}
          >
            FILTRAR ESTRATÉGIA
          </Typography>
        </SC.Name>

        <SC.IconContainer rotate={showListOption ? 0 : 180}>
          <Icon name="AiFillCaretUp" />
        </SC.IconContainer>
      </SC.ContainerInput>

      {showListOption !== null && (
        <SC.WrapperListOption
          id={`wrapper-list-option-${wrapperKey}`}
          visible={showListOption}
        >
          {options.map(OptionItem)}
        </SC.WrapperListOption>
      )}
    </SC.Container>
  )
}

export default SelectSignalToView
