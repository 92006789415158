/* eslint-disable @typescript-eslint/no-unused-vars */

import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
// import Loading from '@components/Loading'
import Button from '@components/UI/Button'
import DataGrid from '@components/UI/DataGrid'
import ModalConfirmDelete from '@components/ModalConfirmDelete'
import { ProfileTypes } from '@resources/types/profileTypes'
import SignUpAccess from '@pages/Backoffice/SignUpAccess'
import UpdateAccess from '@pages/Backoffice//UpdateAccess'
import { useAccessManagementContainer } from './useAccessManagementContainer'
import * as SC from './styles'

const AccessManagementContainer = () => {
  const {
    // isError, //TODO - handleErrorAfter
    isLoading,
    handleSignUpModal,
    showSignUpModal,
    header,
    handleData,
    userData,
    handleUpdateModal,
    showDeleteModal,
    handleDeleteModal,
    fetchDeleteModal,
    isLoadingDeleteUser,
    profile,
  } = useAccessManagementContainer()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('access.title')}</PageTitle>

        <Button onClick={handleSignUpModal}>{t('access.buttonAdd')}</Button>
      </SC.WrapperHeader>

      <Separator verticalSize={8} />

      {/* {isLoading && <Loading />} */}
      <DataGrid header={header} data={handleData()} loading={isLoading} />

      {showSignUpModal && (
        <SignUpAccess
          showModal={showSignUpModal}
          handleModal={handleSignUpModal}
          isMaster={profile === ProfileTypes.master}
        />
      )}

      {!!userData && (
        <UpdateAccess
          showModal={!!userData}
          handleModal={handleUpdateModal}
          data={userData}
          isMaster={userData?.profile === ProfileTypes.master}
        />
      )}

      {!!showDeleteModal && (
        <ModalConfirmDelete
          showModal={showDeleteModal}
          onCloseModal={!isLoadingDeleteUser ? handleDeleteModal : () => ''}
          onReturnYes={fetchDeleteModal}
          onReturnNo={handleDeleteModal}
        />
      )}
    </SC.Container>
  )
}

export default AccessManagementContainer
