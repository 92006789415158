/* eslint-disable @typescript-eslint/no-unused-vars */
import { isMobile } from 'react-device-detect'

import Field from '@components/UI/Field'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Button from '@components/UI/Button'
import Modal from '@components/Modal'
import Loading from '@components/Loading'

import { useModalCustomProspecting } from './useModalCustomProspecting'
import * as SC from './styles'
import { IProps } from './types'

const ModalCustomProspecting = ({ display, handleCloseModal }: IProps) => {
  const { formik, isLoading } = useModalCustomProspecting({ handleCloseModal })

  return (
    <Modal
      display={display}
      onClose={handleCloseModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={20}
        >
          Fale Conosco
        </Typography>
      }
      width={isMobile ? 350 : 500}
      height={450}
    >
      <>
        {isLoading ? (
          <>
            <Separator verticalSize={20} />

            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              size={14}
              align="center"
            >
              Aguarde, estamos enviado seu contato
            </Typography>

            <Separator verticalSize={10} />

            <Loading />
          </>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <SC.SectionMain>
              <SC.WrapperField widthFull>
                <Field
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      Nome
                    </Typography>
                  }
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  placeholder="Digite seu nome completo"
                  widthFull
                />
              </SC.WrapperField>

              <Separator verticalSize={5} />

              <SC.WrapperField widthFull>
                <Field
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      Email
                    </Typography>
                  }
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Digite seu Email"
                  widthFull
                />
              </SC.WrapperField>

              <Separator verticalSize={5} />

              <SC.WrapperField widthFull>
                <Field
                  label={
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      Telefone para contato
                    </Typography>
                  }
                  name="cellphone"
                  type="number"
                  onChange={(e) => {
                    formik.setFieldValue('cellphone', e.target.value)
                  }}
                  value={formik.values.cellphone}
                  placeholder="Digite o telefone para contato"
                  maxLength={20}
                  widthFull
                />
              </SC.WrapperField>

              <Separator verticalSize={10} />

              <SC.OrganizeHorizontal>
                <Button
                  type="submit"
                  height={60}
                  width={150}
                  loading={isLoading}
                  disabled={!formik.isValid || !formik.dirty}
                >
                  ENVIAR
                </Button>
              </SC.OrganizeHorizontal>
            </SC.SectionMain>
          </form>
        )}
      </>
    </Modal>
  )
}

export default ModalCustomProspecting
