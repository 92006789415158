import { useState } from 'react'

import { useGetSignalList } from '@hooks/signal/useSignal'
import {
  cookieVariabels,
  setCookie,
  getCookie,
} from '@resources/helpers/application/cookie'
import { ISignal } from '@resources/types/services/signal'

import { OptionType } from './SelectSignalToView/types'
import { SignalToViewOperation } from './types'

export const useStrategiesMonitor = () => {
  const getSignalFromCookie =
    getCookie(cookieVariabels.exchangeSignalSelected)?.split(',') || []

  const { data, isLoading } = useGetSignalList()
  const [dataOptionsSelected, setDataOptionsSelected] =
    useState<string[]>(getSignalFromCookie)
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [selectedSignal, setSelectedSignal] = useState<ISignal | string>('')

  const handleSelectSignal = async (
    optionSelected: string,
    action: keyof typeof SignalToViewOperation,
  ) => {
    try {
      if (action === SignalToViewOperation.add) {
        setDataOptionsSelected((itens) => [...itens, optionSelected])
        setCookie(
          cookieVariabels.exchangeSignalSelected,
          [...dataOptionsSelected, optionSelected].join(','),
        )
      }

      if (action === SignalToViewOperation.remove) {
        const filterOptions = dataOptionsSelected.filter(
          (item) => item !== optionSelected,
        )

        setDataOptionsSelected(filterOptions)
        setCookie(
          cookieVariabels.exchangeSignalSelected,
          filterOptions.join(','),
        )
      }

      console.log('Selecte Plan:', optionSelected)
    } catch (error) {
      console.error('Selecte Plan:', error)
    }
  }

  const handleSignalMarking = (option: string) => {
    if (!dataOptionsSelected.length) return true

    return dataOptionsSelected.includes(option)
  }

  const dataOptions: OptionType[] =
    data?.map((item) => ({
      id: item.uuid,
      name: item.strategy.name,
    })) || []

  const handleDetailModal = (signalId: string) => {
    const signalById = data?.find((item) => item.uuid === signalId) as ISignal

    setSelectedSignal(signalId ? signalById : '')
    setShowDetailModal(!showDetailModal)
  }

  return {
    data,
    isLoading,
    handleSelectSignal,
    dataOptions,
    dataOptionsSelected,
    handleSignalMarking,
    showDetailModal,
    handleDetailModal,
    selectedSignal,
  }
}
