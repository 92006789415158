import React from 'react'

import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import { generateKey } from '@resources/utils/generateKey'

import { IProps } from './types'
import * as SC from './styles'

const PivotPoint = ({ singularData }: IProps) => {
  if (
    !singularData ||
    (!singularData?.pp && !singularData?.r && !singularData?.s)
  ) {
    return (
      <Typography
        key={generateKey()}
        fontType={FontType.medium}
        color={theme.palette.neutral.regular}
        size={13}
        align="center"
      >
        Sem Informação
      </Typography>
    )
  }

  const dataRender = (data) => {
    const { pp, r, s } = data

    return (
      <>
        {r.map((value) => (
          <Typography
            key={generateKey()}
            fontType={FontType.medium}
            color={theme.palette.success.regular}
            size={13}
          >
            {String(value).slice(0, 6)}
          </Typography>
        ))}

        {pp.map((value) => (
          <Typography
            key={generateKey()}
            fontType={FontType.medium}
            color={theme.palette.primary.lightest}
            size={13}
          >
            {String(value).slice(0, 6)}
          </Typography>
        ))}

        {s.map((value) => (
          <Typography
            key={generateKey()}
            fontType={FontType.medium}
            color={theme.palette.error.regular}
            size={13}
          >
            {String(value).slice(0, 6)}
          </Typography>
        ))}
      </>
    )
  }

  return <SC.Container>{dataRender(singularData)}</SC.Container>
}

export default PivotPoint
