import Icon from '@components/UI/Icon'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import { useAuthMethods } from '@store/auth'
import UpdateContainer from '@pages/Backoffice/UpdateProfile'
import { translateNameProfile } from '@resources/utils/forString'

import * as SC from './styles'
import { IViewProps } from './types'

const MenuView = ({ logout, handleUpdateModal, userData }: IViewProps) => {
  const authStore = useAuthMethods()

  return (
    <SC.WrappeMenu>
      {authStore.profile?.parentCustomer && (
        <SC.CustomerInfo>
          <Typography
            fontType={FontType.bold}
            size={14}
            color={theme.palette.neutral.regular}
          >
            {authStore.profile.parentCustomer?.name.toUpperCase() ||
              authStore.profile.parentCustomer?.corporateName.toUpperCase()}
          </Typography>
        </SC.CustomerInfo>
      )}

      <SC.PersonInfo>
        <Typography
          fontType={FontType.light}
          size={14}
          color={theme.palette.primary.light}
        >
          OLÁ, {authStore.profile.name.toUpperCase()}
        </Typography>

        <Typography
          fontType={FontType.medium}
          size={14}
          color={theme.palette.neutral.regular}
        >
          {translateNameProfile(authStore.profile.profile)}
        </Typography>
      </SC.PersonInfo>

      <Separator horizontalSize={16} />

      <SC.WrapperProfile>
        <SC.ProfileMenu>
          <Icon name="menuHamburguer" />
        </SC.ProfileMenu>

        <SC.MenuList>
          <SC.MenuItem onClick={() => handleUpdateModal(true)}>
            <Typography
              fontType={FontType.regular}
              color={theme.palette.primary.light}
            >
              Perfil
            </Typography>
            <Icon name="avatar" />
          </SC.MenuItem>

          <SC.MenuItem onClick={logout}>
            <Typography
              fontType={FontType.regular}
              color={theme.palette.primary.light}
            >
              Sair
            </Typography>
            <Icon name="logout" />
          </SC.MenuItem>
        </SC.MenuList>
      </SC.WrapperProfile>

      {!!userData && (
        <UpdateContainer
          showModal={!!userData}
          handleModal={() => handleUpdateModal(false)}
          data={userData}
        />
      )}
    </SC.WrappeMenu>
  )
}

export default MenuView
