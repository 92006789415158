import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/constants/devices'

export const Wrapper = styled.div`
  width: 100%;
  height: 150px !important;
`

export const Container = styled.div<
  { fixedTop: boolean; openMenuMobile: boolean } & DefaultProps
>`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  background: transparent;
  transform: translate(0, 0);
  transition: 0.4s;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  svg {
    width: 190px;
    height: 154px;
  }

  #menu-icon {
    svg {
      width: 45px;
      height: 35px;
      margin: 0;
    }
  }

  @media (${mediaQuerydevices.mobile}) {
    height: ${({ openMenuMobile }) => (!openMenuMobile ? 160 : 120)}px;

    svg {
      width: 150px;
      height: 114px;
      margin-left: 15px;
    }
  }

  ${({ fixedTop, theme }) =>
    fixedTop &&
    `
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 99;
      transform: translate(0, -5px);
      box-shadow: 0px -9.14237px 27.4271px ${theme.palette.primary.dark};
      border-bottom: 2px solid ${theme.palette.secondary.darkest};
      background-color: ${theme.palette.primary.darkest};

      svg {
        width: 90px;
        height: 77px;
      }

      @media (${mediaQuerydevices.mobile}) {
        svg {
          margin-left: 20px;
        }
      }
  `};
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
  width: 1024px;

  @media (${mediaQuerydevices.maxDesktop}) {
    width: 1360px;
  }

  @media (${mediaQuerydevices.mobile}) {
    justify-content: space-between;
    width: 100%;
  }
`
export const WrapperLogo = styled.div`
  width: 90px;
  height: 70px;
  margin: 0;
`

export const WrapperMenu = styled.div<{ hidden: boolean }>`
  display: flex;
  flex: 1;
  height: 50px;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};

  @media (${mediaQuerydevices.mobile}) {
    flex: none;
    width: 200px;
    height: 160px;
    align-items: flex-start;
    flex-direction: column;
  }
`
export const MenuIcon = styled.label`
  width: 60px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 20px;

  @media (${mediaQuerydevices.maxDesktop}) {
    opacity: 0;
  }
`
export const HandleShowIcon = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`
