import {
  IQuotesByPairsResponse,
  IQuotesByPairs,
} from '@resources/types/services/quotes'

export const adapterFromQuoteApi = (
  apiData: IQuotesByPairsResponse[],
): IQuotesByPairs[] => {
  const prepareDataByPair = (data) => {
    try {
      const setupByPay = {
        'SEK/BRL': () => {
          return {
            averagePrice: data.p,
            purchasePrice: data.b,
            salePrice: data.a,
            symbol: data.s,
            timestamp: data.t,
          }
        },
      }

      return setupByPay[data.s]()
    } catch {
      return {
        averagePrice: data.p,
        purchasePrice: data.a,
        salePrice: data.b,
        symbol: data.s,
        timestamp: data.t,
      }
    }
  }

  const response = apiData.map((data) => prepareDataByPair(data))

  return response
}
