import { ProfileTypes } from '@resources/types/profileTypes'

import PathRoutes from '../PathRoutes'

export const permissionsByProfile = {
  [ProfileTypes.master]: {
    menu: [
      'trading-panel',
      'strategy-dashboard',
      'strategy-dashboard_monitor',
      'strategy-dashboard_trending-market',
      'strategy-dashboard_trendless-market',
      'admin-panel',
      'admin-panel_customer',
      'admin-panel_access-management',
      'notifications-monitoring-panel',
      'notifications-monitoring-panel_tools',
      'notifications-monitoring-panel_market-summary-analysis',
      'notifications-monitoring-panel_currency-analysis',
      'notifications-monitoring-panel_commodities-analysis',
      'notifications-monitoring-panel_news',
    ],
    routes: [
      PathRoutes.backoffice.operationalManagement.url,
      PathRoutes.backoffice.strategiesMonitor.url,
      PathRoutes.backoffice.trendingMarket.url,
      PathRoutes.backoffice.trendlessMarket.url,
      PathRoutes.backoffice.customer.url,
      PathRoutes.backoffice.usersByCustomer.url,
      PathRoutes.backoffice.accessManagement.url,
    ],
  },
  [ProfileTypes.admin]: {
    menu: [
      'trading-panel',
      'strategy-dashboard',
      'strategy-dashboard_monitor',
      'strategy-dashboard_trending-market',
      'strategy-dashboard_trendless-market',
      'treasury-panel',
      'treasury-panel_management-report',
      'treasury-panel_perfomance-report',
      'treasury-panel_operation-matching',
      'admin-panel',
      'admin-panel_tables',
      'admin-panel_coverage-taxes',
      'admin-panel_market-opening',
      'admin-panel_access-by-customer',
      'notifications-monitoring-panel',
      'notifications-monitoring-panel_tools',
      'notifications-monitoring-panel_market-summary-analysis',
      'notifications-monitoring-panel_currency-analysis',
      'notifications-monitoring-panel_commodities-analysis',
      'notifications-monitoring-panel_news',
    ],
    routes: [
      PathRoutes.backoffice.operationalManagement.url,
      PathRoutes.backoffice.strategiesMonitor.url,
      PathRoutes.backoffice.trendingMarket.url,
      PathRoutes.backoffice.trendlessMarket.url,
      PathRoutes.backoffice.operationalManagementReport.url,
      PathRoutes.backoffice.perfomanceReport.url,
      PathRoutes.backoffice.operationalMatching.url,
      PathRoutes.backoffice.tables.url,
      PathRoutes.backoffice.coverageTaxes.url,
      PathRoutes.backoffice.marketOpening.url,
      PathRoutes.backoffice.accessByCustomer.url,
    ],
  },
  [ProfileTypes.paymaster]: {
    menu: [
      'trading-panel',
      'strategy-dashboard',
      'strategy-dashboard_monitor',
      'strategy-dashboard_trending-market',
      'strategy-dashboard_trendless-market',
      'treasury-panel',
      'treasury-panel_management-report',
      'treasury-panel_perfomance-report',
      'treasury-panel_operation-matching',
      'admin-panel',
      'admin-panel_tables',
    ],
    routes: [
      PathRoutes.backoffice.operationalManagement.url,
      PathRoutes.backoffice.strategiesMonitor.url,
      PathRoutes.backoffice.trendingMarket.url,
      PathRoutes.backoffice.trendlessMarket.url,
      PathRoutes.backoffice.operationalManagementReport.url,
      PathRoutes.backoffice.perfomanceReport.url,
      PathRoutes.backoffice.operationalMatching.url,
      PathRoutes.backoffice.tables.url,
    ],
  },
  [ProfileTypes.operator]: {
    menu: [
      'trading-panel',
      'strategy-dashboard',
      'strategy-dashboard_monitor',
      'strategy-dashboard_trending-market',
      'strategy-dashboard_trendless-market',
      'treasury-panel',
      'treasury-panel_management-report',
      'treasury-panel_management-report_',
    ],
    routes: [
      PathRoutes.backoffice.operationalManagement.url,
      PathRoutes.backoffice.strategiesMonitor.url,
      PathRoutes.backoffice.trendingMarket.url,
      PathRoutes.backoffice.trendlessMarket.url,
      PathRoutes.backoffice.operationalManagementReport.url,
    ],
  },
}
