import React, { Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from 'react-query'
import { StyledEngineProvider } from '@mui/material/styles'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import Routes from '@route/Routes'
import { registerTranslations } from '@resources/translation/register-translations'

import { StyleGlobal, DesignSystemProvider } from '@components/DesignSystem'

import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient()

const App: React.FC = () => {
  registerTranslations()

  const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
    transition: transitions.SCALE,
  }

  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <DesignSystemProvider>
            <StyledEngineProvider injectFirst>
              <StyleGlobal />
              <AlertProvider template={AlertTemplate} {...options}>
                <Routes />
              </AlertProvider>
              <ToastContainer />
            </StyledEngineProvider>
          </DesignSystemProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Fragment>
  )
}

export default App
