import { ClipLoader } from 'react-spinners'

import { WrapperLoading, Text } from './styles'
import { IViewProps } from './types'

const AuthLoading = ({
  color,
  loading = true,
  text = 'Autenticando usuário. Aguarde...',
}: IViewProps) => {
  return (
    <WrapperLoading>
      {loading && <ClipLoader size={50} color={color} loading={true} />}
      <Text color={color}>{text}</Text>
    </WrapperLoading>
  )
}

export default AuthLoading
