import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div``

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
  padding: 0 30px 0 0;
`

export const WrapperTradables = styled.div`
  display: flex;
  justify-content: space-between;
`
export const WrapperTradableCurrencies = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 50px);
  height: 100%;
  padding: 20px 15px;
  margin: 0 20px 0 0;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.primary.dark};
`

export const WrapperExoticCurrencies = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(25% - 30px);
  height: 100%;
  padding: 20px 15px;
  margin: 0;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.primary.dark};
`
export const WrapperFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`
