import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'strategiesMonitor', {
    title: 'MONITOR DE ESTRATÉGIAS',
    signalCard: {
      goal: 'OBJETIVO',
      prohibited: 'ENTRADA',
      escape: 'FUGA',
    },
  })
}
