import Modal from '@components/Modal'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'

// import FieldSelect from '@components/UI/FieldSelect'
// import FieldCurrency from '@components/UI/FieldCurrency'
// import Typography, { FontType } from '@components/UI/Typography'
// import { theme } from '@resources/theme'

import CustomerOperationAdd from './add'
import CustomerOperationSearch from './search'
import { useModalCustomerOperation } from './useModalCustomerOperation'
import { IProps } from './types'

const ModalCustomerOperation = ({
  display,
  closeModal,
  onCallback,
}: IProps) => {
  const {
    handleCloseModal,
    currentStep,
    setCurrentStep,
    documentSearch,
    setDocumentSearch,
  } = useModalCustomerOperation({
    closeModal,
  })

  const steps = {
    search: {
      modal: {
        width: 500,
        height: 500,
      },
      component: () => (
        <CustomerOperationSearch
          documentSearch={documentSearch}
          setDocumentSearch={setDocumentSearch}
          selectCustomerOperation={onCallback}
          setCurrentStep={setCurrentStep}
          handleCloseModal={handleCloseModal}
        />
      ),
    },
    add: {
      modal: {
        width: 500,
        height: 500,
      },
      component: () => (
        <CustomerOperationAdd
          selectCustomerOperation={onCallback}
          setCurrentStep={setCurrentStep}
          handleCloseModal={handleCloseModal}
        />
      ),
    },
  }

  const renderStep = steps[currentStep]

  return (
    <Modal
      display={display}
      onClose={handleCloseModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={20}
        >
          CLIENTE DA OPERAÇÃO
        </Typography>
      }
      width={renderStep.modal.width}
      height={renderStep.modal.height}
    >
      {renderStep.component()}
    </Modal>
  )
}

export default ModalCustomerOperation
