import { useQuery } from 'react-query'

import { OperationManagementService } from '@services/operationManagement'

import {
  IOperationManagementAddRequest,
  IOperationManagementResponse,
} from '@resources/types/services/operationManagement'

export const useAddOperationManagement = (
  enable: boolean,
  data: IOperationManagementAddRequest | null,
) => {
  return useQuery<IOperationManagementResponse, Error>(
    ['useAddOperationManagement', enable, data],
    () => {
      return OperationManagementService.addOperation(
        data as IOperationManagementAddRequest,
      )
    },
    {
      enabled: enable,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}
