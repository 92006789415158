import React, { createElement } from 'react'

import { IGridHeader, IGridData, IProps, IViewProps } from './types'
import View from './view'

const DataGridContainer: React.FC<IProps> = ({
  header,
  data,
  loading = false,
  width = '100%',
}) => {
  const viewProps: IViewProps = {
    header,
    data,
    loading,
    width: loading ? '100%' : width,
  }

  return createElement(View, viewProps)
}

export default DataGridContainer
export type { IGridHeader, IGridData }
