import { useQuery } from 'react-query'

import { QuotesService } from '@services/quotes'
import {
  IQuotesByPairs,
  IMarketStatusResponse,
} from '@resources/types/services/quotes'

export const useQuotesByPairs = (pairs: string) => {
  return useQuery<IQuotesByPairs[], Error>(
    ['quotesByPairs'],
    () => {
      return QuotesService.getQuotesByPairs(pairs)
    },
    {
      refetchInterval: 1000,
    },
  )
}

export const useMarketStatus = () => {
  return useQuery<IMarketStatusResponse, Error>(['marketStatus'], () => {
    return QuotesService.getMarketStatus()
  })
}
