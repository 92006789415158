import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

import { IStylesProps } from './types'

export const Container = styled.div<DefaultProps & IStylesProps>`
  visibility: hidden;
  padding: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  z-index: 9999;
  transition: 0.2s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    `
    visibility: visible;
    opacity: 1;
  `};
`

export const WrapperModal = styled.div<IStylesProps & DefaultProps>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.primary.dark};
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translate(0, -100%);
  transition: 0.4s;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: translate(0, 0);
    opacity: 1;
  `};
`

export const WrapperContent = styled.div<IStylesProps & DefaultProps>`
  ${({ height }) => height && `height: ${height}px`};
  ${({ width }) => width && `width: ${width}px`};
  display: flex;
  align-items: center;
  flex-direction: column;

  background: ${({ theme }) => theme.palette.primary.dark};
  border-radius: 20px;
`

export const Body = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
  margin: 10px 0;
  padding: 20px 20px;
  text-align: center;
`

export const Header = styled.header<DefaultProps & IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: calc(100% - 40px);
  margin: 0;
  padding: 10px 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

export const CloseButton = styled.div<DefaultProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.secondary.darkest};
`
