import deepmerge from 'deepmerge'

type Translation = {
  [key: string]: Translation | string
}

export class TranslationsManager {
  private static translations: {
    [language: string]: {
      [section: string]: Translation
    }
  } = {}

  private static variables: Record<string, unknown> = {}

  public static addVariables(record: Record<string, unknown>): void {
    TranslationsManager.variables = deepmerge(
      TranslationsManager.variables,
      record,
    )
  }

  public static getVariables(): Record<string, unknown> {
    return TranslationsManager.variables
  }

  public static add(
    language: string,
    section: string,
    translations: Translation,
  ): void {
    const newTranslation = {
      [language]: {
        [section]: translations,
      },
    }

    TranslationsManager.translations = deepmerge(
      TranslationsManager.translations,
      newTranslation,
    )
  }

  public static addThemeTranslations(
    language: string,
    translations: Record<string, Translation>,
  ): void {
    const themeTranslations = {
      [language]: {
        ...translations,
      },
    }

    TranslationsManager.translations = deepmerge(
      TranslationsManager.translations,
      themeTranslations,
    )
  }

  public static generateTranslations(language: string): Translation {
    if (!TranslationsManager.translations[language]) {
      return {}
    }

    return TranslationsManager.translations[language]
  }
}
