import { useLocation } from 'react-router-dom'

import Button from '@components/UI/Button'

import * as SC from './styles'
import { IViewProps } from './types'

const MenuView = ({ navigateToBackoffice }: IViewProps) => {
  const location = useLocation()

  return (
    <SC.Container>
      <SC.WrapperMenu>
        <SC.Ul>
          <SC.Li className={['', '#'].includes(location.hash) ? 'active' : ''}>
            <a href="#">Home</a>
          </SC.Li>
          <SC.Li
            className={['#produtos'].includes(location.hash) ? 'active' : ''}
          >
            <a href="#produtos">Produtos</a>
          </SC.Li>
          <SC.Li
            className={['#quem-somos'].includes(location.hash) ? 'active' : ''}
          >
            <a href="#quem-somos">Quem somos</a>
          </SC.Li>
        </SC.Ul>
      </SC.WrapperMenu>

      <SC.WrapperButton>
        <Button onClick={navigateToBackoffice}>
          &nbsp;&nbsp;&nbsp;&nbsp;Entrar&nbsp;&nbsp;&nbsp;&nbsp;
        </Button>
      </SC.WrapperButton>
    </SC.Container>
  )
}

export default MenuView
