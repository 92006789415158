import * as Yup from 'yup'

export const updateSchema = Yup.object().shape({
  name: Yup.string()
    .test('isValidName', 'Digite seu nome completo', (val) => {
      return String(val).length > 6
    })
    .required('Digite seu nome completo'),

  cpf: Yup.string()
    .test('isValidCPF', 'Digite um CPF válido', (val) => {
      return String(val).length > 11
    })
    .required('Required'),

  cellphone: Yup.string()
    .test('isValidPhone', 'Digite um número de celular válido', (val) => {
      return String(val).length > 11
    })
    .required('Required'),

  email: Yup.string().email('Digite um email válido').required('Required'),

  password: Yup.string().required('Senha é obrigatória'),
  confirmPassword: Yup.string().when('password', {
    is: (val) => val && val.length > 0,
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Senhas precisam ser iguais',
    ),
  }),
})
