import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'createPassword', {
    title: 'Criar senha',
    input: {
      password: {
        label: 'Senha',
        placeholder: 'Insira uma senha',
      },
      confirmPassword: {
        label: 'Confirme a senha',
        placeholder: 'Confirme sua senha',
      },
    },
    button: 'Confirmar',
  })
}
