import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'signUp', {
    title: 'CADASTRO DE USUÁRIO ',
    input: {
      name: {
        label: 'NOME COMPLETO',
        placeholder: 'Insira seu nome completo',
      },
      id: {
        label: 'CPF',
        placeholder: 'Insira seu CPF',
      },
      phone: {
        label: 'CELULAR',
        placeholder: 'Insira seu celular com DDD',
      },
      email: {
        label: 'E-MAIL',
        placeholder: 'Insira seu e-email',
      },
    },
    buttonCreateAccount: 'Cadastrar',
  })
}
