import React from 'react'

export interface IViewProps {
  inputWidth?: number
  inputHeight?: number
  widthFull?: boolean
  maxLength?: number

  testID?: string | undefined
  type?: string
  id?: string
  name?: string | undefined
  value?:
    | ((string | number | null) & (string | number | readonly string[]))
    | undefined
  inputRef?: any
  placeholder?: string
  label?: React.ReactNode
  onChange?: (e) => any
  onBlur?: (event?: React.FocusEvent<HTMLInputElement, Element>) => void
  focus?: boolean
  error?: boolean

  currency?: boolean
  secondary?: boolean
  tertiary?: boolean
  disabled?: boolean

  /* Remover */
  labelColor?: string
  labelTop?: string
  marginInputTop?: number
  decimal?: number
  mask?: string
}

export interface IinputStyle {
  textColor?: string
  backgroundColor?: string
  placeholderColor?: string
  inputWidth: number
  inputHeight: number
  widthFull: boolean
}

export enum EInputType {
  PASSWORD = 'password',
  DOCUMENT = 'document',
}
