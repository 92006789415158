import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import BuySellOperation from '@components/BuySellOperation'
import { ISentiment } from '@resources/types/services/sentiments'
import Loading from '@components/Loading'
import { generateKey } from '@resources/utils/generateKey'
import Toggle from '@components/UI/Toggle'

import { useOperationalManagement } from './useOperationalManagement'
import CoinCard from './CoinCard'
import * as SC from './styles'

const OperationalManagement = () => {
  const {
    showModal,
    operation,
    handleCloseModal,
    handleOperationBuy,
    handleOperationSell,
    coins,
    price,
    quoteId,
    coverageRateApplied,
    setCoverageRateApplied,
    isLoading,
    quotesByPairs,
    isLoadingMerketStatus,
    sentimentIsLoading,
    sentiments,
    isLoadingCoverageRates,
    handleCoverageRateBuy,
    handleCoverageRateSell,
    enableCoverageRate,
    disabledOperation,
    isLoadingMarketOpening,
    getUpdatedRate,
  } = useOperationalManagement()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('operationalManagement.title')}</PageTitle>

        {enableCoverageRate && (
          <Toggle
            labelLeft="SPOT"
            labelRight="TAXAS"
            id="teste"
            isChecked={coverageRateApplied}
            handleChange={() => setCoverageRateApplied(!coverageRateApplied)}
          />
        )}
      </SC.WrapperHeader>

      <SC.WrapperTradables>
        {isLoading ||
        sentimentIsLoading ||
        isLoadingMerketStatus ||
        isLoadingMarketOpening ||
        isLoadingCoverageRates ? (
          <Loading />
        ) : (
          <>
            <SC.WrapperTradableCurrencies>
              <SC.WrapperFlex>
                {quotesByPairs?.map((quote) => {
                  const sentiment = sentiments?.find(
                    (item) => item.symbol === quote.symbol,
                  )

                  return (
                    <CoinCard
                      key={generateKey()}
                      quoteId={quote.timestamp}
                      purchasePrice={handleCoverageRateBuy(
                        quote.purchasePrice,
                        quote.symbol,
                      )}
                      salePrice={handleCoverageRateSell(
                        quote.salePrice,
                        quote.symbol,
                      )}
                      sentiment={sentiment as ISentiment}
                      coins={quote.symbol.split('/')}
                      onClickBuy={handleOperationBuy}
                      onClickSell={handleOperationSell}
                      disabled={disabledOperation}
                    />
                  )
                })}
              </SC.WrapperFlex>
            </SC.WrapperTradableCurrencies>
          </>
        )}
      </SC.WrapperTradables>

      {showModal && (
        <BuySellOperation
          display={showModal}
          operation={operation}
          coins={coins}
          price={
            ['buy'].includes(operation)
              ? handleCoverageRateBuy(price, coins[0])
              : handleCoverageRateSell(price, coins[0])
          }
          quoteId={quoteId}
          closeModal={handleCloseModal}
          getUpdatedRate={() => getUpdatedRate(coins[0], operation)}
        />
      )}
    </SC.Container>
  )
}

export default OperationalManagement
