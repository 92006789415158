import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div<DefaultProps>`
  width: 100%;
`

export const WrapperField = styled.div`
  width: calc(100% - 20px);
  height: 140px;
  text-align: left;
  padding: 20px 0 0;
`
