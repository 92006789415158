import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'forgetPassword', {
    title: 'Forgot Password',
    subTitle: 'Type your email below',
    input: {
      email: {
        label: 'Email',
        placeholder: 'Enter your email',
      },
    },
    buttonSign: 'Login',
    buttonForgot: 'Send link',
  })
}
