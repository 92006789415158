import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'tables', {
    title: 'MESA DE CONSOLIDAÇÃO',
    buttonAdd: 'Adicionar',
  })
}
