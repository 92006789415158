import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import { translateNameProfile } from '@resources/utils/forString'
import { IGridHeader } from '@components/UI/DataGrid'
import Icon from '@components/UI/Icon'
import { useDeleteUserById, useGetAllByCustomer } from '@hooks/user/useUser'
import { IUsersByCustomerResponse } from '@resources/types/services/user'
import { useCustomerMethods } from '@store/customer'

const header: IGridHeader[] = [
  {
    label: 'Nome',
    grid: 3.5,
    action: null,
    value: 'name',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 14,
    },
  },
  {
    label: 'Email',
    grid: 4.5,
    action: null,
    value: 'email',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: 'Perfil',
    grid: 2.5,
    action: null,
    value: 'profile',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 13,
    },
  },
  {
    label: '',
    grid: 0.5,
    action: null,
    value: 'icon1',
    field: 'element',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
  {
    label: '',
    grid: 0.5,
    action: null,
    value: 'icon2',
    field: 'element',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
]

export const useUsersByCustomer = () => {
  const navigate = useNavigate()

  const customerStore = useCustomerMethods()
  const { isError, data, isLoading, refetch } = useGetAllByCustomer(
    customerStore.uuid,
  )

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [dataDeleteId, setDataDeleteId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [userData, setUserData] = useState<IUsersByCustomerResponse | null>(
    null,
  )
  const queryClient = useQueryClient()

  const { mutate, isLoading: isLoadingDeleteUser } = useDeleteUserById()

  const alert = useAlert()

  const handleModal = () => {
    setShowModal(!showModal)

    refetch()
  }
  const handleUpdateModal = () => {
    setUserData(null)
  }

  const openUpdateModal = (data: IUsersByCustomerResponse) => {
    setUserData(data)
  }

  const goBack = () => {
    navigate(-1)
  }

  const handleDeleteModal = (id = '') => {
    setDataDeleteId(id)
    setShowDeleteModal(!showDeleteModal)
  }

  const onSuccessDeleteUser = (): void => {
    alert.success('Cliente excluída com sucesso!')

    queryClient.invalidateQueries(['useCustomerList'])

    refetch()
  }
  const onErrorDeleteUser = (): void => {
    alert.error('Houve uma erro ao deletear o cliente.')
  }

  const fetchDeleteModal = () => {
    const deleteUserById = { uuid: dataDeleteId }
    mutate(deleteUserById, {
      onSuccess: onSuccessDeleteUser,
      onError: onErrorDeleteUser,
    })

    setShowDeleteModal(!showDeleteModal)
  }

  const handleData = () => {
    if (!data) return []

    const prepareData = data?.map((item) => {
      return {
        id: item.id.toString(),
        icon1: (
          <Icon name="FaSearch" onCallback={() => openUpdateModal(item)} />
        ),

        icon2: (
          <Icon
            name="FaTrash"
            onCallback={() => handleDeleteModal(item.uuid)}
          />
        ),
        values: {
          name: item.name,
          email: item.email,
          profile: translateNameProfile(item.profile),
        },
      }
    })

    return prepareData
  }

  return {
    isError,
    isLoading,
    handleModal,
    showModal,
    header,
    handleData,
    userData,
    handleUpdateModal,
    parentId: customerStore.uuid,
    parentName: customerStore.name || customerStore.corporateName,
    goBack,
    showDeleteModal,
    handleDeleteModal,
    fetchDeleteModal,
    isLoadingDeleteUser,
  }
}
