import { t } from '@resources/translation'
import { theme } from '@resources/theme'
import Modal from '@components/Modal'
import Typography, { FontType } from '@components/UI/Typography'

import * as SC from './styles'
import { IProps } from './types'
import { useSetupOperator } from './useSetupOperator'
import Loading from '@components/Loading'
import DataGrid from '@components/UI/DataGrid'
import FieldSelect from '@components/UI/FieldSelect'
import Button from '@components/UI/Button'
import Icon from '@components/UI/Icon'

const SetupOperator = ({ handleModal, showModal, tableId }: IProps) => {
  const {
    isLoading,
    header,
    fieldSelect,
    dataExistingOperators,
    dataNewOperators,
    selectOPeratorUUID,
    handleSubmit,
    selected,
    handleAddOperator,
  } = useSetupOperator({
    tableId,
    handleModal,
  })

  return (
    <Modal
      display={showModal}
      onClose={handleModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={30}
        >
          {t('setupOperator.title')}
        </Typography>
      }
      width={900}
      height={650}
    >
      {isLoading && (
        <SC.WrapperLoading>
          <Loading />
        </SC.WrapperLoading>
      )}

      <SC.WrapperGrids>
        <SC.Header>
          <SC.WrapperField>
            <FieldSelect
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  OPERADORES
                </Typography>
              }
              name="state"
              id="state"
              options={fieldSelect}
              selected={selected}
              handleChange={selectOPeratorUUID}
            />
          </SC.WrapperField>

          <SC.PlusButton onClick={handleAddOperator} disabled={!selected}>
            <Icon name="FaPlus" />
          </SC.PlusButton>
        </SC.Header>

        <SC.WrapperContent>
          <DataGrid
            header={header}
            data={[...dataExistingOperators, ...dataNewOperators]}
          />
        </SC.WrapperContent>
      </SC.WrapperGrids>

      {!!dataNewOperators?.length && (
        <SC.WrapperButton>
          <Button width={300} onClick={handleSubmit}>
            {t('setupOperator.buttonAddOperator')}
          </Button>
        </SC.WrapperButton>
      )}
    </Modal>
  )
}

export default SetupOperator
