import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import FieldTextArea from '@components/UI/FieldTextArea'

import * as SC from './styles'
import { IProps } from '../types'

const Memo = ({ formik }: IProps) => {
  return (
    <SC.Container>
      <SC.WrapperField>
        <FieldTextArea
          label={
            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              size={14}
            >
              Anotações à tesouraria
            </Typography>
          }
          placeholder="Espaço disponível para anotações visíveis à tesouraria"
          maxLength={1000}
          inputHeight={150}
          labelColor={theme.palette.primary.regular}
          placeholderColor={theme.palette.neutral.dark}
          name={'privateNote'}
          value={formik.values.privateNote}
          onChange={formik.handleChange}
          backgroundInputColor={theme.palette.primary.regular}
          widthFull
        />
      </SC.WrapperField>
    </SC.Container>
  )
}

export default Memo
