// import { isMobile } from 'react-device-detect'

import Typography, { FontType } from '@components/UI/Typography'
import Separator from '@components/UI/Separator'
import { theme } from '@resources/theme'

import * as SC from './styles'

const About = () => {
  return (
    <SC.Content>
      <Typography
        fontType={FontType.semiBold}
        size={40}
        color={theme.palette.primary.lightest}
        lineHeight="1.2"
      >
        Somos uma plataforma de conexão
      </Typography>

      <Separator verticalSize={50} />

      <Typography
        fontType={FontType.regular}
        size={18}
        color={theme.palette.secondary.light}
        lineHeight="30px"
        align="justify"
      >
        A Exchange Spot traz soluções financeiras dentro de um só ambiente,
        conectando as demandas mais complexas às instituições financeiras
        especializadas. Estregamos um terminal / sistema operacional de gestão
        para o segmento de câmbio financeiro.
      </Typography>

      <Separator verticalSize={50} />

      <Typography
        fontType={FontType.semiBold}
        size={25}
        color={theme.palette.primary.lightest}
        lineHeight="1.2"
        align="center"
      >
        Nosso time só tem camisa 10! Todos certificados com ABT1 e 2 (ABRACAM)
      </Typography>

      <Separator verticalSize={50} />

      <SC.WrapperPerson>
        <SC.Person>
          <SC.PersonHeader>
            <SC.Image />
            <SC.PersonData>
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.success.dark}
                lineHeight="20px"
                align="center"
              >
                NOME COMPLETO
              </Typography>
              <Separator verticalSize={1} />
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.neutral.regular}
                lineHeight="20px"
                align="center"
              >
                CTO
              </Typography>
            </SC.PersonData>
          </SC.PersonHeader>

          {/* <Typography
            fontType={FontType.regular}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="30px"
          >
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </Typography> */}
        </SC.Person>

        <SC.Person>
          <SC.PersonHeader>
            <SC.Image />
            <SC.PersonData>
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.success.dark}
                lineHeight="20px"
                align="center"
              >
                NOME COMPLETO
              </Typography>
              <Separator verticalSize={1} />
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.neutral.regular}
                lineHeight="20px"
                align="center"
              >
                CFO
              </Typography>
            </SC.PersonData>
          </SC.PersonHeader>

          {/* <Typography
            fontType={FontType.regular}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="30px"
          >
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </Typography> */}
        </SC.Person>

        <SC.Person>
          <SC.PersonHeader>
            <SC.Image />
            <SC.PersonData>
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.success.dark}
                lineHeight="20px"
                align="center"
              >
                NOME COMPLETO
              </Typography>
              <Separator verticalSize={1} />
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.neutral.regular}
                lineHeight="20px"
                align="center"
              >
                HEAD MESA DE OPERAÇÕES
              </Typography>
            </SC.PersonData>
          </SC.PersonHeader>

          {/* <Typography
            fontType={FontType.regular}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="30px"
          >
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </Typography> */}
        </SC.Person>

        <SC.Person>
          <SC.PersonHeader>
            <SC.Image />
            <SC.PersonData>
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.success.dark}
                lineHeight="20px"
                align="center"
              >
                NOME COMPLETO
              </Typography>
              <Separator verticalSize={1} />
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.neutral.regular}
                lineHeight="20px"
                align="center"
              >
                COO
              </Typography>
            </SC.PersonData>
          </SC.PersonHeader>

          {/* <Typography
            fontType={FontType.regular}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="30px"
          >
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </Typography> */}
        </SC.Person>

        <SC.Person>
          <SC.PersonHeader>
            <SC.Image />
            <SC.PersonData>
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.success.dark}
                lineHeight="20px"
                align="center"
              >
                NOME COMPLETO
              </Typography>
              <Separator verticalSize={1} />
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.neutral.regular}
                lineHeight="20px"
                align="center"
              >
                CCO
              </Typography>
            </SC.PersonData>
          </SC.PersonHeader>

          {/* <Typography
            fontType={FontType.regular}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="30px"
          >
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </Typography> */}
        </SC.Person>

        <SC.Person>
          <SC.PersonHeader>
            <SC.Image />
            <SC.PersonData>
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.success.dark}
                lineHeight="20px"
                align="center"
              >
                NOME COMPLETO
              </Typography>
              <Separator verticalSize={1} />
              <Typography
                fontType={FontType.semiBold}
                size={14}
                color={theme.palette.neutral.regular}
                lineHeight="20px"
                align="center"
              >
                AAI
              </Typography>
            </SC.PersonData>
          </SC.PersonHeader>

          {/* <Typography
            fontType={FontType.regular}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="30px"
          >
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </Typography> */}
        </SC.Person>
      </SC.WrapperPerson>
    </SC.Content>
  )
}

export default About
