import React from 'react'

import { t } from '@resources/translation'

import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import { EInputType } from '@components/UI/Field/types'
import Button from '@components/UI/Button'
import PathRoutes from '@route/PathRoutes'

import * as SC from './styles'
import { useSignInContainer } from './useSignInContainer'

const SignIn = () => {
  const { formik, isLoading } = useSignInContainer()

  return (
    <SC.WrapperContent>
      <Separator verticalSize={30} />
      <Typography
        fontType={FontType.bold}
        size={35}
        color={theme.palette.primary.light}
      >
        {t('signIn.title')}
      </Typography>

      <SC.WrapperForm>
        <form onSubmit={formik.handleSubmit}>
          <Field
            id="email"
            name="email"
            type={EInputType.DOCUMENT}
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('signIn.input.email.label')}
              </Typography>
            }
            placeholder={t('signIn.input.email.placeholder')}
            onChange={formik.handleChange}
            value={formik.values.email}
            widthFull
          />

          <Separator verticalSize={16} />

          <Field
            id="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('signIn.input.password.label')}
              </Typography>
            }
            placeholder={t('signIn.input.password.placeholder')}
            type={EInputType.PASSWORD}
            maxLength={100}
            widthFull
          />

          <Separator verticalSize={60} />

          <Button
            widthFull
            type="submit"
            loading={isLoading}
            disabled={!formik.isValid || !formik.dirty}
          >
            {t('signIn.buttonSign')}
          </Button>
        </form>

        <Separator verticalSize={15} />

        <SC.WrapperLink>
          <SC.LinkMenu
            nav-item="link"
            to={PathRoutes.backoffice.forgot.url}
            target=""
          >
            {t('signIn.buttonForgot')}
          </SC.LinkMenu>
        </SC.WrapperLink>
      </SC.WrapperForm>
    </SC.WrapperContent>
  )
}

export default SignIn
