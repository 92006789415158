export const HttpCodes = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  UNAUTHORIZED_LOGOUT: 418,
  TOO_MANY_REQUESTS: 429,
  NOT_FOUND: 404,
  AWAITING_LIBERATION: 419,
  APPLICATION_EXCEPTION: 420,
  INTERNAL_SERVER_ERROR: 500,
}
