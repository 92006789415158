import Separator from '@components/UI/Separator'
import { theme } from '@resources/theme'

import { IViewProps } from './types'
import * as SC from './styles'

const FieldTextArea = ({
  inputWidth = 100,
  inputHeight = 48,
  widthFull = false,
  label = '',
  name,
  id,
  onChange,
  placeholder,
  value,
  secondary = false,
  tertiary = false,
}: IViewProps) => {
  const themes = {
    primary: {
      active: {
        background: theme.palette.primary.light,
        color: theme.palette.neutral.regular,
        placeholder: theme.palette.neutral.regular,
      },
      disabled: {
        background: theme.palette.neutral.regular,
        color: theme.palette.primary.light,
        placeholder: '',
      },
    },
    secondary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
    tertiary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
  }

  const themeActive =
    themes[(secondary && 'secondary') || (tertiary && 'tertiary') || 'primary']

  return (
    <SC.Container>
      {!!label && (
        <>
          {label}
          <Separator verticalSize={4} />
        </>
      )}
      <SC.Container>
        <SC.Input
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autoFocus
          inputWidth={inputWidth}
          widthFull={widthFull}
          inputHeight={inputHeight}
          textInputColor={themeActive.active.color}
          backgroundInputColor={themeActive.active.background}
          placeholderColor={themeActive.active.placeholder}
        />
      </SC.Container>
    </SC.Container>
  )
}

export default FieldTextArea
