export const setLocalStorage = (name: string, value: string) => {
  sessionStorage.setItem(name, value)
}

export const getLocalStorage = (name: string): string | null => {
  return sessionStorage.getItem(name)
}

export const removeLocalStorage = (name: string): void => {
  sessionStorage.removeItem(name)
}

export const removeAlLocalStorage = (): void => {
  sessionStorage.clear()
}
