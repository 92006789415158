import React from 'react'

import { t } from '@resources/translation'

import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import { EInputType } from '@components/UI/Field/types'
import Button from '@components/UI/Button'

import * as SC from './styles'
import { IViewProps } from './types'

const NewPassword = ({ formik, isLoading }: IViewProps) => {
  return (
    <SC.WrapperContent>
      <Separator verticalSize={30} />
      <Typography
        fontType={FontType.bold}
        size={35}
        color={theme.palette.primary.light}
      >
        {t('newPassword.title')}
      </Typography>

      <SC.WrapperForm>
        <form onSubmit={formik.handleSubmit}>
          <Field
            id="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            type={EInputType.PASSWORD}
            maxLength={100}
            labelColor={theme.palette.primary.lightest}
            marginInputTop={6}
            placeholder={t('newPassword.input.password.placeholder')}
            widthFull
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('newPassword.input.password.label')}
              </Typography>
            }
          />

          <Separator verticalSize={40} />

          <Field
            id="confirmPassword"
            name="confirmPassword"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            type={EInputType.PASSWORD}
            maxLength={100}
            labelColor={theme.palette.primary.lightest}
            marginInputTop={6}
            placeholder={t('newPassword.input.confirmPassword.placeholder')}
            widthFull
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('newPassword.input.confirmPassword.label')}
              </Typography>
            }
          />

          <Separator verticalSize={25} />

          <SC.WrapperDesc>
            <Typography
              fontType={FontType.extraLight}
              size={14}
              color={theme.palette.primary.lightest}
            >
              {'* Sua senha deve conter no mínimo 8 caracteres  '}
            </Typography>

            <Typography
              fontType={FontType.extraLight}
              size={14}
              color={theme.palette.primary.lightest}
            >
              {
                '* Sua senha deve conter pelo menos 1 caracter especial (* @ %...)'
              }
            </Typography>
          </SC.WrapperDesc>

          <Separator verticalSize={35} />

          <Button
            widthFull
            type="submit"
            loading={isLoading}
            disabled={!formik.isValid || !formik.dirty}
          >
            {t('newPassword.button')}
          </Button>
        </form>
      </SC.WrapperForm>
    </SC.WrapperContent>
  )
}

export default NewPassword
