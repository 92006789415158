import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  background: ${({ theme }) => theme.palette.primary.darkest};
`
