import * as Yup from 'yup'

import { PersonType } from '@pages/Backoffice/Customer/SignUpCustomer'

export const updateSchema = (typeRegister = 'PJ') => {
  const schema = Yup.object().shape({
    corporateName:
      typeRegister === PersonType.PF
        ? Yup.string().test('isValidName', 'Digite a razão social', (val) => {
            return String(val).length > 6
          })
        : Yup.string()
            .test('isValidName', 'Digite a razão social', (val) => {
              return String(val).length > 6
            })
            .required('Digite seu nome completo'),

    name:
      typeRegister === PersonType.PJ
        ? Yup.string().test(
            'isValidName',
            'Digite seu nome completo',
            (val) => {
              return String(val).length > 6
            },
          )
        : Yup.string()
            .test('isValidName', 'Digite seu nome completo', (val) => {
              return String(val).length > 6
            })
            .required('Digite seu nome completo'),

    cpfCnpj: Yup.string()
      .test('isValidCPF', 'Digite um CPF/CNPJ válido', (val) => {
        return String(val).length > 11
      })
      .required('Required'),

    cellphoneNumber: Yup.string()
      .test('isValidPhone', 'Digite um número de celular válido', (val) => {
        return String(val).length > 11
      })
      .required('Required'),

    phone: Yup.string()
      .test('isValidPhone', 'Digite um número de telefone válido', (val) => {
        return String(val).length > 10
      })
      .required('Required'),

    email: Yup.string().email('Digite um email válido').required('Required'),

    state: Yup.string().required('Selecione um estado'),

    city: Yup.string().required('Selecione uma cidade'),

    address: Yup.string().required('Digite endereço'),

    zipCode: Yup.string().required('Digite CEP'),

    negotiationPanel: Yup.string().required('Você deve selecionar uma opção'),

    strategyMonitor: Yup.string().required('Você deve selecionar uma opção'),
  })

  return schema
}
