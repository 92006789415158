import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { Link as BaseLink } from 'react-router-dom'
import { FontType } from '@components/UI/Typography'

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 470px;
  padding: 0;
  margin: 50px 0 0 0;
`

export const WrapperForm = styled.div<DefaultProps>`
  width: 550px;
  margin: 0 0 0 0;
  padding: 50px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.primary.dark};
`

export const WrapperFormContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  background: green;
  padding: 0;
  margin: 0;
  border-radius: 0 10px 10px 0;
`

export const WrapperLink = styled.div`
  width: 100%;
  text-align: center;
  margin: 20px 0 0 0;
`

export const LinkMenu = styled(BaseLink)<DefaultProps>`
  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};

  font-style: normal;
  cursor: pointer;
`
