import { IProfileResponse } from '@resources/types/services/auth'
import { create } from 'zustand'

import {
  getLocalStorage,
  setLocalStorage,
  constantsStorage,
} from '@resources/helpers/application/storage'

import { IAuthState, INITIAL_STATE_PROFILE } from './types'

export const useAuthStore = create<IAuthState>()((set) => ({
  auth: {
    credentials: {
      accessToken: getLocalStorage(constantsStorage.token) ?? '',
    },
    profile:
      JSON.parse(getLocalStorage(constantsStorage.profile) ?? '{}') ??
      INITIAL_STATE_PROFILE,
  },
  actions: {
    setAuth: (accessToken: string, profile: IProfileResponse) => {
      set(() => ({
        auth: {
          credentials: {
            accessToken,
          },
          profile,
        },
      }))

      setLocalStorage(constantsStorage.token, accessToken)
      setLocalStorage(constantsStorage.profile, JSON.stringify(profile))
    },

    updateProfile: (profile: IProfileResponse) =>
      set((state: IAuthState) => ({
        ...state,
        profile,
      })),
  },
}))

// state
export const useAuthMethods = () => useAuthStore((state) => state.auth)

// actions
export const useAuthActions = () => useAuthStore((state) => state.actions)
