import { useEffect, useState, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useAlert } from 'react-alert'

import { useAuthMethods } from '@store/auth'
import { useOperationManagementPerfomanceReport } from '@hooks/operationManagement/useOperationManagementPerfomanceReport'
import {
  usePerfomanceReportFeeByCustomer,
  usePerfomanceReportFeeCreateOrUpdate,
} from '@hooks/perfomanceReportFee/usePerfomanceReportFee'
import { usePivotPointGetWithTimes } from '@hooks/pivotPoints/usePivotPoints'
import { currencyFormatterSixPlaces } from '@resources/utils/forCurrency'

import { useFormik } from 'formik'

import 'dayjs/locale/pt-br'

import { ICheckedOperation } from './types'

export const usePerformanceReport = () => {
  const alert = useAlert()
  const queryClient = useQueryClient()
  const { profile } = useAuthMethods()

  const [valueFormik, setValueFormik] = useState({})
  const [symbols, setSymbols] = useState<string[]>([])
  const [suggestedActionFilter, setSuggestedActionFilter] = useState('')
  const [checkedOperations, setCheckedOperations] = useState<
    Array<ICheckedOperation>
  >([])
  const [showModalOperationMatching, setShowModalOperationMatching] =
    useState<boolean>(false)
  const [dataOperationMatching, setDataOperationMatching] = useState<any>({})

  const { data: pivotPointData, isLoading: pivotPointIsLoading } =
    usePivotPointGetWithTimes(symbols)

  const { isLoading, data, isRefetching } =
    useOperationManagementPerfomanceReport(profile.parentCustomer.uuid)

  const { data: fieldFeeData, isLoading: fieldsFeeIsLoading } =
    usePerfomanceReportFeeByCustomer(profile.parentCustomer.uuid)
  const { mutate, isLoading: isLoadingMutate } =
    usePerfomanceReportFeeCreateOrUpdate()

  const formatCurrencyWithouSimbol = (value) => {
    if (!value) return '0.00'

    return value
      .toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
      .replace('R$', '')
  }

  const formatReal = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  const formatDolar = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }

  useEffect(() => {
    if (
      !data ||
      (!data.perfomance.length && !fieldFeeData) ||
      !fieldFeeData?.length
    )
      return

    const prepareInitialValues = {}
    const prepareSymbols: string[] = []

    data.perfomance.forEach((item) => {
      const foundFee = fieldFeeData.find((fee) => fee.coin === item.dealtCcy)

      prepareInitialValues[item.dealtCcy] = String(foundFee?.value)
      prepareSymbols.push(item.dealtCcy)
      // currencyFormatterSixPlaces(String(foundFee?.value)) || 0
    })
    setSymbols(prepareSymbols)

    setValueFormik(prepareInitialValues)

    formik.setValues(prepareInitialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fieldFeeData])

  const formik = useFormik({
    initialValues: valueFormik,
    onSubmit: () => undefined,
  })

  const onSuccessUpdateFee = (): void => {
    queryClient.invalidateQueries([
      'operationManagementPerfomanceReport',
      profile.parentCustomer.uuid,
    ])
    queryClient.invalidateQueries([
      'perfomanceReportFeeByCustomer',
      profile.parentCustomer.uuid,
    ])

    alert.success('Taxa de perfomance atualizada com sucesso!')
  }

  const onErrorUpdateFee = (): void => {
    queryClient.invalidateQueries([
      'operationManagementPerfomanceReport',
      profile.parentCustomer.uuid,
    ])
    queryClient.invalidateQueries([
      'perfomanceReportFeeByCustomer',
      profile.parentCustomer.uuid,
    ])
    alert.error('Houve uma erro ao atualizar taxa de perfomance.')
  }

  const updateReporteFee = (coin: string, value: string) => {
    const data = {
      customer: profile.parentCustomer.uuid,
      user: profile.uuid,
      coin,
      value: Number(value),
    }

    mutate(data, {
      onSuccess: onSuccessUpdateFee,
      onError: onErrorUpdateFee,
    })
  }

  const dataPerfomance = useMemo(() => data?.perfomance, [data?.perfomance])

  const getValue = (coin: string) => {
    const foundFee = fieldFeeData?.find((fee) => fee.coin === coin)

    const value = currencyFormatterSixPlaces(String(foundFee?.value)) || 0

    return value
  }

  const handleCheckedOperations = (id: number, dealt: string) => {
    let prepareChecked: ICheckedOperation[] = []

    if (checkedOperations.find((item) => item.id === id)) {
      prepareChecked = [...checkedOperations.filter((item) => item.id !== id)]
    } else {
      prepareChecked = [...checkedOperations, { id, dealt }]
    }

    setCheckedOperations(prepareChecked)
  }

  const filteredDataPerfomance = dataPerfomance?.filter((item) =>
    suggestedActionFilter === ''
      ? item
      : suggestedActionFilter === 'buy'
      ? item?.vlrEstrang < 0
      : item?.vlrEstrang > 0,
  )

  const submitOperationsMatchingIsValid = useMemo(() => {
    const isValid = checkedOperations.every(
      (item) => item.dealt === checkedOperations[0].dealt,
    )

    console.log(
      'submitOperationsMatchingIsValid',
      checkedOperations,
      checkedOperations.length > 1 && isValid,
    )

    return checkedOperations.length > 1 && isValid
  }, [checkedOperations])

  useEffect(() => {
    const valid = submitOperationsMatchingIsValid
    console.log('\n\n checkedOperations', checkedOperations, valid)
  }, [checkedOperations, submitOperationsMatchingIsValid])

  const handleSubmitOperationsMatching = () => {
    const dataFound = data?.data.find(
      (item) => item.dealt === checkedOperations[0].dealt,
    )

    const ids = checkedOperations.map((item) => item.id)

    const buy = dataFound?.buy.filter((item) => ids.includes(item.id))
    const sell = dataFound?.sell.filter((item) => ids.includes(item.id))

    const prepareData = { buy, sell, dealt: checkedOperations[0].dealt }
    setDataOperationMatching(prepareData)

    setShowModalOperationMatching(!showModalOperationMatching)
  }

  const handleCloseModal = () => {
    setCheckedOperations([])
    setShowModalOperationMatching(false)
  }

  return {
    isLoading:
      isRefetching || isLoading || fieldsFeeIsLoading || pivotPointIsLoading,
    pivotPointData,
    data,
    formatCurrencyWithouSimbol,
    formatDolar,
    formatReal,
    formik,
    updateReporteFee,
    isLoadingMutate,
    dataPerfomance,
    getValue,
    suggestedActionFilter,
    setSuggestedActionFilter,
    filteredDataPerfomance,
    handleCheckedOperations,
    checkedOperations,
    handleSubmitOperationsMatching,
    submitOperationsMatchingIsValid,
    showModalOperationMatching,
    dataOperationMatching,
    handleCloseModal,
  }
}
