/* eslint-disable @typescript-eslint/no-unused-vars */
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'

import * as SC from './styles'
import { IViewProps } from './types'

const BarProgress = ({ percentBuy = 100 }: IViewProps) => {
  const restCalculation = 100 - percentBuy

  const conditionSentiment = () => {
    if (percentBuy === restCalculation) {
      return <>{percentBuy}%</>
    }

    if (percentBuy > restCalculation) {
      return <>{percentBuy}% COMPRADO</>
    }

    return <>{restCalculation}% VENDIDO</>
  }

  return (
    <SC.Container>
      <Typography
        fontType={FontType.semiBold}
        color={theme.palette.primary.lightest}
        size={7.5}
      >
        TENDÊNCIA
      </Typography>

      <SC.WrapperProgress>
        <SC.Progress progress={percentBuy} />
      </SC.WrapperProgress>

      <Typography
        fontType={FontType.semiBold}
        color={theme.palette.primary.lightest}
        size={7.5}
      >
        {conditionSentiment()}
      </Typography>
    </SC.Container>
  )
}

export default BarProgress
