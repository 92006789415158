import * as Yup from 'yup'

export const signInSchema = Yup.object().shape({
  email: Yup.string().email('Digite um email válido').required('Required'),

  password: Yup.string()
    .test('isValidPassword', 'Digite uma senha válida', (val) => {
      return String(val).length > 5
    })
    .required('Required'),
})
