/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import { useAddOperationManagement } from '@hooks/operationManagement/useOperationManagement'
import { IOperationManagementAddRequest } from '@resources/types/services/operationManagement'
import { useAuthMethods } from '@store/auth'
import { ProfileTypes } from '@resources/types/profileTypes'

import { buySellOperationSchema } from './yup/buySellOperation'
import { IUseProps, OperationCustomer, IFormikValues } from './types'
import {
  useGetTableListByCustomer,
  useGetTableByOperator,
} from '@hooks/tables/useTables'

export const useBuySellOperation = ({
  closeModal,
  quoteId,
  coins,
  operation,
  price,
}: IUseProps) => {
  const queryClient = useQueryClient()
  const alert = useAlert()

  const { profile } = useAuthMethods()

  const isOperator = profile?.profile === ProfileTypes.operator

  const fetchGetTableList = useGetTableListByCustomer(
    !isOperator ? profile?.parentCustomer?.uuid : '',
  )

  const fetchGetTableListByOperator = useGetTableByOperator(
    isOperator ? profile?.uuid : '',
  )

  const TIMER_SECOND = {
    START: 0,
    END: 20,
  }
  const INITIAL_OPERATION_CUSTOMER = { id: '', name: '', document: '' }

  const [displayCustomer, setDisplayCustomer] = useState<boolean>(false)
  const [date, setDate] = useState<Dayjs | null>(null)
  const [buySellOperation, setBuySellOperation] =
    useState<IOperationManagementAddRequest | null>(null)
  const [timer, setTimer] = useState<number>(0)
  const [start, setStart] = useState<boolean>(false)
  const [enableAddOperationManagement, setEnableAddOperationManagement] =
    useState<boolean>(false)
  const [operationCustomer, setOperationCustomer] = useState<OperationCustomer>(
    INITIAL_OPERATION_CUSTOMER,
  )

  const { isLoading, isSuccess, isError } = useAddOperationManagement(
    enableAddOperationManagement,
    buySellOperation,
  )

  const handleBuySellSubmit = useCallback(
    (status = 'success') => {
      const executionTime = dayjs(new Date()).format(
        'YYYY-MM-DDTHH:mm:ss.sss[Z]',
      )
      const executionDate = dayjs(buySellOperation?.executionDate).format(
        'YYYY-MM-DDTHH:mm:ss.sss[Z]',
      )
      const baseSplitValueDate = dayjs(
        buySellOperation?.baseSplitValueDate,
      ).format('YYYY-MM-DDTHH:mm:ss.sss[Z]')
      const termsSplitValueDate = dayjs(
        buySellOperation?.termsSplitValueDate,
      ).format('YYYY-MM-DDTHH:mm:ss.sss[Z]')

      const decisionStart =
        status !== 'success' && status === 'aborted' && timer > 0
          ? 'aborted'
          : 'timeout'

      const preapreBuySellOperation = {
        ...buySellOperation,
        executionTime,
        executionDate,
        termsSplitValueDate,
        baseSplitValueDate,
        status: decisionStart,
      }

      setBuySellOperation(
        () => preapreBuySellOperation as IOperationManagementAddRequest,
      )
      setEnableAddOperationManagement(true)
    },
    [buySellOperation, timer],
  )

  const handleCloseModal = useCallback(() => {
    setOperationCustomer(INITIAL_OPERATION_CUSTOMER)
    handleBuySellSubmit('aborted')
    closeModal()
  }, [INITIAL_OPERATION_CUSTOMER, closeModal, handleBuySellSubmit])

  const finish = () => {
    handleBuySellSubmit()
    setStart(false)
    setTimer(TIMER_SECOND.START)
  }

  const startOperation = () => {
    setStart(true)
    setTimer(TIMER_SECOND.END)
  }

  useEffect(() => {
    if (timer === 0) return

    const timeOutTimer = setTimeout(() => {
      setTimer((time) => time - 1)
    }, 1000)

    return () => {
      clearTimeout(timeOutTimer)
    }
  }, [timer])

  const handleModalCustomer = () => {
    setDisplayCustomer(!displayCustomer)
  }

  const handleBuySellStartTimer = (prop: IFormikValues) => {
    const dealtAmountNumber = Number(
      prop.dealtAmount.replace(',', '').replace('$', ''),
    )

    const preapreBuySellOperation: IOperationManagementAddRequest = {
      customer: profile.parentCustomer.uuid,
      proxy: profile.uuid,
      operationCustomer: operationCustomer.id,
      executionDate: prop.executionDate,
      termsSplitValueDate: prop.termsSplitValueDate,
      executionTime: '',
      gidId: String(quoteId),
      dealtCcy: coins[0],
      baseDirection: operation,
      direction: operation,
      dealtAmount: String(dealtAmountNumber),
      contraAmount: String(dealtAmountNumber * price),
      allInRate: String(price),
      privateNote: prop.privateNote,
      exchangeTable: prop.operationTable,
      tenor: 'SPOT',
      baseSplitValueDate: prop.baseSplitValueDate,
      status: '',
    }

    // console.log('preapreBuySellOperation', preapreBuySellOperation)
    // return

    setBuySellOperation(() => preapreBuySellOperation)
    startOperation()
  }

  const handleTable = useCallback(() => {
    const isLoading =
      fetchGetTableList.isLoading || fetchGetTableListByOperator.isLoading

    const prepareData = [
      {
        value: '',
        label: isLoading ? 'Carregando...' : 'Selecione a mesa',
      },
    ]

    if (fetchGetTableListByOperator.data) {
      fetchGetTableListByOperator.data.forEach((item) => {
        prepareData.push({
          value: item?.exchangeTable?.uuid,
          label: item?.exchangeTable?.name,
        })
      })
    }

    if (fetchGetTableList.data) {
      fetchGetTableList.data.map((item) => {
        prepareData.push({
          value: item.uuid,
          label: item.name,
        })
      })
    }

    return prepareData
  }, [fetchGetTableList.data, fetchGetTableListByOperator.data])

  const formik = useFormik({
    initialValues: {
      operationTable: handleTable()[0].value,
      executionDate: new Date().setDate(new Date().getDate()),
      dealtAmount: '',
      privateNote: '',
      baseSplitValueDate: new Date().setDate(new Date().getDate()),
      termsSplitValueDate: new Date().setDate(new Date().getDate() + 2),
    },
    validationSchema: buySellOperationSchema,
    onSubmit: handleBuySellStartTimer,
  })

  const getCoinWithMaximumLenght = (coin, price) => {
    const setupPair = {
      USD: { maxLength: 6 },
      EUR: { maxLength: 6 },
      GBP: { maxLength: 6 },
      JPY: { maxLength: 8 },
      CHF: { maxLength: 6 },
      AUD: { maxLength: 6 },
      CAD: { maxLength: 6 },
      DKK: { maxLength: 6 },
      NOK: { maxLength: 6 },
      SEK: { maxLength: 6 },
      CNY: { maxLength: 6 },
      NZD: { maxLength: 6 },
      MXN: { maxLength: 6 },
      ZAR: { maxLength: 6 },
      CLP: { maxLength: 6 },
      ARS: { maxLength: 6 },
    }

    const setupPairFound = setupPair[coin] ?? 6

    return String(price).slice(0, setupPairFound.maxLength)
  }

  useEffect(() => {
    if (isSuccess) {
      alert.success('Operação realizada com sucesso!')

      queryClient.invalidateQueries([
        'useOperationManagementReportByCustomer',
        profile?.parentCustomer?.uuid,
      ])

      handleCloseModal()
    } else if (isError) {
      alert.error('Ocorreu um erro. Por favor, tente novamente')
      setEnableAddOperationManagement(false)

      handleCloseModal()
    }
  }, [isSuccess, isError])

  return {
    date,
    setDate,
    timer,
    startOperation,
    start,
    finish,
    closeModal,
    displayCustomer,
    handleModalCustomer,
    operationCustomer,
    setOperationCustomer,
    handleCloseModal,
    tableListOptions: handleTable(),
    defaultDate: new Date().setDate(new Date().getDate() + 2),
    formik,
    isLoading,
    handleBuySellStartTimer,
    getCoinWithMaximumLenght,
  }
}
