import axios, { AxiosResponse } from 'axios'

import { endpoints } from '@services/endpoints'
import {
  IQuotesByPairsResponse,
  IQuotesByPairs,
  IConvertCurrencyResponse,
  IMarketStatusResponse,
} from '@resources/types/services/quotes'
import { translateUrlParam } from '@resources/utils/forString'
import { adapterFromQuoteApi } from '@resources/adapters/quotes'
import { convertResponse } from '@resources/utils/convertResponse'

import { FOREX_QUOTES_API_KEY } from './contants'

export const QuotesService = {
  getQuotesByPairs: async (pairs: string): Promise<Array<IQuotesByPairs>> => {
    const url = translateUrlParam(endpoints.URL.quotes.quotesByPairs, {
      pairs,
      apiKey: FOREX_QUOTES_API_KEY,
    })

    const response: AxiosResponse = await axios.get(url)

    return adapterFromQuoteApi(response.data as IQuotesByPairsResponse[])
  },

  getSymbolsList: async (): Promise<Array<string>> => {
    const url = translateUrlParam(endpoints.URL.quotes.symbolsList, {
      apiKey: FOREX_QUOTES_API_KEY,
    })

    const response: AxiosResponse = await axios.get(url)

    return response.data
  },

  getConvertCurrency: async (
    pairFrom: string,
    pairTo: string,
  ): Promise<IConvertCurrencyResponse> => {
    const url = translateUrlParam(endpoints.URL.quotes.convert, {
      pairFrom,
      pairTo,
      apiKey: FOREX_QUOTES_API_KEY,
    })

    const response: AxiosResponse = await axios.get(url)

    return response.data
  },

  getMarketStatus: async (): Promise<IMarketStatusResponse> => {
    const url = translateUrlParam(endpoints.URL.quotes.marketStatus, {
      apiKey: FOREX_QUOTES_API_KEY,
    })

    const response: AxiosResponse = await axios.get(url)

    return convertResponse(response.data)
  },
}
