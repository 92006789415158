import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'

import { IOperationCustomerAddRequest } from '@resources/types/services/operationCustomer'
import { useAddOperarionCustomerByCustomer } from '@hooks/customerOperation/useCustomerOperation'
import { customerOperationAddSchema } from './yup/customerOperationAddSchema'
import { clearCpfMask } from '@resources/utils/forString'
import { useAuthMethods } from '@store/auth'

import { IFormikValues } from './types'

export const useCustomerOperationAdd = ({
  selectCustomerOperation,
  handleCloseModal,
}) => {
  const alert = useAlert()
  const { profile } = useAuthMethods()
  const [addOperarionCustomerByCustomer, setAddOperarionCustomerByCustomer] =
    useState<IOperationCustomerAddRequest | null>(null)

  const { isError, isSuccess, isLoading, data } =
    useAddOperarionCustomerByCustomer(addOperarionCustomerByCustomer)

  const handleAddOperarionCustomer = (prop: IFormikValues) => {
    setAddOperarionCustomerByCustomer({
      customer: profile?.parentCustomer?.uuid,
      name: prop.name,
      email: prop.email,
      document: clearCpfMask(prop.document),
      cellphone: prop.cellphone,
    })
  }

  useEffect(() => {
    if (!isSuccess) return

    const prepareCustomerOperation = {
      id: data?.uuid,
      name: data?.name,
      document: data?.document,
    }

    selectCustomerOperation(prepareCustomerOperation)
    handleCloseModal()

    alert.success('Cliente cadastro com sucesso!')
  }, [isSuccess, data, selectCustomerOperation, handleCloseModal, alert])

  useEffect(() => {
    if (!isError) return

    alert.error('Houve um erro ao realizar o cadastro.')
  }, [isError, alert])

  const formik = useFormik({
    initialValues: {
      customer: '',
      name: '',
      email: '',
      document: '',
      cellphone: '',
    },
    validationSchema: customerOperationAddSchema,
    onSubmit: handleAddOperarionCustomer,
  })

  return { formik, isError, isSuccess, isLoading, data, profile }
}
