import { TranslationsManager } from '@resources/translation'
import { registerEnTranslations } from './en'
import { registerPtBrTranslations } from './pt-br'

export const registerNewsCommoditiesTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  registerPtBrTranslations(translationsManager)
  registerEnTranslations(translationsManager)
}
