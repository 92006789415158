/* eslint-disable @typescript-eslint/no-unused-vars */
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'

import BarProgress from './BarProgress'

import * as SC from './styles'
import { IViewProps } from './types'

const Overview = ({
  disabled = false,
  onClickBuy,
  onClickSell,
  quoteId,
  coins,
  purchasePrice,
  salePrice,
  sentiment,
}: IViewProps) => {
  const color = disabled
    ? theme.palette.neutral.darkest
    : theme.palette.neutral.darkest

  // const spread = {
  //   'USD/BRL': {
  //     sell: -0.004,
  //     buy: 0.004,
  //   },
  //   'EUR/BRL': {
  //     sell: 0.008,
  //     buy: 0.001,
  //   },
  //   'GBP/BRL': {
  //     sell: 0.006,
  //     buy: 0.006,
  //   },
  //   'JPY/BRL': {
  //     sell: 0.002,
  //     buy: 0.004,
  //   },
  //   'CHF/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'AUD/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'CAD/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'DKK/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'NOK/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'SEK/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'CNY/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'NZD/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'MXN/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'ZAR/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'CLP/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  //   'ARS/BRL': {
  //     sell: 0.004,
  //     buy: 0.004,
  //   },
  // }

  const initialPartPrice = (price: number) => {
    const priceCompleted = String(price).padEnd(6, '0')

    return priceCompleted.slice(0, 4)
  }

  const finalPartPrice = (price: number) => {
    const priceCompleted = String(price).padEnd(6, '0')

    return priceCompleted.slice(4, 6)
  }

  const buySentiment = Math.trunc(sentiment.sentiment * 100)

  // const spreadSelected = spread[`${coins[0]}/${coins[1]}`]
  // const purchaseWithSpread = purchasePrice + spreadSelected.buy
  // const saleWithSpread = salePrice + spreadSelected.sell

  return (
    <SC.CoinCard>
      <Typography
        fontType={FontType.bold}
        color={theme.palette.primary.lightest}
        size={15}
      >
        {`${coins[0]} - ${coins[1]}`}
      </Typography>

      <Separator verticalSize={5} />

      <SC.WrapperFlexBetween>
        <SC.WrapperCoinValue>
          <Typography
            fontType={FontType.semiBold}
            color={theme.palette.primary.lightest}
            size={7}
          >
            VENDER {coins[0]}
          </Typography>

          <SC.WrapperCircle
            sel
            onClick={() =>
              onClickSell
                ? onClickSell([coins[0], coins[1]], salePrice, quoteId)
                : () => null
            }
          >
            <Typography fontType={FontType.bold} color={color} size={12}>
              {initialPartPrice(salePrice)}
            </Typography>
            <Typography fontType={FontType.bold} color={color} size={28}>
              {finalPartPrice(salePrice)}
            </Typography>
          </SC.WrapperCircle>
        </SC.WrapperCoinValue>

        <SC.WrapperCoinValue>
          <Typography
            fontType={FontType.semiBold}
            color={theme.palette.primary.lightest}
            size={7}
          >
            COMPRAR {coins[0]}
          </Typography>

          <SC.WrapperCircle
            buy
            onClick={() =>
              onClickBuy
                ? onClickBuy([coins[0], coins[1]], purchasePrice, quoteId)
                : () => null
            }
          >
            <Typography fontType={FontType.bold} color={color} size={12}>
              {initialPartPrice(purchasePrice)}
            </Typography>
            <Typography fontType={FontType.bold} color={color} size={28}>
              {finalPartPrice(purchasePrice)}
            </Typography>
          </SC.WrapperCircle>
        </SC.WrapperCoinValue>
      </SC.WrapperFlexBetween>

      <Separator verticalSize={3} />

      <SC.WrapperBottom>
        <BarProgress percentBuy={buySentiment} />
      </SC.WrapperBottom>

      {disabled && <SC.Disabled />}
    </SC.CoinCard>
  )
}

export default Overview
