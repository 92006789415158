import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'signIn', {
    title: 'LOGIN',
    input: {
      email: {
        label: 'E-mail',
        placeholder: 'Insira seu e-email',
      },
      password: {
        label: 'Senha',
        placeholder: 'Insira sua senha',
      },
    },
    buttonSign: 'Entrar',
    buttonForgot: 'Esqueceu a senha?',
  })
}
