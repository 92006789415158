import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'

import { useAccessUpdate } from '@hooks/accessManagement/useAccessManagement'
import { updateSchema } from './yup/updateSchema'
import { cellPhoneMask, cpfMask } from '@resources/helpers/utils'
import { useAuthActions, useAuthMethods } from '@store/auth'

import { IFormikValues, IHookProps } from './types'

export const useUpdateProfile = ({ handleModal, data }: IHookProps) => {
  const alert = useAlert()

  const { profile } = useAuthMethods()
  const { updateProfile } = useAuthActions()

  const { mutate, isLoading } = useAccessUpdate()

  const onSuccess = (response): void => {
    alert.success('Dados alterados com sucesso')

    updateProfile({
      id: Number(data?.id) || 0,
      uuid: data?.uuid || '',
      profile: String(data?.profile),
      email: response?.email || '',
      name: response?.name || '',
      cpf: response?.cpf || '',
      cellphone: response?.cellphone || '',
      parentCustomer: data?.parentCustomer,
    })

    handleModal()
  }

  const onError = (error): void => {
    alert.error('Ocorreu um erro inesperado. Tente novamente.', error)
  }

  const handleAuthenticate = (prop: IFormikValues) => {
    const dataUpdate = {
      data: {
        profile: prop.profile,
        name: prop.name,
        cpf: prop.cpf,
        cellphone: prop.cellphone,
        email: prop.email,
        password: prop.password,
      },
      userid: String(data?.uuid),
    }

    mutate(dataUpdate, { onSuccess, onError })
  }

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      cpf: cpfMask(data?.cpf.toString() || ''),
      cellphone: cellPhoneMask(data?.cellphone || ''),
      email: data?.email || '',
      profile: profile.profile,
      password: '123456789',
      confirmPassword: '',
    },
    validationSchema: updateSchema,
    onSubmit: handleAuthenticate,
  })

  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    formik,
    isLoading,
    handleModal,
  }
}
