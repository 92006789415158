import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'

import * as SC from './styles'

const Tables = () => {
  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('fibonacciCalculator.title')}</PageTitle>
      </SC.WrapperHeader>

      <Separator verticalSize={20} />

      <SC.WrapperFilter>
        <SC.WrapperIFrame>
          <iframe src="https://ssltools.investing.com/fibonacci-calculator/index.php?force_lang=12"></iframe>
        </SC.WrapperIFrame>
      </SC.WrapperFilter>
    </SC.Container>
  )
}

export default Tables
