/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal from '@components/Modal'
import Field from '@components/UI/Field'
import FieldSelect from '@components/UI/FieldSelect'
// import FieldCurrency from '@components/UI/FieldCurrency'
import Icon from '@components/UI/Icon'
import Separator from '@components/UI/Separator'
import Button from '@components/UI/Button'
// import FieldDatePicker from '@components/UI/FieldDatePicker'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import ModalCustomerOperation from '@components/ModalCustomerOperation'
import FieldDatePicker from '@components/UI/FieldDatePicker'
import { useAuthMethods } from '@store/auth'

import Tabs from './Tabs'
import { useBuySellOperation } from './useBuySellOperation'
import { IProps } from './types'
import * as SC from './styles'

const BuySellOperation = ({
  display,
  closeModal,
  quoteId,
  operation,
  coins,
  price,
  getUpdatedRate,
}: IProps) => {
  const authStore = useAuthMethods()

  const {
    timer,
    startOperation,
    start,
    finish,
    displayCustomer,
    handleModalCustomer,
    operationCustomer,
    setOperationCustomer,
    handleCloseModal,
    tableListOptions,
    formik,
    isLoading,
    handleBuySellStartTimer,
    getCoinWithMaximumLenght,
  } = useBuySellOperation({ closeModal, quoteId, coins, operation, price })

  const title = (
    <>
      <Typography
        fontType={FontType.bold}
        color={theme.palette.primary.lightest}
        size={30}
      >
        {`${operation.toUpperCase()} ${coins[0]}`}
      </Typography>
      <Separator horizontalSize={6} />
      <Typography
        fontType={FontType.bold}
        color={theme.palette.neutral.regular}
        size={30}
      >
        vs
      </Typography>
      <Separator horizontalSize={6} />
      <Typography
        fontType={FontType.bold}
        color={theme.palette.primary.lightest}
        size={30}
      >
        {`${coins[1]} SPOT`}
      </Typography>
    </>
  )

  return (
    <Modal
      display={display}
      onClose={() => (!isLoading ? handleCloseModal() : '')}
      title={title}
      width={700}
      height={850}
    >
      <SC.SectionMain>
        <SC.OrganizeHorizontal>
          <SC.WrapperField>
            <Field
              label={
                <SC.OrganizeHorizontal>
                  <Typography
                    fontType={FontType.medium}
                    color={
                      operation === 'buy'
                        ? theme.palette.success.dark
                        : theme.palette.error.dark
                    }
                    size={20}
                  >
                    {`${operation.toUpperCase()} ${
                      coins[0]
                    } (${getCoinWithMaximumLenght(coins[0], price)})`}
                  </Typography>

                  <Icon
                    name="RxUpdate"
                    onCallback={getUpdatedRate}
                    alt="Atualizar Taxa"
                    title="Atualizar Taxa"
                  />
                </SC.OrganizeHorizontal>
              }
              name="dealtAmount"
              value={formik.values.dealtAmount}
              onChange={(e) => {
                formik.setFieldValue(
                  'dealtAmount',
                  e.target.value.replace('R$', '').trim(),
                )
              }}
              currency
              widthFull
            />
          </SC.WrapperField>
        </SC.OrganizeHorizontal>

        <SC.OrganizeHorizontal>
          <SC.WrapperField>
            <FieldDatePicker
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  DATA PARA OPERAÇÃO
                </Typography>
              }
              minDate={new Date()}
              defaultDate={formik.values.executionDate}
              onChange={(date: string) =>
                formik.setFieldValue('executionDate', date)
              }
              widthFull
            />
          </SC.WrapperField>

          <Separator horizontalSize={10} />

          <SC.WrapperField>
            <FieldDatePicker
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  DATA PARA LIQUIDAÇÃO
                </Typography>
              }
              minDate={new Date()}
              defaultDate={formik.values.termsSplitValueDate}
              onChange={(date: string) => console.log(date)}
              widthFull
            />
          </SC.WrapperField>
        </SC.OrganizeHorizontal>

        <SC.OrganizeHorizontal>
          <SC.WrapperField>
            <Field
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  CLIENTE DA OPERAÇÃO
                </Typography>
              }
              name="customer_buy_sel"
              value={operationCustomer.name}
              placeholder="Selecione o cliente"
              maxLength={100}
              onChange={() => null}
              widthFull
            />
            <SC.WrapperSearch>
              <Icon name={'FaSearch'} onCallback={handleModalCustomer} />
            </SC.WrapperSearch>
          </SC.WrapperField>

          <SC.WrapperField>
            <FieldSelect
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  MESA
                </Typography>
              }
              name="operationTable"
              options={tableListOptions ?? []}
              selected={formik.values.operationTable}
              handleChange={(value) => {
                formik.setFieldValue('operationTable', value)
              }}
            />
          </SC.WrapperField>
        </SC.OrganizeHorizontal>

        <Separator verticalSize={10} />

        <Tabs formik={formik} />

        <SC.WrapperBottom>
          {start && (
            <SC.WrapperTimer>
              <Icon name={'FaClock'} />
              <Separator horizontalSize={5} />
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.darkest}
                size={15}
              >
                {timer} SEG
              </Typography>
            </SC.WrapperTimer>
          )}

          <SC.WrapperButton>
            {!start ? (
              <Button
                tertiary
                onClick={() => handleBuySellStartTimer(formik.values)}
                height={60}
                width={150}
                loading={isLoading}
                disabled={!formik.isValid || !operationCustomer.id || isLoading}
              >
                {isLoading ? 'ENVIANDO' : 'ENVIAR'}
              </Button>
            ) : (
              <Button
                tertiary
                onClick={finish}
                height={60}
                width={300}
                disabled={!timer || isLoading}
              >
                FINALIZAR
              </Button>
            )}
          </SC.WrapperButton>
        </SC.WrapperBottom>

        <SC.WrapperFooter>
          {authStore.profile?.parentCustomer && (
            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              size={10}
            >
              {authStore.profile.parentCustomer?.name.toUpperCase() ||
                authStore.profile.parentCustomer?.corporateName.toUpperCase()}
            </Typography>
          )}
        </SC.WrapperFooter>
      </SC.SectionMain>

      <ModalCustomerOperation
        display={displayCustomer}
        closeModal={handleModalCustomer}
        onCallback={setOperationCustomer}
      />
    </Modal>
  )
}

export default BuySellOperation
