import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'setupOperator', {
    title: 'OPERADORES DA MESA',
    add: 'Incluir',
    buttonAddOperator: 'Salvar',
  })
}
