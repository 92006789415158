import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/types/devices'

export const Container = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.palette.primary.darkest};

  @media (${mediaQuerydevices.tablet}) {
    height: 135vh;
  }
`

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: ${({ theme }) => theme.layout.desktop.size.max};
  height: 100vh;
`
