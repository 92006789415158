import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { months } from '@resources/helpers/utils'

export const formatStringDate = (date: string, dateFormat = 'DD/MM/YYYY') => {
  return dayjs(date).format(dateFormat)
}

export const formatMonthYearDate = (dateStr: string) => {
  const dt = dayjs(dateStr?.split('T')[0])

  return `${months[dt.get('month')]} ${dt.get('year')}`
}
