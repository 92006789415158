import { XMLParser } from 'fast-xml-parser'
import { UseGetRssFeed } from '@hooks/rssFeed/useRssFeed'
import { useEffect, useState } from 'react'

export const UseRssFeed = ({ url }: { url: string }) => {
  const [items, setItems] = useState<any[]>([])
  const { data } = UseGetRssFeed(url)

  useEffect(() => {
    if (!data) return

    const result = parseRssFeed(data)

    setItems(result)
  }, [data])

  const parseRssFeed = (data: any) => {
    const parser = new XMLParser({
      ignoreAttributes: false,
    })

    const base64Data = data.split(',')[1]
    const decodedData = window.atob(base64Data)
    const parsed = parser.parse(decodedData)

    return parsed.rss.channel.item
  }

  function fixGarbledText(garbledText: string) {
    const uint8Array = new Uint8Array(garbledText.length)
    for (let i = 0; i < garbledText.length; i++) {
      uint8Array[i] = garbledText.charCodeAt(i)
    }
    const textDecoder = new TextDecoder('utf-8')
    return textDecoder.decode(uint8Array)
  }

  return {
    items,
    fixGarbledText,
  }
}
