import { createElement, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'

import View from './view'
import { useResetPassword } from '@hooks/auth/useAuth'
import { IFormikValues, IViewProps } from './types'
import PathRoutes from '@route/PathRoutes'
import { useFormik } from 'formik'
import { newPasswordSchema } from './yup/newPasswordSchema'

const NewPasswordContainer = () => {
  const alert = useAlert()
  const navigate = useNavigate()
  const { userid } = useParams()

  const [password, setPassword] = useState('')

  const reset = useResetPassword(password, userid)

  useEffect(() => {
    if (reset.isError) {
      alert.error('Não foi possível resetar sua senha. Tente novamente.')
      return
    }

    if (reset.isSuccess) {
      alert.success('Senha salva com sucesso')
      navigate(PathRoutes.backoffice.signIn.url)
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset.isSuccess, reset.isError])

  const handleNewPassword = ({ password }: IFormikValues) => {
    if (!userid) {
      alert.error('Não foi possível resetar sua senha. Tente novamente.')
      return
    }

    setPassword(password)
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: newPasswordSchema,
    onSubmit: handleNewPassword,
  })

  const viewProps: IViewProps = {
    formik,
    isLoading: reset.isLoading,
  }

  return createElement(View, viewProps)
}

export default NewPasswordContainer
