import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { DefaultProps } from '@resources/types'

const down = keyframes`
  from {
    height: 0;
    visibility: visible;
  }
  to {
    visibility: visible;
  }
`

const up = keyframes`
  to {
    height: 5px;
    visibility: hidden;
  }
  from {
    visibility: visible;
  }
`

export const Container = styled.div`
  width: 100%;
  background: transparent;
  padding: 0;
  height: 37px;
  margin: -5px 0 0 0;
`

export const ContainerInput = styled.div<DefaultProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: calc(100% - 20px);
  height: 35px;
  background: ${({ theme }) => theme.palette.primary.dark};
  border-radius: 8px;
  cursor: pointer;
`

export const Name = styled.div<DefaultProps>`
  height: 30px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.secondary.light};
`

export const IconContainer = styled.div<{ rotate: number } & DefaultProps>`
  width: 30px;
  height: 26px;
  rotate: ${({ rotate }) => `${rotate}deg`};
  ${({ theme }) => theme.mixins.transition('0.5s')};
`

export const WrapperListOption = styled.div<
  { visible: boolean } & DefaultProps
>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  max-height: 300px;
  overflow-x: auto;
  position: absolute;
  z-index: 999;
  background: ${({ theme }) => theme.palette.secondary.darkest};
  margin: 5px 0 0 -70px;
  padding: 10px 0px;
  border-radius: 10px;
  visibility: hidden;
  animation: ${({ visible }) => (visible ? down : up)} 0.5s forwards;
  ${({ theme }) => theme.mixins.secondaryShadow};
`

export const WrapperItem = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  min-height: 40px;
  margin: 0 10px;
  padding: 0 10px;
  background: transparente;
  margin: 2px 0;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.dark};

  :hover {
    background: ${({ theme }) => theme.palette.primary.regular}80;
  }
`

export const WrapperInfo = styled.div`
  width: 100%;
  margin: -5px 0 0 0;
  padding: 0;
`
