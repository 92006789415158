import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const WrappeMenu = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`

export const CustomerInfo = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const PersonInfo = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const WrapperProfile = styled.div<DefaultProps>`
  width: 50px;
  height: 80px;
  z-index: 999;
  transition: 300ms all;

  &:hover {
    div + div {
      visibility: visible;
      svg:first-of-type {
        visibility: visible;
      }
    }
  }
`

export const ProfileMenu = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 15px 0 0 0;
  background: ${({ theme }) => theme.palette.primary.dark};
  cursor: pointer;
  transition: 300ms all;
`

export const MenuList = styled.div<DefaultProps>`
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 204px;
  min-height: 40px;
  border-radius: 8px;
  margin: 5px 0 0 -170px;
  background: ${({ theme }) => theme.palette.primary.dark};
  padding: 10px 8px;

  ${({ theme }) => theme.mixins.t}
`

export const MenuItem = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 15px;
  margin: 0 0 5px;
  padding: 10px 10px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.darkest}80;
  }
`
