import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'createTable', {
    title: 'Create',
    input: {
      name: {
        label: 'Table name',
        placeholder: 'Type the table name',
      },
      description: {
        label: 'Description',
        placeholder: 'Type the Description',
      },
    },
    buttonCreateAccount: 'Create',
  })
}
