import * as Yup from 'yup'

export const buySellOperationSchema = Yup.object().shape({
  baseSplitValueDate: Yup.string().required(''),
  dealtAmount: Yup.string()
    .test('isValidName', 'Informe o valor da operação', (val) => {
      return String(val).length > 0
    })
    .required('Informe o valor da operação'),
  executionDate: Yup.string().required(''),
  termsSplitValueDate: Yup.string().required(''),
  operationTable: Yup.string().required(''),
})
