import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import {
  ICoverageRates,
  ICoverageRatesUpdateRequest,
} from '@resources/types/services/coverageRates'
import { translateUrlParam } from '@resources/utils/forString'

export const CoverageRatesService = {
  crateRatesByCustomer: async ({
    customerId,
  }: {
    customerId: string
  }): Promise<ICoverageRates[]> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.coverageRates.createAllRatesByCustomer,
      {
        customer: customerId,
        coin: '',
        spread: 0,
        spot: 0,
      },
    )

    return response.data
  },

  getRatesByCustomer: async (customerId: string): Promise<ICoverageRates[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(
      endpoints.URL.coverageRates.getRatesByCustomer,
      {
        customerId,
      },
    )

    const response: AxiosResponse = await api.get(url)

    return response.data
  },

  updateRateById: async ({
    id,
    spread,
  }: ICoverageRatesUpdateRequest): Promise<ICoverageRates> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.coverageRates.updateById, {
      id,
    })

    const response: AxiosResponse = await api.patch(url, { spread })

    return response.data
  },
}
