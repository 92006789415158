/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import { useAccessManagement } from '@hooks/accessManagement/useAccessManagement'
import { ProfileTypes } from '@resources/types/profileTypes'
import { IGridHeader, IGridData } from '@components/UI/DataGrid'
import Icon from '@components/UI/Icon'
import { IAccessResponse } from '@resources/types/services/accessManagement'
import { useDeleteUserById } from '@hooks/user/useUser'
import { useAuthMethods } from '@store/auth'
import { translateNameProfile } from '@resources/utils/forString'

const header: IGridHeader[] = [
  {
    label: 'Perfil',
    grid: 2.5,
    action: null,
    value: 'profile',
    field: 'values',
    styles: {
      align: 'left',
      fontSize: 12,
    },
  },
  {
    label: 'Nome',
    grid: 3.5,
    action: null,
    value: 'name',
    field: 'values',
    styles: {
      align: 'left',
      fontSize: 14,
    },
  },
  {
    label: 'Email',
    grid: 4.5,
    action: null,
    value: 'email',
    field: 'values',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
  {
    label: '',
    grid: 0.5,
    action: null,
    value: 'icon1',
    field: 'element',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
  {
    label: '',
    grid: 0.5,
    action: null,
    value: 'icon2',
    field: 'element',
    styles: {
      align: 'left',
      fontSize: 13,
    },
  },
]

export const useAccessManagementContainer = () => {
  const alert = useAlert()
  const queryClient = useQueryClient()
  const { profile } = useAuthMethods()

  const { isError, data, isLoading } = useAccessManagement()
  const { mutate, isLoading: isLoadingDeleteUser } = useDeleteUserById()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [dataDeleteId, setDataDeleteId] = useState('')
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [userData, setUserData] = useState<IAccessResponse | null>(null)

  const handleSignUpModal = () => {
    setShowSignUpModal(!showSignUpModal)
  }
  const handleUpdateModal = () => {
    setUserData(null)
  }

  const openUpdateModal = (data: IAccessResponse) => {
    setUserData(data)
  }

  const handleDeleteModal = (id = '') => {
    setDataDeleteId(id)
    setShowDeleteModal(!showDeleteModal)
  }

  const onSuccessDeleteUser = (): void => {
    queryClient.invalidateQueries(['useAccessManagement'])
    alert.success('Acesso excluído com sucesso!')
  }
  const onErrorDeleteUser = (): void => {
    queryClient.invalidateQueries(['useAccessManagement'])
    alert.error('Houve uma erro ao deletear o acesso.')
  }

  const fetchDeleteModal = () => {
    const deleteUserById = { uuid: dataDeleteId }
    mutate(deleteUserById, {
      onSuccess: onSuccessDeleteUser,
      onError: onErrorDeleteUser,
    })

    setShowDeleteModal(!showDeleteModal)
  }

  const handleData = () => {
    if (!data) return []

    const dataFilterdPaymaster = data.filter(
      (item) => item.profile === ProfileTypes.master,
    )

    const prepareData = dataFilterdPaymaster.map((item) => ({
      id: item.id.toString(),
      icon1: <Icon name="FaSearch" onCallback={() => openUpdateModal(item)} />,
      icon2: (
        <Icon name="FaTrash" onCallback={() => handleDeleteModal(item.uuid)} />
      ),
      element: undefined,
      values: {
        profile: translateNameProfile(item.profile),
        name: item.name,
        email: item.email,
      },
    }))

    return prepareData
  }

  return {
    isError,
    isLoading,
    handleSignUpModal,
    showSignUpModal,
    header,
    handleData,
    userData,
    handleUpdateModal,
    showDeleteModal,
    handleDeleteModal,
    fetchDeleteModal,
    isLoadingDeleteUser,
    profile: profile.profile,
  }
}
