import styled from '@emotion/styled'

export const WrapperLoading = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 20px);
  padding: 0;
`
export const Text = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-family: Open-Sans, Helvetica, Sans-Serif;
  font-size: 25px;
  margin: 20px;
  text-align: center;
`
