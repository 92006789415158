import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div``

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WrapperFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const WrapperBackButton = styled.div<DefaultProps>`
  width: 110px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  background: ${({ theme }) => theme.palette.neutral.regular};
  cursor: pointer;
`
