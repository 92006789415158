import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const WrapperProgress = styled.div<DefaultProps>`
  width: 100%;
  height: 3px;
  background: ${({ theme }) => theme.palette.error.regular};
`

export const Progress = styled.div<{ progress: number } & DefaultProps>`
  width: ${({ progress }) => (progress ? progress : 100)}%;
  height: 3px;
  background: ${({ theme }) => theme.palette.success.dark};
`
