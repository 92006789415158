import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { ISignal } from '@resources/types/services/signal'
import { translateUrlParam } from '@resources/utils/forString'

export const SignalService = {
  getSignalList: async (): Promise<ISignal[]> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.get(endpoints.URL.signal.list)

    return response.data
  },

  getSignalByCategory: async (category: string): Promise<ISignal[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.signal.listByCategory, {
      category,
    })
    const response: AxiosResponse = await api.get(url)

    return response.data
  },
}
