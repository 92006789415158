import { useQuery } from 'react-query'

import { OperationManagementService } from '@services/operationManagement'
import { IOperationManagementReportConsolidatedByCustomerResponse } from '@resources/types/services/operationManagement'

export const useOperationManagementReportByCustomer = (
  customerId: string,
  data: {
    proxy: string | null
    exchangeTable: string | null
    direction: string | null
    createdAtFrom: string
    createdAtTo: string
  },
) => {
  return useQuery<
    IOperationManagementReportConsolidatedByCustomerResponse,
    Error
  >(
    ['useOperationManagementReportByCustomer', customerId],
    () => {
      return OperationManagementService.reportConsolidetedList(customerId, data)
    },
    {
      enabled: !!customerId,
    },
  )
}
