/* eslint-disable @typescript-eslint/no-unused-vars */
import Field from '@components/UI/Field'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Button from '@components/UI/Button'
import Icon from '@components/UI/Icon'
import { cpfCnpjMask, cellPhoneMask } from '@resources/helpers/utils'
import { ProfileTypes } from '@resources/types/profileTypes'

import { useCustomerOperationAdd } from './useCustomerOperationAdd'
import * as SC from './styles'
import { IProps } from './types'

const CustomerOperationAdd = ({
  selectCustomerOperation,
  setCurrentStep,
  handleCloseModal,
}: IProps) => {
  const { formik, isError, isSuccess, isLoading, data, profile } =
    useCustomerOperationAdd({ selectCustomerOperation, handleCloseModal })

  return (
    <form onSubmit={formik.handleSubmit}>
      <SC.SectionMain>
        <SC.WrapperField widthFull>
          <Field
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                Nome
              </Typography>
            }
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Nome do cliente"
            widthFull
          />
        </SC.WrapperField>

        <Separator verticalSize={5} />

        <SC.WrapperField widthFull>
          <Field
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                Email
              </Typography>
            }
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email do cliente"
            widthFull
          />
        </SC.WrapperField>

        <Separator verticalSize={5} />

        <SC.OrganizeHorizontal>
          <SC.WrapperField>
            <Field
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  CPF/CNPJ
                </Typography>
              }
              name="document"
              onChange={(e) => {
                formik.setFieldValue(
                  'document',
                  cpfCnpjMask(e.target.value || ''),
                )
              }}
              value={formik.values.document}
              placeholder="Documento do cliente"
              widthFull
              maxLength={18}
            />
          </SC.WrapperField>

          <Separator horizontalSize={10} />

          <SC.WrapperField>
            <Field
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  Celular
                </Typography>
              }
              name="cellphone"
              onChange={(e) => {
                formik.setFieldValue(
                  'cellphone',
                  cellPhoneMask(e.target.value || ''),
                )
              }}
              value={formik.values.cellphone}
              placeholder="Celular do cliente"
              widthFull
            />
          </SC.WrapperField>
        </SC.OrganizeHorizontal>

        <Separator verticalSize={10} />

        <SC.OrganizeHorizontal>
          <SC.BackButton onClick={() => setCurrentStep('search')}>
            <SC.WrapperIcone>
              <Icon name="FaArrowLeft" />
            </SC.WrapperIcone>

            <Typography
              fontType={FontType.medium}
              color={theme.palette.primary.light}
              size={16}
            >
              Voltar
            </Typography>
          </SC.BackButton>
          <Button
            type="submit"
            height={60}
            width={150}
            loading={isLoading}
            disabled={
              !formik.isValid ||
              !formik.dirty ||
              [profile.profile].includes(ProfileTypes.master)
            }
          >
            CADASTRAR
          </Button>
        </SC.OrganizeHorizontal>
      </SC.SectionMain>
    </form>
  )
}

export default CustomerOperationAdd
