import React, { Fragment } from 'react'

import Footer from './Footer'
import HeaderContainer from './Header'
import Menu from './Menu'

import * as SC from './styles'
import { StyleGlobal } from './styleGlobal'

const Panel = ({ children }: any) => {
  return (
    <Fragment>
      <StyleGlobal />

      <SC.Container>
        <HeaderContainer />
        <SC.Main>
          <Menu />
          <SC.WrapperContent>
            <SC.Content>{children}</SC.Content>
          </SC.WrapperContent>
        </SC.Main>
        <Footer />
      </SC.Container>
    </Fragment>
  )
}

export default Panel
