/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from '@resources/translation'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Icon from '@components/UI/Icon'

import * as SC from './styles'
import { IProps } from './types'

const SignalCard = ({
  name,
  symbol,
  marketPosition,
  orderPrice,
  targetPrice,
  stopPrice,
  handleDetailModal,
}: IProps) => {
  // const checkValuesToDisabled = () => {
  //   const result = [!!targetPrice, !!orderPrice, !!stopPrice].every(
  //     (item) => item,
  //   )

  //   return !result
  // }

  return (
    <SC.SignalCard>
      <SC.WrapperHorizontal>
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={18}
        >
          <SC.WrapperHorizontal>
            {name}

            <Separator horizontalSize={8} />
            <Icon name="BiDetail" onCallback={handleDetailModal} />
          </SC.WrapperHorizontal>

          <Typography
            fontType={FontType.medium}
            color={theme.palette.primary.lightest}
            size={13}
          >
            {symbol}
          </Typography>
        </Typography>

        <SC.WrapperSemaphore>
          <SC.Semaphore color={marketPosition} />
          {/* {['flat', 'short'].includes(marketPosition) && <SC.Disabled />}
          <SC.Semaphore color="long" />

          {['long', 'short'].includes(marketPosition) && <SC.Disabled />}
          <SC.Semaphore color="flat" />

          {['flat', 'long'].includes(marketPosition) && <SC.Disabled />}
          <SC.Semaphore color="short" /> */}
        </SC.WrapperSemaphore>
      </SC.WrapperHorizontal>

      <Separator verticalSize={10} />

      <SC.WrapperHorizontal>
        <SC.WrapperBox>
          <SC.BoxValue>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.success.dark}
              size={13}
            >
              {marketPosition === 'flat' || !targetPrice ? '-' : targetPrice}
            </Typography>
          </SC.BoxValue>
          <Typography
            fontType={FontType.medium}
            color={theme.palette.primary.lightest}
            size={10}
          >
            {t('strategiesMonitor.signalCard.goal')}
          </Typography>
        </SC.WrapperBox>
        <SC.WrapperBox>
          <SC.BoxValue>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.primary.lightest}
              size={13}
            >
              {marketPosition === 'flat' || !orderPrice
                ? '-'
                : `${String(orderPrice).slice(0, 8)}`}
            </Typography>
          </SC.BoxValue>
          <Typography
            fontType={FontType.medium}
            color={theme.palette.primary.lightest}
            size={10}
          >
            {t('strategiesMonitor.signalCard.prohibited')}
          </Typography>
        </SC.WrapperBox>
        <SC.WrapperBox>
          <SC.BoxValue>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.error.regular}
              size={13}
            >
              {marketPosition === 'flat' || !stopPrice
                ? '-'
                : `${String(stopPrice).slice(0, 8)}`}
            </Typography>
          </SC.BoxValue>
          <Typography
            fontType={FontType.medium}
            color={theme.palette.primary.lightest}
            size={10}
          >
            {t('strategiesMonitor.signalCard.escape')}
          </Typography>
        </SC.WrapperBox>
      </SC.WrapperHorizontal>
    </SC.SignalCard>
  )
}

export default SignalCard
