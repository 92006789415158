import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import {
  IOperationManagementAddRequest,
  IOperationManagementResponse,
  IOperationManagementReportConsolidatedByCustomerResponse,
  IOperationManagementPerfomanceReportResponse,
} from '@resources/types/services/operationManagement'

export const OperationManagementService = {
  addOperation: async (
    data: IOperationManagementAddRequest,
  ): Promise<IOperationManagementResponse> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.operationManagement.add,
      data,
    )

    return response.data
  },

  reportConsolidetedList: async (
    customerId: string,
    data: any,
  ): Promise<IOperationManagementReportConsolidatedByCustomerResponse> => {
    const api = getApiInstance()

    const url = translateUrlParam(
      endpoints.URL.operationManagement.list.consolidated,
      {
        customerId,
      },
    )

    const response: AxiosResponse = await api.post(url, data)

    return response.data
  },

  perfomanceReport: async (
    customerId: string,
  ): Promise<IOperationManagementPerfomanceReportResponse> => {
    const api = getApiInstance()

    const url = translateUrlParam(
      endpoints.URL.operationManagement.perfomeReport,
      {
        customerId,
      },
    )

    const response: AxiosResponse = await api.post(url, {})

    return response.data.data
  },
}
