import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'forgetPassword', {
    title: 'Esqueceu a senha?',
    subTitle:
      'Informe seu e-mail no campo abaixo que \niremos te enviar um e-mail para redefinir a senha.',
    input: {
      email: {
        label: 'E-mail',
        placeholder: 'Insira seu e-email',
      },
    },
    buttonSign: 'Efetuar login',
    buttonForgot: 'Enviar link',
  })
}
