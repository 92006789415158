import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import { AccessManagementService } from '@services/accessManagement'
import {
  ISignUpRequest,
  IUpdateAccessRequest,
  IAccessResponse,
} from '@resources/types/services/accessManagement'
import {
  ISignInResponse,
  IProfileResponse,
} from '@resources/types/services/auth'
import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'

export const useAccessUpdate = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  IAccessResponse | IProfileResponse,
  any,
  IUpdateAccessRequest,
  unknown
> => {
  return useMutation(AccessManagementService.update, options)
}

export const useAccessSignUp = (
  options?: IMutationOptionsRequest,
): UseMutationResult<ISignInResponse, any, ISignUpRequest, unknown> => {
  return useMutation(AccessManagementService.signUp, options)
}

export const useAccessManagement = (): UseQueryResult<IAccessResponse[]> => {
  return useQuery(['useAccessManagement'], () => {
    return AccessManagementService.getAccessList()
  })
}
