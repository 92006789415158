import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useAlert } from 'react-alert'
import { useFormik } from 'formik'

import { useAddTable } from '@hooks/tables/useTables'
import { useAuthMethods } from '@store/auth'
import { FieldSelectOptionsProps } from '@components/UI/FieldSelect/types'
import { useGetAllByCustomer } from '@hooks/user/useUser'
import { ProfileTypes } from '@resources/types/profileTypes'

import { IHookProps, IFormikValues } from './types'

import { createTableSchema } from './yup/createTableSchema'

export const useCreateTable = ({ handleModal, showModal }: IHookProps) => {
  const alert = useAlert()
  const queryClient = useQueryClient()

  const { profile } = useAuthMethods()
  const { data, isLoading } = useGetAllByCustomer(profile.parentCustomer.uuid)

  const { mutate, isLoading: isLoadingAddTable } = useAddTable()

  const filterProfile = (): FieldSelectOptionsProps[] => {
    if (!data || !data.length) return []

    const list: FieldSelectOptionsProps[] = [{ value: '', label: '' }]

    data.forEach((item) => {
      if (item.profile === ProfileTypes.paymaster) {
        list.push({ value: item.uuid, label: item.name })
      }
    })

    return list
  }

  const onSuccess = (): void => {
    alert.success('Mesa criada com sucesso')

    queryClient.invalidateQueries([
      ['useGetTableListByCustomer', profile.parentCustomer.uuid],
    ])
    handleModal()
  }
  const onError = (): void => {
    alert.error('Ocorreu um erro inesperado. Tente novamente.')
  }

  const handleAuthenticate = (prop: IFormikValues) => {
    const data = {
      customer: prop.customer,
      paymaster: prop.paymaster,
      name: prop.name,
      description: prop.description,
    }

    mutate(data, {
      onSuccess,
      onError,
    })
  }

  const formik = useFormik({
    initialValues: {
      customer: profile?.parentCustomer?.uuid ?? '',
      name: '',
      description: '',
      paymaster: '',
    },
    validationSchema: createTableSchema,
    onSubmit: handleAuthenticate,
  })

  useEffect(() => {
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    formik,
    isLoading: isLoading || isLoadingAddTable,
    showModal,
    handleModal,
    filterProfile,
  }
}
