import React from 'react'

const NotFound = () => {
  return (
    <div>
      <p>{'There`s nothing here: 404!'}</p>
    </div>
  )
}

export default NotFound
