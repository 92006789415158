import { useEffect, useState } from 'react'

// import { clearCpfMask } from '@resources/utils/forString'
import { useGetCustomerOperationByName } from '@hooks/customerOperation/useCustomerOperation'
import { useAuthMethods } from '@store/auth'

export const useCustomerOperationSearch = ({
  handleCloseModal,
  selectCustomerOperation,
  documentSearch,
  setDocumentSearch,
}) => {
  const { profile } = useAuthMethods()

  const [searchOperationCustomer, setSearchOperationCustomer] =
    useState<any>(null)
  const [fetchSearch, setFetchSearch] = useState<boolean>(false)

  const { isLoading, data, isSuccess, isError } = useGetCustomerOperationByName(
    fetchSearch,
    documentSearch,
    profile?.parentCustomer?.uuid,
  )

  const handleSelectCustomerOperation = (data: any) => {
    const prepareCustomerOperation = {
      id: data?.uuid,
      name: data?.name,
      document: data?.document,
    }

    selectCustomerOperation(prepareCustomerOperation)
    handleCloseModal()
  }

  useEffect(() => {
    if (!fetchSearch) return

    setTimeout(() => {
      setFetchSearch(false)
    }, 500)
  }, [fetchSearch, documentSearch, setDocumentSearch])

  return {
    handleSelectCustomerOperation,
    searchOperationCustomer,
    setSearchOperationCustomer,
    fetchSearch,
    setFetchSearch,
    isLoading,
    data,
    isSuccess,
    isError,
  }
}
