import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { ISignInResponse } from '@resources/types/services/auth'
import { translateUrlParam } from '@resources/utils/forString'
import {
  ICustomerResponse,
  ISignUpCustomerRequest,
  IUpdateRequest,
  IUsersByCustomerResponse,
} from '@resources/types/services/customer'
import { HttpCodes } from '@resources/types/httpCode'

export const CustomerService = {
  signUp: async (data: ISignUpCustomerRequest): Promise<ISignInResponse> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.customer.signUp,
      data,
    )

    return response.data
  },

  getCustomerList: async (): Promise<ICustomerResponse[]> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.get(endpoints.URL.customer.list)

    return response.data
  },

  getUsersByCustomerList: async (
    uuid: string,
  ): Promise<IUsersByCustomerResponse[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.customer.usersByCustomer, {
      userid: uuid,
    })

    const response: AxiosResponse = await api.get(url)

    return response.data
  },

  update: async (data: IUpdateRequest): Promise<boolean> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.customer.update, {
      customerId: data.user,
    })

    const response: AxiosResponse = await api.patch(url, data)

    return response.status === HttpCodes.SUCCESS
  },
}
