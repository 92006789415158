import { t } from '@resources/translation'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import Button from '@components/UI/Button'
import Modal from '@components/Modal'
import { cellPhoneMask } from '@resources/helpers/utils'
import Typography, { FontType } from '@components/UI/Typography'

import { useUpdateProfile } from './useUpdateProfile'
import * as SC from './styles'
import { IProps } from './types'

const Update = ({ showModal, handleModal, data }: IProps) => {
  const { formik, isLoading } = useUpdateProfile({
    handleModal,
    data,
  })

  return (
    <Modal
      display={showModal}
      onClose={handleModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={30}
        >
          {t('update.title')}
        </Typography>
      }
      width={950}
      height={580}
    >
      <SC.WrapperContent>
        <form onSubmit={formik.handleSubmit}>
          <Field
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            widthFull
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('signUp.input.name.label')}
              </Typography>
            }
            placeholder={t('update.input.name.placeholder')}
          />

          <Separator verticalSize={15} />

          <Field
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            widthFull
            disabled
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('signUp.input.email.label')}
              </Typography>
            }
            placeholder={t('update.input.email.placeholder')}
          />

          <Separator verticalSize={15} />

          <SC.WrapperFormContent>
            <Field
              id="cpf"
              name="cpf"
              value={formik.values.cpf}
              inputWidth={384}
              maxLength={100}
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('signUp.input.id.label')}
                </Typography>
              }
              placeholder={t('update.input.id.placeholder')}
              disabled
            />

            <Separator horizontalSize={16} />
            <Separator verticalSize={15} />

            <Field
              id="cellphone"
              name="cellphone"
              onChange={(e) => {
                formik.setFieldValue(
                  'cellphone',
                  cellPhoneMask(e.target.value || ''),
                )
              }}
              value={formik.values.cellphone}
              inputWidth={384}
              maxLength={100}
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('signUp.input.phone.label')}
                </Typography>
              }
              placeholder={t('update.input.phone.placeholder')}
            />
          </SC.WrapperFormContent>

          <Separator verticalSize={15} />

          <SC.WrapperFormContent>
            <Field
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              inputWidth={384}
              maxLength={100}
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('update.input.password.label')}
                </Typography>
              }
              placeholder={t('update.input.password.placeholder')}
            />

            <Separator horizontalSize={16} />
            <Separator verticalSize={15} />

            <Field
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              inputWidth={384}
              maxLength={100}
              disabled={formik.values.password === '123456789'}
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  {t('update.input.confirmPassword.label')}
                </Typography>
              }
              placeholder={t('update.input.confirmPassword.placeholder')}
            />
          </SC.WrapperFormContent>

          <Separator verticalSize={50} />

          <Button
            type="submit"
            width={384}
            height={38}
            loading={isLoading}
            disabled={!formik.isValid}
          >
            {t('update.buttonCreateAccount')}
          </Button>
        </form>
      </SC.WrapperContent>
    </Modal>
  )
}

export default Update
