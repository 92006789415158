import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'createTable', {
    title: 'CADASTRAR MESA',
    input: {
      name: {
        label: 'NOME DA MESA',
        placeholder: 'Insira o nome da mesa',
      },
      description: {
        label: 'DESCRIÇÃO',
        placeholder: 'Insira descrição da mesa',
      },
    },
    buttonCreateAccount: 'Cadastrar',
  })
}
