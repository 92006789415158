import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'

import * as SC from './styles'

const View = () => {
  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('currencyConverter.title')}</PageTitle>
      </SC.WrapperHeader>

      <Separator verticalSize={20} />

      <SC.WrapperFilter>
        <SC.WrapperIFrame>
          <iframe src="https://ssltools.investing.com/currency-converter/?from=17&to=12&force_lang=12&with_powered_by=true"></iframe>
        </SC.WrapperIFrame>
      </SC.WrapperFilter>
    </SC.Container>
  )
}

export default View
