import { useState } from 'react'
import dayjs from 'dayjs'

import { useQuotesByPairs, useMarketStatus } from '@hooks/quotes/useQuotes'
import { useGetSentimentsList } from '@hooks/sentiments/useSentiments'
import { useAuthMethods } from '@store/auth'
import { useGetCovertageRatesByCustomer } from '@hooks/coverageRates/useCoverageRates'
import { ProfileTypes } from '@resources/types/profileTypes'
import { useGetMarketOpeningByCustomer } from '@hooks/marketOpening/useMarketOpening'

const pairsSelected = [
  'USD/BRL',
  'EUR/BRL',
  'GBP/BRL',
  'JPY/BRL',
  'CHF/BRL',
  'AUD/BRL',
  'CAD/BRL',
  'DKK/BRL',
  'NOK/BRL',
  'SEK/BRL',
  'CNY/BRL',
  'NZD/BRL',
  'MXN/BRL',
  'ZAR/BRL',
  'CLP/BRL',
  'ARS/BRL',
]

const daysWeek = {
  0: {
    open: 'sundayOpening',
    close: 'sundayClosing',
  },
  1: {
    open: 'mondayOpening',
    close: 'mondayClosing',
  },
  2: {
    open: 'tuesdayOpening',
    close: 'tuesdayClosing',
  },
  3: {
    open: 'wednesdayOpening',
    close: 'wednesdayClosing',
  },
  4: {
    open: 'thursdayOpening',
    close: 'thursdayClosing',
  },
  5: {
    open: 'fridayOpening',
    close: 'fridayClosing',
  },
  6: {
    open: 'saturdayOpening',
    close: 'saturdayClosing',
  },
}

export const useOperationalManagement = () => {
  const { profile } = useAuthMethods()

  const dayOfWeek = dayjs().day()
  const dayWeekToday = daysWeek[dayOfWeek]
  const hourNow = dayjs(new Date()).format('HH:mm:ss')

  const enableCoverageRate = [
    ProfileTypes.paymaster,
    ProfileTypes.admin,
    ProfileTypes.operator,
  ].includes(profile.profile as ProfileTypes)

  const INITAL_COVERAGE_RATE_APPLIED =
    profile.profile === ProfileTypes.operator ? true : false

  const pairs = pairsSelected.join(',')
  const { isLoading, data: quotesByPairs } = useQuotesByPairs(pairs)
  const { isLoading: isLoadingMerketStatus, data: marketStatus } =
    useMarketStatus()
  const { isLoading: sentimentIsLoading, data: sentiments } =
    useGetSentimentsList()
  const { isLoading: isLoadingCoverageRates, data: dataCoverageRates } =
    useGetCovertageRatesByCustomer(
      enableCoverageRate ? profile?.parentCustomer?.uuid : '',
    )
  const { isLoading: isLoadingMarketOpening, data: dataMarketOpening } =
    useGetMarketOpeningByCustomer(profile?.parentCustomer?.uuid)

  const [showModal, setShowMoldal] = useState(false)
  const [operation, setOperation] = useState('')
  const [price, setPrice] = useState<number>(0)
  const [quoteId, setQuoteId] = useState<number>(0)
  const [coins, setCoins] = useState<Array<string>>([])
  const [coverageRateApplied, setCoverageRateApplied] = useState(
    INITAL_COVERAGE_RATE_APPLIED,
  )

  const handleOperationBuy = (
    coins: Array<string>,
    price: number,
    coinId: number,
  ) => {
    setOperation('buy')
    setPrice(price)
    setShowMoldal(true)
    setCoins(coins)
    setQuoteId(coinId)
  }

  const handleOperationSell = (
    coins: Array<string>,
    price: number,
    coinId: number,
  ) => {
    setOperation('sell')
    setPrice(price)
    setShowMoldal(true)
    setCoins(coins)
    setQuoteId(coinId)
  }

  const handleCloseModal = () => {
    setOperation('')
    setPrice(0)
    setShowMoldal(false)
  }

  const handleCoverageRateBuy = (purchasePrice: number, coin: string) => {
    if (!coverageRateApplied) return purchasePrice

    const findCoverage = dataCoverageRates?.find((item) => item.coin === coin)
    const spread = findCoverage?.spread ?? 0
    const sum = purchasePrice + spread

    return sum
  }

  const handleCoverageRateSell = (salesPrice: number, coin: string) => {
    if (!coverageRateApplied) return salesPrice

    const findCoverage = dataCoverageRates?.find((item) => item.coin === coin)
    const spread = findCoverage?.spread ?? 0
    const sum = salesPrice - spread

    return sum
  }

  const validOpenning = (hour: string) => {
    const dateNow = dayjs(new Date()).format('YYYY-MM-DD')
    const hourOpening = dayjs(`${dateNow} ${hourNow}`).isAfter(
      `${dateNow} ${hour}`,
    )

    return hourOpening
  }

  const validClosing = (hour: string) => {
    const dateNow = dayjs(new Date()).format('YYYY-MM-DD')

    const hourClosing = dayjs(`${dateNow} ${hourNow}`).isBefore(
      `${dateNow} ${hour}`,
    )

    return hourClosing
  }

  const marketOpeningOpen = dataMarketOpening?.[0]?.[dayWeekToday.open]
  const marketOpeningClose = dataMarketOpening?.[0]?.[dayWeekToday.close]
  const validMarketinOpening =
    !marketOpeningOpen && !marketOpeningClose
      ? false
      : !validOpenning(marketOpeningOpen) || !validClosing(marketOpeningClose)

  const getUpdatedRate = (coin: string, operation: string) => {
    const quoteByCoin = quotesByPairs?.filter((item) =>
      item.symbol.includes(coin),
    ) as any

    const price =
      operation === 'buy'
        ? handleCoverageRateBuy(
            Number(quoteByCoin?.[0]?.purchasePrice),
            `${coin}/BRL`,
          )
        : handleCoverageRateSell(
            Number(quoteByCoin?.[0]?.salePrice),
            `${coin}/BRL`,
          )

    setPrice(price)
  }

  // console.log('useOperationalManagement', {
  //   hourNow,
  //   marketStatus: marketStatus?.marketIsOpen,
  //   validMarketinOpening,
  //   marketOpeningOpen,
  //   marketOpeningClose,
  //   rule1: profile.profile === ProfileTypes.operator && !coverageRateApplied,
  //   rule:
  //     (profile.profile === ProfileTypes.operator && !coverageRateApplied) ||
  //     !marketStatus?.marketIsOpen ||
  //     validMarketinOpening,
  // })

  const conditionForDev = false

  return {
    showModal,
    operation,
    handleCloseModal,
    handleOperationBuy,
    handleOperationSell,
    coins,
    price,
    quoteId,
    coverageRateApplied,
    setCoverageRateApplied,
    isLoading,
    quotesByPairs,
    isLoadingMerketStatus,
    marketStatus,
    sentimentIsLoading,
    sentiments,
    isLoadingCoverageRates,
    dataCoverageRates,
    handleCoverageRateBuy,
    handleCoverageRateSell,
    enableCoverageRate,
    disabledOperation:
      // (profile.profile === ProfileTypes.operator && !coverageRateApplied) ||
      conditionForDev ||
      !coverageRateApplied ||
      !marketStatus?.marketIsOpen ||
      validMarketinOpening,
    isLoadingMarketOpening,
    dataMarketOpening: !dataMarketOpening ? dataMarketOpening?.[0] : null,
    getUpdatedRate,
  }
}
