import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div<DefaultProps>`
  width: calc(100% - 40px);
  height: 20px;
  padding: 20px;
  margin: 0 0 20px;
  background: ${({ theme }) => theme.palette.primary.regular};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
`

export const Tab = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  height: 20px;
  margin: 0 20px 0;
  cursor: pointer;

  :first-child {
    margin-left: 0px;
  }
`
