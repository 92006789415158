import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { Link as BaseLink } from 'react-router-dom'

export const LinkOption = styled(BaseLink)<DefaultProps>`
  text-align: left;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: 700;
  font-size: 13px;
  font-style: normal;

  cursor: pointer;
`

export const Container = styled.footer<DefaultProps>`
  display: flex;
  width: 100vw;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
`

export const WrapperEmail = styled.div``
