import styled from '@emotion/styled'
// import { mediaQuerydevices } from '@resources/constants/devices'

import { DefaultProps } from '@resources/types'

export const Container = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.palette.primary.darkest};
`

export const Main = styled.div<DefaultProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  width: ${({ theme }) => theme.layout.desktop.size.max};
  padding: 0;
  margin: 0 0 50px 0;
`

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 15px);
  height: calc(100% - 60px);
  padding: 30px;
  margin: 0 0 0 15px;
  background: ${({ theme }) => theme.palette.primary.regular};
  border-radius: 20px;
`

export const Content = styled.div<DefaultProps>`
  width: 100%;
  max-width: 1360px;
  height: 100%;
  margin: 0;
  padding: 0;
`
