import {
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  IFontWeight,
  IFontFamily,
  IFontsTypography,
} from './types'

const fontWeight: IFontWeight = {
  light: fontWeightLight,
  regular: fontWeightRegular,
  medium: fontWeightMedium,
  bold: fontWeightBold,
}

export const fonts: IFontFamily = {
  normal: 'Helvetica, "Trebuchet MS", Verdana, sans-serif',
  black: "'Montserrat', sans-serif;",
  bold: "'Montserrat', sans-serif;",
  extraBold: '',
  extraLight: "'Montserrat', sans-serif;",
  light: "'Montserrat', sans-serif;",
  medium: "'Montserrat', sans-serif;",
  regular: "'Montserrat', sans-serif;",
  semiBold: "'Montserrat', sans-serif;",
  thin: "'Montserrat', sans-serif;",
}

export const typography: IFontsTypography = {
  lineHeight: {
    tight: '100%',
    medium: '130%',
    loose: '150%',
    xLoose: '200%',
  },
  fontSize: {
    xxxsmall: 10,
    xxsmall: 12,
    xsmall: 14,
    small: 16,
    medium: 18,
    large: 20,
    xlarge: 24,
    xxlarge: 32,
  },
  fontWeight,
  fonts,
}
