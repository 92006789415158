import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'

import { DefaultProps } from '@resources/types'

const animatedLeft = keyframes`
  0% { left: calc(0% - 100px); opacity: 0; }

  60% { opacity: 0.1; }

  80% { opacity: 0.25; }

  90% { opacity: 1; }

  100% { left: 0; }
`

const animatedRight = keyframes`
  0% { right: calc(0% - 90px); opacity: 0;  }

  99.9% { opacity: 0.1; }

  100% { right: 0; opacity: 1; }
`

export const Container = styled.div<DefaultProps>`
  width: calc(100% - 40px);
  height: 290px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.palette.primary.regular};
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
`

export const Tab = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  height: 20px;
  margin: 0 10px 0;
  cursor: pointer;
`

export const Body = styled.div`
  width: 100%;
  height: 230px;
`

export const Content = styled.div<{ currentDirection: string }>`
  width: 100%;
  display: block;
  position: relative;

  ${({ currentDirection }) =>
    currentDirection &&
    css`
      animation: ${currentDirection === 'right' ? animatedRight : animatedLeft}
        0.3s;
    `}
`
