import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'operationalManagementReport', {
    title: 'RELATÓRIO DE NEGOCIAÇÃO',
    buttonExport: 'Exportar',
    buy: {
      total: 'Total BUY',
    },
    sell: {
      total: 'Total SELL',
    },
    net: {
      total: 'NET',
    },
    consolidated: {
      title: 'Dados Consolidados',
    },
  })
}
