import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'

import * as SC from './styles'
import RssFeed from '@components/RssFeed'

const View = () => {
  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('marketResumeTechnicalAnalysis.title')}</PageTitle>
      </SC.WrapperHeader>

      <Separator verticalSize={20} />

      <SC.WrapperFilter>
        <SC.WrapperIFrame>
          {RssFeed({
            url: 'https://br.investing.com/rss/market_overview_Technical.rss',
          })}
        </SC.WrapperIFrame>
      </SC.WrapperFilter>
    </SC.Container>
  )
}

export default View
