/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import PathRoutes from '@route/PathRoutes'

export const useHome = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const url = window.location.search
    const params = new URLSearchParams(url)

    const resolveRoutes = {
      confirm: () =>
        navigate(
          PathRoutes.backoffice.confirmPassword.url.replace(
            ':userid',
            params.get('confirm') ?? '',
          ),
        ),
      'new-password': () =>
        navigate(
          PathRoutes.backoffice.newPassword.url.replace(
            ':userid',
            params.get('new-password') ?? '',
          ),
        ),
    }

    const confirm = params.get('confirm')
    const newPassword = params.get('new-password')

    const callRoute =
      (confirm && 'confirm') || (newPassword && 'new-password') || ''

    if (callRoute) {
      resolveRoutes[callRoute]()
    }
  }, [navigate])

  return {}
}
