import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import {
  IUsersByCustomerResponse,
  IUserDeleteByIdRequest,
} from '@resources/types/services/user'
import { translateUrlParam } from '@resources/utils/forString'

export const UserService = {
  getAllByCustomer: async (
    customerId: string,
  ): Promise<IUsersByCustomerResponse[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.user.allByCustomer, {
      customerId,
    })

    const response: AxiosResponse = await api.get(url)

    return response.data
  },

  deleteById: async ({ uuid }: IUserDeleteByIdRequest): Promise<any> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.user.delete, {
      uuid,
    })

    const response: AxiosResponse = await api.delete(url)

    return response.data
  },
}
