import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import PathRoutes from '@route/PathRoutes'
import { clearStorage } from '@store/index'
import { useAuthActions } from '@store/auth'
import AuthLoading from '@components/UI/AuthLoading'
import { getApiInstance } from '@services/api'
import { INITIAL_STATE_PROFILE } from '@store/auth/types'

import { Container } from './styles'

const SignOut = () => {
  const navigate = useNavigate()
  const { setAuth } = useAuthActions()

  const handleLogout = useCallback(async (): Promise<void> => {
    await clearStorage()
    setAuth('', INITIAL_STATE_PROFILE)
    getApiInstance('', true)

    navigate(PathRoutes.backoffice.signIn.url, { replace: true })
  }, [navigate, setAuth])

  useEffect(() => {
    handleLogout()
  }, [handleLogout])

  return (
    <Container>
      <AuthLoading
        color={'#FFFFFF'}
        text={'Aguarde, você está sendo deslogado.'}
      />
    </Container>
  )
}

export default SignOut
