import styled from '@emotion/styled'

import { ReactComponent as BlockedEye_SVG } from '@assets/images/blocked-eye.svg'
import { ReactComponent as UnblockedEye_SVG } from '@assets/images/unblocked-eye.svg'
import { ReactComponent as Logo_SVG } from '@assets/images/logo.svg'
import { ReactComponent as Padlock_SVG } from '@assets/images/padlock.svg'
import { ReactComponent as LogoWhite_SVG } from '@assets/images/logo-white.svg'
import { ReactComponent as HomeInvist_SVG } from '@assets/images/home-invist.svg'
import { ReactComponent as HomeTrader_SVG } from '@assets/images/home-trader.svg'
import { ReactComponent as HomeService_SVG } from '@assets/images/home-service.svg'
import { ReactComponent as FooterLinkedin_SVG } from '@assets/images/footer-linkedin.svg'
import { ReactComponent as FooterYouTube_SVG } from '@assets/images/footer-youtube.svg'
import { ReactComponent as LogoDashboard_SVG } from '@assets/images/logo-dashboard.svg'
import { ReactComponent as MenuHamburguer_SVG } from '@assets/images/menu-hamburguer.svg'
import { ReactComponent as Avatar_SVG } from '@assets/images/avatar.svg'
import { ReactComponent as Logout_SVG } from '@assets/images/logout.svg'
import { ReactComponent as DashboardIconHome_SVG } from '@assets/images/dashboard-icon-home.svg'
import { ReactComponent as ArrowPositive_SVG } from '@assets/images/arrow-positive.svg'
import { ReactComponent as ArrowNegative_SVG } from '@assets/images/arrow-negative.svg'
import { ReactComponent as Close_SVG } from '@assets/images/close.svg'
import LogoDashboard_PNG from '@assets/images/logo-dashboard.png'

export const Wrapper = styled.div`
  cursor: pointer;
`

export const BlockedEyeSVG = styled(BlockedEye_SVG)``

export const UnblockedEyeSVG = styled(UnblockedEye_SVG)``

export const LogoSVG = styled(Logo_SVG)``

export const PadlockSVG = styled(Padlock_SVG)``

export const LogoWhiteSVG = styled(LogoWhite_SVG)``

export const HomeInvistSVG = styled(HomeInvist_SVG)``

export const HomeTraderSVG = styled(HomeTrader_SVG)``

export const HomeServiceSVG = styled(HomeService_SVG)``

export const FooterLinkedinSVG = styled(FooterLinkedin_SVG)``

export const FooterYouTubeSVG = styled(FooterYouTube_SVG)``

export const LogoDashboardSVG = styled(LogoDashboard_SVG)``

export const MenuHamburguerSVG = styled(MenuHamburguer_SVG)``

export const AvatarSVG = styled(Avatar_SVG)``

export const LogoutSVG = styled(Logout_SVG)``

export const DashboardIconHomeSVG = styled(DashboardIconHome_SVG)``

export const ArrowPositiveSVG = styled(ArrowPositive_SVG)``

export const ArrowNegativeSVG = styled(ArrowNegative_SVG)``

export const CloseSVG = styled(Close_SVG)``

export const LogoDashboard = styled.img({
  src: { LogoDashboard_PNG },
})
