import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import { UserService } from '@services/auth'

import {
  ISignInResponse,
  ISignInRequest,
  IProfileResponse,
} from '@resources/types/services/auth'
import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'

export const useSignIn = (
  options?: IMutationOptionsRequest,
): UseMutationResult<ISignInResponse, any, ISignInRequest, unknown> => {
  return useMutation(UserService.signIn, options)
}

export const useProfile = (token = ''): UseQueryResult<IProfileResponse> => {
  return useQuery(
    ['useProfile', token],
    () => {
      return UserService.profile()
    },
    {
      enabled: !!token,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useForgotPassword = (
  email = '',
): UseQueryResult<ISignInResponse> => {
  return useQuery(
    ['useForgotPassword', email],
    () => {
      return UserService.forgotPassword(email)
    },
    {
      enabled: !!email,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useNewPassword = (
  newPassword: string | null,
  userid: string | undefined,
): UseQueryResult<ISignInResponse> => {
  return useQuery(
    ['newPassword', newPassword, userid],
    () => {
      return UserService.newPassword(newPassword, userid)
    },
    {
      enabled: !!newPassword && !!userid,
      // refetchOnMount: true,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false,
      // staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useResetPassword = (
  newPassword: string | null,
  userid: string | undefined,
): UseQueryResult<ISignInResponse> => {
  return useQuery(
    ['resetPassword', newPassword, userid],
    () => {
      return UserService.resetPassword(newPassword, userid)
    },
    {
      enabled: !!newPassword && !!userid,
      // refetchOnMount: true,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false,
      // staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useConfirmUser = (
  userid: string | undefined,
  fetchAPI: boolean,
): UseQueryResult<ISignInResponse> => {
  return useQuery(
    ['newPassword', userid, fetchAPI],
    () => {
      return UserService.confirmUser(userid)
    },
    {
      enabled: fetchAPI,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}
