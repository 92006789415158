import styled from '@emotion/styled'
import { DefaultProps } from '@resources/types'

type PlusButtonProps = DefaultProps & {
  disabled: boolean
}

export const WrapperGrids = styled.div`
  flex-direction: column;
  display: flex;
`
export const WrapperContent = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: auto;
  border-radius: 8px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 40px;
  gap: 10px;
`
export const WrapperField = styled.div`
  width: 90%;
`
export const WrapperLoading = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
`
export const WrapperButton = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 20px;
`

export const PlusButton = styled.div<PlusButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  width: 52px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ theme, disabled }) =>
    disabled ? theme.palette.secondary.darkest : theme.palette.success.dark};
`
