import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'signUp', {
    title: 'Sign up',
    input: {
      name: {
        label: 'Full name',
        placeholder: 'Type your full name',
      },
      id: {
        label: 'ID',
        placeholder: 'Type your ID',
      },
      phone: {
        label: 'Phone',
        placeholder: 'Type your phone',
      },
      email: {
        label: 'E-mail',
        placeholder: 'Type your e-email',
      },
      password: {
        label: 'Password',
        placeholder: 'Type your password',
      },
      confirmPassword: {
        label: 'Confirm your password',
        placeholder: 'Confirm your password',
      },
    },
    notification: 'I want to receive notifications',
    buttonCreateAccount: 'Sign up',
  })
}
