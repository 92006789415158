import PathRoutes from '../PathRoutes'

import { useAuthMethods } from '@store/auth'
import { INewMenuByProfile } from './types'

export const getNewMenuByProfile = () => {
  const { profile: userProfile } = useAuthMethods()

  const setup: Array<INewMenuByProfile> = [
    {
      id: 'trading-panel',
      icon: 'BsCurrencyExchange',
      label: 'Painel de Negociação',
      link: PathRoutes.backoffice.operationalManagement.url,
      allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
      items: [],
    },
    {
      id: 'strategy-dashboard',
      icon: 'AiOutlineSliders',
      label: 'Painel de Estratégias',
      link: '',
      allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
      items: [
        {
          id: 'strategy-dashboard_monitor',
          icon: '',
          label: 'Monitor de Estratégias',
          link: PathRoutes.backoffice.strategiesMonitor.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
        {
          id: 'strategy-dashboard_trending-market',
          icon: '',
          label: 'Mercado com Tendência',
          link: PathRoutes.backoffice.trendingMarket.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
        {
          id: 'strategy-dashboard_trendless-market',
          icon: '',
          label: 'Mercado Lateral',
          link: PathRoutes.backoffice.trendlessMarket.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
      ],
    },
    {
      id: 'treasury-panel',
      icon: 'FaCoins',
      label: 'Painel de Tesousaria',
      link: '',
      allowed: userProfile?.parentCustomer?.strategyMonitor === '1',
      items: [
        {
          id: 'treasury-panel_management-report',
          icon: '',
          label: 'Relatório de Operações',
          link: PathRoutes.backoffice.operationalManagementReport.url,
          allowed: userProfile?.parentCustomer?.strategyMonitor === '1',
        },
        {
          id: 'treasury-panel_perfomance-report',
          icon: '',
          label: 'Relatório de Perfomance',
          link: PathRoutes.backoffice.perfomanceReport.url,
          allowed: userProfile?.parentCustomer?.strategyMonitor === '1',
        },
        {
          id: 'treasury-panel_operation-matching',
          icon: '',
          label: 'Operações Casadas',
          link: PathRoutes.backoffice.operationalMatching.url,
          allowed: userProfile?.parentCustomer?.strategyMonitor === '1',
        },
      ],
    },
    {
      id: 'admin-panel',
      icon: 'BsNewspaper',
      label: 'Painel ADM',
      link: '',
      allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
      items: [
        {
          id: 'admin-panel_tables',
          icon: '',
          label: 'Mesa de Operações',
          link: PathRoutes.backoffice.tables.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
        {
          id: 'admin-panel_coverage-taxes',
          icon: '',
          label: 'Spread Interbancário',
          link: PathRoutes.backoffice.coverageTaxes.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
        {
          id: 'admin-panel_market-opening',
          icon: '',
          label: 'Abertura de Mercado',
          link: PathRoutes.backoffice.marketOpening.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
        {
          id: 'admin-panel_access-by-customer',
          icon: '',
          label: 'Usuários',
          link: PathRoutes.backoffice.accessByCustomer.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
        {
          id: 'admin-panel_customer',
          icon: '',
          label: 'Clientes',
          link: PathRoutes.backoffice.customer.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
        {
          id: 'admin-panel_access-management',
          icon: '',
          label: 'Usuários',
          link: PathRoutes.backoffice.accessManagement.url,
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
        },
      ],
    },
    {
      id: 'notifications-monitoring-panel',
      icon: 'FaNewspaper',
      label: 'Painel de Notificações e Monitoramento',
      link: '',
      allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
      items: [
        {
          id: 'notifications-monitoring-panel_tools',
          icon: '',
          label: 'Ferramentas',
          link: '',
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
          items: [
            {
              id: 'notifications-monitoring-panel_tools_economic-calendar',
              icon: '',
              label: 'Calendário Econômico',
              link: PathRoutes.backoffice.economicCalendar.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_tools_currency-converter',
              icon: '',
              label: 'Conversor de Moedas',
              link: PathRoutes.backoffice.currencyConverter.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_tools_',
              icon: '',
              label: 'Calculadora de Fibonacci',
              link: PathRoutes.backoffice.fibonacciCalculator.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_tools_pivot-point',
              icon: '',
              label: 'Calculadora de Ponto de Pivô',
              link: PathRoutes.backoffice.pivotPointCalculator.url,
              allowed: true,
            },
            // {
            //   id: 'notifications-monitoring-panel_tools_heat-map',
            //   icon: '',
            //   label: 'Mapa de calor de moedas',
            //   link: PathRoutes.backoffice.currencyHeatmap.url,
            //   allowed: true,
            // },
            // {
            //   id: 'notifications-monitoring-panel_tools_volatitlity',
            //   icon: '',
            //   label: 'Volatilidade de moedas',
            //   link: PathRoutes.backoffice.currencyVolatility.url,
            //   allowed: true,
            // },
          ],
        },
        {
          id: 'notifications-monitoring-panel_market-summary-analysis',
          icon: '',
          label: 'Análise de Resumo do Mercado',
          link: '',
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
          items: [
            {
              id: 'notifications-monitoring-panel_market-summary-analysis_technique',
              icon: '',
              label: 'Análise Técnica',
              link: PathRoutes.backoffice.marketResumeTechnicalAnalysis.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_market-summary-analysis_fundamentalist',
              icon: '',
              label: 'Analise Fundamentalista',
              link: PathRoutes.backoffice.marketResumeFundamentalistAnalysis
                .url,
              allowed: true,
            },
          ],
        },
        {
          id: 'notifications-monitoring-panel_currency-analysis',
          icon: '',
          label: 'Análise de Moedas',
          link: '',
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
          items: [
            {
              id: 'notifications-monitoring-panel_currency-analysis_technique',
              icon: '',
              label: 'Análise Técnica',
              link: PathRoutes.backoffice.currencyTechnicalAnalysis.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_currency-analysis_fundamentalist',
              icon: '',
              label: 'Analise Fundamentalista',
              link: PathRoutes.backoffice.currencyFundamentalistAnalysis.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_currency-analysis_signals',
              icon: '',
              label: 'Sinais',
              link: PathRoutes.backoffice.currencySignals.url,
              allowed: true,
            },
          ],
        },
        {
          id: 'notifications-monitoring-panel_commodities-analysis',
          icon: '',
          label: 'Análise de Commodities',
          link: '',
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
          items: [
            {
              id: 'notifications-monitoring-panel_commodities-analysis_technique',
              icon: '',
              label: 'Análise Técnica',
              link: PathRoutes.backoffice.commoditiesTechnicalAnalysis.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_commodities-analysis_fundamentalist',
              icon: '',
              label: 'Analise Fundamentalista',
              link: PathRoutes.backoffice.commoditiesFundamentalistAnalysis.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_commodities-analysis_strategy',
              icon: '',
              label: 'Estratégia',
              link: PathRoutes.backoffice.commoditiesStrategy.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_commodities-analysis_metals',
              icon: '',
              label: 'Análise de Metais',
              link: PathRoutes.backoffice.commoditiesMetalsAnalysis.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_commodities-analysis_energy',
              icon: '',
              label: 'Análise de Energia',
              link: PathRoutes.backoffice.commoditiesEnergyAnalysis.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_commodities-analysis_agriculture',
              icon: '',
              label: 'Análise de Agricultura',
              link: PathRoutes.backoffice.commoditiesAgricultureAnalysis.url,
              allowed: true,
            },
          ],
        },
        {
          id: 'notifications-monitoring-panel_news',
          icon: '',
          label: 'Notícias',
          link: '',
          allowed: userProfile?.parentCustomer?.negotiationPanel === '1',
          items: [
            {
              id: 'notifications-monitoring-panel_news_currencies-exchange',
              icon: '',
              label: 'Moedas e Câmbio',
              link: PathRoutes.backoffice.newsCurrencyAndExchange.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_news_federal-republic',
              icon: '',
              label: 'Politica',
              link: PathRoutes.backoffice.newsPolitics.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_news_commodities',
              icon: '',
              label: 'Commodities',
              link: PathRoutes.backoffice.newsCommodities.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_news_stock-marketing',
              icon: '',
              label: 'Mercado de Ações',
              link: PathRoutes.backoffice.newsStockMarket.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_news_economic-indicators',
              icon: '',
              label: 'Indicadores Econômicos',
              link: PathRoutes.backoffice.newsEconomicIndicators.url,
              allowed: true,
            },
            {
              id: 'notifications-monitoring-panel_news_economy',
              icon: '',
              label: 'Econômia',
              link: PathRoutes.backoffice.newsEconomy.url,
              allowed: true,
            },
          ],
        },
      ],
    },
  ]

  return setup
}

export type { INewMenuByProfile }
