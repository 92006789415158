import { Fragment } from 'react'
import dayjs from 'dayjs'

import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import { generateKey } from '@resources/utils/generateKey'
import Loading from '@components/Loading'
import * as SC from './styles'
import { useOperationalMatching } from './useOperationalMatching'

const OperationalMactching = () => {
  const { isLoading, data, formatDolar } = useOperationalMatching()

  const getColorBg = (value: number) => {
    return value % 2 == 1 ? `${theme.palette.neutral.dark}50` : 'transparent'
  }

  if (!data?.length) {
    return (
      <SC.Container>
        <SC.WrapperHeader>
          <PageTitle>Operações Casadas</PageTitle>
        </SC.WrapperHeader>

        <Separator verticalSize={200} />

        <Typography
          fontType={FontType.bold}
          color={theme.palette.neutral.regular}
          size={16}
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            Nenhum(a) operação casada em aberto
          </div>
        </Typography>
        <Separator verticalSize={300} />
      </SC.Container>
    )
  }

  if (isLoading) {
    return (
      <SC.Container>
        <SC.WrapperHeader>
          <PageTitle>Operações Casadas</PageTitle>
        </SC.WrapperHeader>

        <Separator verticalSize={200} />

        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>

        <Separator verticalSize={300} />
      </SC.Container>
    )
  }

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>Operações Casadas</PageTitle>

        {/* <Button onClick={() => ''}>
          {t('operationalManagementReport.buttonExport')}
        </Button> */}
        {/* <SC.ButtonExport {...csvReport}>
          {t('performanceReport.buttonExport')}
        </SC.ButtonExport> */}
      </SC.WrapperHeader>

      <SC.SectionMain>
        <SC.WrapperTable>
          <table
            border={0}
            style={{
              width: '100%',
              textAlign: 'right',
            }}
          >
            <Fragment key={generateKey()}>
              <thead>
                <tr style={{ backgroundColor: theme.palette.neutral.dark }}>
                  <td style={{ textAlign: 'center', height: 40 }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      SYMBOL
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center', height: 40 }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      Total de Compra
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      Total de Venda
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      Saldo da Operação
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      Data de Registro
                    </Typography>
                  </td>
                </tr>
              </thead>

              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={item.id}
                    style={{
                      height: 30,
                    }}
                  >
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(index),
                      }}
                    >
                      <Typography
                        fontType={FontType.bold}
                        color={theme.palette.primary.light}
                        size={14}
                      >
                        &nbsp;{item.symbol}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(index),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{formatDolar(Number(item.totalBuy))}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        background: getColorBg(index),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{formatDolar(Number(item.totalSell))}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        background: getColorBg(index),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{formatDolar(Number(item.balance))}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(index),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;
                        {dayjs(item.createdAt).format('DD/MM/YYYY - HH[h]ss')}
                      </Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Fragment>
          </table>
        </SC.WrapperTable>
      </SC.SectionMain>
    </SC.Container>
  )
}

export default OperationalMactching
