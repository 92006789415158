import { IViewProps, typeIcons } from './types'
import { Wrapper } from './styles'

const Icon = ({
  element,
  dimensions,
  type,
  onCallback,
  alt,
  title,
  id,
}: IViewProps) => {
  const IconRender = element

  return (
    element && (
      <Wrapper onClick={onCallback ? onCallback : undefined}>
        {type === typeIcons.RA ? (
          <>{element}</>
        ) : (
          <IconRender
            size={dimensions.width}
            width={dimensions.width}
            height={dimensions.height}
            alt={alt}
            title={title}
            id={id}
          />
        )}
      </Wrapper>
    )
  )
}

export default Icon
