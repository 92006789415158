import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { Link as BaseLink } from 'react-router-dom'

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
`

export const WrapperForm = styled.div<DefaultProps>`
  width: 550px;
  margin: 50px 0 0 0;
  padding: 50px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.primary.dark};
`

export const WrapperFormContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  background: green;
  padding: 0;
  margin: 0;
  border-radius: 0 10px 10px 0;
`
export const LinkMenu = styled(BaseLink)<DefaultProps>`
  text-align: left;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.secondary.regular};
  font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  font-size: 14px;
  font-style: normal;

  cursor: pointer;
`

export const WrapperDesc = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
`
