import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import { TablesService } from '@services/tables'

import {
  IAddTableRequest,
  ITable,
  IDeleteTableRequest,
  IaddOperationBytableRequest,
  ITableDetail,
  ITableOperationByOperator,
} from '@resources/types/services/tables'
import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'

export const useGetTableList = (enable = ''): UseQueryResult<ITable[]> => {
  return useQuery(
    ['getTableList', enable],
    () => {
      return TablesService.getTableList()
    },
    {
      enabled: !!enable,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useGetTableListByCustomer = (
  customerId: string,
): UseQueryResult<ITable[]> => {
  return useQuery(
    ['useGetTableListByCustomer', customerId],
    () => {
      return TablesService.getTableListByCustomer(customerId)
    },
    {
      enabled: !!customerId,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useGetTableDetail = (
  uuid: string,
): UseQueryResult<ITableDetail> => {
  return useQuery(
    ['useGetTableDetail', uuid],
    () => {
      return TablesService.getTableDetail(uuid)
    },
    {
      enabled: !!uuid,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useAddTable = (
  options?: IMutationOptionsRequest,
): UseMutationResult<any, any, IAddTableRequest, unknown> => {
  return useMutation(TablesService.addTable, options)
}

export const useDeleteTable = (
  options?: IMutationOptionsRequest,
): UseMutationResult<any, any, IDeleteTableRequest, unknown> => {
  return useMutation(TablesService.deleteTable, options)
}

export const useSetupTableOperator = (
  options?: IMutationOptionsRequest,
): UseMutationResult<any, any, IaddOperationBytableRequest, unknown> => {
  return useMutation(TablesService.addOperationBytable, options)
}

export const useGetTableByOperator = (
  uuid: string,
): UseQueryResult<ITableOperationByOperator[]> => {
  return useQuery(
    ['useGetTableByOperator', uuid],
    () => {
      return TablesService.getTableByOperator(uuid)
    },
    {
      enabled: !!uuid,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  )
}

export const useRemoveOperatorFromTable = (
  options?: IMutationOptionsRequest,
): UseMutationResult<any, any, string, unknown> => {
  return useMutation(TablesService.removeOperatorFromTable, options)
}
