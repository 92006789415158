import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { Link as BaseLink } from 'react-router-dom'

export const LinkOption = styled(BaseLink)<DefaultProps>`
  text-align: left;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: 700;
  font-size: 13px;
  font-style: normal;

  cursor: pointer;
`

export const Container = styled.footer<DefaultProps>`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 30px;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
  background: ${({ theme }) => theme.palette.primary.darkest};
  z-index: 99;
`

export const WrapperEmpty = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;

  flex: 0.33;
`

export const WrapperEmail = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;

  flex: 0.33;
`

export const WrapperPrivacy = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-end;

  flex: 0.33;
`
