/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Loading from '@components/Loading'
import { generateKey } from '@resources/utils/generateKey'
import { ISignal } from '@resources/types/services/signal'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'

import CardDetail from './CardDetail'
import SelectSignalToView from './SelectSignalToView'
import SignalCard from './SignalCard'
import { useStrategiesMonitor } from './useStrategiesMonitor'
import * as SC from './styles'

const StrategiesMonitor = () => {
  const {
    data,
    isLoading,
    handleSelectSignal,
    dataOptions,
    dataOptionsSelected,
    handleSignalMarking,
    showDetailModal,
    handleDetailModal,
    selectedSignal,
  } = useStrategiesMonitor()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('strategiesMonitor.title')}</PageTitle>

        <SC.WrapperFiedlOptions>
          <SelectSignalToView
            onChange={handleSelectSignal}
            selected={dataOptionsSelected}
            options={dataOptions}
          />
        </SC.WrapperFiedlOptions>
      </SC.WrapperHeader>

      <SC.WrapperTradables>
        {isLoading && <Loading />}

        {!isLoading && data?.length && (
          <>
            {data.map(
              (item) =>
                handleSignalMarking(item.uuid) && (
                  <SignalCard
                    key={generateKey()}
                    name={item.strategy.name}
                    symbol={item.symbol}
                    marketPosition={item.marketPosition}
                    orderPrice={item.orderPrice}
                    targetPrice={item.targetPrice}
                    stopPrice={item.stopPrice}
                    handleDetailModal={() => handleDetailModal(item.uuid)}
                  />
                ),
            )}
          </>
        )}
      </SC.WrapperTradables>

      <SC.WrapperConclusino>
        <Typography
          fontType={FontType.regular}
          color={theme.palette.neutral.regular}
          size={12}
        >
          Cada estratégia apresentada não apenas captura sua atenção com
          gatilhos eficazes, mas também oferece uma abordagem única e detalhada
          para transformar informações em decisões lucrativas. Estamos aqui para
          guiá-lo através do mundo complexo da gestão de tesouraria
          internacional que nesse sistema gera receita, garantindo que suas
          negociações sejam informadas, estratégicas e rentáveis. Juntos, vamos
          alcançar o sucesso financeiro.
        </Typography>
      </SC.WrapperConclusino>

      {showDetailModal && selectedSignal && (
        <CardDetail
          display={showDetailModal}
          closeModal={() => handleDetailModal('')}
          data={selectedSignal as ISignal}
        />
      )}
    </SC.Container>
  )
}

export default StrategiesMonitor
