import Button from '@components/UI/Button'
import Modal from '@components/Modal'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'

import { IProps } from './types'
import * as SC from './styles'

const ModalConfirmDelete = ({ showModal, onReturnYes, onReturnNo }: IProps) => {
  return (
    <Modal display={showModal} width={500} height={300}>
      <SC.WrapperContent>
        <SC.Container>
          <Typography
            fontType={FontType.bold}
            color={theme.palette.primary.lightest}
            size={22}
          >
            Tem certeza que deseja deletar?
          </Typography>

          <Separator verticalSize={80} />

          <SC.WrapperFormContent>
            <Button width={200} height={38} secondary onClick={onReturnNo}>
              <Typography
                fontType={FontType.bold}
                color={theme.palette.neutral.dark}
                size={16}
              >
                NÃO
              </Typography>
            </Button>

            <Button width={200} height={38} onClick={onReturnYes}>
              <Typography
                fontType={FontType.bold}
                color={theme.palette.primary.darkest}
                size={16}
              >
                SIM
              </Typography>
            </Button>
          </SC.WrapperFormContent>
        </SC.Container>
      </SC.WrapperContent>
    </Modal>
  )
}

export default ModalConfirmDelete
