import { IPalette } from './types'

export const palette: IPalette = {
  primary: {
    darkest: '#050B19',
    dark: '#191C27',
    regular: '#20242F',
    light: '#F5F6F8',
    lightest: '#FFFFFF',
  },
  secondary: {
    darkest: '#1E2530',
    dark: '#A0D393',
    regular: '#5859AB',
    light: '#D5D0D1',
    lightest: '',
  },
  neutral: {
    darkest: '#001B20',
    dark: '#363B4C',
    regular: '#898D93',
    light: '',
    lightest: '',
  },
  error: {
    darkest: '#811E18',
    dark: '#B71E15',
    regular: '#FC4466',
    light: '#FF7A7A',
    lightest: '#F4A8A4',
  },
  success: {
    darkest: '#4B838A',
    dark: '#00E7B7',
    regular: '#A0D393',
    light: '#7DCAB4',
    lightest: '#A1D8C9',
  },
  warning: {
    darkest: '#F8B603',
    dark: '#F1960E',
    regular: '#F4AC40',
    light: '#F7C06E',
    lightest: '#FAD59E',
  },
  info: {
    darkest: '#304F66',
    dark: '#497FA7',
    regular: '#709AB9',
    light: '',
    lightest: '#B9CCD5',
  },
  gradiente: {
    primary: {
      darkest: '',
      dark: '',
      light: '',
    },
    secondary: {
      darkest: '',
      dark: '',
      light: '',
    },
    tertiary: {
      darkest: '',
      dark: '',
      light: '',
    },
  },
}
