import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'

import Loading from '@components/Loading'
import Button from '@components/UI/Button'
import DataGrid from '@components/UI/DataGrid'

import * as SC from './styles'
import { useTables } from './useTables'
import CreateTableContainer from './CreateTable'
import ModalConfirmDelete from '@components/ModalConfirmDelete'
import SetupOperator from './SetupOperator'

const Tables = () => {
  const {
    isLoading,
    header,
    handleData,
    handleCreateModal,
    showCreateModal,
    handleDeleteModal,
    showDeleteModal,
    fetchDeleteModal,
    showOperatorModal,
    handleViewOperators,
    tableId,
    enableButton,
  } = useTables()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('tables.title')}</PageTitle>

        {enableButton && (
          <Button onClick={handleCreateModal}>{t('tables.buttonAdd')}</Button>
        )}
      </SC.WrapperHeader>

      <Separator verticalSize={8} />

      {isLoading && <Loading />}

      <DataGrid header={header} data={handleData()} />

      {showCreateModal && (
        <CreateTableContainer
          showModal={showCreateModal}
          handleModal={handleCreateModal}
        />
      )}

      {showDeleteModal && (
        <ModalConfirmDelete
          showModal={showDeleteModal}
          onCloseModal={handleDeleteModal}
          onReturnYes={fetchDeleteModal}
          onReturnNo={handleDeleteModal}
        />
      )}

      {showOperatorModal && (
        <SetupOperator
          showModal={showOperatorModal}
          handleModal={handleViewOperators}
          tableId={tableId}
        />
      )}
    </SC.Container>
  )
}

export default Tables
