import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'setupOperator', {
    title: 'Operators of the table',
    add: 'Incluir',
    buttonAddOperator: 'Confirm',
  })
}
