import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/constants/devices'

export const Content = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 60px);
  margin: 0 0 200px 0;

  @media (${mediaQuerydevices.mobile}) {
    width: 100%;
  }
`

export const WrapperPerson = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (${mediaQuerydevices.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const Person = styled.div`
  width: 380px;
  margin: 0 20px 60px 0;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  @media (${mediaQuerydevices.mobile}) {
    margin: 0 20px 30px 0;

    &:nth-of-type(3n) {
      margin-right: 20px;
    }
  }
`

export const PersonHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 20px 0;
`

export const Image = styled.div<DefaultProps>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.light};
  margin: 0 10px 0 0;
`

export const PersonData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100px;
  align-items: flex-start;
  justify-content: center;
`
