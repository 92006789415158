import { useQuery } from 'react-query'

import { PivotPointService } from '@services/pivotPoint'
import { IPivotPointWhitTimes } from '@resources/types/services/pivotPoint'

export const usePivotPointGetWithTimes = (symbols: string[]) => {
  return useQuery<Array<IPivotPointWhitTimes>, Error>(
    ['pivotPointGetWithTimes', symbols],
    () => {
      return PivotPointService.getWithTimes(symbols)
    },
    {
      enabled: !!symbols,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
      refetchInterval: 0,
    },
  )
}
