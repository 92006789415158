import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div``

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const WrapperTradables = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
`

export const WrapperFiedlOptions = styled.div<DefaultProps>`
  width: 300px;
  height: 40px;
`

export const WrapperConclusino = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  width: calc(100% - 70px);
  padding: 20px;
  margin: 30px 15px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.primary.dark};
`
