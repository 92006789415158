import React, { PropsWithChildren, ReactElement } from 'react'
import { Global, ThemeProvider, css } from '@emotion/react'

import { theme } from '@resources/theme'

export const DesignSystemProvider = ({
  children,
}: PropsWithChildren<unknown>): ReactElement => (
  <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>
)

export const StyleGlobal = (): ReactElement => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
      }

      body {
        background: white;
        font-family: Open-Sans, Helvetica, Sans-Serif;
      }
    `}
  />
)
