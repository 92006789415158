import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import { MarketOpeningService } from '@services/marketOpening'

import {
  IMarketOpening,
  IRequestCreateMarketOpening,
  IRequestUpdateMarketOpening,
} from '@resources/types/services/marketOpening'
import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'

export const useCreateMarketOpeningByCustomer = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  IMarketOpening,
  any,
  IRequestCreateMarketOpening,
  unknown
> => {
  return useMutation(MarketOpeningService.crateByCustomer, options)
}

export const useGetMarketOpeningByCustomer = (
  customerId = '',
): UseQueryResult<IMarketOpening> => {
  return useQuery(
    ['useGetMarketOpeningByCustomer', customerId],
    () => {
      return MarketOpeningService.getByCustomer(customerId)
    },
    {
      enabled: !!customerId,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useUpdateMarketOpeningById = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  IMarketOpening,
  any,
  IRequestUpdateMarketOpening,
  unknown
> => {
  return useMutation(MarketOpeningService.updateById, options)
}
