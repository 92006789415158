import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import {
  IOperationMatchingCreateRequest,
  IOperationMatchingCreateResponse,
  IOperationMatchingResponse,
} from '@resources/types/services/operationMatching'

export const OperationMatchingService = {
  create: async (
    data: IOperationMatchingCreateRequest,
  ): Promise<IOperationMatchingCreateResponse> => {
    const api = getApiInstance()

    console.log('OperationMatchingService->create', data)

    const response: AxiosResponse = await api.post(
      endpoints.URL.operationMatching.create,
      data,
    )

    console.log('OperationMatchingService->create', response)

    return response.data
  },
  getOpenedByCustomer: async (
    customerId: string,
  ): Promise<IOperationMatchingResponse[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(
      endpoints.URL.operationMatching.getOpenedByCustomer,
      {
        customerId,
      },
    )

    const response: AxiosResponse = await api.get(url)

    return response.data
  },
}
