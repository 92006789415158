import { Translation } from './translation'
import { TranslationsManager } from './managers'

import { registerCommonTranslations } from '@resources/translation/common/register-translations'
import { registerSignInTranslations } from '@pages/Backoffice/SignIn/translations/register-translations'
import { registerForgetTranslations } from '@pages/Backoffice/ForgotPassword/translations/register-translations'
import { registerNewPasswordTranslations } from '@pages/Backoffice/NewPassword/translations/register-translations'
import { registerCreatePasswordTranslations } from '@pages/Backoffice/CreatePassword/translations/register-translations'
import { registerSignUpAccessTranslations } from '@pages/Backoffice/SignUpAccess/translations/register-translations'
import { registerUpdateAccessTranslations } from '@pages/Backoffice/UpdateAccess/translations/register-translations'
import { registerCreateTableTranslations } from '@pages/Backoffice/Tables/CreateTable/translations/register-translations'
import { registerSetupTableOperatorTranslations } from '@pages/Backoffice/Tables/SetupOperator/translations/register-translations'
import { registerUpdateCustomerTranslations } from '@pages/Backoffice/Customer/UpdateCustomer/translations/register-translations'
import { registerSignUpCustomerTranslations } from '@pages/Backoffice/Customer/SignUpCustomer/translations/register-translations'
import { registerOperationalManagementTranslations } from '@pages/Backoffice/OperationalManagement/translations/register-translations'
import { registerAccessManagementTranslations } from '@pages/Backoffice/AccessManagement/translations/register-translations'
import { registerCustomerTranslations } from '@pages/Backoffice/Customer/translations/register-translations'
import { registerTablesTranslations } from '@pages/Backoffice/Tables/translations/register-translations'
import { registerOperationalManagementReportTranslations } from '@pages/Backoffice/OperationalManagementReport/translations/register-translations'
import { registerStrategiesMonitorTranslations } from '@pages/Backoffice/StrategiesMonitor/translations/register-translations'
import { registerPerfomanceReportTranslations } from '@pages/Backoffice/PerformanceReport/translations/register-translations'
import { registerCoverageRatesTranslations } from '@pages/Backoffice/CoverageRates/translations/register-translations'
import { registerMarketOpeningTranslations } from '@pages/Backoffice/MarketOpening/translations/register-translations'
import { registerTrendingMarketTranslations } from '@pages/Backoffice/TrendingMarket/translations/register-translations'
import { registerTrendlessMarketTranslations } from '@pages/Backoffice/TrendlessMarket/translations/register-translations'
import { registerCurrencyConverterTranslations } from '@pages/Backoffice/CurrencyConverter/translations/register-translations'
import { registerEconomicCalendarTranslations } from '@pages/Backoffice/EconomicCalendar/translations/register-translations'
import { registerFibonacciCalculatorTranslations } from '@pages/Backoffice/FibonacciCalculator/translations/register-translations'
import { registerPivotPointCalculatorTranslations } from '@pages/Backoffice/PivotPointCalculator/translations/register-translations'
import { registerCurrencyTechnicalAnalysisTranslations } from '@pages/Backoffice/CurrencyTechnicalAnalysis/translations/register-translations'
import { registerCommoditiesTechnicalAnalysisTranslations } from '@pages/Backoffice/CommoditiesTechnicalAnalysis/translations/register-translations'
import { registerCurrencyFundamentalistAnalysisTranslations } from '@pages/Backoffice/CurrencyFundamentalistAnalysis/translations/register-translations'
import { registerCurrencySignalsTranslations } from '@pages/Backoffice/CurrencySignals/translations/register-translations'
import { registerMarketResumeTechnicalAnalysisTranslations } from '@pages/Backoffice/MarketResumeTechnicalAnalysis/translations/register-translations'
import { registerMarketResumeFundamentalistAnalysisTranslations } from '@pages/Backoffice/MarketResumeFundamentalistAnalysis/translations/register-translations'
import { registerCommoditiesMetalsAnalysisTranslations } from '@pages/Backoffice/CommoditiesMetalsAnalysis/translations/register-translations'
import { registerCommoditiesStrategyTranslations } from '@pages/Backoffice/CommoditiesStrategy/translations/register-translations'
import { registerCommoditiesFundamentalistAnalysisTranslations } from '@pages/Backoffice/CommoditiesFundamentalistAnalysis/translations/register-translations'
import { registerCommoditiesAgricultureAnalysisTranslations } from '@pages/Backoffice/CommoditiesAgricultureAnalysis/translations/register-translations'
import { registerCommoditiesEnergyAnalysisTranslations } from '@pages/Backoffice/CommoditiesEnergyAnalysis/translations/register-translations'
import { registerNewsCommoditiesTranslations } from '@pages/Backoffice/NewsCommodities/translations/register-translations'
import { registerNewsCurrencyAndExchangeTranslations } from '@pages/Backoffice/NewsCurrencyAndExchange/translations/register-translations'
import { registerNewsEconomyTranslations } from '@pages/Backoffice/NewsEconomy/translations/register-translations'
import { registerNewsStockMarketTranslations } from '@pages/Backoffice/NewsStockMarket/translations/register-translations'
import { registerNewsEconomicIndicatorsTranslations } from '@pages/Backoffice/NewsEconomicIndicators/translations/register-translations'
import { registerNewsPoliticsTranslations } from '@pages/Backoffice/NewsPolitics/translations/register-translations'

export const registerTranslations = (): void => {
  registerCommonTranslations(TranslationsManager)
  registerSignInTranslations(TranslationsManager)
  registerForgetTranslations(TranslationsManager)
  registerNewPasswordTranslations(TranslationsManager)
  registerCreatePasswordTranslations(TranslationsManager)
  registerSignUpAccessTranslations(TranslationsManager)
  registerUpdateAccessTranslations(TranslationsManager)
  registerSetupTableOperatorTranslations(TranslationsManager)
  registerCreateTableTranslations(TranslationsManager)
  registerUpdateCustomerTranslations(TranslationsManager)
  registerSignUpCustomerTranslations(TranslationsManager)
  registerOperationalManagementTranslations(TranslationsManager)
  registerAccessManagementTranslations(TranslationsManager)
  registerCustomerTranslations(TranslationsManager)
  registerTablesTranslations(TranslationsManager)
  registerOperationalManagementReportTranslations(TranslationsManager)
  registerStrategiesMonitorTranslations(TranslationsManager)
  registerPerfomanceReportTranslations(TranslationsManager)
  registerCoverageRatesTranslations(TranslationsManager)
  registerMarketOpeningTranslations(TranslationsManager)
  registerTrendingMarketTranslations(TranslationsManager)
  registerTrendlessMarketTranslations(TranslationsManager)
  registerEconomicCalendarTranslations(TranslationsManager)
  registerCurrencyConverterTranslations(TranslationsManager)
  registerFibonacciCalculatorTranslations(TranslationsManager)
  registerPivotPointCalculatorTranslations(TranslationsManager)

  /* CURRENCY TRANSLATIONS */
  registerCurrencyTechnicalAnalysisTranslations(TranslationsManager)
  registerCurrencyFundamentalistAnalysisTranslations(TranslationsManager)
  registerCurrencySignalsTranslations(TranslationsManager)

  /* MARKET TRANSLATIONS */
  registerMarketResumeFundamentalistAnalysisTranslations(TranslationsManager)
  registerMarketResumeTechnicalAnalysisTranslations(TranslationsManager)

  /* COMMODITIES TRANSLATIONS */
  registerCommoditiesTechnicalAnalysisTranslations(TranslationsManager)
  registerCommoditiesFundamentalistAnalysisTranslations(TranslationsManager)
  registerCommoditiesStrategyTranslations(TranslationsManager)
  registerCommoditiesMetalsAnalysisTranslations(TranslationsManager)
  registerCommoditiesEnergyAnalysisTranslations(TranslationsManager)
  registerCommoditiesAgricultureAnalysisTranslations(TranslationsManager)

  /* NEWS TRANSLATIONS */
  registerNewsCommoditiesTranslations(TranslationsManager)
  registerNewsCurrencyAndExchangeTranslations(TranslationsManager)
  registerNewsEconomicIndicatorsTranslations(TranslationsManager)
  registerNewsEconomyTranslations(TranslationsManager)
  registerNewsPoliticsTranslations(TranslationsManager)
  registerNewsStockMarketTranslations(TranslationsManager)

  Translation.initialize(TranslationsManager)
}
