import Icon from '@components/UI/Icon'

import * as SC from './styles'

const Logo = () => {
  return (
    <SC.WrapperIcon>
      <Icon name="logoDashboard" />
    </SC.WrapperIcon>
  )
}

export default Logo
