import { create } from 'zustand'

import { ICustomerState } from './types'

export const useCustomerStore = create<ICustomerState>()((set) => ({
  customer: {
    uuid: '',
    email: '',
    name: '',
    tradingName: '',
    corporateName: '',
    personType: '',
    document: '',
    cellphone: '',
    phone: '',
    city: '',
    state: '',
    zipCode: '',
    address: '',
  },
  actions: {
    setCustomer: (customer) =>
      set(() => ({
        customer,
      })),
  },
}))

// state
export const useCustomerMethods = () =>
  useCustomerStore((state) => state.customer)

// actions
export const useCustomerActions = () =>
  useCustomerStore((state) => state.actions)
