import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'customer', {
    title: 'GESTÃO DE CLIENTES',
    buttonAdd: 'Adicionar',
  })
}
