//TODO: Inserir tipagem
export const initialValues = {
  mondayOpening: '09:00',
  mondayClosing: '17:00',
  tuesdayOpening: '09:00',
  tuesdayClosing: '17:00',
  wednesdayOpening: '09:00',
  wednesdayClosing: '17:00',
  thursdayOpening: '09:00',
  thursdayClosing: '17:00',
  fridayOpening: '09:00',
  fridayClosing: '17:00',
  saturdayOpening: '09:00',
  saturdayClosing: '17:00',
  sundayOpening: '09:00',
  sundayClosing: '17:00',
}
