import Footer from './Footer'
import HeaderContainer from './Header'

import * as SC from './styles'
import { StyleGlobal } from './styleGlobal'

const Panel = ({ children }: any) => {
  return (
    <SC.Container>
      <StyleGlobal />

      <HeaderContainer />

      <SC.WrapperContent>{children}</SC.WrapperContent>

      <Footer />
    </SC.Container>
  )
}

export default Panel
