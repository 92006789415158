/* eslint-disable @typescript-eslint/no-unused-vars */
import Separator from '@components/UI/Separator'
import { theme } from '@resources/theme'
import { currencyFormatterSixPlaces } from '@resources/utils/forCurrency'

import { IViewProps } from './types'
import * as SC from './styles'

const Field = ({
  inputWidth = 100,
  inputHeight = 48,
  widthFull = false,
  maxLength = 50,

  testID = 'InputComponentID',
  type = 'text',
  id = '',
  name = '',
  value,
  inputRef,
  placeholder,
  label = '',
  onChange,
  onBlur,
  focus = false,

  currency = false,
  secondary = false,
  tertiary = false,
  disabled = false,
  decimal = 2,
  mask = '',
}: IViewProps) => {
  const currencyFormatter = (value) => {
    if (!Number(value)) return ''

    const amount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value / 100)

    return `${amount.replace('$', '').trim()}`
  }

  const themes = {
    primary: {
      active: {
        background: theme.palette.primary.light,
        color: theme.palette.neutral.regular,
        placeholder: theme.palette.neutral.regular,
      },
      disabled: {
        background: theme.palette.neutral.regular,
        color: theme.palette.primary.light,
        placeholder: '',
      },
    },
    secondary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
    tertiary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
  }

  const themeActive =
    themes[(secondary && 'secondary') || (mask && 'tertiary') || 'primary']

  const inputs = {
    default: (
      <SC.Input
        ref={inputRef}
        data-testid={testID}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoFocus={focus}
        onBlur={onBlur}
        maxLength={maxLength}
        type={type}
        textColor={
          disabled ? themeActive.disabled.color : themeActive.active.color
        }
        backgroundColor={
          disabled
            ? themeActive.disabled.background
            : themeActive.active.background
        }
        placeholderColor={
          disabled
            ? themeActive.disabled.placeholder
            : themeActive.active.placeholder
        }
        inputWidth={inputWidth}
        widthFull={widthFull}
        inputHeight={inputHeight}
        id={id}
        name={name}
        disabled={disabled}
      />
    ),
    currency: (
      <SC.InputCurrency
        ref={inputRef}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoFocus={focus}
        onBlur={onBlur}
        decimalScale={decimal}
        decimalSeparator="."
        thousandSeparator=","
        format={decimal === 2 ? currencyFormatter : currencyFormatterSixPlaces}
        allowNegative={false}
        textColor={themeActive.active.color}
        backgroundColor={themeActive.active.background}
        placeholderColor={themeActive.active.placeholder}
        inputWidth={inputWidth}
        widthFull={widthFull}
        inputHeight={inputHeight}
        id={id}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
      />
    ),
    mask: (
      <SC.MaskInput
        mask={mask}
        ref={inputRef}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoFocus={focus}
        onBlur={onBlur}
        textColor={themeActive.active.color}
        backgroundColor={themeActive.active.background}
        placeholderColor={themeActive.active.placeholder}
        inputWidth={inputWidth}
        widthFull={widthFull}
        inputHeight={inputHeight}
        id={id}
        name={name}
        disabled={disabled}
      />
    ),
  }

  const inputActive =
    inputs[(currency && 'currency') || (mask && 'mask') || 'default']

  return (
    <SC.Container>
      {!!label && (
        <>
          {label}
          <Separator verticalSize={3} />
        </>
      )}

      <SC.Content>{inputActive}</SC.Content>
    </SC.Container>
  )
}

export default Field
