import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/types/devices'

export const WrapperContent = styled.div<DefaultProps>`
  padding: 0 40px;
  width: calc(100% - 80px);
  height: 700px;
`

export const WrapperForm = styled.div<DefaultProps>`
  width: calc(100% - 20px);
  padding: 0;
  overflow-y: scroll;
  padding: 0 10px;
  height: 600px;
`

export const WrapperFormContent = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;

  @media (${mediaQuerydevices.tablet}) {
    flex-direction: column;
  }
`

export const WrapperField = styled.div`
  width: 384px;
`
