import dayjs from 'dayjs'

export const useCsvExport = (data) => {
  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'executionDate', key: 'executionDate' },
    { label: 'termsSplitValueDate', key: 'termsSplitValueDate' },
    { label: 'executionTime', key: 'executionTime' },
    { label: 'gidId', key: 'gidId' },
    { label: 'dealtCcy', key: 'dealtCcy' },
    { label: 'baseDirection', key: 'baseDirection' },
    { label: 'termsSplitValdealtAmountueDate', key: 'dealtAmount' },
    { label: 'allInRate', key: 'allInRate' },
    { label: 'privateNote', key: 'privateNote' },
    { label: 'tenor', key: 'tenor' },
    { label: 'baseSplitValueDate', key: 'baseSplitValueDate' },
    { label: 'contraAmount', key: 'contraAmount' },
    { label: 'createdAt', key: 'createdAt' },
    { label: 'customer', key: 'customer' },
    { label: 'exchangeTable', key: 'exchangeTable' },
    { label: 'proxy', key: 'proxy' },
    { label: 'operationCustomer', key: 'operationCustomer' },
  ]

  const preparedDate = data.map((item) => ({
    id: item.id,
    executionDate: item.executionDate,
    termsSplitValueDate: item.termsSplitValueDate,
    executionTime: item.executionTime,
    gidId: item.gidId,
    dealtCcy: item.dealtCcy,
    baseDirection: item.baseDirection,
    dealtAmount: item.dealtAmount,
    allInRate: item.allInRate,
    privateNote: item.privateNote,
    tenor: item.tenor,
    baseSplitValueDate: item.baseSplitValueDate,
    contraAmount: item.contraAmount,
    createdAt: item.createdAt,
    customer: item.customer.name || item.customer.corporateName,
    exchangeTable: item.exchangeTable.name,
    proxy: item.proxy.name,
    operationCustomer: item.operationCustomer.name,
  }))

  const csvReport = {
    data: preparedDate,
    headers: headers,
    filename: `report_operation_${dayjs(new Date()).format(
      'YYYY-MM-DDTHH:mm:ss',
    )}.csv`,
  }

  return { csvReport }
}
