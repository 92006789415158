import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'marketOpening', {
    title: 'Market Opening',
    buttonExport: 'Export',
  })
}
