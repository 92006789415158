import { useFormik } from 'formik'
import { useAlert } from 'react-alert'

import { useAuthMethods } from '@store/auth'
import {
  useGetMarketOpeningByCustomer,
  useUpdateMarketOpeningById,
  useCreateMarketOpeningByCustomer,
} from '@hooks/marketOpening/useMarketOpening'

import { schemaValidation, initialValues } from './form'

import 'dayjs/locale/pt-br'
import { useEffect, useState } from 'react'

export const useMarketOpening = () => {
  const alert = useAlert()
  const { profile } = useAuthMethods()

  const [updateFormik, setUpdateFormik] = useState(false)

  const { isLoading, data, refetch, isRefetching } =
    useGetMarketOpeningByCustomer(profile.parentCustomer.uuid)

  const { isLoading: mutateCreateIsLoading, mutate: mutateCreate } =
    useCreateMarketOpeningByCustomer()
  const { isLoading: mutateIsLoading, mutate } = useUpdateMarketOpeningById()

  const formik = useFormik({
    initialValues,
    validationSchema: schemaValidation,
    onSubmit: (values) => {
      console.log(values)
    },
  })

  const onSuccessUpdate = (): void => {
    setUpdateFormik(false)
    refetch()
    alert.success('Abertura/Fechamento de mercado atualizada com sucesso!')
  }

  const onErrorUpdate = (): void => {
    alert.error('Houve uma erro ao atualizar Abertura/Fechamento.')
  }

  const handleUpdate = () => {
    if (!data) return
    mutate(
      { uuid: data[0].uuid, data: formik.values as any },
      {
        onSuccess: onSuccessUpdate,
        onError: onErrorUpdate,
      },
    )
  }

  const onSuccessCrate = (): void => {
    refetch()
    alert.success('Abertura de mercado criado com sucesso!')
  }

  const onErrorCreatee = (): void => {
    alert.error('Houve uma erro ao criar taxa.')
  }

  const createMarketOpening = () => {
    if (mutateCreateIsLoading) return

    const data = { customer: profile.parentCustomer.uuid, ...formik.values }

    mutateCreate(data as any, {
      onSuccess: onSuccessCrate,
      onError: onErrorCreatee,
    })
  }

  useEffect(() => {
    if (!data || !data?.[0] || updateFormik) return

    formik.setValues({ ...data[0] })
    setUpdateFormik(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    isLoading:
      isRefetching || isLoading || mutateIsLoading || mutateCreateIsLoading,
    mutateCreateIsLoading,
    data,
    formik,
    handleUpdate,
    createMarketOpening,
  }
}
