import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'performanceReport', {
    title: 'Performance Report',
    buttonExport: 'Export',
  })
}
