import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'trendlessMarket', {
    title: 'MERCADO LATERAL',
    subtitle: 'SEM TENDENCIA',
    tabs: {
      tab1: { label: 'TRESTLES', key: 'trestles' },
      tab2: { label: 'BELLS', key: 'bells' },
      tab3: { label: 'PENICHE', key: 'peniche' },
    },
    signalCard: {
      goal: 'OBJETIVO',
      prohibited: 'ENTRADA',
      escape: 'FUGA',
    },
  })
}
