import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import {
  IOperationCustomerResponse,
  IOperationCustomerAddRequest,
} from '@resources/types/services/operationCustomer'

export const OperationCustomerService = {
  getByDocument: async (
    document: string,
    customerId: string,
  ): Promise<IOperationCustomerResponse> => {
    const api = getApiInstance()

    const url = translateUrlParam(
      endpoints.URL.customerOperation.getByDocument,
      {
        document,
        customerId,
      },
    )

    const response: AxiosResponse = await api.get(url)

    return response.data
  },

  getByName: async (
    name: string,
    customerId: string,
  ): Promise<IOperationCustomerResponse[]> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.customerOperation.getByName, {
      name,
      customerId,
    })

    const response: AxiosResponse = await api.get(url)

    console.log('getByName', {
      url,
      response,
    })

    return response.data
  },

  addOperarionCustomerByCustomer: async (
    operationCustomer: IOperationCustomerAddRequest,
  ): Promise<IOperationCustomerResponse> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.customerOperation.addByCustomer,
      operationCustomer,
    )

    return response.data
  },
}
