// import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import { IPivotPointWhitTimes } from '@resources/types/services/pivotPoint'

export const PivotPointService = {
  getWithTimes: async (
    symbol: string[],
  ): Promise<Array<IPivotPointWhitTimes>> => {
    const api = getApiInstance()

    const urls = symbol.map((item) =>
      translateUrlParam(endpoints.URL.pivotPoint.withTimes, {
        symbol: `${item}/BRL`,
      }),
    )

    const responses = await Promise.all(urls.map((item) => api.get(item)))

    const data = responses?.map((item) => ({
      symbol: item?.data?.[0]?.symbol,
      pivotPoits: item?.data,
    }))

    return data
  },
}
