import styled from '@emotion/styled'

import { FontType } from '@components/UI/Typography'
import { DefaultProps } from '@resources/types'

export const Container = styled.div``

export const WrapperHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
`

export const WrapperFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const WrapperLink = styled.div`
  width: 100%;
  text-align: center;
  padding: 150px 0;
`

export const Link = styled.span<DefaultProps>`
  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  text-align: center;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.info.dark};
  font-style: normal;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: ${({ theme }) => theme.palette.info.dark}80;
  }
`
