import Icon from '@components/UI/Icon'

import { WrapperIcon } from './styles'

const Logo = () => {
  return (
    <WrapperIcon>
      <Icon name="logoWhite" width={140} height={127} />
    </WrapperIcon>
  )
}

export default Logo
