/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import Icon from '@components/UI/Icon'

import Logo from './Logo'
import Menu from './Menu'

import * as SC from './styles'

const Header = () => {
  return (
    <SC.Container>
      <SC.Content>
        <SC.WrapperLogo>
          <Logo />
        </SC.WrapperLogo>

        <SC.WrapperMenu>
          <Menu />
        </SC.WrapperMenu>
      </SC.Content>
    </SC.Container>
  )
}

export default Header
