import { createElement } from 'react'

import { IViewProps } from './types'
import View from './view'

const HeaderContainer = () => {
  const viewProps: IViewProps = {}

  return createElement(View, viewProps)
}

export default HeaderContainer
