import styled from '@emotion/styled'
import InputMask from 'react-input-mask'
import NumberFormat from 'react-number-format'
import CurrencyInput from 'react-currency-input-field'

import { DefaultProps } from '@resources/types'
import { IinputStyle } from './types'
import { FontType } from '@components/UI/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
`
export const Content = styled.div`
  background: transparent;
  width: 100%;
`

export const Input = styled.input<DefaultProps & IinputStyle>`
  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  text-align: center;
  border: ${({ backgroundColor }) => `1px solid ${backgroundColor}`};
  box-sizing: border-box;
  font-size: 14px;
  height: ${({ inputHeight }) => `${inputHeight}px`};
  outline: none;
  padding: 0 8px;
  width: ${({ inputWidth, widthFull }) =>
    widthFull ? '100%' : `${inputWidth}px`};
  color: ${({ textColor }) => textColor};

  :-ms-input-placeholder,
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`

export const InputCurrency = styled(NumberFormat)<DefaultProps & IinputStyle>`
  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  text-align: center;
  border: ${({ backgroundColor }) => `1px solid ${backgroundColor}`};
  box-sizing: border-box;
  font-size: 14px;
  height: ${({ inputHeight }) => `${inputHeight}px`};
  outline: none;
  padding: 0 8px;
  width: ${({ inputWidth, widthFull }) =>
    widthFull ? '100%' : `${inputWidth}px`};
  color: ${({ textColor }) => textColor};

  :-ms-input-placeholder,
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`

export const InputCurrencyField = styled(CurrencyInput)<
  DefaultProps & IinputStyle
>`
  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  text-align: center;
  border: ${({ backgroundColor }) => `1px solid ${backgroundColor}`};
  box-sizing: border-box;
  font-size: 14px;
  height: ${({ inputHeight }) => `${inputHeight}px`};
  outline: none;
  padding: 0 8px;
  width: ${({ inputWidth, widthFull }) =>
    widthFull ? '100%' : `${inputWidth}px`};
  color: ${({ textColor }) => textColor};

  :-ms-input-placeholder,
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`

export const MaskInput = styled(InputMask)<DefaultProps & IinputStyle>`
  ${({ theme }) => theme.mixins.fonts(FontType.medium)};
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  text-align: center;
  border: ${({ backgroundColor }) => `1px solid ${backgroundColor}`};
  box-sizing: border-box;
  font-size: 14px;
  height: ${({ inputHeight }) => `${inputHeight}px`};
  outline: none;
  padding: 0 8px;
  width: ${({ inputWidth, widthFull }) =>
    widthFull ? '100%' : `${inputWidth}px`};
  color: ${({ textColor }) => textColor};

  :-ms-input-placeholder,
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
  }
`
