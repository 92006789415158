import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'

import * as SC from './styles'

const Footer = () => {
  return (
    <SC.Container>
      <SC.WrapperEmail>
        <Typography
          fontType={FontType.bold}
          color={theme.palette.neutral.regular}
          size={13}
        >
          Powered by ExchangeSpot
        </Typography>
      </SC.WrapperEmail>
    </SC.Container>
  )
}

export default Footer
