import styled from '@emotion/styled'

import bannerHome from '@assets/images/bg-site-publicity.png'

import { DefaultProps } from '@resources/types'
import { mediaQuerydevices } from '@resources/constants/devices'

export const Container = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: calc(100vh);
  margin: 0;
  padding: 0;
  background-image: url(${bannerHome});
  background-repeat: repeat;
  background-attachment: scroll, local;
  background-position: center bottom;
  background-size: cover;
  background-color: ${({ theme }) => theme.palette.primary.darkest};
`

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;
  padding: 0;
  margin: 140px 0 0 0;
  flex: 1;
  width: 1024px;

  @media (${mediaQuerydevices.maxDesktop}) {
    width: 1360px;
  }

  @media (${mediaQuerydevices.mobile}) {
    width: calc(100% - 48px);
    margin: 200px 24px 0 24px;
  }
`

export const Content = styled.section<DefaultProps>`
  width: 100%;
  height: calc(100% - 150px);
  margin: 0 0 20px 0;
`
