import React from 'react'

import PathRoutes from '@route/PathRoutes'

import * as SC from './styles'

const NotFound = () => {
  return (
    <SC.Layout>
      <SC.Content>
        <SC.Title>404</SC.Title>
        <SC.Description>
          Não encontramos a página que você procurou :(
        </SC.Description>

        <SC.TextCommon>O que pode ter acontecido?</SC.TextCommon>
        <ul>
          <li>A página pode ter sido removida</li>
          <li>A página pode está temporariamente indisponível</li>
          <li>Você pode ter digitado o endereço incorreto</li>
        </ul>

        <SC.Link to={PathRoutes.backoffice.signIn.url}>
          Quer ir para sistema? Clique aqui!
        </SC.Link>
      </SC.Content>
    </SC.Layout>
  )
}

export default NotFound
