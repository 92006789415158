import { createElement } from 'react'

import View from './view'

const PanelContainer = ({ children, type }: any) => {
  const viewProps = {
    children,
    type,
  }

  return createElement(View, viewProps)
}

export default PanelContainer
