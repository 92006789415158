import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'marketOpening', {
    title: 'ABERTURA DE MERCADO',
    buttonExport: 'Exportar',
    total: 'Total BUY',
    foreigner: 'VLR Estrang',
    averageRate: 'TX Média',
    valueInReais: 'VLR Reais',
    performanceFee: 'TX Perfor',
    performancePercentage: '% Perfor',
    revenue: 'Receita',
  })
}
