/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'

import { SignalToViewOperation } from '../types'
import { OptionType } from './types'

export const useFieldOptionsCustom = ({
  onChange,
  selected,
  wrapperKey,
  options,
}) => {
  const [showListOption, setShowListOption] = useState<boolean | null>(null)

  const handleWrapperListOption = () => {
    setShowListOption(!showListOption)
  }

  const handleChange = (option: OptionType) => {
    const action = selected.includes(option.id)
      ? SignalToViewOperation.remove
      : SignalToViewOperation.add

    onChange(option.id, action)
    // handleWrapperListOption()
  }

  window.addEventListener('click', function (e: any) {
    const input = document.getElementById(`input-list-option-${wrapperKey}`)
    const listOption = document.getElementById(
      `wrapper-list-option-${wrapperKey}`,
    )
    const itemOption = document.getElementsByClassName('item-option')

    const areas = {
      input: input?.contains(e?.target),
      listOption: listOption?.contains(e?.target),
      itemOption: options.some(
        (item) => item.name === e?.target.childNodes[0].data,
      ),
    }

    if (
      [areas.input, areas.listOption, areas.itemOption].some(
        (item) => item === undefined,
      )
    )
      return

    if (!areas.input && !areas.listOption && !areas.itemOption) {
      setShowListOption(false)
    }
  })

  return {
    handleChange,
    showListOption,
    handleWrapperListOption,
  }
}
