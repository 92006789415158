import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import { useAccessSignUp } from '@hooks/accessManagement/useAccessManagement'
import { useAuthMethods } from '@store/auth'
import { ProfileTypes } from '@resources/types/profileTypes'

import { useFormik } from 'formik'
import { signUpSchema } from './yup/signUpSchema'
import {
  IFormikValues,
  IHookProps,
  UserProfileRule,
  UserMasterProfileRule,
} from './types'

export const useSignUpAccess = ({
  handleModal,
  parentId,
  isMaster,
}: IHookProps) => {
  const alert = useAlert()
  const queryClient = useQueryClient()

  const { profile } = useAuthMethods()
  const { mutate, isLoading } = useAccessSignUp()

  const createCommonRoleList = Object.values(UserProfileRule).map((item) => {
    switch (item) {
      case UserProfileRule.PROFILE_OPERATOR:
        return { value: item, label: 'Operador' }

      case UserProfileRule.PROFILE_PAYMASTER:
        return { value: item, label: 'Tesoureiro' }

      default:
        return { value: item, label: 'Administrador' }
    }
  })

  const createMasterRoleList = [
    {
      value: UserMasterProfileRule.PROFILE_MASTER,
      label: UserMasterProfileRule.PROFILE_MASTER,
    },
  ]

  const onSuccess = (): void => {
    queryClient.invalidateQueries(['useAccessManagement', 'allUserByCustomer'])
    alert.success('O usuário irá receber um email para cadastrar nova senha')

    handleModal()
  }

  const onError = (): void => {
    alert.error('Ocorreu um erro inesperado. Tente novamente.')
  }

  const handleAuthenticate = (prop: IFormikValues) => {
    const data = {
      parentCustomer: parentId ? parentId : '',
      profile: prop.profile,
      name: prop.name,
      cpf: prop.cpf,
      cellphone: prop.phone,
      email: prop.email,
      password: '123456789',
    }

    mutate(data, { onSuccess, onError })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      phone: '',
      email: '',
      profile: [ProfileTypes.master].includes(profile.profile as ProfileTypes)
        ? ProfileTypes.master
        : '',
    },
    validationSchema: signUpSchema,
    onSubmit: handleAuthenticate,
  })

  return {
    formik,
    createRoleList: isMaster
      ? createMasterRoleList
      : [{ value: '', label: 'Selecione  perfil' }, ...createCommonRoleList],
    isLoading,
    handleModal,
  }
}
