import React from 'react'

import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Loading from '@components/Loading'

import { IViewProps } from './types'
import { Container, Row, Column } from './styles'

const DataGrid: React.FC<IViewProps> = ({ header, data, loading, width }) => {
  return (
    <Container width={width}>
      <Row>
        {header.map((headerItem, headerIndex) => (
          <Column
            align={headerItem.styles.align}
            grid={headerItem.grid}
            key={`${headerIndex}-${String(headerItem.label)}`}
          >
            {typeof headerItem.label === 'string' ? (
              <Typography
                fontType={FontType.bold}
                size={13}
                color={theme.palette.primary.lightest}
              >
                {headerItem.label}
              </Typography>
            ) : (
              <>{headerItem.label}</>
            )}
          </Column>
        ))}
      </Row>

      {loading ? (
        <>
          <Separator verticalSize={150} />
          <Loading />
          <Separator verticalSize={100} />
        </>
      ) : (
        <>
          {data.map((dataItem, dataIndex) => {
            return (
              <Row key={`${dataIndex}-${String(dataItem)}`} item>
                {header.map((headerItem, headerIndex) => {
                  const element = dataItem.values[headerItem.value]

                  return (
                    <Column
                      align={headerItem.styles.align}
                      grid={headerItem.grid}
                      key={`${headerIndex}-${String(headerItem.label)}`}
                    >
                      <>
                        {headerItem.field === 'values' && (
                          <Typography
                            fontType={headerItem.styles.fontType}
                            size={headerItem.styles.fontSize}
                            color={theme.palette.primary.light}
                          >
                            {element}
                          </Typography>
                        )}
                        {headerItem.field === 'element' &&
                          headerItem.value === 'element' && (
                            <>{dataItem.element}</>
                          )}

                        {/* TODO - Criar uma lista de ícones */}
                        {headerItem.field === 'element' &&
                          headerItem.value === 'icon1' && <>{dataItem.icon1}</>}
                        {headerItem.field === 'element' &&
                          headerItem.value === 'icon2' && <>{dataItem.icon2}</>}
                        {headerItem.field === 'element' &&
                          headerItem.value === 'icon3' && <>{dataItem.icon3}</>}
                      </>
                    </Column>
                  )
                })}
              </Row>
            )
          })}
        </>
      )}
    </Container>
  )
}

export default DataGrid
