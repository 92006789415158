import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { IStyleProps } from './types'

const alignOptions = (align: string): string => {
  const types: any = {
    center: 'center',
    left: 'flex-start',
    right: 'flex-end',
  }

  return types[align] || types.center
}

export const Container = styled.div<{ width: string } & DefaultProps>`
  max-width: 1440px;
  width: ${({ width }) => width};
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
`
export const Row = styled.div<IStyleProps & DefaultProps>`
  display: flex;
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  padding: 5px 0;
  margin: 5px 0;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  ${({ item, theme }) =>
    !item &&
    `
    background: ${theme.palette.neutral.dark};
    border-radius: 6px;
  `}

  ${({ item, theme }) =>
    item &&
    `
		border-bottom: 1px solid ${theme.palette.neutral.regular}50;
    &:hover {
      background: ${theme.palette.neutral.dark}50;
      border-radius: 6px;
    }
  `}
`

export const Column = styled.div<{ grid: number; align: string }>`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.9rem;
  min-height: 1px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 2px;
  justify-content: ${({ align }) => alignOptions(align)};

  @media only screen and (min-width: 768px) {
    width: ${({ grid }) => (grid ? (grid / 12) * 100 : '8:33')}%;
  }

  &:first-of-type {
    margin: 0px 2px 0px 0px;
  }

  &:last-child {
    margin: 0px 0px 0px 2px;
  }
`
