import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const SectionMain = styled.div<DefaultProps>`
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OrganizeHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
`

export const WrapperField = styled.div`
  width: 300px;
`

export const WrapperSearch = styled.div`
  position: absolute;
  margin: -35px 0 0 260px;
  width: 30px;
  cursor: pointer;
`

export const WrapperBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  margin: 15px 0 0 0;
`

export const WrapperTimer = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
  margin: 0 20px 0 0;
  border-radius: 30px;
  background: ${({ theme }) => theme.palette.warning.darkest};
`

export const WrapperButton = styled.div`
  width: 100%;
  height: 60px;
  text-align: right;
`

export const WrapperFooter = styled.div`
  width: 100%;
  height: 20px;
  padding: 5px 0;
  margin: 10px 0 0 0;
  text-align: center;
`
