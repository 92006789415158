import * as Yup from 'yup'

export const customerOperationAddSchema = Yup.object().shape({
  name: Yup.string()
    .test('isValidName', 'Digite seu nome completo', (val) => {
      return String(val).length > 6
    })
    .required('Digite seu nome completo'),

  document: Yup.string()
    .test('isValidCPF', 'Digite um CPF válido', (val) => {
      return String(val).length > 11
    })
    .required('Required'),
})
