import { useState } from 'react'

import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'

import * as SC from './styles'
import { IProps, ITabItem } from './types'

const Tab = ({ data, onCallback }: IProps) => {
  const [tabCurrent, setTabCurrent] = useState<ITabItem>(data[0])

  return (
    <SC.Container>
      <SC.Header>
        {data.map((item) => (
          <SC.Tab
            key={`tab-${item.key}`}
            onClick={() => {
              setTabCurrent(item)
              onCallback(item.key)
            }}
          >
            <Typography
              fontType={FontType.medium}
              color={
                tabCurrent?.key === item.key
                  ? theme.palette.success.dark
                  : theme.palette.neutral.regular
              }
              size={15}
            >
              {item.label}
            </Typography>
          </SC.Tab>
        ))}
      </SC.Header>
    </SC.Container>
  )
}

export default Tab
