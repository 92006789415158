/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'

import { useSendEmailCustomProspecting } from '@hooks/sendEmail/useSendEmail'

import { customProspectingchema } from './yup/customProspecting'
// import { IFormikValues } from './types'

export const useModalCustomProspecting = ({ handleCloseModal }) => {
  const alert = useAlert()

  const { mutate, isLoading, isError } = useSendEmailCustomProspecting()

  const onSuccess = (): void => {
    handleCloseModal()
    alert.success('Contato enviado com sucesso!')
  }

  const onError = (): void => {
    alert.error('Houve uma erro ao enviar o contato, tente novamente.')
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      cellphone: '',
    },
    validationSchema: customProspectingchema,
    onSubmit: (data) => {
      console.log('useModalCustomProspecting->handleSendEmail', { data })

      mutate(data, {
        onSuccess: () => {
          onSuccess()
          formik.setValues({
            name: '',
            email: '',
            cellphone: '',
          })
        },
        onError,
      })
    },
  })

  return { isLoading, formik }
}
