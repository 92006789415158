export enum UserProfileRule {
  PROFILE_OPERATOR = 'operator',
  PROFILE_PAYMASTER = 'paymaster',
  PROFILE_ADMIN = 'admin',
}

export enum UserMasterProfileRule {
  PROFILE_MASTER = 'master',
}

export interface IProps {
  showModal: boolean
  handleModal: () => void
  parentId?: string
  parentName?: string
  isMaster?: boolean
}
export interface IHookProps {
  handleModal: () => void
  parentId?: string
  isMaster?: boolean
}

export interface IFormikValues {
  parentCustomer?: string
  profile: string
  name: string
  cpf: string
  phone: string
  email: string
}
