import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Loading from '@components/Loading'

import * as SC from './styles'
import { IProps } from './types'

const SelectList = ({
  name = '',
  id = '',
  label,
  selected = '',
  handleChange,
  options,
  disabled = false,
  secondary = false,
  tertiary = false,
  loading = false,
}: IProps) => {
  const themes = {
    primary: {
      active: {
        background: theme.palette.primary.light,
        color: theme.palette.neutral.regular,
        placeholder: theme.palette.neutral.regular,
      },
      disabled: {
        background: theme.palette.neutral.regular,
        color: theme.palette.primary.light,
        placeholder: '',
      },
    },
    secondary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
    tertiary: {
      active: {
        background: '',
        color: '',
        placeholder: '',
      },
      disabled: {
        background: '',
        color: '',
        placeholder: '',
      },
    },
  }

  const themeActive =
    themes[(secondary && 'secondary') || (tertiary && 'tertiary') || 'primary']

  if (loading)
    <SC.SelectListContainer>
      <Loading size={30} color={theme.palette.primary.regular} />
    </SC.SelectListContainer>

  return (
    <SC.SelectListContainer>
      {label && (
        <>
          {label}
          <Separator verticalSize={3} />
        </>
      )}

      <SC.Select
        value={selected}
        onChange={(e) => {
          handleChange(e.target.value)
        }}
        name={name}
        id={id}
        disabled={disabled}
        data-testid="select-data-field"
        textInputColor={themeActive.active.color}
        backgroundInputColor={themeActive.active.background}
        placeholderColor={themeActive.active.placeholder}
        defaultValue={selected}
      >
        {options?.map((option) => (
          <option
            key={option.value}
            selected={option.value === selected}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </SC.Select>
    </SC.SelectListContainer>
  )
}

export default SelectList
