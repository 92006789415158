import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'

import { IViewProps } from './types'
import * as SC from './styles'

const PageTitle = ({ children }: IViewProps) => (
  <SC.Wrappertitle>
    <Typography
      fontType={FontType.bold}
      color={theme.palette.primary.lightest}
      size={26}
    >
      {children}
    </Typography>
  </SC.Wrappertitle>
)
export default PageTitle
