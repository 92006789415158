import styled from '@emotion/styled'
import { DefaultProps } from '@resources/types'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin: 0 0 20px 0;
`
export const Content = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.layout.desktop.size.max};
  padding: 0;
  margin: 0;
`
