import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { FontType } from '@components/UI/Typography'
import { IinputStyle } from './types'

export const Container = styled.div``

export const Label = styled.label<IinputStyle & DefaultProps>`
  color: ${({ labelColor }) => labelColor};
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
`
export const Input = styled.textarea<IinputStyle & DefaultProps>`
  background: ${({ backgroundInputColor }) => backgroundInputColor};
  border: ${({ backgroundInputColor }) => `1px solid ${backgroundInputColor}`};
  border: 0px;
  outline: none;
  padding: 10px;
  border-radius: 6px;
  width: ${({ inputWidth, widthFull }) =>
    widthFull ? '100%' : `${inputWidth}px`};
  height: ${({ inputHeight }) => `${inputHeight}px`};

  ${({ theme }) => theme.mixins.fonts(FontType.medium)}
  font-size: 13px;
  color: ${({ textInputColor }) => textInputColor};

  ::-webkit-input-placeholder {
    color: ${({ theme, placeholderColor }) =>
      placeholderColor ? placeholderColor : theme.palette.secondary.lightest};
  }

  :-ms-input-placeholder {
    color: ${({ theme, placeholderColor }) =>
      placeholderColor ? placeholderColor : theme.palette.secondary.lightest};
  }

  ::placeholder {
    color: ${({ theme, placeholderColor }) =>
      placeholderColor ? placeholderColor : theme.palette.secondary.lightest};
  }
`
