import { IProfileResponse } from '@resources/types/services/auth'

export interface IAuthState {
  auth: {
    credentials: {
      accessToken: string
    }
    profile: IProfileResponse
  }
  actions: {
    setAuth: (accessToken: string, profile: IProfileResponse) => void
    updateProfile: (profile: IProfileResponse) => void
  }
}

export const INITIAL_STATE_PROFILE = {
  id: 0,
  uuid: '',
  profile: '',
  email: '',
  name: '',
  cpf: '',
  cellphone: '',
  parentCustomer: {
    address: '',
    cellphone: '',
    city: '',
    corporateName: '',
    createdAt: '',
    deletedAt: null,
    document: '',
    email: '',
    id: 0,
    name: '',
    personType: '',
    phone: '',
    state: '',
    tradingName: '',
    updatedAt: '',
    uuid: '',
    zipCode: '',
    negotiationPanel: '0',
    strategyMonitor: '0',
  },
}
