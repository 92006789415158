import { getEnvOrDefault } from '@resources/utils/envs'

const {
  REACT_APP_DEVICE_ID: ENV_DEVICE_ID,
  REACT_APP_API_URL: ENV_API_URL,
  REACT_APP_API_AUTHORIZATION: ENV_API_AUTHORIZATION,
  REACT_APP_API_KEY: ENV_API_KEY,
} = process.env

export const DEVICE_ID = getEnvOrDefault({ ENV_DEVICE_ID }, '')
export const API_URL = getEnvOrDefault({ ENV_API_URL }, '')
export const API_AUTHORIZATION = getEnvOrDefault({ ENV_API_AUTHORIZATION }, '')
export const API_KEY = getEnvOrDefault({ ENV_API_KEY }, '')
