import { createElement, useState } from 'react'
import { useAlert } from 'react-alert'
import { useQueryClient } from 'react-query'

import View from './view'
import { useCustomerSignUp } from '@hooks/customer/useCustomer'
import { useFormik } from 'formik'
import { signUpSchema } from './yup/signUpSchema'
import { IFormikValues, IProps } from './types'
import { States } from '@resources/helpers/states'
import { useAuthMethods } from '@store/auth'

export enum PersonType {
  PJ = 'PJ',
  PF = 'PF',
}

const SignUpContainer = ({ showModal, handleModal }: IProps) => {
  const alert = useAlert()
  const queryClient = useQueryClient()

  const modulesOptions = [
    { value: 'o', label: 'Negar acesso' },
    { value: '1', label: 'Liberar acesso' },
  ]

  const [cityOptions, setCityOptions] = useState<
    { value: string; label: string }[]
  >([])
  const [personType, setPersonType] = useState<PersonType | null>(null)

  const { profile } = useAuthMethods()

  const { mutate, isLoading } = useCustomerSignUp()

  const onSuccess = (): void => {
    alert.success(
      'Cliente cadastrado com sucesso! \nCrie usuários para acessar o sistema.',
    )

    queryClient.invalidateQueries(['useCustomerList'])
    handleModal()
  }
  const onError = (): void => {
    alert.error('Ocorreu um erro inesperado. Tente novamente.')

    handleModal()
  }

  const handleAuthenticate = (prop: IFormikValues) => {
    const data = {
      user: profile.uuid,
      address: prop.address,
      email: prop.email,
      name: prop.name,
      tradingName: prop.tradingName || '',
      corporateName: prop.corporateName,
      personType: personType === PersonType.PJ ? PersonType.PJ : PersonType.PF,
      document: prop.cpfCnpj,
      cellphone: prop.cellPhone,
      phone: prop.phone,
      city: prop.city,
      state: prop.state,
      zipCode: prop.zipCode,
      negotiationPanel: prop.negotiationPanel,
      strategyMonitor: prop.strategyMonitor,
    }

    mutate(data, { onSuccess, onError })
  }

  const stateList = () => {
    return [
      { value: '', label: '' },
      ...States.map((item) => {
        return { value: item.name, label: item.name }
      }),
    ]
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      corporateName: '',
      tradingName: '',
      cpfCnpj: '',
      cellPhone: '',
      phone: '',
      address: '',
      zipCode: '',
      email: '',
      state: '',
      city: '',
      negotiationPanel: '0',
      strategyMonitor: '0',
    },
    validationSchema: signUpSchema,
    onSubmit: handleAuthenticate,
  })

  const handleStateChange = (stateSelected: string) => {
    const state = States.find((item) => item.name === stateSelected)

    if (!state) return

    const cities = [
      { value: '', label: '' },
      ...state.cities.map((item) => {
        return { value: item, label: item }
      }),
    ]

    setCityOptions(cities)
    formik.setFieldValue('state', stateSelected)
    formik.setFieldValue('city', '')
  }

  const handleCityChange = (citySelected: string) => {
    formik.setFieldValue('city', citySelected)
  }

  const viewProps = {
    formik,
    isLoading,
    showModal,
    handleModal,
    personType,
    setPersonType,
    handleStateChange,
    handleCityChange,
    cityOptions,
    stateList,
    modulesOptions,
  }

  return createElement(View, viewProps)
}

export default SignUpContainer
