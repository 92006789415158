// import { useEffect, useState, useMemo } from 'react'
// import { useAlert } from 'react-alert'

import { useOperationMatchingGetOpenedByCustomer } from '@hooks/operationMatching/useOperationMatching'

import 'dayjs/locale/pt-br'

// import { ICheckedOperation } from './types'

export const useOperationalMatching = () => {
  // const alert = useAlert()

  // const { profile } = useAuthMethods()

  const { data, isLoading } = useOperationMatchingGetOpenedByCustomer()

  const formatReal = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  const formatDolar = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }

  return {
    isLoading,
    data,
    formatDolar,
    formatReal,
  }
}
