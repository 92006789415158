import * as SC from './styles'

const Chat = () => {
  return (
    <SC.Container>
      <SC.WrapperContent>O chat estará disponível em breve</SC.WrapperContent>
    </SC.Container>
  )
}

export default Chat
