import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import { UserService } from '@services/user'

import {
  IUsersByCustomerResponse,
  IUserDeleteByIdRequest,
} from '@resources/types/services/user'
import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'

export const useGetAllByCustomer = (
  customerId: string,
): UseQueryResult<IUsersByCustomerResponse[]> => {
  return useQuery(
    ['allUserByCustomer', customerId],
    () => {
      return UserService.getAllByCustomer(customerId)
    },
    {
      enabled: !!customerId,
    },
  )
}

export const useDeleteUserById = (
  options?: IMutationOptionsRequest,
): UseMutationResult<any, any, IUserDeleteByIdRequest, unknown> => {
  return useMutation(UserService.deleteById, options)
}
