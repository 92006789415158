import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance, getApiHeader } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import {
  ISignInResponse,
  ISignInRequest,
  IProfileResponse,
} from '@resources/types/services/auth'

export const UserService = {
  signIn: async (data: ISignInRequest): Promise<ISignInResponse> => {
    const api = getApiInstance()
    const header = getApiHeader()

    const response: AxiosResponse = await api.post(
      endpoints.URL.auth.signIn,
      data,
      header,
    )

    getApiInstance(response?.data?.access_token)

    return response.data
  },

  profile: async (): Promise<IProfileResponse> => {
    const api = getApiInstance()
    const header = getApiHeader()

    const response: AxiosResponse = await api.get(
      endpoints.URL.auth.profile,
      header,
    )

    return response.data
  },

  forgotPassword: async (email = ''): Promise<ISignInResponse> => {
    const api = getApiInstance()
    const header = getApiHeader()

    const url = translateUrlParam(endpoints.URL.user.forgot, {
      email,
    })

    const response: AxiosResponse = await api.get(url, header)

    return response.data
  },

  resetPassword: async (
    newPassword: string | null,
    userid = '',
  ): Promise<ISignInResponse> => {
    const api = getApiInstance()
    const header = getApiHeader()

    const url = translateUrlParam(endpoints.URL.user.reset, {
      userid,
    })

    const response: AxiosResponse = await api.patch(
      url,
      { password: newPassword },
      header,
    )

    return response.data
  },

  newPassword: async (
    newPassword: string | null,
    userid = '',
  ): Promise<ISignInResponse> => {
    const api = getApiInstance()
    const header = getApiHeader()

    const url = translateUrlParam(endpoints.URL.user.confirm, {
      userid,
    })

    const response: AxiosResponse = await api.patch(
      url,
      { password: newPassword },
      header,
    )

    return response.data
  },

  confirmUser: async (userid = ''): Promise<ISignInResponse> => {
    const api = getApiInstance()
    const header = getApiHeader()

    const url = translateUrlParam(endpoints.URL.user.confirm, {
      userid,
    })

    const response: AxiosResponse = await api.get(url, header)

    return response.data
  },
}
