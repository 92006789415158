import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'newPassword', {
    title: 'New Password',
    input: {
      password: {
        label: 'Password',
        placeholder: 'Type your password',
      },
      confirmPassword: {
        label: 'Confirm your password',
        placeholder: 'Confirm your password',
      },
    },
    button: 'Confirm',
  })
}
