import { ProfileTypes } from '@resources/types/profileTypes'

export const getFirstWord = (words: string) => {
  if (!words) return null

  const [firstWord] = words.split(' ')

  return firstWord
}

export const translateUrlParam = (url: string, obj: Record<string, string>) => {
  let translateUrlParam = url

  Object.keys(obj).forEach((key) => {
    translateUrlParam = translateUrlParam.replace(`{{${key}}}`, obj[key])
  })

  return translateUrlParam
}

export const hidePart = (phoneNumber: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return `(xx) xxxxx-${phoneNumber.slice(7)}`
}

export const replaceSlashWithDash = (text: string) => {
  return text.replace('/', ' - ')
}

export const clearCpfMask = (str: string) => {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[\(\)\.\s-/]+/g, '')
}

export const translateNameProfile = (profile: string) => {
  const profiles = {
    [ProfileTypes.master]: 'Gestor do Sistema',
    [ProfileTypes.admin]: 'Administrador',
    [ProfileTypes.paymaster]: 'Tesoureiro',
    [ProfileTypes.operator]: 'Operador',
  }
  return profiles[profile]
}
