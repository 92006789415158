import { useState } from 'react'

import { useGetSignalListByCategory } from '@hooks/signal/useSignal'
import {
  cookieVariabels,
  setCookie,
  getCookie,
} from '@resources/helpers/application/cookie'

import { OptionType } from './SelectSignalToView/types'
import { SignalToViewOperation } from './types'

export const useTrendlessMarket = () => {
  const getSignalFromCookie =
    getCookie(cookieVariabels.exchangeSignalSelected)?.split(',') || []

  const [categorySelected, setCategorySelected] = useState<string>('15-minutos')
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)

  const { data, isLoading } = useGetSignalListByCategory(categorySelected)
  const [dataOptionsSelected, setDataOptionsSelected] =
    useState<string[]>(getSignalFromCookie)

  const handleSelectSignal = async (
    optionSelected: string,
    action: keyof typeof SignalToViewOperation,
  ) => {
    try {
      if (action === SignalToViewOperation.add) {
        setDataOptionsSelected((itens) => [...itens, optionSelected])
        setCookie(
          cookieVariabels.exchangeSignalSelected,
          [...dataOptionsSelected, optionSelected].join(','),
        )
      }

      if (action === SignalToViewOperation.remove) {
        const filterOptions = dataOptionsSelected.filter(
          (item) => item !== optionSelected,
        )

        setDataOptionsSelected(filterOptions)
        setCookie(
          cookieVariabels.exchangeSignalSelected,
          filterOptions.join(','),
        )
      }

      console.log('Selecte Plan:', optionSelected)
    } catch (error) {
      console.error('Selecte Plan:', error)
    }
  }

  const handleSignalMarking = (option: string) => {
    if (!dataOptionsSelected.length) return true

    return dataOptionsSelected.includes(option)
  }

  const dataOptions: OptionType[] =
    data?.map((item) => ({
      id: item.uuid,
      name: item.strategy.name,
    })) || []

  const tabs = [
    {
      label: '15 MINUTOS',
      key: '15-minutos',
    },
    {
      label: '60 MINUTOS',
      key: '60-minutos',
    },
    {
      label: '240 MINUTOS',
      key: '240-minutos',
    },
  ]

  const handleSelectTab = (tabKey: string) => {
    setCategorySelected(tabKey)
  }
  const handleDetailModal = () => {
    setShowDetailModal(!showDetailModal)
  }

  return {
    data,
    isLoading,
    handleSelectSignal,
    dataOptions,
    dataOptionsSelected,
    handleSignalMarking,
    tabs,
    handleSelectTab,
    handleDetailModal,
    showDetailModal,
  }
}
