/* eslint-disable @typescript-eslint/no-unused-vars */
import Icon from '@components/UI/Icon'

import Logo from './Logo'
import Menu from './Menu'

import { IViewProps } from './types'
import * as SC from './styles'

const Header = ({ fixedTop, handleClick, menuOpen }: IViewProps) => {
  console.log('Header', menuOpen)

  return (
    <SC.Wrapper>
      <SC.Container fixedTop={fixedTop} openMenuMobile={!menuOpen}>
        <SC.Content>
          <SC.WrapperLogo>
            <Logo />
          </SC.WrapperLogo>

          <SC.MenuIcon id="menu-icon" onClick={handleClick}>
            <SC.HandleShowIcon hidden={menuOpen}>
              <Icon name="FaStream" />
            </SC.HandleShowIcon>

            <SC.HandleShowIcon hidden={!menuOpen}>
              <Icon name="AiOutlineClose" />
            </SC.HandleShowIcon>
          </SC.MenuIcon>

          <SC.WrapperMenu hidden={!menuOpen}>
            <Menu />
          </SC.WrapperMenu>
        </SC.Content>
      </SC.Container>
    </SC.Wrapper>
  )
}

export default Header
