import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const SignalCard = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 180px;
  min-height: 130px;
  padding: 25px;
  margin: 10px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.primary.dark};
  border: 1px solid ${({ theme }) => theme.palette.neutral.dark};
`

export const WrapperHorizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

export const WrapperVertical = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
`

export const WrapperSemaphore = styled.div`
  width: 25px;
  height: 40px;
`
export const Semaphore = styled.div<{ color: string } & DefaultProps>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0 0 7px 0;

  ${({ color, theme }) =>
    color === 'long' && `background:  ${theme.palette.success.dark}`};

  ${({ color, theme }) =>
    color === 'flat' && `background:  ${theme.palette.warning.regular}`};

  ${({ color, theme }) =>
    color === 'short' && `background:  ${theme.palette.error.regular}`};
`

export const Disabled = styled.div<DefaultProps>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #00000099;
  z-index: 99;
  position: absolute;
`

export const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80px;
  height: 70px;
  padding: 0;
`
export const BoxValue = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.neutral.dark};
  border: 1px solid ${({ theme }) => theme.palette.neutral.dark};
`
export const WrapperTitleAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 20px;
  background: red;
`
