/* eslint-disable @typescript-eslint/no-unused-vars */
import { createElement, useEffect } from 'react'

import View from './view'
import { IProps, IViewProps } from './types'

const PanelContainer = ({ children }: IProps) => {
  const viewProps: IViewProps = {}

  return createElement(View, viewProps, children)
}

export default PanelContainer
