import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div<DefaultProps>`
  width: 100%;
`

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 500px;
  height: 200px;
  padding: 0 20px;
  color: white;
`
