import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'tables', {
    title: 'Table managment',
    buttonAdd: 'Add',
  })
}
