import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/react'

import { DefaultProps } from '@resources/types'

const scaleIn = keyframes`
  from {
    transform: scale(0) translate(0, 0);
    height: 0px
  }
  to {
    transform: scale(1) translate(0, 0);
    height: auto;
  }
`

const scaleOut = keyframes`
  from {
    transform: scale(1) translate(0, 0);
    height: auto;
  }
  to {
    transform: scale(0) translate(0, 0);
		height: 0px
  }
`

export const Container = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 100%;
  position: relative;
  z-index: 9999;
`
export const MenuItem = styled.div<
  { active: boolean; menuItemsChildrenClassName?: string } & DefaultProps
>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 30px);
  min-height: 25px;
  margin: 0 0 6px;
  padding: 10px 15px;
  /* border-radius: 20px; */
  cursor: pointer;
  background: ${({ theme, active }) => active && theme.palette.primary.regular};

  &:hover {
    background: ${({ theme }) => theme.palette.primary.regular}80;

    ${({ menuItemsChildrenClassName }) => css`
      .${menuItemsChildrenClassName} {
        display: block;
      }
    `}
  }
`

export const MenuItemLabel = styled.div`
  width: calc(100% - 15px);
  margin: 0 0 0 15px;
  text-align: left;
`

export const AccordionWrapper = styled.div<DefaultProps>`
  width: 100%;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  margin: 0 0 5px;
  padding: 0px;
  transition: all 1.2s ease-in-out;
`

export const OrganizerHorizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`

export const AccordionTitle = styled.div<
  { isActive: boolean; hasItems: boolean } & DefaultProps
>`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.primary.darkest};

  ${({ theme, isActive, hasItems }) =>
    isActive
      ? `
      border-bottom-left-radius: ${hasItems ? 0 : 20}px;
      border-bottom-right-radius: ${hasItems ? 0 : 20}px;
      transition: all 0.5s ease-in-out;
      background: ${theme.palette.primary.regular};
  `
      : `

      transition: all 0.9s ease-in-out;
  `};

  &:hover {
    background: ${({ theme }) => theme.palette.primary.regular};
  }
`

export const AccordionContent = styled.div<
  { open: boolean; firstrender: boolean } & DefaultProps
>`
  width: 100%;
  height: 0px;
  padding: 0;
  background: ${({ theme }) => theme.palette.primary.darkest};
  transform-origin: top center;
  transform: ${({ open }) => (open ? 'scale(1)' : 'scale(0)')};
  transition: transform 0.6s ease;
  animation: ${({ open }) => (open ? scaleIn : scaleOut)} 0.5s ease both;

  ${({ firstrender }) =>
    firstrender &&
    css`
      visibility: hidden;
    `}
`

export const MenuItemsChildren = styled.div<DefaultProps>`
  position: absolute;
  top: 0;
  left: 98%;
  display: none;
  padding: 15px 0;
  min-width: 250px;
  background: ${({ theme }) => theme.palette.neutral.dark};
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
`
