import { useState } from 'react'
import { isMobile } from 'react-device-detect'

import Button from '@components/UI/Button'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
// import Button from '@components/UI/Button'

import * as SC from './styles'
import ModalCustomProspecting from './ModalCustomProspecting'
// import { IProps } from './types'

const Publicity = () => {
  const [showForm, setShowForm] = useState(false)

  const breakText = isMobile ? '<br />' : ''
  // const spaceskText = isMobile ? '' : '&nbsp;&nbsp;&nbsp;&nbsp'

  const pub = () => (
    <>
      <Typography
        fontType={FontType.bold}
        size={50}
        color={theme.palette.primary.light}
        lineHeight="1"
      >
        Exchange Spot
      </Typography>

      <Separator verticalSize={25} />

      <Typography
        fontType={FontType.light}
        size={isMobile ? 18 : 25}
        color={theme.palette.secondary.light}
        lineHeight={'1.5'}
      >
        É uma plataforma de conexão que traz soluções financeiras dentro de um
        só ambiente, conectando as demandas mais complexas às instituições
        financeiras especializadas. Estregamos um terminal / sistema operacional
        de gestão para o segmento de câmbio financeiro.
      </Typography>

      <Separator verticalSize={35} />

      <Button
        width={600}
        widthFull={isMobile}
        onClick={() => setShowForm(true)}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: `Quer experimentar? ${breakText}Clique aqui e fale
        conosco`,
          }}
        ></p>
      </Button>
    </>
  )

  return (
    <>
      <SC.Content>{pub()}</SC.Content>

      <ModalCustomProspecting
        display={showForm}
        handleCloseModal={() => setShowForm(false)}
      />
    </>
  )
}

export default Publicity
