import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { CSVLink } from 'react-csv'

import { DefaultProps } from '@resources/types'
import { Fonts } from '@resources/types/mixins'
export const Container = styled.div`
  width: 1040px;
  height: auto;
  padding: 0 0 50px 0;
`

export const WrapperHeader = styled.div`
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
`

export const SectionMain = styled.div<DefaultProps>`
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SectionValues = styled.div<DefaultProps>`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
`

export const WrapperTable = styled.div<DefaultProps>`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: start;
  overflow-y: auto;
`

export const WrapperFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const WrapperConsolidated = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin: 20px 0 20px 0;
  border-radius: 8px;
  /* background: ${({ theme }) => theme.palette.neutral.regular}; */
`

export const WrapperTotals = styled.div`
  width: 100%;
  margin: 0 0 0px 0;
`

export const Total = styled.div<{ width?: string }>`
  text-align: left;
  flex-direction: row;

  width: ${({ width }) => (width ? width : '50%')};
  height: 30px;
  margin-right: 10px;
`

export const WrapperFilter = styled.div`
  width: calc(100% - 40px);
  padding: 20px;
  background: #f0f0f010;
  border-radius: 10px;
`
export const OrganizeHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 10px 0;
`

export const WrapperField = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '450px' : '210px')};
  min-height: 20px;
  margin: 0 20px 0 0;
`

export const WrapperButonSearch = styled.div`
  padding: 22px 0 0 0;
  height: 40px;
`

export const WrapperDataGrid = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 0;
  overflow-x: scroll;
`

export const ButtonExport = styled(CSVLink)<DefaultProps>`
  height: 35px;
  width: 100px;

  background-color: ${({ theme }) => theme.palette.success.dark};
  padding: 10px 25px 0px;
  border-radius: 30px;
  border: ${({ theme }) => `2px solid ${theme.palette.primary.darkest}`};

  cursor: pointer;
  ${({ theme }) => theme.mixins.fonts(Fonts.medium)};
  font-size: 15px;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;

    ${({ theme }) => {
      return css`
        li a: {
          color: ${theme.typography.fonts.normal};
        }
      `
    }}
  }
`
export const WrapperAlignField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
`

export const WrapperIcon = styled.div`
  width: 30px;
  margin: 0px 0 0 10px;
`

export const WrapperPositionFilter = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`

export const WrapperFieldFilter = styled.div`
  width: 150px;
  height: 50px;
  margin: 0;
`

export const WrapperPivotPoint = styled.div<DefaultProps>`
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 300px;
  padding: 0;
  margin: -13px 0 0 0;
  gap: 10px;
  z-index: 10;
`

export const WrapperTimePivotPoin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  & > div:last-of-type {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s ease-in-out;
    height: 0px;
  }

  & > div:first-of-type:hover {
    & + div {
      visibility: visible;
      opacity: 1;
      height: auto;
    }
  }
`

export const TimePivotPoint = styled.div<DefaultProps>`
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 5px 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral.regular};
  cursor: pointer;

  margin-top: 25px;
`

export const TimePivotPointData = styled.div<DefaultProps>`
  width: 90%;
  background: ${({ theme }) => theme.palette.neutral.dark};
`

export const WrapperPerformance = styled.div`
  padding: 30px;
`
