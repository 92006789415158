/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useAlert } from 'react-alert'
import DayJs from 'dayjs'
import { useFormik } from 'formik'

import { IGridData, IGridHeader } from '@components/UI/DataGrid'
import Icon from '@components/UI/Icon'
import { currencyFormatterSixPlaces } from '@resources/utils/forCurrency'

import { useAuthMethods } from '@store/auth'
import { ProfileTypes } from '@resources/types/profileTypes'
import Field from '@components/UI/Field'
import { ICoverageRates } from '@resources/types/services/coverageRates'

import {
  useGetCovertageRatesByCustomer,
  useUpdateCovertageRatesById,
  useCreateCovertageRatesAllByCustomer,
} from '@hooks/coverageRates/useCoverageRates'

const header: IGridHeader[] = [
  {
    label: 'Moeda',
    grid: 1.5,
    action: null,
    value: 'name',
    field: 'values',
    styles: {
      align: 'left',
      fontType: undefined,
      fontSize: 14,
    },
  },
  {
    label: 'Taxa Spread',
    grid: 2.5,
    action: null,
    value: 'element',
    field: 'element',
    styles: {
      align: 'center',
      fontSize: 13,
    },
  },

  {
    label: 'Atualizar Spred',
    grid: 2,
    action: null,
    value: 'icon1',
    field: 'element',
    styles: {
      align: 'center',
      fontSize: 13,
    },
  },
  {
    label: 'Última Atualização',
    grid: 4,
    action: null,
    value: 'updatedAt',
    field: 'values',
    styles: {
      align: 'center',
      fontSize: 13,
    },
  },
]

export const useCoverageRates = () => {
  const alert = useAlert()
  const queryClient = useQueryClient()
  const { profile } = useAuthMethods()

  const { isLoading, data, refetch } = useGetCovertageRatesByCustomer(
    profile.parentCustomer.uuid,
  )
  const { mutate, isLoading: isLoadingMutation } = useUpdateCovertageRatesById()
  const { mutate: mutateCrateRates, isLoading: isLoadingMutationCreateRates } =
    useCreateCovertageRatesAllByCustomer()
  const [setValueFormik, setSetValueFormik] = useState({})

  const getValuesForm = useCallback(() => {
    const prepareInitialValues = {}

    data?.forEach((item: ICoverageRates) => {
      prepareInitialValues[item.uuid] = currencyFormatterSixPlaces(item.spread)
    })

    setSetValueFormik(prepareInitialValues)

    return prepareInitialValues
  }, [data])

  useEffect(() => {
    if (!data || !data.length) return

    getValuesForm()
  }, [data, getValuesForm])

  const formik = useFormik({
    initialValues: setValueFormik,
    onSubmit: () => undefined,
  })

  const onSuccessUpdateRate = (): void => {
    queryClient.invalidateQueries(['getCovertageRatesByCustomer'])
    alert.success('Taxa atualizada com sucesso!')
  }

  const onErrorDUpdateRate = (): void => {
    queryClient.invalidateQueries(['getCovertageRatesByCustomer'])
    alert.error('Houve uma erro ao atualizar taxa.')
  }

  const updateTaxeRateById = (id: string) => {
    const data = {
      id,
      spread: Number(formik.values[id]),
    }
    mutate(data, {
      onSuccess: onSuccessUpdateRate,
      onError: onErrorDUpdateRate,
    })
  }

  const handleData = (): IGridData[] | [] => {
    if (!data || !data.length) return []

    const dataTable: IGridData[] = []

    data.forEach((table) => {
      const prepareData = {
        id: table.uuid.toString(),
        element: (
          <Field
            id={`field-${table.uuid}`}
            name={table.uuid}
            value={formik.values[table.uuid]}
            onChange={(event) => {
              const value = event.target.value.replace('$', '')
              formik.setFieldValue(table.uuid, value)
            }}
            currency
            decimal={6}
            widthFull
            disabled={isLoadingMutation}
          />
        ),
        icon1:
          profile.profile === ProfileTypes.admin ? (
            <Icon
              name="RxUpdate"
              onCallback={() => {
                if (isLoadingMutation) return
                updateTaxeRateById(table.uuid)
              }}
              alt="Atualizar Spread"
              title="Atualizar Spread"
            />
          ) : null,
        values: {
          name: table.coin,
          spread: table.spread,
          id: table.id,
          updatedAt: DayJs(table.updatedAt).isValid()
            ? DayJs(table.updatedAt).format('DD/MM/YYYY HH:mm')
            : ' - - -',
        },
      }

      dataTable.push(prepareData)
    })

    return dataTable
  }

  useEffect(() => {
    if (
      Object.keys(formik.values).length ||
      !Object.keys(setValueFormik).length
    )
      return

    formik.setValues(setValueFormik)
  }, [formik, setValueFormik])

  const onSuccessCrateRate = (): void => {
    refetch()
    alert.success('Taxa criada com sucesso!')
  }

  const onErrorCreateeRate = (): void => {
    alert.error('Houve uma erro ao criar taxa.')
  }

  const createCoverageRates = () => {
    if (isLoadingMutationCreateRates) return

    const data = { customerId: profile.parentCustomer.uuid }

    mutateCrateRates(data, {
      onSuccess: onSuccessCrateRate,
      onError: onErrorCreateeRate,
    })
  }

  return {
    isLoading:
      isLoading || (!data?.length ? false : !Object.keys(formik.values).length),
    isLoadingMutationCreateRates,
    header,
    data,
    handleData,
    createCoverageRates,
  }
}
