import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance, getApiHeader } from '@services/api'
import { translateUrlParam } from '@resources/utils/forString'
import {
  IAccessResponse,
  IUpdateAccessRequest,
} from '@resources/types/services/accessManagement'
import { ISignUpRequest } from '@resources/types/services/accessManagement'
import { ISignInResponse } from '@resources/types/services/auth'

export const AccessManagementService = {
  signUp: async (data: ISignUpRequest): Promise<ISignInResponse> => {
    const api = getApiInstance()

    const header = getApiHeader()

    const response: AxiosResponse = await api.post(
      endpoints.URL.accessManagement.signUp,
      data,
      header,
    )

    return response.data
  },

  getAccessList: async (): Promise<IAccessResponse[]> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.get(
      endpoints.URL.accessManagement.access,
    )

    return response.data
  },

  update: async ({
    userid,
    data,
  }: IUpdateAccessRequest): Promise<IAccessResponse> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.accessManagement.update, {
      userid,
    })

    const response: AxiosResponse = await api.patch(url, data)

    return response.data
  },
}
