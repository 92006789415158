import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'
import { Link as BaseLink } from 'react-router-dom'
import { mediaQuerydevices } from '@resources/constants/devices'

export const LinkOption = styled(BaseLink)<DefaultProps>`
  text-align: left;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: 700;
  font-size: 12px;
  font-style: normal;

  cursor: pointer;
`

export const Container = styled.footer<DefaultProps>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100vw;
  height: 120px;
  margin: 0;
  padding: 30px 0;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.dark};

  @media (${mediaQuerydevices.mobile}) {
    height: 190px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 120px;
  width: 1024px;

  @media (${mediaQuerydevices.maxDesktop}) {
    width: 1360px;
  }

  @media (${mediaQuerydevices.mobile}) {
    width: calc(100% - 48px);
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 24px;
    height: 200px;
    padding: 0;
  }
`

export const WrapperInformation = styled.div`
  width: 500px;
  height: 120px;
  margin: 0;
`

export const WrapperFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
`

export const WrapperPrivacy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
