import * as Yup from 'yup'

export const customProspectingchema = Yup.object().shape({
  name: Yup.string()
    .test('isValidName', 'Digite seu nome completo', (val) => {
      return String(val).length > 6
    })
    .required('Digite seu nome completo'),

  email: Yup.string()
    .email('Formato de e-mail inválido')
    .min(3, 'Mínimo de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('Email é obrigatório'),

  cellphone: Yup.string()
    .min(9, 'Mínimo de 3 caracteres')
    .max(20, 'Máximo de 50 caracteres')
    .required('Telefone para contato é obrigatório'),
})
