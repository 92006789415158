import { useQuery, useMutation, UseMutationResult } from 'react-query'

import { OperationMatchingService } from '@services/operationMatching'

import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'
import {
  IOperationMatchingCreateRequest,
  IOperationMatchingCreateResponse,
  IOperationMatchingResponse,
} from '@resources/types/services/operationMatching'

export const useOperationMatchingGetOpenedByCustomer = (customerId = '') => {
  return useQuery<IOperationMatchingResponse[], Error>(
    ['operationMatchingGetOpenedByCustomer', customerId],
    () => {
      return OperationMatchingService.getOpenedByCustomer(customerId)
    },
    {
      enabled: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useCreateOperationMatching = (
  options?: IMutationOptionsRequest,
): UseMutationResult<
  IOperationMatchingCreateResponse,
  any,
  IOperationMatchingCreateRequest,
  unknown
> => {
  return useMutation(OperationMatchingService.create, options)
}
