import Typography, { FontType } from '@components/UI/Typography'
import Separator from '@components/UI/Separator'
import Icon from '@components/UI/Icon'
import { theme } from '@resources/theme'

import * as SC from './styles'

const Products = () => {
  return (
    <SC.Content>
      <Typography
        fontType={FontType.semiBold}
        size={40}
        color={theme.palette.primary.lightest}
        lineHeight="1.2"
      >
        SER TECNOLÓGICO NÃO É DEIXAR DE SER HUMANO
        {/* Ser tecnológico não é deixar de ser humano */}
      </Typography>
      <Separator verticalSize={50} />
      <Typography
        fontType={FontType.regular}
        size={18}
        color={theme.palette.secondary.light}
        lineHeight="1.5"
        align="justify"
      >
        Com certeza, você já deve ter ouvido falar das fintechs no Brasil,
        certo? Essas empresas são inovadoras no setor financeiro e utilizam
        tecnologia de maneira brilhante para fornecer serviços financeiros
        altamente especializados. A melhor parte disso tudo? Essa revolução
        tecnológica traz benefícios tanto para você, cliente, quanto para o
        sistema financeiro como um todo.
        <br />
        <br />
        Entre 2016 e 2022, nada menos que 513 novas fintechs floresceram no
        cenário brasileiro. Hoje, já contabilizamos um total impressionante de
        1.289 fintechs presentes apenas no Brasil, e isso gerou uma verdadeira
        revolução no mundo das finanças para o consumidor comum.
        <br />
        <br />
        Mas à medida que mais empresas entram nesse setor em crescimento, a
        regulação desses produtos se torna cada vez mais importante. É aqui que
        entra a Exchange Spot, com sua consultoria especializada.
        <br />
        <br />
        Nós, da Exchange Spot, acreditamos firmemente que a tecnologia é uma
        aliada, não uma substituta do ser humano no mundo das finanças. É por
        isso, inserimos o cliente no centro do nosso conceito. Nosso objetivo é
        conectar você a um propósito real, fornecendo um serviço financeiro
        personalizado que se adapte perfeitamente às suas necessidades e metas.
        Com a nossa ajuda, você obtém o melhor dos dois mundos: a eficiência da
        tecnologia e a expertise de profissionais dedicados em fazer a diferença
        em sua vida financeira.
        <br />
        <br />
        Nosso conceito é conectar clientes à propósitos!
      </Typography>

      <Separator verticalSize={40} />

      <Typography
        fontType={FontType.semiBold}
        size={30}
        color={theme.palette.primary.lightest}
        lineHeight="1.2"
        align="center"
      >
        O que nós oferecemos
      </Typography>

      <Separator verticalSize={40} />

      <SC.WrapperCard>
        <SC.Card>
          <SC.WrapperImage>
            <Icon name="homeInvist" />
          </SC.WrapperImage>

          <Typography
            fontType={FontType.semiBold}
            size={16}
            color={theme.palette.success.dark}
            lineHeight="20px"
            align="center"
          >
            TELA DE NEGOCIAÇÃO
          </Typography>

          <Separator verticalSize={10} />

          <Typography
            fontType={FontType.medium}
            size={14}
            color={theme.palette.secondary.light}
            lineHeight="20px"
            align="left"
          >
            - Oferta de taxas de câmbio spot em tempo real para operadores e
            tesouraria;
            <br />- Indicador de tendência em tempo real para moeda negociada;
          </Typography>
        </SC.Card>

        <SC.Card>
          <SC.WrapperImage>
            <Icon name="homeTrader" />
          </SC.WrapperImage>

          <Typography
            fontType={FontType.semiBold}
            size={16}
            color={theme.palette.success.dark}
            lineHeight="20px"
            align="center"
          >
            RELATÓRIO
          </Typography>

          <Separator verticalSize={10} />

          <Typography
            fontType={FontType.medium}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="20px"
            align="center"
          >
            - Controle das operações internas lançadas pelos operadores;
          </Typography>
        </SC.Card>

        <SC.Card>
          <SC.WrapperImage>
            <Icon name="homeService" />
          </SC.WrapperImage>

          <Typography
            fontType={FontType.semiBold}
            size={16}
            color={theme.palette.success.dark}
            lineHeight="20px"
            align="center"
          >
            RELATÓRIO DE PERFOMANCE
          </Typography>

          <Separator verticalSize={10} />

          <Typography
            fontType={FontType.medium}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="20px"
            align="center"
          >
            - Livro caixa de zeragem para uso da tesouraria;
          </Typography>
        </SC.Card>

        <SC.Card>
          <SC.WrapperImage>
            <Icon name="homeService" />
          </SC.WrapperImage>

          <Typography
            fontType={FontType.semiBold}
            size={16}
            color={theme.palette.success.dark}
            lineHeight="20px"
            align="center"
          >
            MONITOR DE ESTRATÉGIAS
          </Typography>

          <Separator verticalSize={10} />

          <Typography
            fontType={FontType.medium}
            size={14}
            color={theme.palette.primary.lightest}
            lineHeight="20px"
            align="center"
          >
            - Sistema de monitoramento das paridades de moeda emitindo sinais ao
            operador;
          </Typography>
        </SC.Card>
      </SC.WrapperCard>
    </SC.Content>
  )
}

export default Products
