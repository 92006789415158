import Icon from '@components/UI/Icon'

import { WrapperIcon } from './styles'

const Logo = () => {
  return (
    <WrapperIcon>
      <Icon id="logoWhite" name="logoWhite" width={180} height={154} />
    </WrapperIcon>
  )
}

export default Logo
