import React from 'react'

import Logo from './Logo'

import * as SC from './styles'

const Header = () => {
  return (
    <SC.Container>
      <SC.Content>
        <Logo />
      </SC.Content>
    </SC.Container>
  )
}

export default Header
