import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationResult,
} from 'react-query'

import {
  ICustomerResponse,
  ISignUpCustomerRequest,
  IUpdateRequest,
  IUsersByCustomerResponse,
} from '@resources/types/services/customer'
import { ISignInResponse } from '@resources/types/services/auth'
import { CustomerService } from '@services/customer'
import { IMutationOptionsRequest } from '@resources/types/hooks/mutation'

export const useCustomerSignUp = (
  options?: IMutationOptionsRequest,
): UseMutationResult<ISignInResponse, any, ISignUpCustomerRequest, unknown> => {
  return useMutation(CustomerService.signUp, options)
}

export const useCustomerList = (): UseQueryResult<ICustomerResponse[]> => {
  return useQuery(['useCustomerList'], () => {
    return CustomerService.getCustomerList()
  })
}

export const useUsersByCustomerList = (uuid: string) => {
  return useQuery<IUsersByCustomerResponse[], Error>(
    ['useUsersByCustomerList'],
    () => {
      return CustomerService.getUsersByCustomerList(uuid)
    },
    {
      enabled: !!uuid,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 0,
    },
  )
}

export const useCustomerUpdate = (
  options?: IMutationOptionsRequest,
): UseMutationResult<any, any, IUpdateRequest, unknown> => {
  return useMutation(CustomerService.update, options)
}
