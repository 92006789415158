import { createElement } from 'react'
import { useNavigate } from 'react-router-dom'

import PathRoutes from '@route/PathRoutes'

import View from './view'
import { IViewProps } from './types'

const MenuContainer = () => {
  const navigate = useNavigate()

  const navigateToBackoffice = () => {
    navigate(PathRoutes.backoffice.signIn.url)
  }

  const viewProps: IViewProps = {
    navigateToBackoffice,
  }

  return createElement(View, viewProps)
}
export default MenuContainer
