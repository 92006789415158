import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAlert } from 'react-alert'

import { Authenticate } from '@resources/helpers/authenticated'
import PathRoutes from '@route/PathRoutes'
import { ISignInResponse } from '@resources/types/services/auth'
import { useProfile, useSignIn } from '@hooks/auth/useAuth'
import { useAuthActions, useAuthMethods } from '@store/auth'
import { ProfileTypes } from '@resources/types/profileTypes'

import { signInSchema } from './yup/signInSchema'
import { IFormikValues } from './types'

export const useSignInContainer = () => {
  const navigate = useNavigate()
  const alert = useAlert()
  const authed = Authenticate()
  const authStore = useAuthMethods()

  const { setAuth } = useAuthActions()

  const [accessToken, setAccessToken] = useState<string>('')

  const { mutate, isLoading } = useSignIn()
  const profileReponse = useProfile(accessToken)

  const onSuccess = (response: ISignInResponse): void => {
    setAccessToken(response.access_token)
  }
  const onError = (): void => {
    alert.error('Login e/ou senha incorretos')
  }

  const handleAuthenticate = ({ email, password }: IFormikValues): void => {
    const data = {
      email,
      password,
    }

    mutate(data, {
      onSuccess,
      onError,
    })
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signInSchema,
    onSubmit: handleAuthenticate,
  })

  useEffect(() => {
    if (!profileReponse.data?.profile) return

    setAuth(accessToken, profileReponse.data)

    if (profileReponse.data.profile === ProfileTypes.master) {
      navigate(PathRoutes.backoffice.operationalManagement.url)
      return
    }

    if (profileReponse.data?.parentCustomer?.negotiationPanel === '1') {
      navigate(PathRoutes.backoffice.operationalManagement.url)
      return
    }

    if (profileReponse.data?.parentCustomer?.strategyMonitor === '1') {
      navigate(PathRoutes.backoffice.operationalManagementReport.url)
      return
    }

    navigate(PathRoutes.backoffice.usersByCustomer.url)
  }, [accessToken, setAuth, profileReponse.data, navigate])

  useEffect(() => {
    if (!authed) return

    if (authStore.profile.profile === ProfileTypes.master) {
      navigate(PathRoutes.backoffice.operationalManagement.url)
      return
    }

    if (authStore.profile.parentCustomer?.negotiationPanel === '1') {
      navigate(PathRoutes.backoffice.operationalManagement.url)
      return
    }

    if (authStore.profile.parentCustomer?.strategyMonitor === '1') {
      navigate(PathRoutes.backoffice.operationalManagementReport.url)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed, authStore])

  return {
    formik,
    isLoading: isLoading || profileReponse.isLoading,
  }
}
