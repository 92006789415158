import axios, { AxiosInstance } from 'axios'

import { HttpCodes } from '@resources/types/httpCode'
import { IKeyArgHeader } from '@resources/types/service'
import {
  getLocalStorage,
  constantsStorage,
} from '@resources/helpers/application/storage'
import { endpoints } from '@services/endpoints'
import PathRoutes from '@route/PathRoutes'

import { ApiGateway } from './configs/GatewayConfig'

const noActionToUnauthorized = [endpoints.URL.auth.signIn]

let instance: null | AxiosInstance = null

export const getApiHeader = (args: IKeyArgHeader[] = []) => {
  const header = { headers: {} }

  if (!args.length) return header

  // const arrayArgValues = [store.getState().auth.credentials?.accessToken]
  const arrayArgValues = []

  args.forEach((arg: IKeyArgHeader) => {
    if (!arrayArgValues[arg.index]) return

    header.headers[arg.field] = arrayArgValues[arg.index]
  })

  return header
}

// const validateExpireTokenAuth = (date: Date, tokenName: string) => {
//   if (new Date(date) < new Date()) {
//     //update redux
//   }
// }

const checkInstanceAuth = (instanceItem: AxiosInstance, token = '') => {
  instanceItem.interceptors.request.use(
    async (config) => {
      const tokenByStotarage = getLocalStorage(constantsStorage.token)
      if (!config.headers) return Promise.reject(config.headers)

      if (token || tokenByStotarage) {
        config.headers.Authorization = `Bearer ${token || tokenByStotarage}`
      }

      // 1 - Pega o dados auth do redux
      // 2 - Verifica se o token aws está expirado chamando o metodo validateExpireTokenAuth
      // 3 - Se não, seta o token no config header --> config.headers[gatewayObj.token.param] = tokenAws
      // 4 - Se sim, chama a API da própria AWS para obter um token AWS e atualiza o redux com os novos dados.
      // Obs: Dessa forma, estamos verificando se o token aws é válido antes de fazer a requisição para a API que pretendemos chamar

      return Promise.resolve(config)
    },
    async (error) => {
      return Promise.reject(error)
    },
  )
}

const checkResponseAuth = (instanceItem: AxiosInstance) => {
  instanceItem.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.response === undefined) {
        return Promise.reject({
          message: error.message,
          config: error.config,
        })
      }

      if (error.response.status === HttpCodes.UNAUTHORIZED) {
        const urlEndpoint = error.config.url.split('?')[0]

        console.log('checkResponseAuth', {
          urlEndpoint,
          status: error.response.status,
          rule: noActionToUnauthorized.includes(urlEndpoint),
          noActionToUnauthorized,
        })

        if (!noActionToUnauthorized.includes(urlEndpoint)) {
          return (window.location.href = PathRoutes.backoffice.signOut.url)
        }
      }

      // return error.response
    },
  )
}

export const getApiInstance = (token = '', destroy = false) => {
  if (instance && !destroy) {
    checkInstanceAuth(instance, token)
    checkResponseAuth(instance)

    return instance
  }

  const INITIAL_SETTING = ApiGateway()

  if (destroy) {
    instance = axios.create(INITIAL_SETTING)
  }

  instance = axios.create(INITIAL_SETTING)

  checkInstanceAuth(instance, token)
  checkResponseAuth(instance)

  return instance
}
