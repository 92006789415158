import { t } from '@resources/translation'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import Button from '@components/UI/Button'
import Modal from '@components/Modal'
import Typography, { FontType } from '@components/UI/Typography'
import FieldSelect from '@components/UI/FieldSelect'

import * as SC from './styles'
import { IProps } from './types'
import { useCreateTable } from './useCreateTable'

const CreateTable = ({ handleModal, showModal }: IProps) => {
  const { formik, isLoading, filterProfile } = useCreateTable({
    handleModal,
    showModal,
  })

  return (
    <Modal
      display={showModal}
      onClose={handleModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={30}
        >
          {t('createTable.title')}
        </Typography>
      }
      width={650}
      height={530}
    >
      <SC.WrapperContent>
        <form onSubmit={formik.handleSubmit}>
          <Field
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            widthFull
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('createTable.input.name.label')}
              </Typography>
            }
            placeholder={t('createTable.input.name.placeholder')}
          />

          <Separator verticalSize={15} />

          <Field
            id="description"
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            inputWidth={500}
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('createTable.input.description.label')}
              </Typography>
            }
            placeholder={t('createTable.input.description.placeholder')}
          />

          <Separator verticalSize={15} />

          <SC.WrapperField>
            <FieldSelect
              label={
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={14}
                >
                  TESOUREIRO
                </Typography>
              }
              name="paymaster"
              id="paymaster"
              options={filterProfile()}
              selected={formik.values.paymaster}
              handleChange={(value) => {
                formik.setFieldValue('paymaster', value)
              }}
            />
          </SC.WrapperField>

          <Separator verticalSize={60} />

          <Button
            type="submit"
            width={384}
            height={38}
            loading={isLoading}
            disabled={!formik.isValid}
          >
            {t('createTable.buttonCreateAccount')}
          </Button>
        </form>
      </SC.WrapperContent>
    </Modal>
  )
}

export default CreateTable
