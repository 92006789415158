import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Button from '@components/UI/Button'
import Field from '@components/UI/Field'
import Loading from '@components/Loading'

import * as SC from './styles'
import { useMarketOpening } from './useMarketOpening'

const MarketOpening = () => {
  const {
    isLoading,
    data,
    formik,
    handleUpdate,
    mutateCreateIsLoading,
    createMarketOpening,
  } = useMarketOpening()

  // console.log('MarketOpening', { isLoading, data })

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('marketOpening.title')}</PageTitle>
      </SC.WrapperHeader>

      <Separator verticalSize={50} />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!data?.[0]?.uuid ? (
            <SC.WrapperLink>
              <Typography
                fontType={FontType.bold}
                color={theme.palette.neutral.regular}
                size={16}
              >
                <div style={{ width: '100%', textAlign: 'center' }}>
                  As configurações para abertura de mercado ainda não foram
                  criada.
                  <br /> Clique no link abaixo para criar e aplicar abertua e
                  fechamento no Painel de Negociação.
                </div>
              </Typography>

              <Separator verticalSize={20} />

              <SC.Link onClick={createMarketOpening}>
                {!mutateCreateIsLoading
                  ? 'Criar minhas configruações de abertura de mercado'
                  : 'Configuraando abertura de mercado, aguarde...'}
              </SC.Link>
            </SC.WrapperLink>
          ) : (
            <>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Typography
                  fontType={FontType.medium}
                  color={theme.palette.neutral.regular}
                  size={16}
                >
                  Abertura deve ser a partir de <b>09h</b> até <b>16h</b>
                  <br /> Horario de fechando até <b>17h</b>
                </Typography>
              </div>

              <Separator verticalSize={20} />
              <form onSubmit={formik.handleSubmit} noValidate className="form">
                <table
                  border={0}
                  style={{ width: '100%', textAlign: 'right', padding: 10 }}
                >
                  <thead
                    style={{ backgroundColor: theme.palette.neutral.dark }}
                  >
                    <tr>
                      <td style={{ textAlign: 'center', height: 40 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.primary.light}
                          size={14}
                        >
                          Dia da Semana
                        </Typography>
                      </td>
                      <td style={{ textAlign: 'center', height: 40 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.primary.light}
                          size={14}
                        >
                          Abertura
                        </Typography>
                      </td>
                      <td style={{ textAlign: 'center', height: 40 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.primary.light}
                          size={14}
                        >
                          Fechamento
                        </Typography>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left', height: 60 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.neutral.regular}
                          size={14}
                        >
                          <Separator verticalSize={10} />
                          &nbsp;Domingo
                        </Typography>

                        <Separator verticalSize={10} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`sundayOpening`}
                          mask="99:99"
                          {...formik.getFieldProps('sundayOpening')}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`sundayClosing`}
                          mask="99:99"
                          {...formik.getFieldProps('sundayClosing')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', height: 60 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.neutral.regular}
                          size={14}
                        >
                          <Separator verticalSize={10} />
                          &nbsp;Segunda-Feira
                        </Typography>

                        <Separator verticalSize={10} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`mondayOpening`}
                          mask="99:99"
                          {...formik.getFieldProps('mondayOpening')}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`mondayClosing`}
                          mask="99:99"
                          {...formik.getFieldProps('mondayClosing')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', height: 60 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.neutral.regular}
                          size={14}
                        >
                          <Separator verticalSize={10} />
                          &nbsp;Terça-Feira
                        </Typography>

                        <Separator verticalSize={10} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`tuesdayOpening`}
                          mask="99:99"
                          {...formik.getFieldProps('tuesdayOpening')}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`tuesdayClosing`}
                          mask="99:99"
                          {...formik.getFieldProps('tuesdayClosing')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', height: 60 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.neutral.regular}
                          size={14}
                        >
                          <Separator verticalSize={10} />
                          &nbsp;Quarta-Feira
                        </Typography>

                        <Separator verticalSize={10} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`wednesdayOpening`}
                          mask="99:99"
                          {...formik.getFieldProps('wednesdayOpening')}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`wednesdayClosing`}
                          mask="99:99"
                          {...formik.getFieldProps('wednesdayClosing')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', height: 60 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.neutral.regular}
                          size={14}
                        >
                          <Separator verticalSize={10} />
                          &nbsp;Quinta-Feira
                        </Typography>

                        <Separator verticalSize={10} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`thursdayOpening`}
                          mask="99:99"
                          {...formik.getFieldProps('thursdayOpening')}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`thursdayClosing`}
                          mask="99:99"
                          {...formik.getFieldProps('thursdayClosing')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', height: 60 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.neutral.regular}
                          size={14}
                        >
                          <Separator verticalSize={10} />
                          &nbsp;Sexta-Feira
                        </Typography>

                        <Separator verticalSize={10} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`fridayOpening`}
                          mask="99:99"
                          {...formik.getFieldProps('fridayOpening')}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`fridayClosing`}
                          mask="99:99"
                          {...formik.getFieldProps('fridayClosing')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', height: 60 }}>
                        <Typography
                          fontType={FontType.bold}
                          color={theme.palette.neutral.regular}
                          size={14}
                        >
                          <Separator verticalSize={10} />
                          &nbsp;Sábado
                        </Typography>

                        <Separator verticalSize={10} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`saturdayOpening`}
                          mask="99:99"
                          {...formik.getFieldProps('saturdayOpening')}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Field
                          id={`saturdayClosing`}
                          mask="99:99"
                          {...formik.getFieldProps('saturdayClosing')}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: 'right',
                          height: 60,
                          paddingRight: 70,
                          paddingTop: 40,
                        }}
                      >
                        <Button
                          disabled={!formik.isValid}
                          onClick={handleUpdate}
                        >
                          Atualizar
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </>
          )}
        </>
      )}
    </SC.Container>
  )
}

export default MarketOpening
