import { AxiosResponse } from 'axios'
import { endpoints } from '@services/endpoints'
import { getApiInstance } from '@services/api'
import {
  IMarketOpening,
  IRequestCreateMarketOpening,
  IRequestUpdateMarketOpening,
} from '@resources/types/services/marketOpening'
import { translateUrlParam } from '@resources/utils/forString'

export const MarketOpeningService = {
  crateByCustomer: async (
    data: IRequestCreateMarketOpening,
  ): Promise<IMarketOpening> => {
    const api = getApiInstance()

    const response: AxiosResponse = await api.post(
      endpoints.URL.marketOpening.createByCustomer,
      data,
    )

    return response.data
  },

  getByCustomer: async (customerId: string): Promise<IMarketOpening> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.marketOpening.getByCustomer, {
      customerId,
    })

    const response: AxiosResponse = await api.get(url)

    return response.data
  },

  updateById: async ({
    uuid,
    data,
  }: IRequestUpdateMarketOpening): Promise<IMarketOpening> => {
    const api = getApiInstance()

    const url = translateUrlParam(endpoints.URL.marketOpening.update, {
      uuid,
    })

    const response: AxiosResponse = await api.patch(url, data)

    return response.data
  },
}
