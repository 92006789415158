/* eslint-disable @typescript-eslint/no-unused-vars */
import { createElement, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { IViewProps } from './types'
import View from './view'

const HeaderContainer = () => {
  const [fixedTop, setFixedTop] = useState<boolean>(false)

  const [menuOpen, setMenuOpen] = useState(isMobile ? false : true)

  const handleClick = () => {
    setMenuOpen(!menuOpen)
  }

  const handlerScroll = () => {
    if (window.scrollY >= 500) {
      setFixedTop(true)
    }

    if (window.scrollY <= 100) {
      setFixedTop(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll)

    return () => {
      window.removeEventListener('scroll', handlerScroll)
    }
  }, [])

  const viewProps: IViewProps = {
    fixedTop,
    handleClick,
    menuOpen,
  }

  return createElement(View, viewProps)
}

export default HeaderContainer
