import { useQuery } from 'react-query'

import { OperationManagementService } from '@services/operationManagement'
import { IOperationManagementPerfomanceReportResponse } from '@resources/types/services/operationManagement'

export const useOperationManagementPerfomanceReport = (customerId: string) => {
  return useQuery<IOperationManagementPerfomanceReportResponse, Error>(
    ['operationManagementPerfomanceReport', customerId],
    () => {
      return OperationManagementService.perfomanceReport(customerId)
    },
    {
      enabled: !!customerId,
    },
  )
}
