import { TranslationsManager } from '@resources/translation'

export const registerEnTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('en', 'signIn', {
    title: 'Login Exchange SPOT',
    input: {
      email: {
        label: 'Email',
        placeholder: 'Enter your email',
      },
      password: {
        label: 'Password',
        placeholder: 'Type your password',
      },
    },
    buttonSign: 'Enter',
    buttonForgot: 'Forgot password',
  })
}
