import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const Container = styled.div<DefaultProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 130px;
`

export const Content = styled.div<DefaultProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  width: ${({ theme }) => theme.layout.desktop.size.max};
  padding: 0;
  margin: 0;
`

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  margin-right: 10px;
`

export const WrapperMenu = styled.div`
  width: 80%;
`
