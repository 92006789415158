import { useEffect, useState } from 'react'

import { useGetSignalListByCategory } from '@hooks/signal/useSignal'
import {
  cookieVariabels,
  setCookie,
  getCookie,
} from '@resources/helpers/application/cookie'

import { OptionType } from './SelectSignalToView/types'
import { SignalToViewOperation } from './types'

const INITIAL_DETAIL_MODAL = {
  name: '',
  title: '',
  decsription: '',
}

export const useTrendingMarket = () => {
  const getSignalFromCookie =
    getCookie(cookieVariabels.exchangeSignalSelected)?.split(',') || []

  const [categorySelected, setCategorySelected] = useState<string>('teahupoo')
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [detailModal, setDetailModal] =
    useState<Record<string, string>>(INITIAL_DETAIL_MODAL)

  const { data, isLoading } = useGetSignalListByCategory(categorySelected)
  const [dataOptionsSelected, setDataOptionsSelected] =
    useState<string[]>(getSignalFromCookie)

  const handleSelectSignal = async (
    optionSelected: string,
    action: keyof typeof SignalToViewOperation,
  ) => {
    try {
      if (action === SignalToViewOperation.add) {
        setDataOptionsSelected((itens) => [...itens, optionSelected])
        setCookie(
          cookieVariabels.exchangeSignalSelected,
          [...dataOptionsSelected, optionSelected].join(','),
        )
      }

      if (action === SignalToViewOperation.remove) {
        const filterOptions = dataOptionsSelected.filter(
          (item) => item !== optionSelected,
        )

        setDataOptionsSelected(filterOptions)
        setCookie(
          cookieVariabels.exchangeSignalSelected,
          filterOptions.join(','),
        )
      }

      console.log('Selecte Plan:', optionSelected)
    } catch (error) {
      console.error('Selecte Plan:', error)
    }
  }

  const handleSignalMarking = (option: string) => {
    if (!dataOptionsSelected.length) return true

    return dataOptionsSelected.includes(option)
  }

  const dataOptions: OptionType[] =
    data?.map((item) => ({
      id: item.uuid,
      name: item.strategy.name,
    })) || []

  const tabs = [
    {
      label: 'TEAHUPOO',
      key: 'teahupoo',
      description: {
        title: 'Surfando nas Ondas da Média Móvel Exponencial',
        text: '<!DOCTYPE html> <html> <body> <h3>Surfando nas Ondas da Média Móvel Exponencial </h3> <p>Inspirada em Larry Williams, um ícone no mercado financeiro, a Estratégia Teahupoo destaca-se pela utilização da média móvel exponencial de 9 períodos. Trabalhando com pivôs e "pré-pivôs", antecipamos entradas para acionar uma série de gatilhos. Ao observar a queda da média móvel, aguardamos o fechamento de um candle acima dela para acionar o Setup Teahupoo "de compra". O inverso é verdadeiro para vendas. Uma abordagem simples, mas poderosa quando integrada ao contexto de mercado. </p> <p>Ao seguir os passos de um mestre como Larry Williams, capturamos a atenção ao oferecer uma estratégia clara e eficaz, destacando a importância das médias móveis exponenciais e a antecipação de entradas.</p> </body> </html>',
      },
    },
    {
      label: 'CLOUDBREAK',
      key: 'cloudbreak',
      description: {
        title: 'Correção com Elegância',
        text: '<!DOCTYPE html> <html> <body> <h3>Correção com Elegância</h3> <p>Inspirada em Larry Williams, a Estratégia Cloudbreak é um setup de correção refinado. Seu funcionamento envolve a interseção da média móvel com a mínima do candle anterior. Com a média ascendente, a entrada é ajustada para o próximo candle acima da média móvel, proporcionando uma operação contínua. </p> <p>Ao apresentar uma estratégia de correção refinada, alinhada com o movimento principal do mercado, envolvemos os operadores de câmbio em uma narrativa de habilidade e oportunidade.</p> </body> </html>',
      },
    },
    {
      label: 'PIPELINE',
      key: 'pipeline',
      description: {
        title: 'Correção com Resiliência',
        text: '<!DOCTYPE html> <html> <body> <h3>Correção com Resiliência</h3> <p>O Pipeline é um setup de correção diferenciado, ocorrendo quando há um fechamento abaixo do candle anterior, não necessariamente abaixo da mínima, mas para se tornar uma estratégia Pipeline, precisa ter mais um fechamento abaixo do candle de referência. Destaca-se pela resiliência em sua aplicação.</p> <p>Ao introduzir uma estratégia de correção com nuances específicas, demonstramos a resiliência do Pipeline, gerando interesse e engajamento ao explorar variações em setups tradicionais.</p> </body> </html>',
      },
    },
    {
      label: 'BELLS',
      key: 'bells',
      description: {
        title: 'Harmonia entre Fechamentos e Médias Móveis',
        text: '<!DOCTYPE html> <html> <body> <h3>Harmonia entre Fechamentos e Médias Móveis</h3> <p>A Estratégia Bells ocorre quando há um fechamento de candle que vira a média móvel para baixo, seguido por um candle que não perde a mínima, revertendo a média móvel para cima. Essa estratégia destaca a harmonia entre fechamentos e médias móveis, mantendo o movimento prévio.</p> <p>Ao narrar a harmonia entre fechamentos e médias móveis, envolvemos os operadores de câmbio em uma história cativante, destacando a importância do timing na maximização de oportunidades.</p> </body>',
      },
    },
    {
      label: 'PENICHE',
      key: 'peniche',
      description: {
        title: 'Precisão na Média Aritmética de 21 Períodos',
        text: '<!DOCTYPE html> <html> <body> <h3>Precisão na Média Aritmética de 21 Períodos</h3> <p>O Setup Peniche é caracterizado por sua precisão em pontos contínuos. Com a média aritmética de 21 períodos ascendente, aguardamos o recuo do preço para essa média, marcando a máxima quando o candle toca a MMA21. A superação desse ponto aciona o gatilho de compra, com o stop sempre posicionado na mínima do movimento corretivo.</p> <p>Ao apresentar uma estratégia de ponto contínuo baseada na média aritmética, destacamos a precisão e o controle, conectando os investidores a uma abordagem que equilibra riscos e oportunidades.</p> </body> </html>',
      },
    },
  ]

  const handleSelectTab = (tabKey: string) => {
    setCategorySelected(tabKey)

    const findTabBydCategory = tabs.find((item) => item.key === tabKey)
    setDetailModal(
      {
        ...findTabBydCategory?.description,
        label: findTabBydCategory?.label || '',
      } || INITIAL_DETAIL_MODAL,
    )
  }

  const handleDetailModal = () => {
    setShowDetailModal(!showDetailModal)
  }

  useEffect(() => {
    handleSelectTab('teahupoo')
  }, [])

  return {
    data,
    isLoading,
    handleSelectSignal,
    dataOptions,
    dataOptionsSelected,
    handleSignalMarking,
    tabs,
    handleSelectTab,
    handleDetailModal,
    detailModal,
    showDetailModal,
  }
}
