import { css, keyframes } from '@emotion/react'

import { theme } from '@resources/theme'

type Css = ReturnType<typeof css>

export default {
  skeletonKeyFrames: () => keyframes`
    0% {
      opacity: 0.1;
      background-color: ${theme.palette.neutral.regular};
    }
    25% {
      opacity: 0.2;
      background-color: ${theme.palette.neutral.regular};
    }
    50% {
      opacity: 0.4;
      background-color: ${theme.palette.neutral.regular};
    }
    75% {
      opacity: 0.2;
      background-color: ${theme.palette.neutral.regular};
    }
    100% {
      opacity: 0.1;
      background-color: ${theme.palette.neutral.regular};
    }
  `,

  cssAnimation: () => keyframes`
    to { opacity: 1; }
  `,

  transition: (time = '0.25s') => css`
    -webkit-transition: all ${time};
    -moz-transition: all ${time};
    transition: all ${time};
  `,

  transitionIn: (type = 'all', time = '0.25s') => css`
    -webkit-transition: ${type} ${time};
    -moz-transition: ${type} ${time};
    transition: ${type} ${time};
  `,

  ellipsis: () => css`
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
    overflow: hidden;
    line-height: 1rem;
  `,

  disabledStyle: (disabled = false) => css`
    opacity: ${disabled ? 0.6 : ''};
    cursor: ${disabled && `default`};
  `,

  shadow: () => css`
    box-shadow: 0px -9.14237px 27.4271px rgba(0, 0, 0, 0.2);
  `,

  gradient: (type): Css =>
    ({
      primary: css`
        background-image: linear-gradient(
          180deg,
          ${theme.palette.gradiente.primary.light},
          ${theme.palette.gradiente.primary.dark},
          ${theme.palette.gradiente.primary.darkest}
        );
      `,
      secondary: css`
        background-image: linear-gradient(
          180deg,
          ${theme.palette.gradiente.primary.light},
          ${theme.palette.gradiente.primary.dark},
          ${theme.palette.gradiente.primary.darkest}
        );
      `,
      tertiary: css`
        background-image: linear-gradient(
          180deg,
          ${theme.palette.gradiente.tertiary.light},
          ${theme.palette.gradiente.tertiary.darkest}
        );
      `,
    }[type]),

  fonts: (type): Css =>
    ({
      extraBold: css`
        font-family: ${theme.typography.fonts.extraBold};
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: ${theme.palette.primary.regular};
      `,
      black: css`
        font-family: ${theme.typography.fonts.black};
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: ${theme.palette.primary.regular};
      `,
      bold: css`
        font-family: ${theme.typography.fonts.bold};
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: ${theme.palette.primary.regular};
      `,
      semiBold: css`
        font-family: ${theme.typography.fonts.semiBold};
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: ${theme.palette.primary.regular};
      `,
      medium: css`
        font-family: ${theme.typography.fonts.medium};
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: ${theme.palette.neutral.darkest};
      `,
      regular: css`
        font-family: ${theme.typography.fonts.regular};
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: ${theme.palette.neutral.darkest};
      `,
      light: css`
        font-family: ${theme.typography.fonts.light};
        font-weight: 300;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.07em;
        color: ${theme.palette.neutral.darkest};
      `,
      extraLight: css`
        font-family: ${theme.typography.fonts.extraLight};
        font-weight: 200;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.07em;
        color: ${theme.palette.neutral.darkest};
      `,
      thin: css`
        font-family: ${theme.typography.fonts.thin};
        font-weight: 100;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.07em;
        color: ${theme.palette.primary.light};
      `,
    }[type]),

  button: (type): Css =>
    ({
      primary: css``,
      secondary: css``,
    }[type]),
}
