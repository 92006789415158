import styled from '@emotion/styled'
import { DefaultProps } from '@resources/types'

export const Container = styled.div<DefaultProps>`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.palette.primary.lightest};

  font-size: 16px;

  width: 100%;
`

export const WrapperContent = styled.div<DefaultProps>`
  display: flex;

  align-items: center;

  justify-content: space-between;

  cursor: pointer;

  width: 95%;

  margin: 10px 0;

  padding: 10px;

  &:hover {
    border-radius: 30px;
    background: ${({ theme }) => theme.palette.neutral.dark};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primary.lightest};
  }
`
export const Title = styled.div`
  margin: 10px 0;

  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 18px;
  font-weight: bold;

  width: 95%;
`
