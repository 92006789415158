/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Fragment } from 'react'
import { useQueryClient } from 'react-query'
import dayjs from 'dayjs'

import Modal from '@components/Modal'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import { generateKey } from '@resources/utils/generateKey'
import Button from '@components/UI/Button'
import { useCreateOperationMatching } from '@hooks/operationMatching/useOperationMatching'
import { useAuthMethods } from '@store/auth'
import { useAlert } from 'react-alert'

import { IProps, IOperation } from './types'
import * as SC from './styles'

const ModalOperationMatching = ({ display, closeModal, data }: IProps) => {
  if (!display) return null

  const { profile } = useAuthMethods()
  const alert = useAlert()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useCreateOperationMatching()

  const totalBuy = data?.buy?.reduce(
    (accumulator, currentValue) =>
      Number(accumulator) + Number(currentValue.dealtAmount),
    0,
  )

  const totalSell = data?.sell?.reduce(
    (accumulator, currentValue) =>
      Number(accumulator) + Number(currentValue.dealtAmount),
    0,
  )

  const balance = totalSell + totalBuy

  const formatReal = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  const getColorBg = (value: number) => {
    return value % 2 == 1 ? `${theme.palette.neutral.dark}50` : 'transparent'
  }

  const formatDolar = (value) => {
    if (!value) return '0.00'

    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }

  const onSuccess = () => {
    alert.success('Casamento de operações registrado com sucesso!')

    queryClient.invalidateQueries([
      'operationManagementPerfomanceReport',
      profile.parentCustomer.uuid,
    ])

    queryClient.invalidateQueries([
      'perfomanceReportFeeByCustomer',
      profile.parentCustomer.uuid,
    ])

    closeModal()
  }
  const onError = (error) => {
    console.log('onError: ', error)

    alert.error(
      'Houve uma erro registrar casamento de operações. Tente novamente!',
    )
  }

  const handleMatchingOperation = () => {
    const prepareData = {
      customer: profile.parentCustomer.uuid,
      operations: [] as Array<number>,
    }

    data?.buy?.forEach((item: IOperation) => {
      const id = item.id
      prepareData.operations.push(id)
    })

    data?.sell?.forEach((item: any) => {
      const id = item.id
      prepareData.operations.push(id)
    })

    console.log('handleMatchingOperation', prepareData)

    mutate(prepareData, {
      onSuccess,
      onError,
    })
  }

  return (
    <Modal
      display={display}
      onClose={isLoading ? () => null : closeModal}
      title={
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={25}
        >
          Casamento de Operações / {data?.dealt}
        </Typography>
      }
      width={900}
      height={620}
    >
      <SC.SectionMain>
        <SC.SectionValues>
          <SC.WrapperField>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              size={16}
            >
              Total de Compra
            </Typography>
            <Separator verticalSize={5} />
            <Typography
              fontType={FontType.regular}
              color={theme.palette.primary.light}
              size={16}
            >
              {formatReal(totalBuy)}
            </Typography>
          </SC.WrapperField>

          <SC.WrapperField>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              size={16}
            >
              Total de Venda
            </Typography>
            <Separator verticalSize={5} />
            <Typography
              fontType={FontType.regular}
              color={theme.palette.primary.light}
              size={16}
            >
              {formatReal(totalSell)}
            </Typography>
          </SC.WrapperField>

          <SC.WrapperField>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.neutral.regular}
              size={16}
            >
              Saldo da Operação
            </Typography>
            <Separator verticalSize={5} />

            <SC.WrapperDescription>
              <Typography
                fontType={FontType.regular}
                color={theme.palette.primary.light}
                size={14}
              >
                {formatReal(balance)}
              </Typography>
            </SC.WrapperDescription>
          </SC.WrapperField>
        </SC.SectionValues>

        <SC.WrapperTable>
          <table
            border={0}
            style={{
              width: '100%',
              textAlign: 'right',
            }}
          >
            <Fragment key={generateKey()}>
              <thead>
                <tr style={{ backgroundColor: theme.palette.neutral.dark }}>
                  <td style={{ textAlign: 'center', height: 40 }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      ID
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center', height: 40 }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      TX Média
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      VLR Estrang
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      VLR Reais
                    </Typography>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      Data de Registro
                    </Typography>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td
                    colSpan={5}
                    style={{
                      textAlign: 'left',
                      height: 30,
                      background: `${theme.palette.success.darkest}`,
                    }}
                  >
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      &nbsp;Compra
                    </Typography>
                  </td>
                </tr>

                {data?.buy.map((buy, indexBuy) => (
                  <tr
                    key={buy.id}
                    style={{
                      height: 30,
                    }}
                  >
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(indexBuy),
                      }}
                    >
                      <Typography
                        fontType={FontType.bold}
                        color={theme.palette.primary.light}
                        size={14}
                      >
                        &nbsp;{buy.id}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(indexBuy),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{buy.allInRate}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        background: getColorBg(indexBuy),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{formatDolar(buy.dealtAmount)}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        background: getColorBg(indexBuy),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{formatReal(buy.contraAmount)}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(indexBuy),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;
                        {dayjs(buy.createdAt).format('DD/MM/YYYY - HH[h]ss')}
                      </Typography>
                    </td>
                  </tr>
                ))}

                {!data?.buy.length && (
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center' }}>
                      <Typography
                        fontType={FontType.regular}
                        color={theme.palette.neutral.regular}
                        size={14}
                      >
                        &nbsp;Sem dados
                      </Typography>

                      <Separator verticalSize={10} />
                    </td>
                  </tr>
                )}

                <tr>
                  {/* <td style={{ textAlign: 'center', width: 50 }}></td> */}
                  <td
                    colSpan={5}
                    style={{
                      textAlign: 'left',
                      height: 30,
                      background: `${theme.palette.error.light}`,
                    }}
                  >
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      &nbsp;Venda
                    </Typography>
                  </td>
                </tr>

                {data?.sell.map((sell, indexSell) => (
                  <tr
                    key={sell.id}
                    style={{
                      height: 30,
                    }}
                  >
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(indexSell),
                      }}
                    >
                      <Typography
                        fontType={FontType.bold}
                        color={theme.palette.primary.light}
                        size={14}
                      >
                        &nbsp;{sell.id}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(indexSell),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{sell.allInRate}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        background: getColorBg(indexSell),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{formatDolar(sell.dealtAmount)}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        background: getColorBg(indexSell),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        &nbsp;{formatReal(sell.contraAmount)}
                      </Typography>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        background: getColorBg(indexSell),
                      }}
                    >
                      <Typography
                        fontType={FontType.medium}
                        color={theme.palette.primary.light}
                        size={12}
                      >
                        {dayjs(sell.createdAt).format('DD/MM/YYYY - HH[h]ss')}
                      </Typography>
                    </td>
                  </tr>
                ))}

                {!data?.sell.length && (
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center' }}>
                      <Typography
                        fontType={FontType.regular}
                        color={theme.palette.neutral.regular}
                        size={14}
                      >
                        &nbsp;Sem negociação
                      </Typography>

                      <Separator verticalSize={10} />
                    </td>
                  </tr>
                )}
              </tbody>
            </Fragment>
          </table>
        </SC.WrapperTable>

        <SC.WrapperFooter>
          <Button
            secondary
            width={300}
            height={40}
            primary
            onClick={closeModal}
            disabled={isLoading}
          >
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.regular}
              size={16}
            >
              Cancelar
            </Typography>
          </Button>

          <Button
            width={300}
            height={40}
            onClick={handleMatchingOperation}
            disabled={isLoading}
            loading={isLoading}
          >
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.regular}
              size={16}
            >
              Confirmar
            </Typography>
          </Button>
        </SC.WrapperFooter>
      </SC.SectionMain>
    </Modal>
  )
}

export default ModalOperationMatching
