import React from 'react'

import { t } from '@resources/translation'

import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import Field from '@components/UI/Field'
import { EInputType } from '@components/UI/Field/types'
import Button from '@components/UI/Button'
import PathRoutes from '@route/PathRoutes'

import * as SC from './styles'
import { IViewProps } from './types'

const ForgotPassword = ({ formik, isLoading }: IViewProps) => {
  return (
    <SC.WrapperContent>
      <Typography
        fontType={FontType.bold}
        size={35}
        color={theme.palette.primary.light}
      >
        {t('forgetPassword.title')}
      </Typography>

      <Separator verticalSize={15} />

      <Typography
        color={theme.palette.neutral.regular}
        fontType={FontType.medium}
        size={16}
        align="center"
      >
        {t('forgetPassword.subTitle')}
      </Typography>

      <Separator verticalSize={20} />

      <SC.WrapperForm>
        <form onSubmit={formik.handleSubmit}>
          <Field
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            type={EInputType.DOCUMENT}
            maxLength={100}
            label={
              <Typography
                fontType={FontType.medium}
                color={theme.palette.neutral.regular}
                size={14}
              >
                {t('forgetPassword.input.email.label')}
              </Typography>
            }
            placeholder={t('forgetPassword.input.email.placeholder')}
            widthFull
          />

          <Separator verticalSize={50} />

          <Button
            widthFull
            type="submit"
            loading={isLoading}
            disabled={!formik.isValid || !formik.dirty}
          >
            {t('forgetPassword.buttonForgot')}
          </Button>
        </form>
      </SC.WrapperForm>

      <SC.WrapperLink>
        <SC.LinkMenu
          nav-item="link"
          to={PathRoutes.backoffice.signIn.url}
          target=""
        >
          {t('forgetPassword.buttonSign')}
        </SC.LinkMenu>
      </SC.WrapperLink>
    </SC.WrapperContent>
  )
}

export default ForgotPassword
