import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
// import Loading from '@components/Loading'
import Button from '@components/UI/Button'
import DataGrid from '@components/UI/DataGrid'
import ModalConfirmDelete from '@components/ModalConfirmDelete'

import SignUpCustomer from './SignUpCustomer'
import UpdateCustomer from './UpdateCustomer'
import { useCustomerContainer } from './useCustomerContainer'
import * as SC from './styles'

const Customer = () => {
  const {
    isLoading,
    handleModal,
    showModal,
    header,
    handleData,
    userData,
    handleUpdateModal,
    showDeleteModal,
    handleDeleteModal,
    fetchDeleteModal,
    isLoadingDeleteUser,
  } = useCustomerContainer()

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('customer.title')}</PageTitle>

        <Button onClick={handleModal}>{t('customer.buttonAdd')}</Button>
      </SC.WrapperHeader>

      <Separator verticalSize={8} />

      <DataGrid header={header} data={handleData()} loading={isLoading} />

      {!!showModal && (
        <SignUpCustomer showModal={showModal} handleModal={handleModal} />
      )}

      {!!userData && (
        <UpdateCustomer
          showModal={!!userData}
          handleModal={handleUpdateModal}
          data={userData}
        />
      )}

      {!!showDeleteModal && (
        <ModalConfirmDelete
          showModal={showDeleteModal}
          onCloseModal={!isLoadingDeleteUser ? handleDeleteModal : () => ''}
          onReturnYes={fetchDeleteModal}
          onReturnNo={handleDeleteModal}
        />
      )}
    </SC.Container>
  )
}

export default Customer
