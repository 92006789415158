export const formatReal = (value) => {
  if (!value) return '0.00'

  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

export const currencyFormatterSixPlaces = (value: string | number) => {
  let number = String(value).padStart(6, '0')

  if (String(value).includes('.')) {
    const valueSplit = String(value).split('.')

    let number = String(valueSplit[1]).padEnd(6, '0')

    if (Number(number.replace(number.slice(-6), '')) === 0) {
      number = number.slice(-6)
    }

    const integerDigits = valueSplit[0]
    const decimalDigits = String(number).slice(-6)

    const integerDigitsWithouZero =
      String(integerDigits).slice(0, 1) === '0' &&
      String(integerDigits).length > 1
        ? String(integerDigits).slice(1, 20)
        : integerDigits

    return `${integerDigitsWithouZero}.${decimalDigits}`
  }

  if (Number(number.replace(number.slice(-6), '')) === 0) {
    number = number.slice(-6)
  }

  const integerDigits =
    Number(number.replace(number.slice(-6), '')) === 0 ||
    String(number).length < 7
      ? 0
      : String(value).replace(String(value).slice(-6), '')
  const decimalDigits = number.slice(-6)

  const integerDigitsWithouZero =
    String(integerDigits).slice(0, 1) === '0' &&
    String(integerDigits).length > 1
      ? String(integerDigits).slice(1, 20)
      : integerDigits

  return `${integerDigitsWithouZero}.${decimalDigits}`
}
