export enum Fonts {
  extraBold = 'extraBold',
  black = 'black',
  bold = 'bold',
  semiBold = 'semiBold',
  medium = 'medium',
  regular = 'regular',
  light = 'light',
  extraLight = 'extraLight',
  thin = 'thin',
}
