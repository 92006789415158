import { t } from '@resources/translation'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import Separator from '@components/UI/Separator'
import PivotPoint from '@components/PivotPoint'

import * as SC from './styles'
import { IProps } from './types'

const SignalCard = ({
  name,
  marketPosition,
  orderPrice,
  pivotPoint,
}: IProps) => {
  return (
    <SC.SignalCard>
      <SC.WrapperHorizontal>
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.lightest}
          size={18}
        >
          <SC.WrapperHorizontal>{name}</SC.WrapperHorizontal>
        </Typography>

        <SC.WrapperSemaphore>
          <SC.Semaphore color={marketPosition} />
        </SC.WrapperSemaphore>
      </SC.WrapperHorizontal>

      <Separator verticalSize={10} />

      <SC.WrapperHorizontal>
        <SC.WrapperVertical>
          <SC.WrapperBox>
            <SC.BoxValue>
              <Typography
                fontType={FontType.medium}
                color={theme.palette.primary.lightest}
                size={13}
              >
                {marketPosition === 'flat' || !orderPrice
                  ? '-'
                  : `${String(orderPrice).slice(0, 8)}`}
              </Typography>
            </SC.BoxValue>
            <Typography
              fontType={FontType.medium}
              color={theme.palette.primary.lightest}
              size={10}
            >
              {t('strategiesMonitor.signalCard.prohibited')}
            </Typography>
          </SC.WrapperBox>
        </SC.WrapperVertical>

        <PivotPoint singularData={pivotPoint} />
      </SC.WrapperHorizontal>
    </SC.SignalCard>
  )
}

export default SignalCard
