import Icon from '@components/UI/Icon'

import * as SC from './styles'
import { IProps } from './types'

const Modal = ({
  display = true,
  onClose = null,
  height = 400,
  width = 500,
  children = '',
  title = '',
}: IProps) => {
  return (
    <SC.Container isOpen={display}>
      <SC.WrapperModal isOpen={display} height={height} width={width}>
        <SC.WrapperContent height={height} width={width}>
          <SC.Header>
            {title}

            {!!onClose && (
              <SC.CloseButton data-testid="close" onClick={onClose}>
                <Icon name="closeSVG" />
              </SC.CloseButton>
            )}
          </SC.Header>

          <SC.Body data-testid="content">{!!children && children}</SC.Body>
        </SC.WrapperContent>
      </SC.WrapperModal>
    </SC.Container>
  )
}

export default Modal
